import React from "react";
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { AppState } from "../../store/AppState";
import isRelevantStructure from "./utils/isRelevantStructure";
import { changeOption, changeAssetClassTree } from "./slice";
import useInstruments from "./useInstruments";
import ExcelExportButton from "./ExcelExportButton";
import useCompareSelector from "./useCompareSelector";
import AllocationType from "../../types/AllocationType";
import InstrumentAutocomplete, { InstrumentSearchResult } from "../shared/InstrumentAutocomplete";

const PREFIX = 'OptionsPanel';
const classes = {
  flexRow: `${PREFIX}-flexRow`,
  rightMost: `${PREFIX}-rightMost`,
  compensateCheckboxOffset: `${PREFIX}-compensateCheckboxOffset`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.flexRow}`]: {
    paddingBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    "& > div + div": {
      marginLeft: theme.spacing(2),
    },
    // following sub-styles are required to enforce equal height for all controls in this row
    "& .MuiInputBase-input": {
      height: "calc(2.3rem - 1px)",
    },
    "& .MuiSelect-root": {
      height: "2.3rem",
    },
  },

  [`& .${classes.rightMost}`]: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.compensateCheckboxOffset}`]: {
    "& .MuiCheckbox-root": {
      marginLeft: 7, // needs to be pixels
    },
  }
}));

export default function OptionsPanel() {
  const allocationType = useCompareSelector((s) => s.allocationType);
  const isMopo = allocationType === AllocationType.ModelPortfolio;
  const isTca = allocationType === AllocationType.Tca;
  const allocationIds = useCompareSelector((s) => s.allocations).map((a: { Id: any; }) => a.Id);
  const options = useCompareSelector((s) => s.options);
  const isAnyAllocationInEditByUser = useCompareSelector((s) => s.editInfo.some((i: { isInEditByUser: any; }) => i.isInEditByUser));
  const trees = useSelector((s: AppState) => s.masterData.trees)
    .filter(isRelevantStructure(allocationType))
    .map((s) => s.Structure);

  const dispatch = useDispatch();
  const { addInstrument, addInstrumentViaDialog } = useInstruments();
  const onSelectInstrument = ({ instrumentId }: InstrumentSearchResult) => {
    if (isMopo || isTca) {
      addInstrument(instrumentId);
    } else {
      addInstrumentViaDialog(instrumentId);
    }
  };
  const toggleShowIc = () => dispatch(changeOption({ showIc: !options.showIc }));
  const toggleShowDyn = () => dispatch(changeOption({ showDyn: !options.showDyn }));
  const toggleShowAvg = () => dispatch(changeOption({ showAvg: !options.showAvg }));
  const toggleShowEmpty = () => dispatch(changeOption({ showEmptyAssetClasses: !options.showEmptyAssetClasses }));
  const setAssetClassTree = (e: SelectChangeEvent<any>) => dispatch(changeAssetClassTree({ assetClassTree: e.target.value }));

  return (
    <Root>
      <div className={classes.flexRow}>
        <FormControl id="tree-selection" variant="outlined">
          <InputLabel id="tree-selection-label">Tree</InputLabel>
          <Select
            labelId="tree-selection-label"
            label="Tree"
            style={{ width: isMopo ? 100 : 160 }}
            value={options.assetClassTree}
            onChange={setAssetClassTree}
            disabled={trees.length === 1}
          >
            {trees.map((t) => (
              <MenuItem key={t} value={t}>
                {t}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isAnyAllocationInEditByUser && !isTca && <InstrumentAutocomplete label="Add Instrument" onSelect={onSelectInstrument} />}
        <div className={classes.rightMost}>
          <ExcelExportButton isMopo={isMopo} allocationIds={allocationIds} includeDynamic={options.showDyn}/>
        </div>
      </div>
      <div className={classes.compensateCheckboxOffset}>
        {isMopo ? (
          <FormControl>
            <FormGroup row>
              <FormControlLabel
                id="show-ic"
                control={<Checkbox checked={options.showIc} onClick={toggleShowIc} />}
                label="IC Target"
              />
              <FormControlLabel
                id="show-dyn"
                control={<Checkbox checked={options.showDyn} onClick={toggleShowDyn} />}
                label="Dynamic"
              />
              <FormControlLabel
                id="show-avg"
                control={<Checkbox checked={options.showAvg} onClick={toggleShowAvg} />}
                label="Average"
              />
            </FormGroup>
          </FormControl>
        ) : isTca ? (
          <></>
        ) : (
          <FormControlLabel
            id="show-dyn"
            control={<Checkbox checked={options.showDyn} onClick={toggleShowDyn} />}
            label="Show dynamic weights"
          />
        )}
      </div>
      {!isTca && (
        <div className={classes.compensateCheckboxOffset}>
          <FormControlLabel
            id="show-empty"
            control={<Checkbox checked={options.showEmptyAssetClasses} onClick={toggleShowEmpty} />}
            label="Show empty asset classes"
          />
        </div>
      )}
    </Root>
  );
}
