import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles"
import * as React from "react";
import { FilterSettings, InstrumentList, InstrumentListItem, ServerMessageSeverity } from "../../types/InstrumentList";
import sortBy from "lodash/sortBy";
import concat from "lodash/concat";
import uniq from "lodash/uniq";
import cloneDeep from "lodash/cloneDeep";
import MultiSelect from "../shared/MultiSelect";
import { countBySeverity, filterItems } from "./utils";

/**
 * Components provides a filter for Asset Sub Class & Status for InstrumentList-Items.
 */

const PREFIX = 'ItemFilter';
const classes = {
  root: `${PREFIX}-root`,
  formGroupCheckboxReset: `${PREFIX}-formGroupCheckboxReset`,
  statusLabel: `${PREFIX}-statusLabel`,
  statusFromControl: `${PREFIX}-statusFromControl`,
}
const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    maxWidth: "80%",
    marginTop: theme.spacing(5),
  },
  [`& .${classes.formGroupCheckboxReset}`]: {
    "& .MuiCheckbox-root": {
      padding: theme.spacing(1),
    },
  },
  [`& .${classes.statusLabel}`]: {
    paddingBottom: theme.spacing(1),
  },
  [`& .${classes.statusFromControl}`]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

function getSubAssetClassFromItems(items?: InstrumentListItem[]) {
  if (!items) {
    return [];
  }

  const subAssetClasses = concat(
    items.filter((it) => it.subAssetClass).map((it) => it.subAssetClass || ""),
    items.filter((it) => it.resolved?.assetSubClass).map((it) => it.resolved?.assetSubClass || "")
  ).filter((it) => it !== "");

  return sortBy(uniq(subAssetClasses));
}

export default React.memo(function ItemFilter(props: {
  filter: FilterSettings;
  updateFilter: (filter: FilterSettings) => void;
  list: InstrumentList;
}) {
  const handleStatusFilterChange = (evt: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const status = evt.currentTarget.name as ServerMessageSeverity;
    const newFilter = cloneDeep(props.filter);
    if (checked) {
      newFilter.status.push(status);
    } else {
      newFilter.status = newFilter.status.filter((it) => it !== status);
    }
    props.updateFilter(newFilter);
  };

  const handleAssetSubClassChange = (_: string, value: string[]) => {
    const newFilter = cloneDeep(props.filter);
    newFilter.assetSubClass = value;
    props.updateFilter(newFilter);
  };

  const isChecked = (status: ServerMessageSeverity) => {
    return props.filter.status.includes(status);
  };

  const subAssetClasses = getSubAssetClassFromItems(props.list.items);
  const subAssetClassItems = subAssetClasses.map((sac) => ({ key: sac, value: sac }));

  // used to calculate the number of items with certain message severity
  // ignores the status-filter itself.
  const filteredItems = filterItems(props.list?.items || [], {
    status: [],
    assetSubClass: props.filter.assetSubClass,
    search: props.filter.search,
  });

  return (
    <Root className={classes.root}>
      <div>
        <Typography variant="body1">Filter</Typography>
      </div>
      <FormControl className={classes.statusFromControl}>
        <FormLabel className={classes.statusLabel}>Status</FormLabel>
        <FormGroup className={classes.formGroupCheckboxReset}>
          <FormControlLabel
            control={<Checkbox checked={isChecked("Error")} onChange={handleStatusFilterChange} name="Error" />}
            label={`Error (${countBySeverity("Error", filteredItems)})`}
          />
          <FormControlLabel
            control={<Checkbox checked={isChecked("Warning")} onChange={handleStatusFilterChange} name="Warning" />}
            label={`Warning (${countBySeverity("Warning", filteredItems)})`}
          />
          <FormControlLabel
            control={<Checkbox checked={isChecked("Information")} onChange={handleStatusFilterChange} name="Information" />}
            label={`Information (${countBySeverity("Information", filteredItems)})`}
          />
        </FormGroup>
      </FormControl>

      <MultiSelect
        id="assetSubClass"
        label="Asset sub class"
        data={subAssetClassItems}
        selectedKeys={props.filter.assetSubClass}
        onChange={handleAssetSubClassChange}
      />
    </Root>
  );
});
