import * as React from "react";
import { styled } from '@mui/material/styles';
import throttle from "lodash/throttle";
import { baseUrl } from "../../store/apiUrlProvider";
import { request } from "../../modules/client";
import DropdownBase from "./DropdownBase";
import { Typography, useTheme } from "@mui/material";

const PREFIX = 'InstrumentAutocomplete';
const classes = {
  menuItem: `${PREFIX}-menuItem`,
  menuItemLine: `${PREFIX}-menuItemLine`,
  ellipsis: `${PREFIX}-ellipsis`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.menuItem}`]: {
    display: "flex",
    flexDirection: "column",
    width: theme.spacing(50),
  },

  [`& .${classes.menuItemLine}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  [`& .${classes.ellipsis}`]: {
    textOverflow: "ellipsis",
    overflowX: "hidden",
    whiteSpace: "nowrap",
  }
}));

export default function InstrumentAutocomplete(props: {
  label: string;
  onSelect: (i: InstrumentSearchResult) => void;
  useExtendedSearch?: boolean;
  limitToNotionals?: boolean;
  preventAutoClear?: boolean;
  preventAutoBlur?: boolean;
  fullWidth?: boolean;
  initialInputValue?: string;
}) {
  const space = useTheme().spacing;
  const { label } = props;
  const preventAutoClear = !!props.preventAutoClear;
  const preventAutoBlur = !!props.preventAutoBlur;
  const fullWidth = !!props.fullWidth;
  const [searchResults, setSearchResults] = React.useState<InstrumentSearchResult[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  async function triggerSearch(inputValue: Maybe<string>) {
    inputValue = inputValue || "";
    if (inputValue?.length < 2) {
      setSearchResults([]);
      return;
    }

    searchInstrument.cancel();
    setIsLoading(true);
    const result = await searchInstrument(inputValue, !!props.useExtendedSearch, !!props.limitToNotionals);
    setIsLoading(false);
    setSearchResults(result || []);
  }

  function selectInstrument(instrument: InstrumentSearchResult) {
    setSearchResults([]);
    props.onSelect(instrument);
  }

  return (
    <DropdownBase
      items={searchResults}
      onSelect={selectInstrument}
      formatItem={(i) => i.name}
      onInputChange={triggerSearch}
      initialSelectedItem={
        (props.initialInputValue && ({ name: props.initialInputValue } as InstrumentSearchResult)) || undefined
      }
      {...{ label, preventAutoBlur, preventAutoClear, fullWidth, isLoading }}
      renderMenuItemContent={(i) => (
        <Root className={classes.menuItem}>
          <div className={classes.menuItemLine}>
            <Typography variant="body2" className={classes.ellipsis}>
              {i.name}
            </Typography>
            <Typography variant="body2" style={{ marginLeft: space(1) }}>
              {i.defaultTradingCurrency || "---"}
            </Typography>
          </div>
          <div className={classes.menuItemLine}>
            <Typography variant="caption" className={classes.ellipsis}>
              {i.bloombergTicker || i.isin || i.instrumentId}
            </Typography>
            <Typography variant="caption" style={{ marginLeft: space(1) }}>
              {i.productType}
            </Typography>
          </div>
        </Root>
      )}
    />
  );
}

export type InstrumentSearchResult = {
  /**
   * the TST instrument id.
   */
  instrumentId: string;
  /**
   * the 'full' instrument-facade id (including the currency).
   */
  imsInstrumentId: string;
  isin: string;
  valor?: number;
  bloombergTicker: string;
  bloombergGlobalId: string;
  name: string;
  productType: string;
  defaultTradingCurrency: string;
  assetSubClass: string;
  riskCurrency: string;
};

const searchInstrument = throttle(
  (searchTerm: string, useExtendedSearch: boolean, limitToNotionals: boolean) =>
    request(baseUrl() + "api/instrument/search", {
      method: "POST",
      payload: {
        text: searchTerm,
        limitToNotionals: limitToNotionals,
        useExtendedSearch: useExtendedSearch,
      },
    }),
  200
);
