import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import * as React from "react";
import { throttle } from "lodash";
import { Banner } from "../../../store/app/AppState";
import { bannerStyle } from "./BannerSlide";
import { endOfTomorrow, toShortIsoDateTimeString } from "../../../modules/date";
import { baseUrl } from "../../../store/apiUrlProvider";
import { request } from "../../../modules/client";
import { updateBanner } from "../../../store/app/app-slice";
import { useDispatch } from "react-redux";
import cn from "classnames";

const processMarkdown = throttle(async (markdown: string, callback: (html: string) => void) => {
  const result: string = await request(baseUrl() + "api/banners/process-markdown", { method: "POST", payload: markdown });
  callback(result);
}, 500);

function saveBanner(banner: Pick<Banner, "Id" | "Importance" | "StartDate" | "EndDate" | "MarkdownMessage">) {
  return request(baseUrl() + "api/banners", { method: "POST", payload: banner });
}
const PREFIX = 'BannerEditor';
const classes = {
  content: `${PREFIX}-content`,
  preview: `${PREFIX}-preview`,
  dates: `${PREFIX}-dates`,
}
const RootDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.content}`]: {
    display: "flex",
    flexDirection: "column",
    "& .MuiFilledInput-root": { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
    "& > .row + .row": {
      // all, but first .row
      marginTop: theme.spacing(3),
    },
  },
  [`& .${classes.preview}`]: {
    ...bannerStyle.globalCss,
    opacity: bannerStyle.bannerCss.opacity,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    minHeight: 75,
  },
  [`& .${classes.dates}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "baseline",
    "& .validation": {
      color: theme.palette.warning.dark,
    },
    "& > div": {
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(1.5),
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
})) as typeof Dialog;

export default React.memo(function BannerEditor({ banner, onDone }: { banner: Partial<Banner>; onDone: () => void }) {
  const bannerId = banner?.Id || 0;
  const dispatch = useDispatch();

  const [importance, setImportance] = React.useState(banner.Importance || 0);
  const [markdownMessage, setMarkdownMessage] = React.useState(banner.MarkdownMessage || "Your banner message goes here.");
  const [previewHtml, setPreviewHtml] = React.useState(
    banner?.HtmlMessage || "<h3>Preview of the rendered <a href='https://commonmark.org/help/' target='_blank'>Markdown</a>.</h3>"
  );
  const [startDate, setStartDate] = React.useState(toShortIsoDateTimeString(banner.StartDate || new Date()));
  const [endDate, setEndDate] = React.useState(toShortIsoDateTimeString(banner.EndDate || endOfTomorrow()));
  const [validationMessage, setValidationMessage] = React.useState(validateDateRange(startDate, endDate));
  const [apiError, setApiError] = React.useState(undefined as string | undefined);
  const [isSaving, setIsSaving] = React.useState(false);

  const isValid = !validationMessage;
  const isUpdate = bannerId > 0;
  const isNew = !isUpdate;
  const color = bannerStyle.getColor(importance);
  const hasChanges =
    importance != banner.Importance ||
    markdownMessage != banner.MarkdownMessage ||
    startDate != toShortIsoDateTimeString(banner.StartDate) ||
    endDate != toShortIsoDateTimeString(banner.EndDate);
  const canSave = (hasChanges || isNew) && !isSaving && isValid;

  function updateStartDate(event: any) {
    const value = event.target.value;
    const validationResult = validateDateRange(value, endDate);
    setValidationMessage(validationResult);
    setStartDate(value);
  }

  function updateEndDate(event: any) {
    const value = event.target.value;
    const validationResult = validateDateRange(startDate, value);
    setValidationMessage(validationResult);
    setEndDate(value);
  }

  function updateMessage(markdown: string) {
    processMarkdown(markdown, setPreviewHtml);
    setMarkdownMessage(markdown);
  }

  function save() {
    setIsSaving(true);
    saveBanner({
      Id: bannerId,
      Importance: importance,
      StartDate: new Date(startDate).toISOString(),
      EndDate: new Date(endDate).toISOString(),
      MarkdownMessage: markdownMessage,
    })
      .then((saved: Banner) => {
        setIsSaving(false);
        onDone();
        dispatch(updateBanner(saved));
      })
      .catch(() => {
        setIsSaving(false);
        setApiError("There was a problem saving the banner.");
        setTimeout(() => setApiError(undefined), 5000);
      });
  }

  return (
    <RootDialog open={true} fullWidth maxWidth="md">
      <React.Fragment>
        <DialogTitle>{isUpdate ? "Update" : "New"} Banner</DialogTitle>
        <DialogContent className={classes.content}>
          <div className="row">
            <div className={classes.preview} style={{ backgroundColor: color }} dangerouslySetInnerHTML={{ __html: previewHtml }}></div>
            <MessageInput value={markdownMessage} onChange={updateMessage} />
          </div>
          <div className="row">
            <ImportanceSelector value={importance} onChange={setImportance} />
          </div>
          <div className={cn(classes.dates, "row")}>
            <TextField label="Start" type="datetime-local" defaultValue={startDate} onChange={updateStartDate} />
            <TextField label="End" type="datetime-local" defaultValue={endDate} onChange={updateEndDate} />
            {validationMessage && (
              <div>
                <Typography className="validation">{validationMessage}</Typography>
              </div>
            )}
            {apiError && (
              <div>
                <Typography className="validation">{apiError}</Typography>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDone}>Cancel</Button>
          <Button variant="contained" onClick={save} disabled={!canSave}>
            Save
          </Button>
        </DialogActions>
      </React.Fragment>
    </RootDialog>
  );
});

function MessageInput({ value, onChange }: { value: string; onChange: (value: string) => void }) {
  return (
    <TextField
      multiline
      rows={3}
      value={value}
      onChange={(event: any) => onChange(event.target.value)}
      variant="filled"
      size="small"
      fullWidth
    />
  );
}

function ImportanceSelector({ value, onChange }: { value: number; onChange: (value: number) => void }) {
  return (
    <FormControl component="fieldset">
      <RadioGroup row value={value} onChange={(event: any) => onChange(event.target.value)}>
        <FormControlLabel
          value="0"
          control={<Radio checked={value == 0} />}
          label="High Importance"
          style={{ opacity: value == 0 ? 1 : 0.5 }}
        />
        <FormControlLabel
          value="1"
          control={<Radio checked={value == 1} />}
          label="Medium"
          style={{ opacity: value == 1 ? 1 : 0.5 }}
        />
        <FormControlLabel
          value="2"
          control={<Radio checked={value == 2} />}
          label="Info"
          style={{ opacity: value == 2 ? 1 : 0.5 }}
        />
      </RadioGroup>
    </FormControl>
  );
}

function validateDateRange(start: string, end: string) {
  // dates in the format yyyy-MM-ddThh:mm are alphabetically sortable
  const now = toShortIsoDateTimeString(new Date());
  if (end <= now) {
    return "Choose a future end date.";
  }
  if (end <= start) {
    return "End date is earlier than start date.";
  }

  return undefined;
}
