import * as React from "react";
import { Typography, Box } from "@mui/material";

/**
 * Wraps content in a tap panel, which is only shown if value equals index.
 */
export default function TabPanel(props: { index: number; value: number; children?: React.ReactNode }) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
