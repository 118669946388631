import * as React from "react";
import { styled } from '@mui/material/styles';
import { Button, Select, MenuItem, Typography } from "@mui/material";
import { InstrumentList } from "../../types/InstrumentList";
import InstrumentListCard from "./InstrumentListCard";
import EditInstrumentListDialog from "./EditInstrumentListDialog";

type InstrumentListSortOrder = "date" | "name";

const PREFIX = 'InstrumentListContainer';
const classes = {
  container: `${PREFIX}-container`,
  headRow: `${PREFIX}-headRow`,
  listContainer: `${PREFIX}-listContainer`,
  sortSelect: `${PREFIX}-sortSelect`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "1100px",
    },
  },

  [`& .${classes.headRow}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.listContainer}`]: {},

  [`& .${classes.sortSelect}`]: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: theme.typography.body2.fontSize,
  }
}));

export interface InstrumentListContainerProps {
  lists: InstrumentList[];
  sortBy: InstrumentListSortOrder;
  canCreateList: boolean;
  updateSorting: (newValue: string) => void;
}

function InstrumentListContainer(props: InstrumentListContainerProps) {
  const [showListDialog, setShowListDialog] = React.useState(false);

  const existingListIds = props.lists.map((list) => list.instrumentListId);

  return (
    <Root className={classes.container}>
      <div>
        <Typography variant="h3">{props.lists.length} Lists</Typography>
      </div>
      <div className={classes.headRow}>
        <div>
          <div>
            <Typography variant="body2" display="inline">
              Sort by{" "}
            </Typography>
            <Select
              className={classes.sortSelect}
              value={props.sortBy}
              onChange={(evt) => {
                props.updateSorting(evt.target.value as string);
              }}
            >
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="name">Name</MenuItem>
            </Select>
          </div>
        </div>
        <div>
          <EditInstrumentListDialog
            open={showListDialog}
            closeHandle={() => setShowListDialog(false)}
            list={undefined}
            existingListIds={existingListIds}
          />
          <Button color="primary" disabled={!props.canCreateList} variant="contained" onClick={() => setShowListDialog(true)}>
            Create new list
          </Button>
        </div>
      </div>
      <div className={classes.listContainer}>
        {props.lists.length > 0 &&
          props.lists.map((list) => (
            <InstrumentListCard key={list.instrumentListId} list={list} existingListIds={existingListIds} />
          ))}
        {props.lists.length === 0 && <div>No matchin lists found.</div>}
      </div>
    </Root>
  );
}

export default InstrumentListContainer;
