import Allocation from "../../../types/Allocation";
import WeightedInstrument from "../../../types/WeightedInstrument";
import { AllocationColumn, InstrumentMap, AllocationRowDescriptor } from "../types";
import { cellsInRowOrder } from "./allocationColumn";
import { isZeroOrUnset } from "./weights";

/**
 * Applies the weights (specified by weightsKey) from the current state in column
 * to the given allocation.
 *
 * This approach here is required, because we rely on AssetTreeBuilder in getTableStructure.
 */
export default function applyWeights(
  allocation: Allocation,
  rows: AllocationRowDescriptor[],
  column: AllocationColumn,
  instruments: InstrumentMap
): Allocation {
  const weightedInstruments: WeightedInstrument[] = [];

  cellsInRowOrder(column, rows, true)
    .filter((c) => !isZeroOrUnset(c.saved))
    .forEach((c) => {
      const instrument = instruments[c.rowId]; // rowId eq instrumentId
      if (instrument) {
        weightedInstruments.push({
          Instrument: instrument,
          IsDynamicWeight: false,
          IsAverageWeight: false,
          Weight: c.saved.toString(),
          SubAssetClass: instrument.assetSubClassOverride,
          RiskCurrency: instrument.riskCurrencyOverride,
        });
      }
    });

  allocation.SavedSnapshot = allocation.SavedSnapshot || { Instruments: [], Version: "0" };
  allocation.SavedSnapshot.Instruments = weightedInstruments;
  return allocation;
}
