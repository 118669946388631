import React from "react";
import { useTheme } from "@mui/material";
import { InfoIcon } from "./Icons";

/**
 * Trivial component that shows some explanatory content with an (i) infront.
 */

export default function InfoBox(props: {
  children: React.ReactNode;
  containerClassName?: string;
}) {
  const theme = useTheme();

  return (
    <div style={{ display: "flex" }} className={props.containerClassName}>
      <div style={{ display: "inline-block" }}>
        <InfoIcon width={16} height={16} />
      </div>
      <div style={{ display: "inline-block", marginLeft: theme.spacing(1) }}>{props.children}</div>
    </div>
  );
}
