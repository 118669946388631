import "../styles/main.scss";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Authenticated } from "./auth/Authenticated";
import { ConfiguredAuthProvider } from "./auth/ConfiguredAuthProvider";
import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { createRoot } from "react-dom/client";
import { store } from "./store/index";
import App from "./components/App";
import React from "react";
import history from "modules/history";
import theme from "./theme";

/* global module */
if (module.hot) {
  module.hot.accept("components/App", render);
}

const container = document.getElementById("app");

const root = createRoot(container);

render();

function render() {
  root.render(
      <ThemeProvider theme={theme}>
          <CssBaseline>
              <Provider store={store}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ConfiguredAuthProvider>
                        <Authenticated>
                          <App history={history} />
                        </Authenticated>
                    </ConfiguredAuthProvider>
                  </LocalizationProvider>
              </Provider>
          </CssBaseline>
      </ThemeProvider>,
  );
}
