import { useAuth } from "react-oidc-context";
import { PropsWithChildren, useEffect } from "react";
import React from "react";
 
export type AuthenticatedProps = PropsWithChildren<{ silent?: boolean }>;
 
export function Authenticated({
  children,
  silent = false,
}: AuthenticatedProps) {
  const auth = useAuth();
 
  useEffect(() => {
    if (
      !auth.isLoading &&
      !auth.activeNavigator &&
      !auth.error &&
      !auth.isAuthenticated
    ) {
      signin();
    }
 
    function signin() {
      if (silent) auth.signinSilent();
      else auth.signinRedirect();
    }
  }, [auth, silent]);
 
  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in…</div>;
    case "signoutRedirect":
      return <div>Signing you out…</div>;
  }
 
  if (auth.isLoading) {
    return <div>Loading…</div>;
  }
 
  if (auth.error) {
    return <div>An error occurred during authentication: {auth.error.message}</div>;
  }
 
  if (!auth.isAuthenticated) {
    return (
      <div>You should be redirected to the login page…</div>
    );
  }
 
  return <>{children}</>;
}