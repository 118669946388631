import Big from "big.js";
import { AllocationColumn, AllocationRowDescriptor } from "../types";
import { cellsInRowOrder } from "./allocationColumn";
import { isZeroOrUnset, ZERO } from "./weights";

/**
 * Scales the non-zero saved weights in an allocation column to result in a total of 100 %.
 * If the current total is zero or below, then apply no scaling at all.
 */
export default function scaleWeights(rows: AllocationRowDescriptor[], column: AllocationColumn): AllocationColumn {
  const cells = cellsInRowOrder(column, rows, true).filter((c) => !isZeroOrUnset(c.saved));
  const total = cells.reduce((res, cur) => res.plus(cur.saved), new Big(0));

  if (total.gt(ZERO)) {
    const factor = new Big(1).div(total);
    cells.forEach((c) => (c.saved = c.saved.mul(factor)));
  }

  return column;
}
