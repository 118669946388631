import * as React from "react";
import { useDispatch } from "react-redux";
import { Button, Card, CardActions, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import { deleteBanner } from "../../../store/app/app-slice";
import { Banner } from "../../../store/app/AppState";
import { bannerStyle } from "./BannerSlide";
import { prettyDateTime } from "../../../modules/date";
import LabelValue from "./LabelValue";
import ConfirmButton from "./ConfirmButton";

const PREFIX = 'BannerItem';
const classes = {
    preview: `${PREFIX}-preview`,
    info: `${PREFIX}-info`,
}
const RootCard = styled(Card)(({ theme }) => ({
    [`& .${classes.preview}`]: {
        "@global": bannerStyle.globalCss,
        ...bannerStyle.bannerCss,
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
    },
    [`& .${classes.info}`]: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        "& .labelvalue": {
            marginRight: theme.spacing(2.5),
            marginBottom: theme.spacing(1.5),
        },
    },
})) as typeof Card;

export default React.memo(function BannerItem(props: Banner & { onEdit: (banner: Banner) => void }) {
  const message = { __html: props.HtmlMessage };
  const color = bannerStyle.getColor(props.Importance);
  const start = prettyDateTime(props.StartDate);
  const end = prettyDateTime(props.EndDate);

  const dispatch = useDispatch();
  function dispatchDelete() {
    dispatch(deleteBanner({ bannerId: props.Id }));
  }

  function editBanner() {
    props.onEdit(props);
  }

  return (
    <RootCard>
      <div className={classes.preview} style={{ backgroundColor: color }} dangerouslySetInnerHTML={message}></div>
      <CardContent className={classes.info}>
        <LabelValue label="Author" value={props.ModifiedBy} />
        <LabelValue label="Start" value={start} />
        <LabelValue label="End" value={end} />
      </CardContent>
      <CardActions>
        <ConfirmButton
          size="small"
          style={{ marginLeft: "auto" }}
          onConfirm={dispatchDelete}
          normalLabel="Delete"
          confirmLabel={(secondsLeft: number) => "Confirm Deletion " + secondsLeft}
        />
        <Button size="small" color="primary" variant="contained" onClick={editBanner}>
          Edit
        </Button>
      </CardActions>
    </RootCard>
  );
});
