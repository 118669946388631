import uniqWith from "lodash/uniqWith";
import { ServerMessage, ServerMessageSeverity, InstrumentList, InstrumentListItem, FilterSettings } from "../../types/InstrumentList";
import { NavigateFunction } from 'react-router';
import { InstrumentLabel } from "../shared/MultiInstrumentSelect";

const hasErrors = (messages?: ServerMessage[]): boolean => {
  if (!messages) {
    return false;
  }
  return messages.filter((msg) => msg.severity === "Error").length > 0;
};

const hasWarnings = (messages?: ServerMessage[]): boolean => {
  if (!messages) {
    return false;
  }
  return messages.filter((msg) => msg.severity === "Warning").length > 0;
};

const hasInformation = (messages?: ServerMessage[]): boolean => {
  if (!messages) {
    return false;
  }
  return messages.filter((msg) => msg.severity === "Information").length > 0;
};

const hasMessages = (messages?: ServerMessage[]): boolean => {
  if (!messages) {
    return false;
  }
  return messages.length > 0;
};

const getSortSeverity = (messages?: ServerMessage[]): number => {
  const severity = getMaxSeverity(messages);
  return severityToNumber(severity);
};

const getMaxSeverity = (messages?: ServerMessage[]): ServerMessageSeverity => {
  if (hasErrors(messages)) {
    return "Error";
  } else if (hasWarnings(messages)) {
    return "Warning";
  } else if (hasInformation(messages)) {
    return "Information";
  } else {
    return "Ok";
  }
};

const countBySeverity = (severity: ServerMessageSeverity, instrumentListItems?: InstrumentListItem[]): number => {
  if (!instrumentListItems) {
    return 0;
  }
  return instrumentListItems.map((it) => getMaxSeverity(it.validationMessages)).filter((sev) => sev == severity).length;
};

const severityToNumber = (severity: ServerMessageSeverity) => {
  if (severity == "Error") {
    return 3;
  } else if (severity === "Warning") {
    return 2;
  } else if (severity === "Information") {
    return 1;
  } else {
    return 0;
  }
};

const areListItemsEditable = (hasWritePermission: boolean, list: InstrumentList | undefined): boolean => {
  if (!list) {
    return false;
  }
  return hasWritePermission;
};

/**
 * escape the user input.
 * Function taken from MDN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
 */
function escapeRegExp(string: string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

const searchInInstrumentLists = (lists: InstrumentList[], searchString: string): InstrumentList[] => {
  if (!searchString) {
    return lists;
  }

  const escaped = escapeRegExp(searchString?.trim());
  const regex = new RegExp(escaped, "i");
  return lists.filter((lst) => {
    return regex.test(lst.instrumentListId) || regex.test(lst.name) || regex.test(lst.description);
  });
};

const filterByInstrumentListCategory = (items: InstrumentList[], categories: string[]): InstrumentList[] => {
  if (!categories || categories.length == 0) {
    return items;
  }

  return items.filter((list) => {
    return categories.indexOf(list.instrumentListCategoryId) > -1;
  });
};

const searchInListItems = (items: InstrumentListItem[], searchString: string): InstrumentListItem[] => {
  // based on basic tests searching via regex is faster for bigish datasets
  // and doesn't make a difference for small ones
  const escaped = escapeRegExp(searchString?.trim());

  const startsWithRegex = new RegExp("^" + escaped.toLowerCase(), "i");
  const withinRegex = new RegExp(escaped.toLowerCase(), "i");

  return items.filter((it) => {
    if (it.resolved?.valor && `${it.resolved?.valor}` == searchString) {
      return true;
    }

    return (
      startsWithRegex.test(it.isin || "") ||
      startsWithRegex.test(it.resolved?.isin || "") ||
      startsWithRegex.test(it.jbGlobalId || "") ||
      startsWithRegex.test(it.bloombergTicker || "") ||
      startsWithRegex.test(it.resolved?.bloombergTicker || "") ||
      startsWithRegex.test(it.bloombergGlobalId || "") ||
      startsWithRegex.test(it.resolved?.bloombergGlobalId || "") ||
      withinRegex.test(it.resolved?.shortName || "")
    );
  });
};

/* filteres the items using the provided FilterSettings */
const filterItems = (items: InstrumentListItem[], filter: FilterSettings) => {
  let filtered: InstrumentListItem[] = [];
  filtered = filtered.concat(items);

  if (filter.status.length > 0) {
    filtered = filtered.filter((it) => {
      const maxSeverity = getMaxSeverity(it.validationMessages);
      return filter.status.includes(maxSeverity);
    });
  }

  if (filter.assetSubClass.length > 0) {
    filtered = filtered.filter((it) => {
      return (
        filter.assetSubClass.includes(it.subAssetClass || "") || filter.assetSubClass.includes(it.resolved?.assetSubClass || "")
      );
    });
  }

  if (filter.search) {
    filtered = searchInListItems(filtered, filter.search);
  }

  return filtered;
};

const uniqueInstrumentFilter = (data: InstrumentLabel[]) => {
  return uniqWith(data, (a, b) => a.instrumentId === b.instrumentId);
};

// Updates the Query-String of the URL (via history push) with the provided values.
const pushStateToHistory = (data: Object, navigate: NavigateFunction, query: URLSearchParams): void => {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];

      // put arrays into the URL as multiple, repeated elements in the URL
      if (Array.isArray(value)) {
        query.delete(key);
        value.forEach((it) => query.append(key, it));
      } else {
        // simple values
        if (!value) {
          query.delete(key);
        } else {
          query.set(key, value);
        }
      }
    }
  }
  navigate("?" + query.toString(), { replace: true });
};

export {
  getSortSeverity,
  countBySeverity,
  searchInInstrumentLists,
  filterByInstrumentListCategory,
  filterItems,
  hasErrors,
  hasWarnings,
  hasMessages,
  severityToNumber,
  areListItemsEditable,
  pushStateToHistory,
  uniqueInstrumentFilter,
};
