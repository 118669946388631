import { AssetTreeRow } from "../types/AssetTree";
import Allocation from "../types/Allocation";
import Big, { BigType } from "big.js";
import Instrument from "../types/Instrument";
import Snapshot from "../types/Snapshot";
import WeightedInstrument from "../types/WeightedInstrument";
import { InstrumentWithOverrides } from "../components/compare-view/types";

/*definition of the keys, used in the object for the MoPo-Weights per Instrument row */
export const AVERAGE_WEIGHT_KEY = "AverageWeight";
export const DYNAMIC_WEIGHT_KEY = "DynamicWeight";
export const PUBLISHED_WEIGHT_KEY = "PublishedWeight";
export const IC_WEIGHT_KEY = "IcWeight";
export const SAVED_WEIGHT_KEY = "SavedWeight";

export interface Weights {
  [AVERAGE_WEIGHT_KEY]?: BigType;
  [DYNAMIC_WEIGHT_KEY]?: BigType;
  [IC_WEIGHT_KEY]?: BigType;
  [PUBLISHED_WEIGHT_KEY]?: BigType;
  [SAVED_WEIGHT_KEY]?: BigType;
}

interface InstrumentRow extends Instrument {
  InvalidInstrument?: boolean;
  SubAssetClass: string;
  Weights?: Weights[];
}

export interface InstrumentRows {
  [id: string]: InstrumentRow;
}

export interface ProjectedAssetTree {
  summarize: Weights[];
  tree: TreeNode[];
}

export interface TreeNode {
  id: string;
  indent: number;
  label: string;
  instrumentTree?: {
    weights: {
      id: string;
      instrumentId: string;
      invalidInstrument?: boolean;
      isin: string;
      subAssetClass: string;
      riskCurrency: string;
      defaultTradingCurrency: string;
      shortName: string;
      longNamePretty: string;
      isDeleted: boolean;
      weights: Weights[];
    }[];
  };
  weights: Weights[];
  tree?: { [id: number]: TreeNode };
}

/**
 * Generate the ID used for Instrument-Rows.
 * We can't use the Instrument.Id here, because we may have positions
 * for the same instrument which differ in asset sub class or risk ccy.
 */
export function generateRowId(instrument: InstrumentWithOverrides, assetTreeName: string) {
  const id = instrument.Id;
  const effectiveAssetSubClass = instrument.assetSubClassOverride || instrument.AssetSubClass || "";
  const effectiveRiskCurrency = instrument.riskCurrencyOverride || instrument.RiskCurrency || "";
  return `${id}+${effectiveAssetSubClass}+${effectiveRiskCurrency}${assetTreeName ? ('+' + instrument.AssetTreeMapping[assetTreeName].Rank) : ''}`;
}

export function generateWeightedInstrumentRowId(wi: WeightedInstrument, assetTreeName: string) {
  return generateRowId(getInstrumentWithOverrides(wi), assetTreeName);
}

export function getInstrumentWithOverrides(wi: WeightedInstrument): InstrumentWithOverrides {
  return { ...wi.Instrument, assetSubClassOverride: wi.SubAssetClass, riskCurrencyOverride: wi.RiskCurrency };
}
export default class AssetTreeBuilder {
  treeStructure: AssetTreeRow[];

  treeName: string;

  allocations: Allocation[];

  constructor(treeStructure: AssetTreeRow[], allocations: Allocation[], treeName: string) {
    this.treeStructure = treeStructure;
    this.allocations = allocations;
    this.treeName = treeName;
  }

  generateTree = (tree: AssetTreeRow[], instruments: InstrumentRows, indent: number = 0): any => {
    const result = tree.map((n) => {
      let subTree;
      let instrumentTree = undefined;

      instrumentTree = this.generateInstrumentTree(n, instruments);
      this.sortByShortName(instrumentTree.weights);
      const instrumentWeights = instrumentTree.weights.map((e) => e.weights);

      let accumulatedWeightsCells = this.accumulatedWeights(instrumentWeights);

      if (n.Tree !== undefined) {
        subTree = this.generateTree(n.Tree, instruments, indent + 1);
        accumulatedWeightsCells = this.accumulatedWeights(subTree.weights);
      }

      return {
        id: n.List + n.Rank,
        weights: accumulatedWeightsCells,
        instrumentTree: instrumentTree,
        tree: subTree,
        label: n.Label,
        indent: indent,
        rank: n.Rank,
      };
    });

    const weights = result.map((e) => e.weights);
    return {
      weights: weights,
      ...result,
    };
  };

  accumulatedWeights = (instrumentsArray: any): Weights[] => {
    //create an array of objects with 0.0 for all values - for every allocation
    //this acts as the default - and as the 'accumulator' objects, where the instrument weights gets summed up
    const result = this.allocations.map(() => {
      return {
        [AVERAGE_WEIGHT_KEY]: Big(0),
        [DYNAMIC_WEIGHT_KEY]: Big(0),
        [IC_WEIGHT_KEY]: Big(0),
        [PUBLISHED_WEIGHT_KEY]: Big(0),
        [SAVED_WEIGHT_KEY]: Big(0),
      };
    });

    if (!instrumentsArray || instrumentsArray.length === 0) {
      return result;
    }

    const accumulateValue = (accumulatorObject: any, additionObject: any, key: string): void => {
      const valA = toBigNumber(accumulatorObject[key] || 0.0);
      const valB = toBigNumber(additionObject[key] || 0.0);
      accumulatorObject[key] = valA.plus(valB);
    };

    instrumentsArray.forEach((mopos: any) => {
      mopos.forEach((mopoWeights: any, idx: number) => {
        const accumulator = result[idx];

        accumulateValue(accumulator, mopoWeights, AVERAGE_WEIGHT_KEY);
        accumulateValue(accumulator, mopoWeights, DYNAMIC_WEIGHT_KEY);
        accumulateValue(accumulator, mopoWeights, IC_WEIGHT_KEY);
        accumulateValue(accumulator, mopoWeights, PUBLISHED_WEIGHT_KEY);
        accumulateValue(accumulator, mopoWeights, SAVED_WEIGHT_KEY);
      });
    });

    return result;
  };

  addInstruments = (
    weightedInstruments: WeightedInstrument[],
    instrumentsRows: InstrumentRows,
    mopoIndex: number,
    targetWeightKey: string,
    assetTreeName: string
  ): void => {
    weightedInstruments
      .filter((f) => f !== null && f !== undefined)
      .forEach((wi) => {
        if (!wi.Instrument) {
          return;
        }

        const rowId = generateWeightedInstrumentRowId(wi, assetTreeName);
        let row: InstrumentRow | undefined = instrumentsRows[rowId];

        if (row === undefined) {
          // create a copy of the instrument as the basis for the new instrument row
          row = { ...wi.Instrument } as InstrumentRow;
          row.SubAssetClass = wi.SubAssetClass || wi.Instrument.AssetSubClass!;
          row.RiskCurrency = wi.RiskCurrency || wi.Instrument.RiskCurrency!;

          if (!row.AssetTreeMapping) {
            row.AssetTreeMapping = { [this.treeName]: { List: "LI_IM_AC_OT", Rank: "" } };
            row.InvalidInstrument = true;
          }

          instrumentsRows[rowId] = row;
        }

        if (!row.InvalidInstrument) {
          if (!row.Weights) {
            //create an empty object for every allocation. This is important, so the rendering of the intrument-row
            //can iterate over those (even when they are empty)
            row.Weights = this.allocations.map(() => ({}));
          }

          row.Weights[mopoIndex][targetWeightKey] = !isBigNumber(wi.Weight) ? "" : new Big(wi.Weight);
        }
      });
  };

  addCalculatedWeights = (snapshot: Snapshot, instrumentsRows: any, mopoIndex: number, assetTreeName: string) => {
    snapshot.Instruments.filter((f) => !!f).forEach((wi) => {
      // in order to not change the behaviour during the typescript migration, ignore the type error here
      // TODO - WTF? InvalidInstrument is never set on a WeightedInstrument instance
      //@ts-ignore
      if (!wi.Instrument || wi.InvalidInstrument) {
        return;
      }

      const row = instrumentsRows[generateWeightedInstrumentRowId(wi, assetTreeName)];
      if (!row) {
        return;
      }

      if (!row.Weights) {
        row.Weights = [];
        row.Weights[mopoIndex] = {};
      }

      row.Weights[mopoIndex][DYNAMIC_WEIGHT_KEY] = !isBigNumber(wi.Instrument.DynamicWeight)
        ? ""
        : new Big(wi.Instrument.DynamicWeight);

      row.Weights[mopoIndex][AVERAGE_WEIGHT_KEY] = !isBigNumber(wi.Instrument.AverageWeight)
        ? ""
        : new Big(wi.Instrument.AverageWeight);
    });
  };

  prepareInstruments = (allocations: Allocation[], includeNonSnapshotInstruments: boolean, assetTreeName: string): InstrumentRows => {
    const instrumentsRows = {};

    allocations.forEach((e, mopoIndex) => {
      if (e.PublishedSnapshot !== null && e.PublishedSnapshot !== undefined) {
        const filtered = includeNonSnapshotInstruments
          ? e.PublishedSnapshot?.Instruments
          : e.PublishedSnapshot?.Instruments.filter((i) => !i.IsDynamicWeight && !i.IsAverageWeight);

        this.addInstruments(filtered, instrumentsRows, mopoIndex, PUBLISHED_WEIGHT_KEY, assetTreeName);
        this.addCalculatedWeights(e.PublishedSnapshot, instrumentsRows, mopoIndex, assetTreeName);
      }
    });

    allocations.forEach((e, mopoIndex) => {
      if (e.SavedSnapshot !== null && e.SavedSnapshot !== undefined) {
        this.addInstruments(e.SavedSnapshot?.Instruments, instrumentsRows, mopoIndex, SAVED_WEIGHT_KEY, assetTreeName);
      }
    });
    return instrumentsRows;
  };

  // checks, if the two given rank values are equivalent
  // this is an extra function, to make sure 'falsy' value are considered the same (so "", null, undefined).
  isSameRank = (a?: string, b?: string) => {
    if (!a && !b) {
      return true;
    } else {
      return a == b;
    }
  };

  /**
   * Checks if the provided AssetTreeRow is the 'Other' subTree.
   * This is done by checking the .List attribute.
   * ("LI_IM_AC_OT" is used in all the different market structures (Nh, Ni, Ni-SM, Ni-EQ, GT, Ni-AS) for 'Other')
   */
  isOtherSubTree = (subTree: AssetTreeRow) => {
    return subTree.List === "LI_IM_AC_OT";
  };

  generateInstrumentTree = (subTree: AssetTreeRow, allInstruments: InstrumentRows) => {
    const subTreeName = subTree.List + subTree.Rank;
    const subTreeRank = subTree.Rank;
    const instrumentWeights: any[] = [];

    const ids = Object.keys(allInstruments);
    ids.forEach((id) => {
      const instrument = allInstruments[id];
      const mapping = instrument.AssetTreeMapping[this.treeName];

      if (
        (instrument && mapping && (mapping.List + mapping.Rank) === subTreeName) ||
        (instrument && !mapping && this.isOtherSubTree(subTree))
      ) {
        instrumentWeights.push({
          id: id,
          instrumentId: instrument.Id,
          weights: instrument.Weights,
          subAssetClass: instrument.SubAssetClass,
          isin: instrument.Isin,
          longNamePretty: instrument.LongNamePretty,
          shortName: instrument.ShortName,
          riskCurrency: instrument.RiskCurrency,
          defaultTradingCurrency: instrument.DefaultTradingCurrency,
          invalidInstrument: instrument.InvalidInstrument,
          isDeleted: instrument.IsDeleted,
        });
      } else if (!mapping) {
        // throw new Error(`Could not find AssetTreeMapping in instrument '${instrument?.ShortName}' for Tree: '${this.treeName}'.`);
        console.warn(`Could not find AssetTreeMapping in instrument '${instrument?.ShortName}' for Tree: '${this.treeName}'.`);
      }
    });

    return { weights: instrumentWeights };
  };

  summarizeRow = (tree: any) => {
    return this.accumulatedWeights(tree.weights);
  };

  /**
   * calculates and returns the 'asset tree'.
   * @param includeNonSnapshotInstruments Set this to true, if you want to include instruments, that are not part of the snapshot (but contain the dynamic or average weights).
   */
  generateAssetTree = (includeNonSnapshotInstruments: boolean, assetTreeName: string): ProjectedAssetTree => {
    const instruments = this.prepareInstruments(this.allocations, includeNonSnapshotInstruments, assetTreeName);
    const tree = this.generateTree(this.treeStructure, instruments);
    return {
      tree: tree,
      summarize: this.summarizeRow(tree),
    };
  };

  /**
   * Sorts the given list inline (!).
   * @param instruments the list of instruments to sort. These must have a 'shortName' property
   */
  private sortByShortName(instruments: { shortName: string }[]): void {
    if (!instruments) {
      return;
    }

    instruments.sort((a, b) => {
      if (!a && !b) {
        return 0;
      } else if (a && !b) {
        return 1;
      } else if (!a && b) {
        return 1;
      } else if (a.shortName === b.shortName) {
        return 0;
      } else if (a.shortName > b.shortName) {
        return 1;
      } else {
        return -1;
      }
    });
  }
}

export const toBigNumber = (a: BigType | number | string): BigType => {
  try {
    return new Big(a);
  } catch (ex) {
    return new Big(0);
  }
};

export const isBigNumber = (a: any): boolean => {
  try {
    new Big(a);
    return true;
  } catch (ex) {
    return false;
  }
};
