import * as React from "react";
import { Message } from "../store/app/AppState";
import { hideAlert } from "../store/app/app-slice";
import { styled } from "@mui/material/styles";
import { IconButton, Snackbar, SnackbarContent } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import { amber, green } from "@mui/material/colors";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store/AppState";

const PREFIX = 'SystemAlerts';
const classes = {
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
  info: `${PREFIX}-info`,
  warning: `${PREFIX}-warning`,
  icon: `${PREFIX}-icon`,
  iconVariant: `${PREFIX}-iconVariant`,
  message: `${PREFIX}-message`
};
const RootSnackbar = styled(Snackbar)((
  {
    theme
  }
) => ({
  [`& .${classes.success}`]: {
    backgroundColor: green[600],
  },

  [`& .${classes.error}`]: {
    backgroundColor: theme.palette.error.dark,
  },

  [`& .${classes.info}`]: {
    backgroundColor: theme.palette.primary.dark,
  },

  [`& .${classes.warning}`]: {
    backgroundColor: amber[700],
  },

  [`& .${classes.icon}`]: {
    fontSize: 20,
  },

  [`& .${classes.iconVariant}`]: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },

  [`& .${classes.message}`]: {
    display: "flex",
    alignItems: "center",
  }
})) as typeof Snackbar;

/**
 * Component showing system alerts (errors, warnings, infos) as toasts
 * in the bottom left corner of the browser window.
 */

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

export default function SystemAlert() {
  const dispatch = useDispatch();
  const alert: Message | undefined = useSelector((s: AppState) => s.app.alerts[0]);

  const close = () => dispatch(hideAlert({ id: alert?.id }));
  const Icon = variantIcon[alert?.type || "info"];

  return (
    <>
      {alert !== undefined && (
        <RootSnackbar
          open={true}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          autoHideDuration={4000}
          onClose={close}
        >
          <SnackbarContent
            className={classes[alert.type]}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <Icon className={classNames(classes.icon, classes.iconVariant)} />
                {alert.message}
              </span>
            }
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" onClick={close}>
                <CloseIcon className={classes.icon} />
              </IconButton>,
            ]}
          />
        </RootSnackbar>
      )}
    </>
  );
}
