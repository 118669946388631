import * as React from "react";
import { useParams } from "react-router-dom";
import { Box, Button, ButtonGroup, Typography, useTheme } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ReferenceDate from "./ReferenceDate";
import { toShortDateString } from "../../modules/date";

export default function ReferenceDateSelector(props: { onChange: (referenceDate: ReferenceDate) => void }) {
  const theme = useTheme();
  const urlParameter = useParams<{ referenceDate?: string }>().referenceDate;
  const initial = urlParameter !== undefined ? ReferenceDate.fromPathParam(urlParameter) : ReferenceDate.getDefault();
  const [current, setCurrent] = React.useState(initial);

  React.useEffect(() => props.onChange(current), []); // initial notification

  function changeTo(date: ReferenceDate) {
    setCurrent(date);
    props.onChange(date);
  }

  return (
    <>
      <ButtonGroup variant="contained" color="primary" disableFocusRipple style={{ width: "100%", maxWidth: "28rem" }}>
        <Button onClick={() => changeTo(current.previous())}>
          <ChevronLeftIcon />
        </Button>
        <Button disabled variant="text" style={{ color: theme.palette.secondary.main, width: "100%" }}>
          {current.toLabel()}
        </Button>
        <Button onClick={() => changeTo(current.next())} disabled={!current.hasNext()}>
          <ChevronRightIcon />
        </Button>
      </ButtonGroup>
      <Box mt={1}>
        <Typography component="div" variant="body2" color="textSecondary">
          Publication Date: {toShortDateString(current.value())}
        </Typography>
      </Box>
    </>
  );
}
