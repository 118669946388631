import React, { useCallback, useMemo } from "react";
import { styled } from '@mui/material/styles';
import { useDispatch } from "react-redux";
import {
    Button,
    DialogActions,
    useTheme,
    DialogContent,
    Dialog,
    DialogTitle,
    InputBase,
    Typography,
} from "@mui/material";
import { MultiplePasteTable } from "./MultiplePasteTable";
import { AllocationUploadValidationResponse } from "../../../types/AllocationUploadValidationResponse";
import { parseMultipleAllocationData } from "../../compare-view/dialogs/paste-instruments-dialog/parseData";
import LoadingIndicator from "../../shared/LoadingIndicator";
import AllocationUploadRow from "../../../types/AllocationUploadRow";
import {
  cancelEditForMultipleAllocations,
  getDiffMultiple,
  validateMultipleAllocationsUpload,
} from "../../compare-view/apiRequests";
import { AllocationDiffPayload } from "../../../types/AllocationDiffPayload";
import { AllocationChangeResult } from "../../../types/AllocationChangeResult";
import { setDialog } from "../../compare-view/slice";
import {
  AllocationParseErrorResult,
  AllocationPasteType,
  MultipleAllocationRowResult,
  MultipleIcAllocationRowResult,
} from "../../shared/types";

const PREFIX = 'MultiplePasteDialog';

const classes = {
    subTitle: `${PREFIX}-subTitle`,
    pasteRoot: `${PREFIX}-pasteRoot`,
    pasteInput: `${PREFIX}-pasteInput`,
    pasteFocused: `${PREFIX}-pasteFocused`
};

const RootDialog = styled(Dialog)((
    {
        theme
  }
) => ({
    [`& .${classes.subTitle}`]: {
        fontSize: "smaller",
        color: theme.palette.text.secondary,
    },

    [`& .${classes.pasteRoot}`]: {
        height: 100,
        width: "100%",
        border: "1px dotted grey",
    },

    [`& .${classes.pasteInput}`]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },

    [`& .${classes.pasteFocused}`]: {
        boxShadow: "0 0 10px #9ecaed",
    }
})) as typeof Dialog;

interface IMultiplePasteDialogProps {
  allocationPasteType: AllocationPasteType;
  close: () => void;
}

export const MultiplePasteDialog: React.FC<IMultiplePasteDialogProps> = ({ allocationPasteType, close }) => {
    const t = useTheme();
    const dispatch = useDispatch();
    const [resolved, setResolved] = React.useState<AllocationUploadValidationResponse[]>([]);
    const [allocationRows, setAllocationRows] = React.useState<MultipleAllocationRowResult[]>([]);
  const [error, setError] = React.useState(undefined as string | undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isConfirming, setIsConfirming] = React.useState(false);

  const hasData = resolved.length > 0;

  const hasError = useMemo(() => resolved.some((r) => !r.IsFullyValid), [resolved]);

  const handleClose = useCallback(async () => {
    if (hasData && !hasError) {
      const allocationCodes = resolved.map((r) => r.AllocationCode);

      await cancelAllocationEdit(allocationCodes);
    }
    close();
  }, [close, hasData, hasError, resolved]);

  async function paste(e: React.ClipboardEvent) {
    setError(undefined);
    const data = e.clipboardData.getData("Text");
    let result = parseMultipleAllocationData(data, allocationPasteType);

    if ((result as AllocationParseErrorResult).error) {
      result = result as AllocationParseErrorResult;
      setError(result.error);
      return;
    }

    const allocationInstruments = result as MultipleAllocationRowResult[];

    if (!allocationInstruments) return;
    setIsLoading(true);

    try {
      setAllocationRows(allocationInstruments);
      await trySetResolved(allocationInstruments);
    } catch {
      setError("Resolving instruments failed.");
    } finally {
      setIsLoading(false);
    }
  }

  async function trySetResolved(allocationInstruments: MultipleAllocationRowResult[]) {
    try {
      setResolved(await validate(allocationInstruments, allocationPasteType));
    } catch (error) {
      let anyError = error as any;
      setError(anyError.response.data.messages[0].description);
    }
  }

  async function confirmAllocations() {
    setIsConfirming(true);
    const allocationChangeResults = await getDiff(resolved);
    setIsConfirming(false);

    dispatch(setDialog({ dialog: "publish-multiple", allocationChangeResults, allocationPasteType, allocationRows }));
  }

  function focusInputField(input: any) {
    if (input) {
      setTimeout(() => input.focus(), 100);
    }
  }

  return (
    <RootDialog open={true} onClose={handleClose} maxWidth={false}>
      <DialogTitle>
        {allocationPasteType === "mopo" && "Paste allocations (model portfolios)"}
        {allocationPasteType === "icAllocation" && "Paste allocations (TAAs / SAAs)"}
        <br />
        <span className={classes.subTitle}>
          {allocationPasteType === "mopo" && "Format: allocationCode / instrument / weight"}
          {allocationPasteType === "icAllocation" &&
            "Format: allocationCode / instrument / weight / riskCurrency / assetSubClass"}
        </span>
      </DialogTitle>
      <DialogContent style={{ width: 700 }}>
        {(hasData && (
          <LoadingIndicator show={isConfirming} size="48" delayInMillis={0}>
            {!isConfirming && <MultiplePasteTable data={resolved} allocationPasteType={allocationPasteType} />}
          </LoadingIndicator>
        )) || (
          <>
            <LoadingIndicator show={isLoading} size="48">
              <InputBase
                placeholder={isLoading ? "Resolving instruments..." : "Copy and paste Excel data here..."}
                onPaste={paste}
                value={""}
                inputRef={focusInputField}
                classes={{
                  root: classes.pasteRoot,
                  focused: classes.pasteFocused,
                  input: classes.pasteInput,
                }}
                disabled={isLoading}
              />
            </LoadingIndicator>
            <Typography color="error">{error}</Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          style={{ marginLeft: t.spacing(2) }}
          onClick={confirmAllocations}
          disabled={hasError || !hasData || isLoading || isConfirming}
        >
          Confirm
        </Button>
      </DialogActions>
    </RootDialog>
  );
};

async function validate(
  rows: MultipleAllocationRowResult[],
  allocationPasteType: AllocationPasteType
): Promise<AllocationUploadValidationResponse[]> {
  if (allocationPasteType === "mopo") {
    const allocationUploadRows = rows.map<AllocationUploadRow>((row) => ({
      AllocationCode: row.allocationCode,
      InstrumentId: row.pasteId,
      Weight: row.weight,
    }));

    return await validateMultipleAllocationsUpload(allocationUploadRows);
  }

  const icAllocationRows = rows as MultipleIcAllocationRowResult[];
  const allocationUploadRows = icAllocationRows.map<AllocationUploadRow>((row) => ({
    AllocationCode: row.allocationCode,
    InstrumentId: row.pasteId,
    Weight: row.weight,
    RiskCurrency: row.riskCurrency,
    SubAssetClass: row.assetSubClass,
  }));

  return await validateMultipleAllocationsUpload(allocationUploadRows);
}

async function getDiff(validationResponses: AllocationUploadValidationResponse[]): Promise<AllocationChangeResult[]> {
  const allocationDiffPayload: AllocationDiffPayload[] = validationResponses.map((v) => {
    return {
      AllocationId: v.AllocationId,
      WeightedInstruments: v.InstrumentWeights.map((i) => {
        return {
          Instrument: i.Instrument,
          Weight: i.Weight,
          RiskCurrency: i.ReceivedOrDefaultRiskCurrency,
          SubAssetClass: i.ReceivedOrDefaultAssetSubClass,
          IsAverageWeight: false,
          IsDynamicWeight: false,
        };
      }),
    };
  });

  return await getDiffMultiple(allocationDiffPayload);
}

async function cancelAllocationEdit(allocationCodes: string[]) {
  await cancelEditForMultipleAllocations(allocationCodes);
}
