import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import * as React from "react";
import { AlpimaRequestSummary, AlpimaRequestHistory } from "./types/AlpimaRequestHistory";
import classNames from "classnames";
import formatDate from "date-fns/format";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../store/app/app-slice";
import * as api from "./apiRequests";
import { hideLoadingScreen, showLoadingScreen } from "../../../store/loading/loading-slice";

const PREFIX = 'AlpimaRequestStagingArea';
const classes = {
  summaryItem: `${PREFIX}-summaryItem`,
  details: `${PREFIX}-details`,
  boolChip: `${PREFIX}-boolChip`,
  title: `${PREFIX}-title`,
  list: `${PREFIX}-list`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.summaryItem}`]: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    "& .chip": {
      color: "white",
      width: "fit-content",
      paddingLeft: theme.spacing(0.75),
      paddingRight: theme.spacing(0.75),
      borderRadius: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },

    // these classes directly map to the possible values of AlpimaRequestSummary.Status
    // and are used in the chip
    "& .Unkown": {
      backgroundColor: theme.palette.error.main,
    },
    "& .Pending": {
      backgroundColor: theme.palette.info.main,
    },
    "& .IncludesUnprocessed": {
      backgroundColor: theme.palette.info.main,
    },
    "& .ValidationErrors": {
      backgroundColor: theme.palette.error.main,
    },
    "& .IntegrationError": {
      backgroundColor: theme.palette.error.main,
    },
    "& .Integrated": {
      backgroundColor: theme.palette.success.main,
    },
  },

  [`& .${classes.details}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontFamily: "monospace",
    "& button": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },

  [`& .${classes.boolChip}`]: {
    color: "white",
    width: "fit-content",
    paddingLeft: theme.spacing(0.75),
    paddingRight: theme.spacing(0.75),
    borderRadius: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: "red",
  },

  [`& .${classes.title}`]: {
    marginRight: theme.spacing(4),
  },

  [`& .${classes.list}`]: {
    "& li:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)", // same as `<ListItem button>`
    },
  }
}));

/**
 * Component that shows the Alpima->TST requests from the staging area.
 */
export default React.memo(function AlpimaRequestStagingArea(props: {
  info: AlpimaRequestHistory;
  doRefresh: () => void;
  loadMore: () => void;
  canWriteAdministration: boolean;
}) {
  const [rejectedAndErrorsOnly, setRejectedAndErrorsOnly] = React.useState(false);

  const dispatch = useDispatch();

  const filteredRequests = rejectedAndErrorsOnly
    ? props.info.Requests.filter((it) => it.Status === "IntegrationError" || it.Status === "ValidationErrors")
    : props.info.Requests;

  const runIntegration = async (requestId?: string) => {
    dispatch(showLoadingScreen());
    try {
      const runResponse = await api.runIntegration("alpima", requestId);
      dispatch(showAlert(runResponse.Message, { type: runResponse.OverallOutcome.toLowerCase() }));
      props.doRefresh();
    } catch (e) {
      dispatch(showAlert("Could not trigger integration.", { type: "error" }));
      console.error("runIntegration failed", e);
    } finally {
      dispatch(hideLoadingScreen());
    }
  };

  return (
    <Root>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="h2" className={classes.title}>
          Incoming Requests in Staging Area
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={rejectedAndErrorsOnly}
              onChange={() => setRejectedAndErrorsOnly(!rejectedAndErrorsOnly)}
              color="secondary"
              disableRipple
            />
          }
          label="rejected &amp; errors only"
        />
      </Box>
      <Box mt={1} mb={1}>
        <Tooltip title="Processes valid but pending requests. This is usually called automatically via UC4.">
          <Button disabled={!props.canWriteAdministration} onClick={() => runIntegration()}>
            Integrate pending requests
          </Button>
        </Tooltip>
      </Box>

      <List dense className={classes.list}>
        {filteredRequests.map((it, i) => {
          return (
            <ListItem key={i}>
              <RequestItem request={it} runIntegration={runIntegration} />
            </ListItem>
          );
        })}
      </List>
      <Box mt={3}>
        <Button disabled={props.info.Requests.length >= props.info.TotalRequestCount} onClick={props.loadMore}>
          Show more
        </Button>
      </Box>
    </Root>
  );
});

/**
 * Displays one single request (including details, which can be expaneded and allow to re-trigger the integration process).
 */
function RequestItem(props: { request: AlpimaRequestSummary; runIntegration: (requestId?: string) => void }) {
  const req = props.request;
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div key={req.RequestId}>
      <div className={classes.summaryItem} onClick={() => setIsExpanded(!isExpanded)}>
        <div>{formatDate(req.Recieved, "yyyy-MM-dd HH:mm:ss.SSS")}</div>
        <div className={classNames("chip", req.Status)}> {prettyPrintStatus(req)} </div>
        <div>
          {req.StagedAllocations?.length} allocation{req.StagedAllocations?.length > 1 && <>s</>} in request: {req.RequestId}
        </div>
      </div>
      {isExpanded && (
        <div className={classes.details}>
          {req.Status !== "Integrated" && req.Status !== "Pending" && (
            <Button size="small" onClick={() => props.runIntegration(req.RequestId)}>
              Force integration
            </Button>
          )}
          {req.StagedAllocations.map((it) => (
            <div>
              {it.Code} - AsOfDate: {formatDate(it.AsOfDate, "yyyy-MM-dd")} - Validation: {formatBool(it.ValidationOk)}
              {it.IntegrationSuccessful !== null && it.IntegrationSuccessful !== undefined && (
                <span>, Integration: {formatBool(it.IntegrationSuccessful)}</span>
              )}
              {it.ValidationMessages && (
                <Box ml={1}>
                  <div>Validation messages:</div>
                  {it.ValidationMessages.split("\\r\\n").map((line) => (
                    <div>{line}</div>
                  ))}
                </Box>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function prettyPrintStatus(summary: AlpimaRequestSummary) {
  if (summary.Status === "IntegrationError") {
    return "integration error";
  } else if (summary.Status === "ValidationErrors") {
    return "contains rejected";
  } else if (summary.Status === "IncludesUnprocessed") {
    return "includes unprocessed";
  } else {
    return summary.Status.toLocaleLowerCase();
  }
}

function formatBool(value: boolean) {
  return value ? "OK" : "Error";
}
