import * as React from "react";
import logo from "../../../assets/images/logo.svg";
import { useSelector } from "react-redux";
import { Location } from "history";
import { Link, useLocation } from "react-router-dom";
import { Piwik } from "../modules/piwik";
import TemporaryDrawer from "./TemporaryDrawer";
import classNames from "classnames";
import { AppState } from "../store/AppState";
import { Chevron, GroupIcon, HelpIcon } from "./shared/Icons";
import { styled } from '@mui/material/styles';
import { responsiveHorizontalPadding } from "./shared/styles";
import { UserState } from "../store/user/UserState";
import { useCats } from "./Nyan";
import { AllocationPageType } from "../types/AllocationPageType";

const PREFIX = 'Header';
const classes = {
  titleRow: `${PREFIX}-titleRow`,
  titleRowNavigation: `${PREFIX}-titleRowNavigation`,
  titleRowLogo: `${PREFIX}-titleRowLogo`,
  titleRowTitle: `${PREFIX}-titleRowTitle`,
  titleRowRight: `${PREFIX}-titleRowRight`
};
const Root = styled('header')((
  {
    theme
  }
) => ({
  // nearly one-to-one from former SASS code
  // TODO - improve / trim
  [`& .${classes.titleRow}`]: {
    fontSize: "18px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main, //$primary,
    color: "white", //$white,
    ...responsiveHorizontalPadding(theme),
  },

  [`& .${classes.titleRowNavigation}`]: {
    flexGrow: 1,
    height: "100%",
    "& ul": {
      height: "100%",
      listStyle: "none",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "start",
      alignItems: "center",
      alignContent: "stretch",
      margin: 0,
      padding: 0,
      "& li": {
        position: "relative",
        height: "100%",
        display: "flex",
        alignItems: "center",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        "& > div:last-child": {
          // the border marking the active item
          position: "absolute",
          height: "6px",
          width: "100%",
          bottom: "-6px",
          borderTop: "6px solid #e4c09f",
          visibility: "hidden",
        },
      },
    },
    "& a": {
      color: "white",
      padding: "6px", // hackish way to increase click area????
    },
    "& li:hover": {
      "& a": {
        backgroundColor: "#edc8a3", //$accent
        border: 0,
        color: "black",
      },
    },
    "& li.divider": {
      width: "4rem",
    },
    "& li.active": {
      "& a": {
        backgroundColor: "unset !important",
        fontWeight: "bold",
        color: "white !important",
      },
      "& > div:last-child": {
        visibility: "visible",
      },
    },
  },

  [`& .${classes.titleRowLogo}`]: {
    width: "26px",
    marginTop: "4px",
    marginRight: "20px",
  },

  [`& .${classes.titleRowTitle}`]: {
    marginTop: "4px",
    paddingRight: "8rem !important",
  },

  [`& .${classes.titleRowRight}`]: {
    "& > div": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      alignContent: "stretch",
      "& > div:first-child": {
        // help link
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        marginRight: "8rem",
        "& a": {
          height: "20px",
        },
      },
      "& > div:last-child": {
        cursor: "pointer",
        "& svg": {
          marginRight: "1rem",
          marginLeft: "1rem",
        },
        display: "flex",
        alignItems: "center",
      },
    },
  }
}));

export default function Header() {
  const location = useLocation();
  const user = useSelector((s: AppState) => s.user);
  const lastAdministrationComponent = useSelector((s: AppState) => s.admin.componentInView);
  const currentAllocationPageType = useSelector((s: AppState) => s.app.currentAllocationPageType);
  const userGuideUrl = useSelector((s: AppState) => s.masterData.userGuideUrl);

  const [cats, emitCat] = useCats();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const topLevelEntries: TopLevelEntry[] = [
    {
      title: "Model Portfolio",
      url: "/modelportfolios?lastState",
      allocationPageType: AllocationPageType.ModelPortfolio,
    },
    {
      title: "Allocation",
      url: "/allocations?lastState",
      allocationPageType: AllocationPageType.Allocation,
    },
    {
      title: "Instrument Lists",
      url: "/instrument-lists?category=AlpimaCore",
      allocationPageType: undefined,
    },
    {
      title: "Administration",
      url: "/administration/" + lastAdministrationComponent?.toLowerCase(),
      allocationPageType: undefined,
      check: (user) => user.canViewAdmin,
    },
    {
      title: "Marketing",
      url: "/marketing",
      allocationPageType: undefined,
      check: (user) => user.canViewDaisy,
    },
  ];

  function toggleDrawer() {
    setDrawerOpen(!drawerOpen);
  }

  function emitNyan() {
    emitCat("header");
    Piwik.push(["trackEvent", "nyan"]);
  }

  return (
    <Root>
      <div className={classes.titleRow}>
        <img onDoubleClick={emitNyan} src={logo as any} className={classes.titleRowLogo} />
        <span className={classes.titleRowTitle}>Target Setting Tool</span>
        <TemporaryDrawer toggleOpen={toggleDrawer} open={drawerOpen} />
        <nav className={classes.titleRowNavigation}>
          <ul>
            {topLevelEntries
              .filter((e) => e.check === undefined || e.check(user))
              .map((e) => ({ ...e, isActive: isActive(location, e, currentAllocationPageType) }))
              .map((e) => (
                <React.Fragment key={e.title}>
                  <li className={classNames({ active: e.isActive })}>
                    <Link to={e.url}>{e.title}</Link>
                    <div /*the border marking the active icon*/ />
                  </li>
                  {/* Acts as a divider. Margin/Padding is not easily practical, because of the border effect on the active element.  */}
                  <li className="divider" />
                </React.Fragment>
              ))}
          </ul>
        </nav>
        <div className={classes.titleRowRight}>
          <div>
            <div>
              <a href={userGuideUrl} rel="noopener noreferrer" target="_blank">
                <HelpIcon htmlColor="white" />
              </a>
            </div>
            <div onClick={toggleDrawer}>
              <GroupIcon htmlColor="white" />
              {user.firstName} {user.lastName}
              <Chevron htmlColor="white" />
            </div>
          </div>
        </div>
      </div>
      {cats}
    </Root>
  );
}

/**
 * Checks if the given toplevelEntry should be highlighted.
 */
function isActive(location: Location, topLevelEntry: TopLevelEntry, allocationPageType?: AllocationPageType) {
  let testUrl = topLevelEntry.url.toLowerCase();

  // strip (normalize) the URL so only the real 'top-level' part of the URL remains.
  if (testUrl.lastIndexOf("/") > 0) {
    testUrl = testUrl.substring(0, testUrl.lastIndexOf("/")).toLowerCase();
  }
  if (testUrl.indexOf("?") > -1) {
    testUrl = testUrl.substring(0, testUrl.indexOf("?"));
  }
  const urlMatch = location.pathname.startsWith(testUrl);

  if (urlMatch && topLevelEntry.allocationPageType === undefined) {
    return urlMatch;
  } else if (allocationPageType !== undefined && topLevelEntry.allocationPageType !== undefined) {
    if (topLevelEntry.allocationPageType !== undefined) {
      return allocationPageType === topLevelEntry.allocationPageType;
    }
  } else {
    return urlMatch;
  }
}

interface TopLevelEntry {
  title: string;
  url: string;
  allocationPageType?: AllocationPageType;
  check?: (user: UserState) => boolean;
}
