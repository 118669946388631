import React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";

/**
 * Basic dialog to confirm an operation.
 * Based on instrument-lists/ConfirmDialog but more generic
 */

export default function ConfirmDialog(props: {
  open: boolean;
  title: string;
  children: React.ReactNode;
  /**
   * defaults to 'OK'
   */
  okButtonLabel?: string;
  handleOk: () => void;
  okButtonDisabled?: boolean;
  handleCancel?: () => void;
  // defaults to true
  autoFocusOkButton?: boolean;
}) {
  const autofocusOk = props.autoFocusOkButton === undefined ? true : props.autoFocusOkButton;

  return (
    <Dialog
      maxWidth="md"
      aria-labelledby="confirmation-dialog-title"
      open={props.open}
      onClick={(evt) => evt.stopPropagation()}
      onClose={(_ev, reason) => reason !== "backdropClick" && reason !== "escapeKeyDown"}
    >
      <DialogTitle id="confirmation-dialog-title">{props.title}</DialogTitle>
      <DialogContent dividers>{props.children}</DialogContent>
      <DialogActions>
        {props.handleCancel && (
          <Button
            autoFocus={autofocusOk}
            onClick={(evt) => {
              evt.stopPropagation();
              if (props.handleCancel) {
                props.handleCancel();
              }
            }}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={(evt) => {
            evt.stopPropagation();
            props.handleOk();
          }}
          color="primary"
          variant="contained"
          disabled={props.okButtonDisabled}
        >
          {props.okButtonLabel || "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
