import React from "react";
import DropdownBase from "../shared/DropdownBase";
import throttle from "lodash/throttle";
import { baseUrl } from "../../store/apiUrlProvider";
import { request } from "../../modules/client";

/**
 * Autocomplete control providing search for users and groups.
 */
export default function UserAndGroupAutocomplete(props: {
  label: string;
  containerId: string;
  initialInputValue: UserAndGroupSearchResult;
  onSelect: (owner: UserAndGroupSearchResult) => void;
  fullWidth?: boolean;
}) {
  const [searchResults, setSearchResults] = React.useState<UserAndGroupSearchResult[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  async function triggerSearch(inputValue: Maybe<string>) {
    inputValue = inputValue || "";
    if (inputValue?.length < MINIMUM_INPUT_LENGTH) {
      setSearchResults([]);
      return;
    }

    search.cancel();
    setIsLoading(true);
    const result = await search(inputValue);
    setIsLoading(false);
    setSearchResults(result || []);
  }

  return (
    <DropdownBase
      label={props.label}
      items={searchResults}
      onSelect={props.onSelect}
      formatItem={(i) => i.DisplayName}
      initialSelectedItem={props.initialInputValue}
      onInputChange={triggerSearch}
      isLoading={isLoading}
      preventAutoClear
      fullWidth={props.fullWidth}
    />
  );
}

type UserAndGroupSearchResult = { Id: number; DisplayName: string };

const MINIMUM_INPUT_LENGTH = 2;

const search = throttle(
  (searchTerm: string) =>
    request(baseUrl() + "api/user/search", {
      method: "POST",
      payload: {
        text: searchTerm,
      },
    }),
  200
);
