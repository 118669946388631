import * as React from "react";
import { IconButton } from "@mui/material";
import { DownloadIcon } from "../shared/Icons";
import { baseUrl } from "../../store/apiUrlProvider";

export default React.memo(function ProductDownload(props: {
  date: string;
  productId: string;
  type: "figures" | "timeseries" | "samplemopo" | "breakdowns";
}) {
  const { date, productId, type } = props;
  const url = `${baseUrl()}api/daisy/products/${type}/iqy?productIds=${productId}&date=${date}`;
  return (
    <IconButton size="small" onClick={() => window.open(url, "_blank")}>
      {downloadIcon}
    </IconButton>
  );
});

const downloadIcon = <DownloadIcon fontSize="small" color="secondary" />;
