/**
 * Enum separate from types/AllocationType to differentiate between the page for SAA&TAA vs. the page for ModelPortfolios.
 */
export enum AllocationPageType {
  /**
   * The page that shows the Modelportfolios only.
   */
  ModelPortfolio,
  /**
   * the page that shows SAA & TAA.
   */
  Allocation,
}

/**
 * Converts the given AllocationPageType into the 'resultKey' used in the store.
 */
export const allocationPageTypeToResultKey = (value: AllocationPageType): "modelPortfolios" | "allocations" => {
  if (value == AllocationPageType.ModelPortfolio) {
    return "modelPortfolios";
  } else if (value == AllocationPageType.Allocation) {
    return "allocations";
  } else {
    throw new Error(`Unhandled AllocationPageType: ${value}`);
  }
};

export function getAllocationPageTypeFromPath(path: string): AllocationPageType | undefined {
  if (path === "/modelportfolios") {
    return AllocationPageType.ModelPortfolio;
  } else if (path === "/allocations") {
    return AllocationPageType.Allocation;
  }
  return undefined;
}
