import React from "react";
import { Environment } from "../types/ConfigVariables";

type ErrorBoundaryProps = { children: React.ReactNode; environment?: Environment; softwareVersion?: string };
type ErrorBoundaryState = { hasError: boolean; error?: Error; errorTime?: Date };

/**
 * Implements an error boundry to display errors in the rendering of the child components.
 * For more information see: https://reactjs.org/docs/error-boundaries.html.
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the error UI.
    return { hasError: !!error, error: error, errorTime: new Date() };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ marginLeft: "1em" }}>
          <h1>
            An error occured. You can try going{" "}
            <a
              href="#"
              onClick={(evt) => {
                evt.preventDefault();
                window.history.back();
                setTimeout(() => {
                  window.location.reload();
                }, 100);
              }}
            >
              back
            </a>{" "}
            and repeating the same action,{" "}
            <a
              href="#"
              onClick={(evt) => {
                evt.preventDefault();
                window.location.reload();
              }}
            >
              refresh
            </a>{" "}
            the page or navigate to the <a href="/">TST homepage</a>.
          </h1>
          {this.state.error && (
            <div>
              <h3>Error: {this.state.error.message}</h3>
              <div>Date: {this.state.errorTime?.toUTCString()}</div>
              <div>Version: {this.props.softwareVersion}</div>
              <div>Environment: {this.props.environment}</div>
              <div>Details:</div>
              <div style={{ fontSize: "smaller", whiteSpace: "pre-wrap" }}>{this.state.error.stack}</div>
            </div>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}
