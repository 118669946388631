import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { showAlert, updateCurrentAllocationPageType } from "../../store/app/app-slice";
import { AppState } from "../../store/AppState";
import { hideLoadingScreen, showLoadingScreen } from "../../store/loading/loading-slice";
import ReferenceDate from "./ReferenceDate";
import ReferenceDateSelector from "./ReferenceDateSelector";
import DaisyFilterPanel from "./DaisyFilterPanel";
import DaisyPublishProgress from "./DaisyPublishProgress";
import DaisyDataSources from "./DaisyDataSources";
import DaisyProductLines from "./DaisyProductLines";
import * as api from "./apiRequests";
import { ProductLineStatusApi, DataSourceStatus, groupProductStatus, ProductLineStatusMap, ProductDataStatus } from "./utils";
import { useQueryParameterSet } from "../../modules/useQuery";
import { isUserTokenValid } from "../../auth/oidcConfig";

let productStatusLoopStarted = false;
export default function Daisy() {
  const dispatch = useDispatch();
  const authorized = useSelector((state: AppState) => state.user.canViewDaisy);
  const [referenceDate, setReferenceDate] = React.useState<ReferenceDate | null>(null);
  const [productStatus, setProductStatus] = React.useState<ProductLineStatusMap>({});
  const [dataSourceStatus, setDataSourceStatus] = React.useState<DataSourceStatus | undefined>();
  const [activeFilters, setActiveFilters] = useQueryParameterSet("f", (q) => q as ProductDataStatus | ProductLineStatusApi);
  const anyDataSourceErrors = Object.keys(dataSourceStatus?.ErrorMessages || {}).length > 0;
  

  function onReferenceDateChange(date: ReferenceDate) {
    setReferenceDate(date);
    syncUri(date);
  }
  
  async function loadProductStatus() {
    try {
      const result = await api.fetchProductStatus(referenceDate!);
      setProductStatus(groupProductStatus(result));
    } catch (err) {
      dispatch(showAlert("Error fetching product status", { type: "error" }));
      console.error(err);
    }
  }

  async function loadDataSourceStatus() {
    try {
      const result = await api.fetchDataSourceStatus(referenceDate!);
      setDataSourceStatus(result);
      console.log(result);
    } catch (err) {
      dispatch(showAlert("Error fetching data source status", { type: "error" }));
      console.error(err);
    } 
  }

  React.useEffect(() => {
    dispatch(updateCurrentAllocationPageType(undefined));
  }, []);


  React.useEffect(() => {
    if (referenceDate) { // only fetch data if referenceDate is not null
      const refreshData = async () => {
        // only fetch data if user is authenticated
        const userTokenValid: boolean = await isUserTokenValid();
        if (!userTokenValid) {
          return;
        }

        await loadProductStatus();
        await loadDataSourceStatus();
      };

      refreshData();
      const intervalId = setInterval(refreshData, 10000);
      return () => clearInterval(intervalId);
    }
  }, [referenceDate]);

  return  (
    <div style={{ position: "relative" }}>      
      <Box mb={5}>
        <Typography variant="h2">Marketing Data Publication</Typography>
      </Box>
      {(authorized && (
        <div>
          <Grid container spacing={4}>
            <Grid item xs={2}>
              <Box style={{ position: "sticky", top: 0 }}>
                <Box pt={2}>
                  <ReferenceDateSelector onChange={onReferenceDateChange} />
                </Box>
                <Box mt={3}>
                  <DaisyPublishProgress data={productStatus} />
                </Box>
                {dataSourceStatus && (
                  <Box mt={3}>
                    <DaisyDataSources data={dataSourceStatus} />
                  </Box>
                )}
                <Box mt={3}>
                  <DaisyFilterPanel data={productStatus} activeFilters={activeFilters} onFilterChange={setActiveFilters} />
                </Box>
              </Box>
            </Grid>
            {referenceDate && <Grid item xs={10}>
              <DaisyProductLines
                data={productStatus}
                filters={activeFilters}
                referenceDate={referenceDate!}
                reloadProductStatus={loadProductStatus}
                publishDisabled={anyDataSourceErrors}
              />
            </Grid>
            }
          </Grid>
        </div>
      )) || <h1>You do not have the necessary privileges to view this page.</h1>}
    </div> 
  );
}

function syncUri(date: ReferenceDate) {
  const path = "/marketing/" + date.toPathParameter();
  if (window.location.pathname !== path) {
    // replace instead of push, because we won't bloat the browser history unnecessarily
    window.history.replaceState(null, "", path + window.location.search);
  }
}
