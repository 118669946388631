import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  IconButton,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import InfoBox from "../shared/InfoBox";
import AaaStrategyNature from "../../types/AaaStrategyNature";
import Allocation from "../../types/Allocation";
import Destination from "../../types/Destination";
import MasterDataEntry from "../../types/MasterDataEntry";
import Distribution from "../../types/Distribution";
import RemoveIcon from "@mui/icons-material/Remove";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import EditButton from "./EditButton";
import EditDistributionDialog from "./EditDistributionDialog";
import AllocationType from "../../types/AllocationType";
import { fromMasterDataEntry } from "../../types/MarketStructure";

const PREFIX = 'DistributionTable';
const classes = {
  headerContainer: `${PREFIX}-headerContainer`,
  oddRow: `${PREFIX}-oddRow`,
  firstColumn: `${PREFIX}-firstColumn`,
  infoBox: `${PREFIX}-infoBox`,
  infoBoxRow: `${PREFIX}-infoBoxRow`,
}
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.headerContainer}`]: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: theme.spacing(0.5),
  },
  [`& .${classes.oddRow}`]: {
    backgroundColor: "#f7f6f2",
  },
  [`& .${classes.firstColumn}`]: {
    paddingLeft: theme.spacing(1),
  },
  [`& .${classes.infoBox}`]: {
    marginTop: theme.spacing(1),
  },
  [`& .${classes.infoBoxRow}`]: {
    display: "flex",
    justifyContent: "flex-start",

    marginTop: theme.spacing(0.5),
    "& :first-child": {
      width: "11em",
    },
    "& :last-child": {
      width: "85%", //HACK: not sure why this is needed for the rows to correctly align
    },
  },
}));

/**
 * Displays a table with the distributions of an allocation.
 * If 'isAdmin' and 'isEditable' distributions can be created/edited/deleted.
 */
export default function DistributionTable(props: {
  allocation: Allocation;
  isAdmin: boolean;
  isEditable: boolean;
  marketStructures: MasterDataEntry[];
  aaaStrategyNatures: AaaStrategyNature[];
  destinations: Destination[];
}) {
  const { allocation, isAdmin, isEditable, marketStructures, aaaStrategyNatures, destinations } = props;
  const [distributionInEdit, setDistributionInEdit] = React.useState<Distribution | null>(null);

  const showButtons = isAdmin && isEditable;

  const isMopo = allocation.AllocationType === AllocationType.ModelPortfolio;

  return (
    <Root>
      {distributionInEdit && (
        <EditDistributionDialog
          allocation={allocation}
          distribution={distributionInEdit}
          marketStructures={marketStructures}
          aaaStrategyNatures={aaaStrategyNatures}
          destinations={destinations}
          closeHandle={() => setDistributionInEdit(null)}
          createAnotherHandle={(value: Distribution) => {
            const copy = { ...value };
            copy.Id = -1;
            setDistributionInEdit(copy);
          }}
        />
      )}
      <div className={classes.headerContainer}>
        <Typography variant="h3">Distribution Configuration</Typography>
        {showButtons && (
          <IconButton
            size="medium"
            onClick={() => {
              // Inital distribution with reasonable defaults for a new distribution
              setDistributionInEdit({
                Id: -1, // used to identify new distributions
                ContainerId: allocation.Id,
                Code: "",
                Destination: destinations.find((it) => it.DestinationShort === "CH"),
                MarketStructure: fromMasterDataEntry(marketStructures.find((it) => it.ShortName === "Ni")),
                AaaStrategyNature: aaaStrategyNatures.find((it) => it.AaaStrategyNatureShort === "AA (IM AA)"),
                EnableInheritance: false,
                UseForStrategyLink: false,
                UseForAllocationPublish: true,
                GenerateDynWeightRequest: false,
              });
            }}
          >
            <AddIcon />
          </IconButton>
        )}
      </div>
      <Table>
        <DistributionTableHead />
        <TableBody>
          {allocation.Distributions?.length > 0 &&
            allocation.Distributions?.map((dist, i) => (
              <DistributionRow
                key={dist.Code}
                distribution={dist}
                editCallback={() => setDistributionInEdit(dist)}
                isMopo={isMopo}
                isOddRow={i % 2 == 0}
                showButtons={showButtons}
              />
            ))}
        </TableBody>
      </Table>
      {allocation.Distributions?.length === 0 && (
        <Typography variant="body1">No distributions configured yet. You can create a new one with the 'Plus' button.</Typography>
      )}

      <InfoBox containerClassName={classes.infoBox}>
        <div className={classes.infoBoxRow}>
          <div>Inherit:</div>
          <div>
            Configurations flagged to be inherited to children are used to pre-populate the distribution configuration of derived
            tailored child allocations.
          </div>
        </div>
        <div className={classes.infoBoxRow}>
          <div>Use for strategy link:</div>
          <div>
            Configurations flagged to be used for strategy links are sent to the respective TripleA to link a model portfolio to a
            client portfolio.
          </div>
        </div>
      </InfoBox>
    </Root>
  );
}

function DistributionRow(props: {
  distribution: Distribution;
  editCallback: () => void;
  isOddRow: boolean;
  isMopo: boolean;
  showButtons: boolean;
}) {
  const { distribution, isOddRow, isMopo, showButtons } = props;
  return (
    <TableRow className={isOddRow ? classes.oddRow : ""}>
      <TableCell className={classes.firstColumn}>{distribution.Destination?.DestinationLong}</TableCell>
      {isMopo && <TableCell>{distribution.MarketStructure?.MarketStructure}</TableCell>}
      {!isMopo && <TableCell>{distribution.AaaStrategyNature?.AaaStrategyNatureShort}</TableCell>}
      <TableCell>
        <Symbol value={distribution.EnableInheritance} />
      </TableCell>
      <TableCell>
        <Symbol value={distribution.UseForStrategyLink} />
      </TableCell>
      <TableCell>
        <Symbol value={distribution.UseForAllocationPublish} />
      </TableCell>
      <TableCell>
        <Symbol value={distribution.GenerateDynWeightRequest} />
      </TableCell>
      <TableCell>{distribution.Code}</TableCell>
      <TableCell align="center">
        {showButtons && <EditButton tooltip="Edit this distribution" onClick={props.editCallback} smallMargin />}
      </TableCell>
    </TableRow>
  );
}

function Symbol(props: { value: boolean | undefined | null }) {
  return props.value ? <CheckIcon /> : <RemoveIcon />;
}

function DistributionTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.firstColumn} style={{ width: "8em" }}>
          Triple'A
        </TableCell>
        <TableCell style={{ width: "9em" }}>
          Market <br />
          Structure
        </TableCell>
        <TableCell style={{ width: "7em" }}>Inherit</TableCell>
        <TableCell style={{ width: "7em" }}>
          Use for <br /> strategy <br /> links
        </TableCell>
        <TableCell style={{ width: "7em" }}>
          Use for <br /> allocation <br /> publish
        </TableCell>
        <TableCell style={{ width: "7em" }}>
          Request <br /> dynamic <br /> weights
        </TableCell>
        <TableCell style={{ width: "35em" }}>Triple'A Code</TableCell>
        <TableCell style={{ width: 46 }}></TableCell>
      </TableRow>
    </TableHead>
  );
}
