import { useDispatch } from "react-redux";
import { request } from "../../modules/client";
import { baseUrl } from "../../store/apiUrlProvider";
import { showAlert } from "../../store/app/app-slice";
import { hideLoadingScreen, showLoadingScreen } from "../../store/loading/loading-slice";
import { changeDistributionSuccess } from "../../store/pages/pages-slice";
import Allocation from "../../types/Allocation";
import { allocationTypeToResultKey } from "../../types/AllocationType";
import Distribution from "../../types/Distribution";

/**
 * Hook encapsulating distribution CRUD API.
 */
export default function useDistributionApi(allocation: Allocation) {
  const dispatch = useDispatch();
  const apiUrl = baseUrl() + "api/modelportfolio/distribution";
  const resultKey = allocationTypeToResultKey(allocation.AllocationType);

  return {
    async createDistribution(distribution: Distribution) {
      await validate(distribution);
      dispatch(showLoadingScreen());
      try {
        const response = (await request(apiUrl, { method: "POST", payload: distribution })) as Distribution[];

        dispatch(
          changeDistributionSuccess({
            id: distribution.ContainerId,
            resultKey, // TODO - pass allocationType and let the slice handle the conversion since this is a state detail
            distributions: response,
          })
        );

        dispatch(showAlert("Changed distribution successfully", { type: "success" }));
      } catch (e) {
        console.error("createDistribution failed", e);
        dispatch(showAlert("Creating distribution failed.", { type: "error" }));
      } finally {
        dispatch(hideLoadingScreen());
      }
    },
    async updateDistribution(distribution: Distribution) {
      await validate(distribution);
      dispatch(showLoadingScreen());
      try {
        const response = await request(apiUrl, { method: "PUT", payload: distribution });

        dispatch(
          changeDistributionSuccess({
            id: distribution.ContainerId,
            resultKey, // TODO - pass allocationType and let the slice handle the conversion since this is a state detail
            distributions: response,
          })
        );

        dispatch(showAlert("Changed distribution successfully", { type: "success" }));
      } catch (e) {
        console.error("updateDistribution failed", e);
        dispatch(showAlert("Updating distribution failed.", { type: "error" }));
      } finally {
        dispatch(hideLoadingScreen());
      }
    },
    async deleteDistribution(distribution: any) {
      dispatch(showLoadingScreen());
      try {
        const response = await request(apiUrl, { method: "DELETE", payload: distribution });

        dispatch(
          changeDistributionSuccess({
            id: distribution.ContainerId,
            resultKey, // TODO - pass allocationType and let the slice handle the conversion since this is a state detail
            distributions: response,
          })
        );

        dispatch(showAlert("Deleted distribution successfully", { type: "success" }));
      } catch (e) {
        console.error("deleteDistribution failed", e);
        dispatch(showAlert("Deleting distribution failed.", { type: "error" }));
      } finally {
        dispatch(hideLoadingScreen());
      }
    },
  };

  async function validate(distribution: Distribution) {
    var validators = [validateRequiredFields, validateUniqueness];
    for (let i = 0; i < validators.length; i++) {
      var result = await validators[i](distribution);
      if (result === true) {
        continue;
      }
      dispatch(showAlert(result, { type: "warning" }));
      throw new Error("invalid distribution");
    }
  }

  function validateRequiredFields(distribution: Distribution) {
    const isValid =
      (distribution.MarketStructure || distribution.AaaStrategyNature) && distribution.Destination && distribution.Code;
    return Promise.resolve(
      !!isValid || "Please enter a distribution configuration with a destination, market structure and code."
    );
  }

  async function validateUniqueness(distribution: Distribution) {
    const objectCode = (await request(
      apiUrl + `/check?destinationId=${distribution.Destination?.Id}&code=${distribution.Code}`
    )) as Maybe<string>;
    if (
      // first occurrence
      !objectCode ||
      // already persisted for the current in-edit-distribution
      // (a user clicks edit, changes nothing, and clicks save)
      (objectCode === allocation.Name &&
        !allocation.Distributions.find(
          (d) => d.Id !== distribution.Id && d.Destination?.Id === distribution.Destination?.Id && d.Code === distribution.Code
        ))
    ) {
      return true;
    }
    const isSameAllo = allocation.Name === objectCode;
    return `This combination of destination and code is already in use ${isSameAllo ? "here" : "in " + objectCode}.`;
  }
}
