import * as React from "react";
import { styled } from '@mui/material/styles';
import { masterDataRequest } from "../../store/masterData/masterData-slice";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useForm, Controller, FieldError } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AssetTree } from "../../types/AssetTree";
import LoadingIndicator from "../shared/LoadingIndicator";
import InfoBox from "../shared/InfoBox";
import { baseUrl } from "../../store/apiUrlProvider";
import { request } from "../../modules/client";
import { showAlert, showErrorDialog } from "../../store/app/app-slice";
import { MasterDataSettings } from "../../store/masterData/MasterDataState";

const PREFIX = 'SettingsDialog';
const classes = {
  fullWidth: `${PREFIX}-fullWidth`,
  row: `${PREFIX}-row`,
  content: `${PREFIX}-content`,
  infoTextContainer: `${PREFIX}-infoTextContainer`
};
const StyledDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`& .${classes.fullWidth}`]: {
    width: "100%",
  },

  [`& .${classes.row}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.content}`]: {
    fontSize: theme.typography.body1.fontSize,
  },

  [`& .${classes.infoTextContainer}`]: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
  }
})) as typeof Dialog;

function ValidationMessage(props: { errors?: FieldError }) {
  if (!props.errors) {
    return <React.Fragment />;
  }
  return <div>{props.errors.message}</div>;
}

async function saveSettingsRequest(values: MasterDataSettings) {
  var options = {
    method: "POST",
    payload: values,
  };

  return request(`${baseUrl()}api/masterdata/settings/`, options);
}

export default function SettingsDialog(props: {
  open: boolean;
  initialValues: MasterDataSettings;
  trees: AssetTree[];
  closeHandle: () => void;
}) {
  const { initialValues, trees } = props;


  const dispatch = useDispatch();
  const [isBusy, setIsBusy] = React.useState<boolean>(false);

  const saveSettings = (values: MasterDataSettings) => {
    setIsBusy(true);
    saveSettingsRequest(values)
      .then((_) => {
        dispatch(showAlert("Updated settings", { type: "success" }));
        dispatch(masterDataRequest());
        props.closeHandle();
        setIsBusy(false);
      })
      .catch((reason) => {
        setIsBusy(false);
        dispatch(showErrorDialog({ reason }));
      });
  };

  const { handleSubmit, register, formState: { errors }, control } = useForm<MasterDataSettings>({ mode: "onChange" });

  return (
    <StyledDialog disableEscapeKeyDown maxWidth="md" aria-labelledby="confirmation-dialog-title" open={props.open}>
      <form onSubmit={handleSubmit(saveSettings)}>
        <DialogTitle id="confirmation-dialog-title">Edit Settings</DialogTitle>

        <DialogContent dividers className={classes.content}>
          <div>
            <div className={classes.row}>
              <FormControl fullWidth>
                <InputLabel id="defaultTree-label">Default asset class tree *</InputLabel>
                {/* register doesn't work with <Select>, so use a Controller here */}
                <Controller
                  render={({ field }) =>
                      <Select
                          {...field}
                          labelId="defaultTree-label"
                          label="Default asset class tree *"
                          children={trees.map((e) => (
                              <MenuItem key={e.Structure} value={e.Structure}>
                                {e.Structure}
                              </MenuItem>
                          ))}
                      />
                  }
                  name="DefaultTree"
                  control={control}
                  defaultValue={initialValues.DefaultTree}
                />
              </FormControl>
            </div>
            <div className={classes.row}>
              <TextField
                fullWidth
                error={!!errors.ThresholdAvgDyn}
                defaultValue={initialValues.ThresholdAvgDyn}
                inputProps={{ step: 0.1 }}
                {...register(
                    'ThresholdAvgDyn',
                    {
                      required: "Required.",
                      min: {
                        value: 0.1,
                        message: "Value must be at least 0.1.",
                      },
                      max: {
                        value: 5,
                        message: "Value must be at most 5.",
                      },
                    }
                )}
                type="number"
                label="Threshold for coloring (in %) *"
              />
              <ValidationMessage errors={errors.ThresholdAvgDyn} />
            </div>
            <div className={classes.row}>
              <TextField
                fullWidth
                error={!!errors.AllocationSumTolerance}
                defaultValue={initialValues.AllocationSumTolerance}
                type="number"
                inputProps={{ step: 0.000001 }}
                {...register(
                    'AllocationSumTolerance',
                    {
                      required: "Required.",
                      min: {
                        value: 0.0,
                        message: "Value must be at least 0.0.",
                      },
                      max: {
                        value: 0.1,
                        message: "Value must be at most 0.1.",
                      },
                    }
                )}
                label="Tolerance for the sum of weights *"
              />
              <ValidationMessage errors={errors.AllocationSumTolerance} />
              <div className={classes.row}>
                <TextField
                  fullWidth
                  error={!!errors.TstMailbox}
                  defaultValue={initialValues.TstMailbox}
                  type="string"
                  {...register(
                      'TstMailbox',
                      {
                        required: "Required.",
                      }
                  )}
                  label="TST mailbox *"
                />
              </div>
              <div className={classes.row}>
                <TextField
                  fullWidth
                  error={!!errors.FinanceDocRequestMailRecipient}
                  defaultValue={initialValues.FinanceDocRequestMailRecipient}
                  type="string"
                  {...register(
                      'FinanceDocRequestMailRecipient',
                      {
                        required: "Required.",
                      }
                  )}
                  label="FinanceDoc request mail recipient *"
                />
              </div>
              <div className={classes.row}>
                <TextField
                  fullWidth
                  error={!!errors.PublishRetryCount}
                  defaultValue={initialValues.PublishRetryCount}
                  inputProps={{ step: 1 }}
                  {...register(
                      'PublishRetryCount',
                      {
                        required: "Required.",
                        min: {
                          value: 0,
                          message: "Value must be at least 0.",
                        },
                        max: {
                          value: 20,
                          message: "Value must be at most 20.",
                        },
                      }
                  )}
                  type="number"
                  label="Publish retry count"
                />
                <ValidationMessage errors={errors.PublishRetryCount} />
              </div>
            </div>
            <InfoBox containerClassName={classes.infoTextContainer}>
              <span>
                This uses decimal values (1.0 is 100%).
                <br />
                Please note, that this applies to the TST-UI, the publishing to TAP and
                <br />
                the validation of allocations from Alpima (in TST-AA).
              </span>
            </InfoBox>
          </div>
        </DialogContent>
        <LoadingIndicator show={isBusy} size="40">
          <DialogActions>
            <Button
              disabled={isBusy}
              autoFocus
              onClick={(evt) => {
                evt.stopPropagation();
                props.closeHandle();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained" disabled={isBusy}>
              Save
            </Button>
          </DialogActions>
        </LoadingIndicator>
      </form>
    </StyledDialog>
  );
}
