import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import cn from "classnames";
import { AllocationRowDescriptor } from "./types";
import { AppState } from "../../store/AppState";
import { didScrollIntoView } from "./slice";
import { descriptorWidths, levelIndent, rowBackground, rowStyle } from "./commonStyles";
import useCompareSelector from "./useCompareSelector";
import AllocationType from "../../types/AllocationType";

/**
 * The table row header showing the category or instrument data.
 */

const PREFIX = 'RowDescriptionCell';
const classes = {
  root: `${PREFIX}-root`,
  hide: `${PREFIX}-hide`,
  addedMarker: `${PREFIX}-addedMarker`,
  highlight: `${PREFIX}-highlight`,
  keyframesHighlight: `@keyframes ${PREFIX}-highlight`,
  header: `${PREFIX}-header`,
  line: `${PREFIX}-line`,
  deleted: `${PREFIX}-deleted`,
}
const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: "relative",
    paddingLeft: theme.spacing(1),
    ...rowStyle(theme),
    boxSizing: "border-box",
    "& *": {
      boxSizing: "border-box",
    },
  },
  [`& .${classes.hide}`]: {
    display: "none",
  },
  [`& .${classes.addedMarker}`]: {
    paddingLeft: theme.spacing(0.5),
    borderLeft: "solid " + theme.spacing(0.5) + "px #001489",
  },
  [`& .${classes.highlight}`]: {
    animation: "$highlight 1.5s ease-out",
  },
  [`& .${classes.keyframesHighlight}`]: {
    "0%": { backgroundColor: "#C0C5E2" },
    "75%": { backgroundColor: "#C0C5E2" },
    "100%": { backgroundColor: "none" },
  },
  [`& .${classes.header}`]: {
    alignItems: "flex-end",
  },
  [`& .${classes.line}`]: {
    display: "flex",
    flexDirection: "row",
    "& > div": {
      paddingRight: theme.spacing(1),
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  [`& .${classes.deleted}`]: {
    textDecoration: "line-through",
  },
}));

export function RowDescriptionHeader(props: { maxLevel: number; level: number }) {
  const theme = useTheme();
  const { maxLevel, level } = props;
  const { valor, isin, name, riskCcy, tradeCcy } = descriptorWidths(theme, maxLevel);

  return (
    <Root className={classes.root}>
      <div className={cn(classes.line, classes.header)}>
        <div style={{ ...valor, ...levelIndent(theme, level) }}>Instrument</div>
        <div style={isin}>ISIN</div>
        <div style={name}>Name</div>
        <div style={riskCcy}>
          Risk
          <br />
          Ccy
        </div>
        <div style={tradeCcy}>
          Trade
          <br />
          Ccy
        </div>
      </div>
    </Root>
  );
}

export default function RowDescriptionCell(props: { row: AllocationRowDescriptor; maxLevel: number }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { row, maxLevel } = props;
  const widths = descriptorWidths(theme, maxLevel);
  const preferPrettyNames = useSelector((s: AppState) => !!s.user.preferences?.preferPrettyInstrumentNames);

  const rowRef = React.useRef(React.createRef<HTMLDivElement>());
  const addedInstrumentIds = useCompareSelector((s) => s.addedInstrumentIds);
  const isNoTca = useCompareSelector((s) => s.allocationType !== AllocationType.Tca);
  const isAdded = row.isInstrument && isNoTca && addedInstrumentIds.includes(row.rowId);
  const highlightInstrumentIds = useCompareSelector((s) => s.highlightInstrumentIds);
  const doScrollIntoView = row.isInstrument && highlightInstrumentIds[0] === row.rowId;
  const doHighlight = row.isInstrument && highlightInstrumentIds.includes(row.rowId);
  const isDeleted = row.isInstrument && row.isDeleted;

  const instrumentTooltipParts: string[] = [];
  if (row.isInstrument) {
    if (row.longNamePretty) {
      instrumentTooltipParts.push(row.longNamePretty);
    }
    if (row.isDeleted) {
      instrumentTooltipParts.push("Attention: This instrument is obsolete and no longer valid!");
    }
  }

  // unfortunately, we cannot structure the tooltip text in terms of line breaks or similar
  const tooltip = instrumentTooltipParts.join(" / ");

  React.useEffect(() => {
    let timers: any[] = [];
    if (doScrollIntoView) {
      timers = [
        setTimeout(() => rowRef.current.current?.scrollIntoView({ behavior: "smooth", block: "center" }), 0),
        setTimeout(() => dispatch(didScrollIntoView()), 1501), // wait for the animation
      ];
    }
    return () => timers.forEach(clearTimeout);
  }, [doScrollIntoView]);

  return (
    <Root
      ref={rowRef.current}
      className={cn(classes.root, doHighlight && classes.highlight, isAdded && classes.addedMarker)}
      style={rowBackground(row)}
    >
      <div className={cn(classes.line, isDeleted && classes.deleted)}>
        {row.isInstrument ? (
          <>
            <div className="valor" style={{ ...widths.valor, ...levelIndent(theme, row.level) }}>
              {row.valor}
            </div>
            <div className="isin" style={widths.isin}>
              {row.isin}
            </div>
            <Tooltip title={tooltip} enterDelay={500}>
              <div className="name" style={widths.name}>
                {preferPrettyNames ? row.longNamePretty : row.shortName}
              </div>
            </Tooltip>
            <div className="riskCcy" style={widths.riskCcy}>
              {row.riskCurrency}
            </div>
            <div className="tradeCcy" style={widths.tradeCcy}>
              {row.defaultTradingCurrency}
            </div>
          </>
        ) : (
          <div className="category" style={{ ...widths.category, ...levelIndent(theme, row.level) }}>
            {row.category}
          </div>
        )}
      </div>
    </Root>
  );
}
