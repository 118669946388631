import * as React from "react";
import { AppState } from "../../store/AppState";
import { connect, ConnectedProps } from "react-redux";
import { modelPortfolioPageRequest, updateFilter } from "../../store/pages/pages-slice";
import AllocationTableContainer from "./AllocationTableContainer";
import AllocationTableHeader from "./AllocationTableHeader";
import { updateCurrentAllocationPageType } from "../../store/app/app-slice";
import { AllocationPageType } from "../../types/AllocationPageType";

const mapStateToProps = (state: AppState) => {
  return {
    masterData: state.masterData,
    pagination: state.pages.pagination.modelPortfolios,
    allocations: state.pages.modelPortfolios,
    page: state.pages.pagination.modelPortfolios.currentPage,
    pageSize: state.pages.pagination.modelPortfolios.pageSize,
    totalCount: state.pages.pagination.modelPortfolios.totalCount,
    selection: state.pages.selection.modelPortfolios,
    filters: state.pages.pagination.modelPortfolios.filter,
    sort: state.pages.pagination.modelPortfolios.order,
  };
};

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

class ModelPortfolios extends React.PureComponent<ReduxProps> {
  constructor(props: ReduxProps) {
    super(props);
    this.props.dispatch(updateCurrentAllocationPageType(AllocationPageType.ModelPortfolio));
  }

  render() {
    const dispatch = this.props.dispatch;
    return (
      <>
        <AllocationTableHeader
          allocationPasteType="mopo"
          title="Model Portfolio"
          onFilterChange={(filterId, filterState) => {
            this.props.dispatch(updateFilter({ id: filterId, state: filterState, pageType: AllocationPageType.ModelPortfolio }));
          }}
          filters={this.props.filters}
        />
        <AllocationTableContainer
          {...this.props}
          allocationPageType={AllocationPageType.ModelPortfolio}
          loadPage={(page: number) => {
            dispatch(modelPortfolioPageRequest(page, this.props.pageSize, this.props.sort, this.props.filters));
          }}
          columnData={columns}
        />
      </>
    );
  }
}

export default connector(ModelPortfolios);

const columns = [
  {
    id: "ProductLine",
    label: "Product",
  },
  {
    id: "ProductDistinction",
    label: "Dist.",
  },
  {
    id: "ReferenceCurrency",
    label: "CCY",
  },
  {
    id: "RiskProfile",
    label: "Inv. Strategy",
  },
  {
    id: "BmoContext",
    label: "BMO",
  },
  {
    id: "Name",
    label: "Code",
  },
  {
    id: "PublishedSnapshot.AsOfDate",
    label: "As-of Date",
  },
  {
    id: "Owner",
    label: "Owner",
  },
  {
    id: "buttons",
    label: "",
  },
];
