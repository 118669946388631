import * as React from "react";
import { InstrumentListItem, InstrumentList, ServerMessage } from "../../types/InstrumentList";
import ItemDialogTable, { ResolvedValues } from "./ItemDialogTable";
import ServerMessageDisplay from "./ServerMessageDisplay";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CloseIcon } from "../shared/Icons";
import { useDispatch, useSelector } from "react-redux";
import { listItemRequest } from "../../store/instrumentLists/instrumentlist-slice";
import { AppState } from "../../store/AppState";
import { hideLoadingScreen, showLoadingScreen } from "../../store/loading/loading-slice";
import { request } from "../../modules/client";
import { errorResponseToServerMessages } from "../../store/instrumentLists/InstrumentListState";
import cloneDeep from "lodash/cloneDeep";
import { hasWarnings, hasErrors, hasMessages } from "./utils";
import InstrumentAutocomplete, { InstrumentSearchResult } from "../shared/InstrumentAutocomplete";

/**
 * The dialog is used to add (and later edit) items to an instrument list.
 */

export interface ItemDialogProps {
  open: boolean;
  closeHandle: () => void;
  list: InstrumentList;
  // provide an item, if it should be edited. For new adding new items, please leave this empty.
  item?: InstrumentListItem;
}

const PREFIX = 'ItemDialog';
const classes = {
    tableContainer: `${PREFIX}-tableContainer`,
    keyColumn: `${PREFIX}-keyColumn`,
    actionContainer: `${PREFIX}-actionContainer`,
    buttonMarginLeft: `${PREFIX}-buttonMarginLeft`,
    forceContainer: `${PREFIX}-forceContainer`,
    validationContainer: `${PREFIX}-validationContainer`,
    infoBox: `${PREFIX}-infoBox`,
    noMarginInput: `${PREFIX}-noMarginInput`,
    inputDisabled: `${PREFIX}-inputDisabled`,
}
const RootDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.tableContainer}`]: {
        minHeight: 250,
    },
    [`& .${classes.keyColumn}`]: {
        fontWeight: "bold",
    },
    [`& .${classes.actionContainer}`]: {
        marginTop: theme.spacing(3),
        margin: theme.spacing(2),
        display: "flex",
        justifyContent: "space-between",
    },
    [`& .${classes.buttonMarginLeft}`]: {
        marginLeft: theme.spacing(2),
    },
    [`& .${classes.forceContainer}`]: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    [`& .${classes.validationContainer}`]: {
        //minimizes the 'jumping around' when showing wait-animation or messages.
        minHeight: 140,
        marginTop: theme.spacing(1),
    },
    [`& .${classes.infoBox}`]: {
        marginTop: theme.spacing(2),
    },
    [`& .${classes.noMarginInput}`]: {
        marginTop: 0,
        marginBottom: 0,
    },
    // TODO style: remove/centralize after migration to new styling.
    [`& .${classes.inputDisabled}`]: {
        background: theme.palette.grey[300],
    },
})) as typeof Dialog;

async function putItemsRequest(baseUrl: string, items: InstrumentListItem[], instrumentListId: string, useForce: boolean) {
  const options = {
    method: "PUT",
    payload: {
      items: items,
    },
  };
  return request(`${baseUrl}api/instrument-lists/${instrumentListId}/items?force=${useForce}`, options);
}

async function validateItemRequest(
  baseUrl: string,
  item: InstrumentListItem,
  instrumentListId: string
): Promise<{ messages: ServerMessage[] }> {
  const options = {
    method: "POST",
    payload: {
      items: [item],
    },
  };

  return request(`${baseUrl}api/instrument-lists/${instrumentListId}/items/validate`, options);
}

const coerceToResolvedValues = (searchResult?: InstrumentSearchResult, item?: InstrumentListItem): ResolvedValues | undefined => {
  if (searchResult) {
    return searchResult;
  } else if (item && item.resolved) {
    return {
      ...item.resolved,
      defaultTradingCurrency: item.resolved.refCurrency,
      name: item.resolved.shortName,
    };
  } else {
    return undefined;
  }
};

export default function ItemDialog(props: ItemDialogProps) {
  const addMode = !props.item;

  const [editItem, setEditItem] = React.useState<InstrumentListItem>(
    cloneDeep(props.item || { instrumentListId: props.list.instrumentListId })
  );

  const dispatch = useDispatch();

  const [searchResult, setSearchResult] = React.useState<InstrumentSearchResult | undefined>(undefined);
  const [useForce, setUseForce] = React.useState(false);
  const [addCustomItem, setAddCustomItem] = React.useState(false);
  // variable to track, if the user has done some editing.
  const [isDirty, setIsDirty] = React.useState(false);
  const [isValidationRunning, setIsValidationRunning] = React.useState(false);

  const [operationStatus, setOperationStatus] = React.useState<{ success?: boolean; messages?: ServerMessage[] }>({
    success: undefined, // TODO: does success still make sense?
    messages: undefined,
  });

  const tstCoreUrl = useSelector((state: AppState) => state.masterData.tstCoreUrl);

  const showTable = !addMode || addCustomItem || searchResult;

  const hasWarningsOrErrors = operationStatus.messages
    ? operationStatus.messages?.filter((it) => it.severity !== "Information").length > 0
    : false;

  const handleCloseDialog = (event: any, reason: string) => {
    if(reason === "backdropClick") {
        return;
    }
    setOperationStatus({ success: undefined, messages: undefined });
    setSearchResult(undefined);
    props.closeHandle();
  };

  const showInstrumentDetails = (instrument: InstrumentSearchResult) => {
    setSearchResult(instrument);
    const updatedItem = {
      ...editItem,
      instrumentId: instrument.imsInstrumentId,
    };
    setEditItem(updatedItem);
    runItemValidation(updatedItem);
  };

  const runItemValidation = (item: InstrumentListItem) => {
    setIsValidationRunning(true);
    validateItemRequest(tstCoreUrl, item, props.list.instrumentListId)
      .then((response: { messages: ServerMessage[] }) => {
        console.log("Messages");
        console.log(response.messages);
        setOperationStatus({ success: false, messages: response.messages });
        setIsValidationRunning(false);
      })
      .catch((e: any) => {
        const messages = errorResponseToServerMessages(e);
        setOperationStatus({ success: false, messages: messages });
        setIsValidationRunning(false);
      });
  };

  const saveItem = () => {
    dispatch(showLoadingScreen());

    putItemsRequest(tstCoreUrl, [editItem], props.list.instrumentListId, useForce)
      .then(() => {
        dispatch(listItemRequest({ listId: props.list.instrumentListId }));
        setOperationStatus({ success: true, messages: undefined });
        dispatch(hideLoadingScreen());
        handleCloseDialog({}, "");
      })
      .catch((e: any) => {
        const messages = errorResponseToServerMessages(e);
        setOperationStatus({ success: false, messages: messages });
        dispatch(hideLoadingScreen());
      });
  };

  return (
    <RootDialog open={props.open} fullWidth={true} maxWidth="md" onClose={handleCloseDialog} disableEscapeKeyDown={true}>
        <DialogTitle
            style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
        >
            <Typography variant="h3" component="span">{addMode ? "Add Instrument" : "Edit Instrument"}</Typography>
            <IconButton aria-label="close" onClick={() => handleCloseDialog({}, "")}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <div>
                {addMode && !addCustomItem && (
                    <React.Fragment>
                        <InstrumentAutocomplete
                            label="Search (ISIN, Valor, Bloomberg Ticket, Bloomberg Global ID, Name)"
                            onSelect={showInstrumentDetails}
                            useExtendedSearch
                            fullWidth
                        />
                        {!showTable && (
                            <React.Fragment>
                                <Box mt={2} mb={2}>
                                    <Typography variant="body1">
                                        <strong> - or - </strong>
                                    </Typography>
                                </Box>
                                <Button
                                    onClick={() => {
                                        setAddCustomItem(true);
                                    }}
                                >
                                    Add custom item
                                </Button>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
                <div className={classes.tableContainer}>
                    {showTable && (
                        <ItemDialogTable
                            classes={classes}
                            requested={editItem}
                            resolved={coerceToResolvedValues(searchResult, props.item)}
                            onBlur={(key, value) => {
                                let updatedItem = cloneDeep(editItem);
                                if (value === "") {
                                    // after the user removed the input,
                                    // send nothing, not even the empty string to the backend.
                                    // otherwise we get errors e.g. for the currency.
                                    delete updatedItem[key];
                                } else {
                                    updatedItem[key] = value;
                                }
                                setEditItem(updatedItem);
                                runItemValidation(updatedItem);
                                setIsDirty(true);
                            }}
                        />
                    )}
                </div>
            </div>

            {(isValidationRunning || operationStatus.messages) && (
                <div className={classes.validationContainer}>
                    {isValidationRunning && <CircularProgress size={80} />}

                    {!isValidationRunning && hasMessages(operationStatus.messages) && (
                        <React.Fragment>
                            <ServerMessageDisplay messages={operationStatus.messages} maxHeight="15em" allowComments={false} />
                            {hasWarnings(operationStatus.messages) && !hasErrors(operationStatus.messages) && (
                                <div className={classes.forceContainer}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={useForce}
                                                onChange={(evt) => {
                                                    setUseForce(evt.target.checked);
                                                }}
                                                color="primary"
                                            />
                                        }
                                        label="Ignore Warnings (Force)"
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </div>
            )}
        </DialogContent>
        <DialogActions className={classes.actionContainer}>
            <div></div>
            <div>
                <Button color="primary" onClick={() => handleCloseDialog({}, "")}>
                    Cancel
                </Button>
                <Button
                    disabled={
                        !(
                            (searchResult || (addCustomItem && isDirty) || (props.item && isDirty)) &&
                            (!hasWarningsOrErrors || useForce) &&
                            !isValidationRunning
                        )
                    }
                    color="primary"
                    variant="contained"
                    className={classes.buttonMarginLeft}
                    onClick={saveItem}
                >
                    {addMode ? "Add Instrument" : "Save Changes"}
                </Button>
            </div>
        </DialogActions>
    </RootDialog>
  );
}
