import * as React from "react";
import { isEqual as deepEqual } from "lodash";
import { Box, useTheme } from "@mui/material";
import { dataIconsMapper, ProductDataStatus, ProductLineStatusApi } from "./utils";

/**
 * An icon and text visualizing the upload status of a certain product.
 */
export default React.memo(function StatusLabel(props: {
  hasErrors?: boolean | undefined;
  status: ProductDataStatus | ProductLineStatusApi;
  iconScale?: number;
  children?: React.ReactNode;
}) {
  const theme = useTheme();
  const { status, hasErrors } = props;
  const colorLogic = () => 
  {
    if(status !== ProductLineStatusApi.Uploaded)
    {
      return dataIconsMapper[status.toString()].getColor(theme);
    }

    return hasErrors ?  theme.palette.warning.main : dataIconsMapper[status.toString()].getColor(theme);  
  };

  return (
    <Box display="flex" alignItems="center" color={colorLogic()}>
      <Box
        style={{
          opacity: 0.7,
          transform: (props.iconScale && `scale(${props.iconScale})`) || undefined,
          paddingRight: theme.spacing(1),
          lineHeight: 0, // fixes misalignment when inside a button
        }}
      >
        {dataIconsMapper[status.toString()].icon}
      </Box>
      {props.children || dataIconsMapper[status.toString()].label}
    </Box>
  );
},
deepEqual);
