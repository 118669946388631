import * as React from "react";
import { ServerMessage } from "../../types/InstrumentList";
import { AlertIcon, InfoIcon } from "../shared/Icons";
import { Tooltip, IconButton } from "@mui/material";

/**
 * Small component that shows an Icon-Button based on the provided Validation-Messages.
 */
export default function ValidationSeverityButton(props: {
  messages?: ServerMessage[];
  onClick?: (evt: React.MouseEvent) => void;
}) {
  if (!props.messages || props.messages.length === 0) {
    return <React.Fragment />;
  }
  var errors = props.messages.filter((msg) => (msg.comment?.severityOverride || msg.severity) === "Error");
  var warnings = props.messages.filter((msg) => (msg.comment?.severityOverride || msg.severity) === "Warning");

  var icon = <InfoIcon htmlColor="green" />;

  if (errors.length > 0) {
    icon = <AlertIcon color="error" />;
  } else if (warnings.length > 0) {
    icon = <AlertIcon htmlColor="orange" />;
  }

  return (
    <Tooltip title="Show messages">
      <IconButton onClick={props.onClick}>{icon}</IconButton>
    </Tooltip>
  );
}
