import { AllocationReference } from "../../types/AllocationReference";
import { AppState } from "../AppState";
import { createSelector } from "@reduxjs/toolkit";
import AllocationType from "../../types/AllocationType";

const sortByName = (list: AllocationReference[]): AllocationReference[] => {
  if (!list) {
    return list;
  }

  // Concat is used to create a copy of the input array, so we don't sort inline.
  return list.concat().sort((a, b) => {
    const nameA = a.Name || "";
    const nameB = b.Name || "";

    return nameA.localeCompare(nameB);
  });
};

const getFavorites = (state: AppState) => state.user.favorites;

export const getSortedFavoriteModelPortfolios = createSelector([getFavorites], (favorites) => {
  const favMopos = favorites.filter((fav) => fav.AllocationType === AllocationType.ModelPortfolio);
  return sortByName(favMopos);
});

export const getSortedFavoriteAllocations = createSelector([getFavorites], (favorites) => {
  const favMopos = favorites.filter(
    (fav) => fav.AllocationType === AllocationType.Taa || fav.AllocationType === AllocationType.Saa
  );
  return sortByName(favMopos);
});

const getOwned = (state: AppState) => state.user.advanced.Owned;

export const getSortedOwned = createSelector([getOwned], (owned) => {
  return sortByName(owned);
});
