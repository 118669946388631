import { showLoadingScreen, hideLoadingScreen } from "../loading/loading-slice";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { request } from "../../modules/client";
import {
  setLists,
  instrumentListsRequest,
  setFetchError,
  listItemRequest,
  setListItems,
  setCategories,
} from "./instrumentlist-slice";
import { AppState } from "../AppState";
import { getMasterData } from "../masterData/masterData-saga";
import { showAlert } from "../app/app-slice";
import { InstrumentResult, InstrumentListItem, ServerMessage } from "../../types/InstrumentList";

const getBaseUrl = (state: AppState) => {
  let url = state.masterData.tstCoreUrl;
  if (url && !url.endsWith("/")) {
    url += "/";
  }
  return url;
};

function* yieldBaseUrl() : any {
  let baseUrl = yield select(getBaseUrl);
  // If we don't have a baseUrl this is probably because the masterdata (settings) haven't been loaded yet
  // So try to load them and try again.
  if (!baseUrl) {
    yield call(getMasterData);
    baseUrl = yield select(getBaseUrl);
  }
  return baseUrl;
}

const requestInstrumentLists = function* ({ payload }: any) : any {
  try {
    yield put(showLoadingScreen());

    let baseUrl = yield* yieldBaseUrl();
    const listResponse = yield call(request, baseUrl + "api/instrument-lists");
    yield put(
      setLists({
        data: listResponse.instrumentLists,
      })
    );

    const categoriesResponse = yield call(request, baseUrl + "api/instrument-lists/categories");
    yield put(
      setCategories({
        data: categoriesResponse.categories,
      })
    );

    yield put(hideLoadingScreen());
  } catch (err) {
    yield put(hideLoadingScreen());
    let msg = "Error fetching list data. Cause: " + err;
    yield put(showAlert(msg, { type: "error" }));
    yield put(setFetchError({ error: msg }));
  }
};

const requestListItems = function* ({ payload }: { payload: { listId: string } }) {
  try {
    yield put(showLoadingScreen());
    let baseUrl = yield* yieldBaseUrl();
    const itemsResponse: { items: InstrumentListItem[] } = yield call(
      request,
      `${baseUrl}api/instrument-lists/${payload.listId}/items`
    );
    const validationResponse: { messages: ServerMessage[] } = yield call(
      request,
      `${baseUrl}api/instrument-lists/${payload.listId}/validate`
    );
    const instrumentResponse: { instruments: InstrumentResult[] } = yield call(
      request,
      `${baseUrl}api/instruments?instrumentListId=${encodeURIComponent(payload.listId)}`
    );
    yield put(
      setListItems({
        listId: payload.listId,
        items: itemsResponse.items,
        validationMessages: validationResponse.messages,
        resolvedInstruments: instrumentResponse.instruments,
      })
    );
    yield put(hideLoadingScreen());
  } catch (err) {
    yield put(hideLoadingScreen());
    let msg = "Error fetching list item data. Cause: " + err;
    yield put(showAlert(msg, { type: "error" }));
  }
};

export default function* root() {
  yield all([
    takeLatest(instrumentListsRequest, requestInstrumentLists),
    takeLatest(listItemRequest.type as any, requestListItems),
  ]);
}
