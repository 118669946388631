import { User, WebStorageStateStore } from "oidc-client-ts";
import { AuthProviderNoUserManagerProps } from "react-oidc-context";
import { getAppOidcConfig } from "../components/AppAuthConfig";

const MAX_RETRIES = 10;
const RETRY_INTERVAL = 100;
const redirectUri = window.location.origin;

export async function getOidcConfig(): Promise<AuthProviderNoUserManagerProps> {
  const appEnvironment = await getAppOidcConfig();

  return {
    authority: appEnvironment.OIDC_AUTHORITY,
    client_id: appEnvironment.OIDC_CLIENT_ID,
    redirect_uri: redirectUri,
    scope: "user_impersonation profile openid",
    response_type: "code",
    post_logout_redirect_uri: redirectUri,
    automaticSilentRenew: true,
    loadUserInfo: false,
    resource: appEnvironment.OIDC_RESOURCE,
    onSigninCallback: () => {
      window.history.replaceState({}, document.title, window.location.pathname);
    },
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  } as AuthProviderNoUserManagerProps;
}

function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function getUserFromLocalStorage(): Promise<User | null> {
  const { authority, client_id } = await getOidcConfig();

  let retries = 1;
  let oidcStorage = localStorage.getItem(`oidc.user:${authority}:${client_id}`);

  while (!oidcStorage && retries < MAX_RETRIES) {
    await wait(RETRY_INTERVAL);
    oidcStorage = localStorage.getItem(`oidc.user:${authority}:${client_id}`);
    retries += 1;
  }

  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

export async function isUserTokenValid() {
  const user: User | null = await getUserFromLocalStorage();
  if (!user) return false;
  if (user.expired) return false;
  return true;
}
