import { request } from "../../modules/client";
import { baseUrl } from "../../store/apiUrlProvider";
import { toShortIsoDateString } from "../../modules/date";
import ReferenceDate from "./ReferenceDate";
import { DataSourceStatus, ProductLinePublicationDataApi, ProductLinePublicationData } from "./utils";
import { PublishStatus } from "../administration/PublishPanel";

export async function fetchProductStatus(referenceDate: ReferenceDate) {
  const result: ProductLinePublicationDataApi[] = await request(
    apiBase + "product-status?referenceDate=" + toShortIsoDateString(referenceDate.value())
  );
  return result;
}

export async function fetchDataSourceStatus(referenceDate: ReferenceDate) {
  const result: DataSourceStatus = await request(
    apiBase + "datasource-status?referenceDate=" + toShortIsoDateString(referenceDate.value())
  );
  return result;
}

export async function postUpload(productLines: ProductLinePublicationData[], referenceDate: ReferenceDate) {
  return await request(apiBase + "upload", {
    method: "POST",
    payload: {
      productLines: productLines.map((pl) => pl.productLine),
      referenceDate: referenceDate.toUploadParameter(),
    },
  });
}

const apiBase = `${baseUrl()}api/daisy/ui/`;
