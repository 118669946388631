import * as React from "react";
import { useCallback } from 'react';
import { AppState } from "../../../store/AppState";
import { Button, DialogActions, Dialog, DialogContent, DialogTitle, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import Allocation from "../../../types/Allocation";
import BasicInformation from "../../meta-view/MetaViewBasicInformation";

/**
 * Component showing the meta view dialog, containing
 * - allocation details
 * - link to the full meta view page
 */
export default function MetaViewDialog(props: { close: () => void; allocation: Allocation }) {
  const { close, allocation } = props;
  const navigate = useNavigate();
  const space = useTheme().spacing;
  const { marketStructures, aaaStrategyNatures, destinations } = useSelector((s: AppState) => s.masterData);

  const onNavigate = useCallback(() => {
      navigate("/modelportfolio/" + allocation.Id);
      close();
  }, [close, navigate, allocation]);

  return (
    <Dialog open={true} onClose={close} maxWidth={false}>
      <DialogTitle>
        <span style={{ textTransform: "none" }}>{allocation.Name}</span>
      </DialogTitle>
      <DialogContent style={{ width: 800 }}>
        <BasicInformation
          enableEditing={false}
          allocation={allocation}
          marketStructures={marketStructures}
          aaaStrategyNatures={aaaStrategyNatures}
          destinations={destinations}
        />
      </DialogContent>
      <DialogActions>
        <Button component="a" onClick={close}>
          Close
        </Button>
        <Button
          style={{ marginLeft: space(2) }}
          color="primary"
          variant="contained"
          onClick={onNavigate}
        >
          Full Meta View
        </Button>
      </DialogActions>
    </Dialog>
  );
}
