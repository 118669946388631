import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BackIcon } from "./Icons";
import { Typography } from "@mui/material";
import { AllocationPageType } from "../../types/AllocationPageType";

/**
 * Simple backlink component.
 * Usually shown on top of a main view.
 */

export default function BackLink(props: {
  label?: string; // link label, defaults to Back to overview',
  targetPath: string; // link target, expected to be absolute (e.g. "/modelportfolio" and not relative)
}) {
  if (props.targetPath.indexOf("/") !== 0) {
    throw new Error("TargetPath must be absolute (start with a /).");
  }
  const location = useLocation();
  const navigate = useNavigate();

  const from = "" + (location?.state as any)?.from;

  const goBack = isComingFromTarget(props.targetPath, from);

  return (
    <Link
      to={props.targetPath}
      onClick={(evt) => {
        if (goBack) {
          navigate(-1);
          evt.preventDefault();
        }
      }}
    >
      <BackIcon color="primary" fontSize="small" style={{ marginBottom: -4 }} />
      &nbsp;
      <Typography color="primary" variant="body1" display="inline">
        {props.label || "Back to overview"}
      </Typography>
    </Link>
  );
}

/**
 * simple helper function to link to the overview-page
 */
export function toOverview(allocationPageType: AllocationPageType) {
  return allocationPageType == AllocationPageType.ModelPortfolio ? "/modelportfolios" : "/allocations";
}

function isComingFromTarget(linkTarget: string, from: string) {
  return from.indexOf(linkTarget) === 0;
}
