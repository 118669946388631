import * as React from "react";
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";

const PREFIX = 'NotFound';
const classes = {
  root: `${PREFIX}-root`
};
const Root = styled('div')({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

export const NotFound: React.FC = () => {
  return (
    <Root className={classes.root}>
      <h1 style={{ fontSize: "12rem" }}>404</h1>
      <h2>
        <Link to="/">go to default page</Link>
      </h2>
    </Root>
  );
}
