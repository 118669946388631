import * as React from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { toDateTimeString } from "../../../modules/date";
import { transformLabel } from "../../../modules/transformLabels";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/AppState";
import { baseUrl } from "../../../store/apiUrlProvider";
import { request } from "../../../modules/client";
import { hideLoadingScreen, showLoadingScreen } from "../../../store/loading/loading-slice";
import { showAlert } from "../../../store/app/app-slice";
import { InEditAllocation } from "./usePeriodicAllocationInfo";

const PREFIX = 'InEditAllocations';
const classes = {
  statusList: `${PREFIX}-statusList`,
  firstTableColumn: `${PREFIX}-firstTableColumn`,
}
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.statusList}`]: {
    paddingBottom: 0,
  },
  [`& .${classes.firstTableColumn}`]: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function InEditAllocations(props: { data: InEditAllocation[]; onTimeoutDone: () => void }) {
  const { data, onTimeoutDone } = props;
  const dispatch = useDispatch();
  const { canWriteAdministration } = useSelector((state: AppState) => ({
    canWriteAdministration: state.user.isAdmin,
  }));

  async function runSingleTimeout(allocation: InEditAllocation) {
    try {
      dispatch(showLoadingScreen());
      await request(`${baseUrl()}api/snapshot/timeout/${allocation.Id}`);
      dispatch(showAlert(`Successfully set allocation ${allocation.Name} to Draft`, { type: "success" }));
      onTimeoutDone();
    } catch (err) {
      console.error("runSingleTimeout failed", err);
      dispatch(showAlert(`Failed to timeout allocation ${allocation.Name}`, { type: "error" }));
    } finally {
      dispatch(hideLoadingScreen());
    }
  }

  async function runTimeoutJob() {
    try {
      dispatch(showLoadingScreen());
      await request(baseUrl() + "api/allocation-state/timeout-expired-allocations", { method: "POST" });
      dispatch(showAlert("Timeout job run successfully", { type: "success" }));
      onTimeoutDone();
    } catch (err) {
      dispatch(showAlert("Timeout job failed", { type: "error" }));
      console.error("Timeout job failed", err);
    } finally {
      dispatch(hideLoadingScreen());
    }
  }

  return (
    <Root style={{ display: "block" }}>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 450 }} className={classes.firstTableColumn}>
                Allocation
              </TableCell>
              <TableCell style={{ width: 200 }}>edited by</TableCell>
              <TableCell style={{ width: 200 }}>at</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((q) => (
              <TableRow key={q.Id}>
                <TableCell className={classes.firstTableColumn}>{q.Name}</TableCell>
                <TableCell>{q.ExecutedBy}</TableCell>
                <TableCell>{toDateTimeString(q.ExecutedAt)}</TableCell>
                <TableCell>
                  <Button size="small" color="primary" variant="contained" onClick={() => runSingleTimeout(q)}>
                    {transformLabel("action", "Timeout")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <Box mt={4} mb={4}>
        <Button disabled={!canWriteAdministration} onClick={runTimeoutJob}>
          Run timeout job
        </Button>
      </Box>
    </Root>
  );
}
