import React, { useCallback, useMemo } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers';
import { DateValidationError } from '@mui/x-date-pickers/models';
import LinkIcon from '@mui/icons-material/Link';
import { styled } from '@mui/material/styles';
import { red } from "@mui/material/colors";
import { transformLabel } from "../../../modules/transformLabels";
import { AllocationChangeResult } from '../../../types/AllocationChangeResult';
import { AllocationUploadValidationResponse } from '../../../types/AllocationUploadValidationResponse';
import AllocationUploadRow from '../../../types/AllocationUploadRow';
import isValidAsOfDate from '../../compare-view/utils/isValidAsOfDate';
import { cancelEditForMultipleAllocations, getDiffCsv, queueMultipleAllocations } from '../../compare-view/apiRequests';
import { MultiplePublishTable } from './MultiplePublishTable';
import { AllocationPasteType, MultipleAllocationRowResult, MultipleIcAllocationRowResult } from '../../shared/types';
import LoadingIndicator from "../../shared/LoadingIndicator";
import { showAlert } from "../../../store/app/app-slice";
import { useDispatch } from "react-redux";
import WarningIcon from '@mui/icons-material/Warning';
import theme from "../../../theme";
import { toShortDateString } from "../../../modules/date";

const PREFIX = 'MultiplePublishDialog';

const classes = {
    root: `${PREFIX}-root`,
    dateInput: `${PREFIX}-dateInput`,
    iconSmall: `${PREFIX}-iconSmall`
};

const StyledPaper = styled(Paper)((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        "& .MuiDialogContent-root": {
            overflowY: "auto", // keeps the title and action bar in place if the content exceeds height
        },
        "& .MuiPaper-root": {
            overflowX: "auto", // allow the dialog to be horizontal scrollable
        }
    },

    [`& .${classes.dateInput}`]: {
        "& > div:first-child": {
            width: "180px",
        },
    },

    [`& .${classes.iconSmall}`]: {
        fontSize: 20,
        marginRight: theme.spacing(1),
    }
}));

interface IMultiplePublishDialogProps {
    allocationChangeResults: AllocationChangeResult[];
    allocationPasteType: AllocationPasteType;
    allocationRows: MultipleAllocationRowResult[];
    close: () => void;
}

export const MultiplePublishDialog: React.FC<IMultiplePublishDialogProps> = ({ allocationChangeResults, allocationPasteType, allocationRows, close }) => {
    const space = useTheme().spacing;
    const dispatch = useDispatch();

    const openCsv = useCallback(async () => {
        const now = new Date();

        const year = now.getFullYear();
        const month = now.getMonth() + 1;
        const day = now.getDate();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();

        const date = `${year}${month}${day}_${hours}${minutes}${seconds}`;

        const csvData = await getDiffCsv(allocationChangeResults);

        const url = window.URL.createObjectURL(new Blob([csvData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${date}_TST_Differences_Export.csv`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }, [getDiffCsv])

    const minDate = useMemo(() => {
        const validEntries = allocationChangeResults.filter(acr => acr.AsOfDate);

        if(validEntries?.length > 0) {
            return new Date(
                Math.max(
                    ...validEntries.map(acr => Number(acr.AsOfDate))
                )
            )
        }

        return undefined;
    }, [allocationChangeResults]);

    const [isLoading, setIsLoading] = React.useState(false);
    const [asOfDate, setAsOfDate] = React.useState<Date | null>(minDate || new Date());
    const [isAsOfDateValid, setIsAsOfDateValid] = React.useState<boolean>(allocationPasteType == "icAllocation" || isValidAsOfDate(minDate, asOfDate));
    const [error, setError] = React.useState<DateValidationError | null>(null);

    const errorMessage = React.useMemo(() => {
        switch (error) {
            case 'minDate': {
                return `The date must be greater or equal to the AsOfDate of the last published snapshot: ${minDate?.toLocaleDateString()}.`;
            }

            case 'invalidDate': {
                return 'Date is not valid';
            }

            default: {
                return '';
            }
        }
    }, [error, minDate]);

    const [lastQueueFailure, setLastQueueFailure] = React.useState<any>();

    const publish = () => {
        if (!asOfDate) {
            throw "Queuing/Publish only allowed with an asOfDate.";
        }

        setIsLoading(true);
        queue(allocationRows, allocationPasteType, asOfDate)
            .then(() => {
                close();
                dispatch(showAlert("Queued all successfully", { type: "success" }));
            })
            .catch(setLastQueueFailure)
            .then(() => setIsLoading(false));
    };

    const handleClose = useCallback(async () => {
        const allocationCodes = allocationChangeResults.map(acr => acr.AllocationCode);
        await cancelAllocationEdit(allocationCodes);
        close();
    }, [close, allocationChangeResults]);

    const anyDiffs = useMemo(() =>
        allocationChangeResults.some(x => x.ChangeResult.Changed.length > 0 ||
            x.ChangeResult.Deleted.length > 0 ||
            x.ChangeResult.Inserted.length > 0
        ), [allocationChangeResults]);

    const allocationDiffs = useMemo(() =>
        allocationChangeResults.map((acr, idx) =>
            <MultiplePublishTable allocationChangeResult={acr} />
        ), [allocationChangeResults]);

    let asOfDateWarning = null;
    if(asOfDate && minDate && asOfDate < minDate){
        asOfDateWarning = (
        <Typography variant="body2" display="inline" style={{ minWidth: "22em", textAlign: 'right', color: theme.palette.warning.main }} marginRight={3}>
            <WarningIcon color="warning" style={{ marginRight: "0.5em", verticalAlign: 'text-bottom' }} />
            <label>The latest publication date is { minDate ? toShortDateString(minDate) : "N/A" }</label>
        </Typography>
        )
    }

    return (
        <Dialog open={true} onClose={handleClose} maxWidth={false} className={classes.root}>
            <DialogTitle>
                {transformLabel("action", "Publish")}
            </DialogTitle>
            <DialogContent style={{ width: 900 }}>
                <LoadingIndicator show={isLoading} size="48">
                    {lastQueueFailure && <ErrorPanel error={lastQueueFailure} />}
                    <Box marginBottom={0} marginTop={2} marginLeft={3} minHeight={space(9)} display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" display="inline" style={{ minWidth: "7em" }}>
                                <label htmlFor="publishAsOfDate">As-of date: </label>
                            </Typography>
                            <DatePicker
                                className={classes.dateInput}
                                value={asOfDate}
                                onError={(newError) => setError(newError)}
                                slotProps={{
                                    textField: {
                                        helperText: errorMessage,
                                    },
                                }}
                                format="dd.MM.yyyy"
                                minDate={allocationPasteType == "icAllocation" ? undefined : minDate}
                                onChange={(value) => {
                                    setAsOfDate(value);
                                    setIsAsOfDateValid(allocationPasteType == "icAllocation" || isValidAsOfDate(minDate, value));
                                }}
                            />
                            {asOfDateWarning}
                        </Box>
                        <Tooltip title="Download differences as CSV">
                            <Box ml={1}>
                                <Button disabled={!anyDiffs} onClick={openCsv} color="primary">
                                    <LinkIcon className={classes.iconSmall} />
                                    Excel
                                </Button>
                            </Box>
                        </Tooltip>
                    </Box>
                    { allocationDiffs }
                </LoadingIndicator>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={handleClose}>Cancel</Button>
                <Button
                    style={{ marginLeft: space(2) }}
                    color="primary"
                    variant="contained"
                    onClick={publish}
                    disabled={!isAsOfDateValid || isLoading}
                >
                    {transformLabel("action", "Publish")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

async function cancelAllocationEdit(allocationCodes: string[]) {
    await cancelEditForMultipleAllocations(allocationCodes);
}

async function queue(rows: MultipleAllocationRowResult[], allocationPasteType: AllocationPasteType, asOfDate: Date) : Promise<void> {
    
    // Make sure we only send the date part to the backend without adjustments of local time to UTC.
    asOfDate.toJSON = function () {
        return `${asOfDate.getFullYear()}-${asOfDate.getMonth() + 1}-${asOfDate.getDate()}T00:00:00Z`;
    };
    
    if(allocationPasteType === "mopo") {
        const allocationUploadRows = rows.map<AllocationUploadRow>(row => ({ AllocationCode: row.allocationCode, InstrumentId: row.pasteId, Weight: row.weight }));

        await queueMultipleAllocations({ AllocationUploadRows: allocationUploadRows, AsOfDate: asOfDate });
        return;
    }

    const icAllocationRows = rows as MultipleIcAllocationRowResult[];
    const allocationUploadRows = icAllocationRows.map<AllocationUploadRow>(row => ({ AllocationCode: row.allocationCode, InstrumentId: row.pasteId, Weight: row.weight, RiskCurrency: row.riskCurrency, SubAssetClass: row.assetSubClass }));

    await queueMultipleAllocations({ AllocationUploadRows: allocationUploadRows, AsOfDate: asOfDate });
}

function ErrorPanel(props: { error: any }) {
    const { error } = props;

    let msg = "";
    if (typeof error === "object") {
        if (error?.response?.data?.Message) {
            msg = error.response.data.Message;
        } else {
            msg = JSON.stringify(error);
        }
    } else {
        msg = `${error}`;
    }

    return (
        <StyledPaper elevation={4}>
            {typeof error === "string" ? (
                <div>error</div>
            ) : (
                <div style={{ color: red[600], marginBottom: "1em", padding: "1em" }}>
                    <h3>Error</h3>
                    {msg.split("\r").map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                </div>
            )}
        </StyledPaper>
    );
}
