/**
 * The different AuReTo Rebalancing methods..
 */
enum AuretoRebalancingMethod {
  'NON-GT' = 0,
  'GT-STANDARD' = 1,
  'GT-TAILORED' = 2,
}

export const getAuretoRebalancingMethodSelectionItems = (): AuretoRebalancingMethod[] => {
  const auretoRebalancingMethods: AuretoRebalancingMethod[] = Object.values(AuretoRebalancingMethod).map(x => AuretoRebalancingMethod[x]);
  const enumValuesOnly: AuretoRebalancingMethod[] = auretoRebalancingMethods.filter(value => typeof value === 'number');
  
  return enumValuesOnly;
};


export default AuretoRebalancingMethod;
