import * as React from "react";
import { useDispatch } from "react-redux";
import { request } from "../../../modules/client";
import { toShortTimeString } from "../../../modules/date";
import { baseUrl } from "../../../store/apiUrlProvider";
import { showAlert } from "../../../store/app/app-slice";
import { hideLoadingScreen, showLoadingScreen } from "../../../store/loading/loading-slice";
import { AllocationReference } from "../../../types/AllocationReference";
import { isUserTokenValid } from "../../../auth/oidcConfig";

export default function usePeriodicAllocationInfo() {
  const dispatch = useDispatch();
  const [info, setInfo] = React.useState<AllocationInfo>({
    InEdit: [],
    ContainingDeletedInstruments: [],
    RefreshTime: "",
  });

  async function doRefresh(initial: boolean = false) {
    initial && dispatch(showLoadingScreen());
    try {
      const response = await fetchAllocationInfo();
      setInfo(response);
    } catch (e) {
      dispatch(showAlert("Failed to load allocation info", { type: "error" }));
      console.error("doRefresh failed", e);
    } finally {
      initial && dispatch(hideLoadingScreen());
    }
  }

  React.useEffect(() => {
    doRefresh(true);
    let timeoutId: any;
    (function next() {
      timeoutId = setTimeout(async () => {
        const userTokenValid: boolean = await isUserTokenValid();
        if (userTokenValid) {
          doRefresh();
        }

        next();
      }, 1000 * 60);
    })();
    return () => clearTimeout(timeoutId);
  }, []);

  return [info, () => doRefresh(true)] as [AllocationInfo, () => {}];
}

async function fetchAllocationInfo() {
  const stats = await request(`${baseUrl()}api/snapshot/stats`);
  return { ...stats, RefreshTime: toShortTimeString(new Date()) } as AllocationInfo;
}

type AllocationInfo = {
  InEdit: InEditAllocation[];
  ContainingDeletedInstruments: AllocationReference[];
  RefreshTime: string;
};

export type InEditAllocation = {
  Id: string;
  Name: string;
  ExecutedBy: string;
  ExecutedAt: string;
};
