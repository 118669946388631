import React from "react";
import { styled } from '@mui/material/styles';
import ActionBar from "./ActionBar";
import AllocationName from "./AllocationName";
import { TotalWeightCells } from "./WeightCells";
import WeightHeaders from "./WeightHeaders";
import { AllocationColumn, EditInfo, ViewOptions } from "./types";
import Allocation from "../../types/Allocation";

const PREFIX = 'AllocationHeader';
const classes = {
  root: `${PREFIX}-root`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    marginTop: theme.spacing(2),
    "& > div + div": {
      marginTop: theme.spacing(1),
    },
  }
}));

// TODO - consider inlining in parent component since most of the props are just passed to childs
export default function AllocationHeader(props: {
  allocation: Allocation;
  editInfo: EditInfo;
  options: ViewOptions;
  column: AllocationColumn;
}) {
  const { allocation, editInfo, options, column } = props;
  const showSaved = editInfo.hasSavedWeights || editInfo.isInEdit;
  return (
    <Root className={classes.root}>
      <AllocationName name={allocation.Name} />
      <ActionBar allocation={allocation} column={column} editInfo={editInfo} />
      <WeightHeaders dates={column.dates} options={options} showSaved={showSaved} />
      <TotalWeightCells weights={column.sum} options={options} inEdit={editInfo.isInEditByUser} showSaved={showSaved} />
    </Root>
  );
}
