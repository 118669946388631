import * as React from "react";
import MultiInstrumentSelect, { InstrumentLabel } from "../shared/MultiInstrumentSelect";
import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import MultiSelect from "../shared/MultiSelect";
import KeyValuePair from "../shared/KeyValuePair";
import { uniqueInstrumentFilter } from "./utils";

/**
 * Simple component with the UI to change the filters for the InstrumentLists.
 */

const PREFIX = 'InstrumentListFilterPanel';
const classes = {
    elementOffset: `${PREFIX}-elementOffset`,
}
const Root = styled('div')(({ theme }) => ({
    [`& .${classes.elementOffset}`]: {
        marginTop: theme.spacing(2),
    },
}));

export default function InstrumentListFilterPanel(props: {
  reverseLookupValues: InstrumentLabel[];
  updateIncludedInstrumentsFilter: (newValues: InstrumentLabel[]) => void;

  updateCategoriesFilter: (newValues: string[]) => void;
  categoryValues: string[];
  categories: KeyValuePair[];
}) {
  return (
    <Root>
      <Typography variant="h3">Filters</Typography>
      <div className={classes.elementOffset}>
        <MultiInstrumentSelect
          label="Included Instruments"
          useExtendedSearch
          selectedInstruments={props.reverseLookupValues}
          onChange={(values) => {
            let converted: InstrumentLabel[] = values.map((result) => {
              return { instrumentId: result.imsInstrumentId || result.instrumentId, name: result.name };
            });
            props.updateIncludedInstrumentsFilter(uniqueInstrumentFilter(converted));
          }}
        />

        <div className={classes.elementOffset}>
          <MultiSelect
            id="instrumentListCategory"
            label="Categories"
            data={props.categories}
            selectedKeys={props.categoryValues}
            onChange={(_, keys) => {
              props.updateCategoriesFilter(keys);
            }}
          />
        </div>
      </div>
    </Root>
  );
}
