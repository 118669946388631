import Big, { BigType } from "big.js";
import Allocation from "../../../types/Allocation";
import { AssetTree } from "../../../types/AssetTree";
import { AllocationColumn, AllocationRowDescriptor, FormattedWeightDates, InstrumentWithOverrides } from "../types";
import AssetTreeBuilder, { generateWeightedInstrumentRowId, TreeNode } from "../../../modules/asset-tree-builder";
import flattenToRows from "./flattenToRows";
import setInstrumentCount from "./setInstrumentCount";
import aggregateWeights from "./aggregateWeights";
import AllocationType from "../../../types/AllocationType";
import { ZERO } from "./weights";
import { cellsInRowOrder } from "./allocationColumn";
import { MaybeDate, toShortDateString } from "../../../modules/date";
import AllocationSource from "../../../types/AllocationSource";
import AuretoRebalancingMethod from "../../../types/AuretoRebalancingMethod";

/**
 * Determine table rows for a given set of allocations and a structure.
 * Complements the cells in the passed columns.
 *
 * TODO - rename to getRows?
 * TODO - simplify instrument-tree-matching currently done in AssetTreeBuilder
 */
export default function getTableStructure(
  allocations: Allocation[],
  structureName: string,
  trees: AssetTree[],
  thresholdAvgDyn: BigType,
  addedInstruments: InstrumentWithOverrides[],
  columns: AllocationColumn[]
): AllocationRowDescriptor[] {
  if (allocations.length !== columns.length) {
    throw new Error("allocations.length and columns.length must match");
  }

  const structure = (trees || []).find((t) => t.Structure === structureName)?.Tree || [];
  const allocationInput = [...allocations, getInstrumentsHolder(addedInstruments)];

  const assetTree = new AssetTreeBuilder(structure, allocationInput, structureName).generateAssetTree(true, structureName);
  const rows = flattenToRows((assetTree.tree as unknown) as { [id: number]: TreeNode } /*???*/, columns);

  setInstrumentCount(rows);
  setIsPartOfAnySnapshot(rows, allocationInput, structureName);

  columns.forEach((c, i) => {
    // TODO - removeCellsWithoutMatchingRows(rows, c)
    setIcWeights(structureName, rows, c, allocations[i]);
    aggregateWeights(rows, c);
    setExceededThresholds(c, rows, thresholdAvgDyn);
    c.dates = getWeightDates(allocations[i]);
  });

  return rows;
}

/**
 * Set IC weights for category rows.
 */
function setIcWeights(structureName: string, rows: AllocationRowDescriptor[], column: AllocationColumn, allocation: Allocation) {
  const icWeights = allocation.ICWeights.find((i) => i.AssetTree === structureName)?.Weights;
  if (!icWeights) {
    return;
  }

  rows.forEach((r) => {
    if (r.isInstrument) return;
    const weight = icWeights.find((w) => w.TreeRowId === r.rowId)?.Weight;
    if (weight === undefined) return;
    column.cells[r.rowId].ic = getBigOrZero(weight).div(100);
  });
}

/**
 * Examines if dynamic and average weights deviate too much from their respective published weights.
 */
function setExceededThresholds(column: AllocationColumn, rows: AllocationRowDescriptor[], thresholdAvgDyn: BigType) {
  cellsInRowOrder(column, rows, true).forEach((c) => {
    c.dynamicExceedsThreshold = c.dynamic.minus(c.published).abs().gt(thresholdAvgDyn);
    c.averageExceedsThreshold = c.average.minus(c.published).abs().gt(thresholdAvgDyn);
  });
}

function setIsPartOfAnySnapshot(rows: AllocationRowDescriptor[], allocations: Allocation[], assetTreeName: string) {
  const isPart: { [rowId: string]: boolean } = {};
  const snapshots = [...allocations.map((a) => a.PublishedSnapshot), ...allocations.map((a) => a.SavedSnapshot)];

  snapshots.forEach((snap) => {
    if (!snap) return;
    snap.Instruments.forEach((wi) => {
      const rowId = generateWeightedInstrumentRowId(wi, assetTreeName);
      isPart[rowId] = !!isPart[rowId] || !wi.IsAverageWeight;
    });
  });

  rows.forEach((r) => {
    if (r.isInstrument) {
      r.isPartOfAnySnapshot = isPart[r.rowId];
    }
  });
}

/**
 * Formats all weight dates of an allocation.
 */
function getWeightDates(a: Allocation): FormattedWeightDates {
  const isPublished = !!a.PublishedSnapshot;
  return {
    ic: weightsDate(a.WeightsDateTime.IC, isPublished),
    published: weightsDate(a.PublishedSnapshot?.AsOfDate, isPublished),
    dynamic: weightsDate(a.WeightsDateTime.Dynamic, isPublished),
    average: weightsDate(a.WeightsDateTime.Average, isPublished),
    saved: weightsDate(a.SavedSnapshot?.AsOfDate),
  };
}

/**
 * Formats a single allocation weight.
 */
function weightsDate(dateTime: MaybeDate, condition: boolean = true) {
  return !!dateTime && condition ? toShortDateString(dateTime) : "n/a";
}

/**
 * Returns a dummy allocation for feeding all instruments to AssetTreeBuilder.
 * We do this just to make sure that also newly added instruments will be considered here.
 */
function getInstrumentsHolder(instruments: InstrumentWithOverrides[]): Allocation {
  const dummyMasterDataEntry = { Name: "X", ShortName: "X", Id: "0" };
  return {
    AllocationType: AllocationType.Taa,
    FrequencyUnit: 0,
    AuretoRebalancingMethod: AuretoRebalancingMethod["NON-GT"],
    Id: "AddedInstrumentsHolder",
    IsDeleted: false,
    Name: "AddedInstrumentsHolder",
    ICWeights: [],
    ProductLine: dummyMasterDataEntry,
    ProductDistinction: dummyMasterDataEntry,
    ReferenceCurrency: dummyMasterDataEntry,
    RiskProfile: dummyMasterDataEntry,
    Starred: false,
    IsInEditableState: false,
    IsEditable: false,
    EnableCashPositionNetting: false,
    Owner: dummyMasterDataEntry,
    IsOwner: false,
    State: dummyMasterDataEntry,
    StateHistory: [],
    Description: "",
    WeightsDateTime: {
      IC: undefined,
      Average: undefined,
      Dynamic: undefined,
    },
    Distributions: [],
    SavedSnapshot: {
      Version: "0",
      Instruments: instruments.map((ai) => ({
        Instrument: ai,
        Weight: ZERO,
        IsDynamicWeight: false,
        IsAverageWeight: false,
        SubAssetClass: ai.assetSubClassOverride,
        RiskCurrency: ai.riskCurrencyOverride,
      })),
    },
    AllocationSource: AllocationSource.TstWeb,
  };
}

function getBigOrZero(value: any): BigType {
  try {
    return new Big(value);
  } catch (ex) {
    return ZERO;
  }
}
