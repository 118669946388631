import * as React from "react";
import Allocation from "../../types/Allocation";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import UserAndGroupAutocomplete from "./UserAndGroupAutocomplete";
import { Controller, useForm } from "react-hook-form";
import { request } from "../../modules/client";
import { baseUrl } from "../../store/apiUrlProvider";
import { useDispatch } from "react-redux";
import { detailPageDataRequest } from "../../store/pages/pages-slice";
import LoadingIndicator from "../shared/LoadingIndicator";
import { showAlert, showErrorDialog } from "../../store/app/app-slice";
import InstrumentAutocomplete, { InstrumentSearchResult } from "../shared/InstrumentAutocomplete";
import FrequencyUnit, { getFrequencyUnitSelectionItems } from "../../types/FrequencyUnit";
import AuretoRebalancingMethod, { getAuretoRebalancingMethodSelectionItems } from "../../types/AuretoRebalancingMethod";
import { width } from "@mui/system";

const PREFIX = 'EditAllocationSettingsDialog';

const classes = {
  row: `${PREFIX}-row`,
  content: `${PREFIX}-content`,
  dialog: `${PREFIX}-dialog`
};
const RootDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`& .${classes.row}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.content}`]: {
    fontSize: theme.typography.body1.fontSize,
  },

  [`&.${classes.dialog}`]: {
    "& .MuiDialog-paper": {
      overflow: "visible !important",
    },
  }
})) as typeof Dialog;

type Inputs = {
  IsDeleted: boolean;
  Description: string;
  OwnerId: number;
  LevelOutInstrumentId: string;
  EnableCashPositionNetting: boolean;
  FrequencyUnit: FrequencyUnit;
  AuretoRebalancingMethod: AuretoRebalancingMethod;
};

async function saveSettingsRequest(allocationId: string, values: Inputs) {
  var options = {
    method: "POST",
    payload: values,
  };

  return request(`${baseUrl()}api/modelportfolio/${allocationId}/settings`, options);
}

export default function EditAllocationSettingsDialog(props: {
  allowOwnerEditing: boolean;
  allowDeletion: boolean;
  allocation: Allocation;
  closeHandle: () => void;
}) {
  const dispatch = useDispatch();
  const [isBusy, setIsBusy] = React.useState<boolean>(false);
  const [showDeletedWarning, setShowDeletedWarning] = React.useState<boolean>(false);

  const saveSettings = (settings: Inputs) => {
    setIsBusy(true);
    saveSettingsRequest(props.allocation.Id, settings)
      .then((result: { Success: boolean; NewObjectName: string; Error: string }) => {
        if (result.Success) {
          dispatch(showAlert("Updated settings", { type: "success" }));
          dispatch(detailPageDataRequest(props.allocation.Id));
          props.closeHandle();
        } else {
          dispatch(showErrorDialog({ reason: result.Error || "Unspecified error" }));
        }
        setIsBusy(false);
      })
      .catch((reason) => {
        setIsBusy(false);
        dispatch(showErrorDialog({ reason: reason?.response?.data?.Error || reason }));
      });
  };

  const { handleSubmit, register, setValue, formState: { errors }, control } = useForm<Inputs>({
    mode: "onBlur",
    defaultValues: {
      Description: props.allocation.Description,
      OwnerId: parseInt(props.allocation.Owner?.Id, 10),
      LevelOutInstrumentId: props.allocation.LevelOutInstrument?.Id,
      FrequencyUnit: props.allocation.FrequencyUnit,
      AuretoRebalancingMethod: props.allocation.AuretoRebalancingMethod,
    },
  });

  const allocationOwner =
    (props.allocation.Owner && {
      Id: parseInt(props.allocation.Owner.Id),
      DisplayName: props.allocation.Owner.Name,
    }) ||
    undefined;

  return (
    <RootDialog
      disableEscapeKeyDown
      maxWidth="md"
      aria-labelledby="confirmation-dialog-title"
      open={true}
      className={classes.dialog}
    >
      <form onSubmit={handleSubmit(saveSettings)}>
        <DialogTitle id="confirmation-dialog-title">Edit Settings for {props.allocation.Name}</DialogTitle>
        <DialogContent dividers className={classes.content}>
          {props.allowDeletion && (
            <div className={classes.row}>
              <div>
                <FormControlLabel
                  style={{ marginLeft: 0 }}
                  control={
                    <Checkbox
                      id="deleted"
                      defaultChecked={props.allocation.IsDeleted}
                      {...register('IsDeleted', {
                        onChange: (evt) => {
                          setShowDeletedWarning(evt.target.checked);
                          setValue("IsDeleted", evt.target.checked);
                        }
                      })}
                    />
                  }
                  label="Deleted"
                />
              </div>
              {showDeletedWarning && (
                <Typography variant="caption">
                  Setting the allocation to deleted will not actually delete the allocation.
                  <br />
                  Deleted allocations are hidden from regular users (only admins can see them).
                </Typography>
              )}
            </div>
          )}
          <div className={classes.row}>
            <TextField
              id="description"
              label="Description"
              fullWidth
              defaultValue={props.allocation.Description}
              {...register("Description")}
            />
          </div>
          <div className={classes.row}>
            <Controller
              control={control}
              name="LevelOutInstrumentId"
              render={(_) => (
                <InstrumentAutocomplete
                  label="Level-Out Instrument"
                  initialInputValue={props.allocation.LevelOutInstrument?.ShortName}
                  onSelect={(i: InstrumentSearchResult) => setValue("LevelOutInstrumentId", i.instrumentId)}
                  limitToNotionals
                  preventAutoClear
                  fullWidth
                />
              )}
            />
          </div>
          <div className={classes.row}>
            <Controller
              control={control}
              name="OwnerId"
              render={(_) => (
                <>
                  {(props.allowOwnerEditing && (
                    <UserAndGroupAutocomplete
                      label="Owner *"
                      containerId={props.allocation.Id}
                      onSelect={(owner) => {
                        setValue("OwnerId", owner.Id);
                      }}
                      initialInputValue={allocationOwner}
                      fullWidth
                    />
                  )) || <TextField label="Owner *" value={props.allocation.Owner?.Name} disabled fullWidth />}
                </>
              )}
            />
          </div>
          <div className={classes.row}>
            <FormControlLabel
              style={{ marginLeft: 0 }}
              control={
                <Checkbox
                  id="enableCashPositionNetting"
                  defaultChecked={props.allocation.EnableCashPositionNetting}
                  {...register(
                      "EnableCashPositionNetting",
                      {
                        onChange: (evt) => {
                          setValue("EnableCashPositionNetting", evt.target.checked);
                        }
                      }
                  )}
                />
              }
              label="CASH/CAFT netting for TripleA"
            />
          </div>
          <div className={classes.row}>
            
          <Controller
              control={control}
              name="FrequencyUnit"
              render={(_) => (
                <FormControl id="frequency-unit-selection" variant="outlined">
                  <InputLabel id="frequency-unit-label">Frequency Unit</InputLabel>
                  <Select
                    labelId="frequency-unit-label"
                    label="Frequency Unit"
                    id="frequencyUnit"
                    defaultValue={props.allocation.FrequencyUnit}
                    onChange={((e: any) => setValue("FrequencyUnit", Number(e.target.value)))}
                    renderValue={((value: number) => `${value} - ${FrequencyUnit[value]}`) as any}
                    style={{width: '300px'}}
                  >
                    {getFrequencyUnitSelectionItems().map(i => (
                      <MenuItem key={i} value={i}>
                        {`${i} - ${FrequencyUnit[i]}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </div>

          
          <div className={classes.row}>
            
          <Controller
              control={control}
              name="AuretoRebalancingMethod"
              render={(_) => (
                <FormControl id="aureto-rebalancing-method-selection" variant="outlined">
                  <InputLabel id="aureto-rebalancing-method-label">AuReTo Rebalancing method</InputLabel>
                  <Select
                    labelId="aureto-rebalancing-method-label"
                    label="AuReTo Rebalancing method"
                    id="auretoRebalancingMethod"
                    defaultValue={props.allocation.AuretoRebalancingMethod}
                    onChange={((e: any) => setValue("AuretoRebalancingMethod", Number(e.target.value)))}
                    renderValue={((value: number) => AuretoRebalancingMethod[value])}
                    style={{width: '300px'}}
                  >
                    {getAuretoRebalancingMethodSelectionItems().map(i => (
                      <MenuItem key={i} value={i}>
                        {AuretoRebalancingMethod[i]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </div>
        </DialogContent>
        <LoadingIndicator show={isBusy} size="40">
          <DialogActions>
            <Button
              disabled={isBusy}
              autoFocus
              onClick={(evt) => {
                evt.stopPropagation();
                props.closeHandle();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained" disabled={isBusy}>
              Save
            </Button>
          </DialogActions>
        </LoadingIndicator>
      </form>
    </RootDialog>
  );
}
