export const CSVToArray = (strData: string, strDelimiter: string = "\t"): string[][] => {
  // Create a regular expression to parse the CSV values.
  const objPattern = new RegExp(
    // Delimiters.
    `(\\${strDelimiter}|\\r?\\n|\\r|^)` +
      // Quoted fields.
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      // Standard fields.
      `([^"\\${strDelimiter}\\r\\n]*))`,
    "gi"
  );

  const arrData: string[][] = [[]];
  let arrMatches = null;

  // eslint-disable-next-line no-cond-assign
  while ((arrMatches = objPattern.exec(strData))) {
    const strMatchedDelimiter = arrMatches[1]; // eslint-disable-line prefer-destructuring
    if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
      arrData.push([]);
    }

    let strMatchedValue;
    if (arrMatches[2]) {
      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
    } else {
      // We found a non-quoted value.
      strMatchedValue = arrMatches[3]; // eslint-disable-line prefer-destructuring
    }
    arrData[arrData.length - 1].push(strMatchedValue);
  }

  if (arrData.length > 1 && arrData[0].length > 1 && arrData[arrData.length - 1].length === 1) {
    return arrData.slice(0, -1);
  }
  return arrData;
};
