import React, { useCallback, useEffect, useState } from "react";
import Allocation from "../../types/Allocation";
import AllocationSetMember from "../../types/AllocationSetMember";
import AllocationType from "../../types/AllocationType";
import Section from "./Section";
import EditButton from "./EditButton";
import SectionHeader from "./SectionHeader";
import EditAllocationLinksDialog from "./EditAllocationLinksDialog";
import { useDispatch } from "react-redux";
import { showAlert } from "../../store/app/app-slice";
import { hideLoadingScreen, showLoadingScreen } from "../../store/loading/loading-slice";
import * as api from "./apiRequests";
import Row from "./Row";
import { Link } from "react-router-dom";
import AllocationSource from "../../types/AllocationSource";
import { setDialog } from "../compare-view/slice";

/**
 * Section showing the allocation links.
 */
export default function AllocationLinksSection(props: { allocation: Allocation; canEdit: boolean }) {
  const { allocation, canEdit } = props;
  const dispatch = useDispatch();
  const [showLinkDialog, setShowLinkDialog] = useState(false);
  const [links, setLinks] = useState<AllocationSetMember[]>([]);
  const taaLink = links.find((l) => l.AllocationType === AllocationType.Taa);
  const tcaLink = links.find((l) => l.AllocationType === AllocationType.Tca);
  const saaLink = links.find((l) => l.AllocationType === AllocationType.Saa);
  const isTstWebManaged = allocation.AllocationSource === AllocationSource.TstWeb;

  useEffect(() => {
    async function doFetch() {
      try {
        dispatch(showLoadingScreen());
        const response = await api.getAllocationLinks(allocation.Id);
        setLinks(response || []);
      } catch (err) {
        dispatch(showAlert("Fetching allocation links failed", { type: "error" }));
        console.error(err);
      } finally {
        dispatch(hideLoadingScreen());
      }
    }

    doFetch();
  }, []);

  const openDialog = useCallback(() => setShowLinkDialog(true), []);

  const closeDialog = useCallback((updatedLinks?: AllocationSetMember[]) => {
    if (updatedLinks) {
      setLinks(updatedLinks);
    }
    setShowLinkDialog(false);
  }, []);

  const closeDialogOnNavigaton = useCallback(() => {
    dispatch(setDialog(undefined));
  }, [close]);

  return (
    <>
      <Section>
        <SectionHeader title="Links">
          {canEdit && isTstWebManaged && <EditButton tooltip="Edit links" onClick={openDialog} />}
        </SectionHeader>
        <Row label="TAA">
          {(taaLink && <Link onClick={closeDialogOnNavigaton} to={"/modelportfolio/" + taaLink.ContainerId}>{taaLink.ObjectName}</Link>) || "-"}
        </Row>
        <Row label="TCA">
          {(tcaLink && <Link onClick={closeDialogOnNavigaton} to={"/modelportfolio/" + tcaLink.ContainerId}>{tcaLink.ObjectName}</Link>) || "-"}
        </Row>
        <Row label="SAA">
          {(saaLink && <Link onClick={closeDialogOnNavigaton} to={"/modelportfolio/" + saaLink.ContainerId}>{saaLink.ObjectName}</Link>) || "-"}
        </Row>
      </Section>
      {showLinkDialog && <EditAllocationLinksDialog allocation={props.allocation} links={links} onClose={closeDialog} />}
    </>
  );
}
