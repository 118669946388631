import Allocation from "../types/Allocation";
import AllocationType from "../types/AllocationType";
import ModelPortfolio from "../types/ModelPortfolio";

const RiskSortArray = ["FI", "IN", "BA", "GR", "CG"];

export const allocationSorter = (a: Allocation, b: Allocation): number => {
  if (a.ProductLine && b.ProductLine && a.ProductLine.Name && b.ProductLine.Name && a.ProductLine.Name !== b.ProductLine.Name) {
    return a.ProductLine.Name.localeCompare(b.ProductLine.Name);
  }

  if (
    a.ProductDistinction &&
    b.ProductDistinction &&
    a.ProductDistinction.Name &&
    b.ProductDistinction.Name &&
    a.ProductDistinction.Name !== b.ProductDistinction.Name
  ) {
    if (a.ProductDistinction.Name === "xx") {
      return -1;
    }
    if (b.ProductDistinction.Name === "xx") {
      return 1;
    }
    return a.ProductDistinction.Name.localeCompare(b.ProductDistinction.Name);
  }

  if (
    a.ReferenceCurrency &&
    b.ReferenceCurrency &&
    a.ReferenceCurrency.Name &&
    b.ReferenceCurrency.Name &&
    a.ReferenceCurrency.Name !== b.ReferenceCurrency.Name
  ) {
    return a.ReferenceCurrency.Name.localeCompare(b.ReferenceCurrency.Name);
  }

  if (a.AllocationType == AllocationType.ModelPortfolio && b.AllocationType == AllocationType.ModelPortfolio) {
    const mopoA = a as ModelPortfolio;
    const mopoB = b as ModelPortfolio;

    if (
      mopoA.BmoContext &&
      mopoB.BmoContext &&
      mopoA.BmoContext.Name &&
      mopoB.BmoContext.Name &&
      mopoA.BmoContext.Name !== mopoB.BmoContext.Name
    ) {
      if (mopoA.BmoContext.Name === "WO") {
        return -1;
      }
      if (mopoB.BmoContext.Name === "WO") {
        return 1;
      }
      return mopoA.BmoContext.Name.localeCompare(mopoB.BmoContext.Name);
    }
  }

  if (a.RiskProfile && b.RiskProfile && a.RiskProfile.Name && b.RiskProfile.Name && a.RiskProfile.Name !== b.RiskProfile.Name) {
    return RiskSortArray.indexOf(a.RiskProfile.Name) - RiskSortArray.indexOf(b.RiskProfile.Name);
  }

  return 0;
};
