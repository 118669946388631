import * as React from "react";
import { styled } from '@mui/material/styles';
import { AppState } from "../store/AppState";
import MetaDetailView from "./meta-view/MetaDetailView";
import { useParams } from 'react-router';
import {
  Navigate,
  createBrowserRouter,
  Outlet,
  RouterProvider, useLocation, RouteObject
} from "react-router-dom";
import { configuredPiwik } from "../modules/piwik";
import { useDispatch, useSelector, useStore } from "react-redux";
import { masterDataRequest } from "../store/masterData/masterData-slice";
import { userInfoRequest, advancedUserInfoRequest } from "../store/user/user-slice";
import Header from "./Header";
import IcAllocations from "./allocation-table/IcAllocations";
import InstrumentLists from "./instrument-lists/InstrumentLists";
import InstrumentListItems from "./instrument-lists/InstrumentListItems";
import LoadingModal from "./LoadingModal";
import ModelPortfolios from "./allocation-table/ModelPortfolios";
import { NotFound } from "./NotFound";
import Nyan from "./Nyan";
import SystemAlert from "./SystemAlerts";
import { ErrorBoundary } from "./ErrorBoundary";
import Footer from "./Footer";
import { History } from "history";
import BannerSlide from "./administration/banners/BannerSlide";
import CompareView from "./compare-view/CompareView";
import ErrorDialogs from "./ErrorDialogs";
import { responsiveHorizontalPadding } from "./shared/styles";
import Administration from "./administration/Administration";
import Daisy from "./daisy/Daisy";
import ReferenceDate from "./daisy/ReferenceDate";
import Dialogs from './shared/Dialogs';
import { useOnLocationChange } from '../modules/useLocationChange';

const PREFIX = 'App';
const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    position: "relative",
    opacity: "1 !important",
    transition: "opacity 0.5s",
  },

  [`& .${classes.content}`]: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    "& header": {
      flexGrow: 0,
    },
    "& main": {
      flexGrow: 1,
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(4),
      ...responsiveHorizontalPadding(theme),
    },
    "& footer": {
      flexGrow: 0,
      ...responsiveHorizontalPadding(theme),
    },
  }
}));

const AppLayout: React.FC<{ softwareVersion: string }> = ({ softwareVersion }) => {
  const location = useLocation();
  useOnLocationChange();

  return <Root className={classes.root}>
    <LoadingModal/>
    <ErrorDialogs/>
    <BannerSlide/>
    <SystemAlert/>
    <div className={classes.content}>
      <Header/>
      <main>
        {/* Outlet = the place where we want to render the content of the RouterProvider */}
        <Outlet/>
      </main>
      <Dialogs/>
      <Footer version={softwareVersion}/>
    </div>
    { location.pathname === "/" && <Navigate to="/modelportfolios" replace={true} />}
  </Root>
};

const ModelPortfoliosRedirect: React.FC = () => {
  const params = useParams();

  return <Navigate to={`/compare/${params.ids}`} />
}

// can be used for tests
export const routerConfig: (element: React.ReactNode) => RouteObject[] = (element: React.ReactNode) => [
  {
    path: "/",
    element,
    children: [
      { path: "/modelportfolios", element: <ModelPortfolios /> },
      { path: "/modelportfolios/:ids", element: <ModelPortfoliosRedirect /> },
      { path: "/compare/:ids", element: <CompareView /> },
      { path: "/modelportfolio/:id", element: <MetaDetailView /> },
      { path: "/allocations", element: <IcAllocations /> },
      { path: "/administration", element: <Navigate to={"/administration/dashboard"} replace={true} /> },
      { path: "/administration/", element: <Navigate to={"/administration/dashboard"} replace={true} /> },
      { path: "/marketing/", element: <Navigate to={`/marketing/${ReferenceDate.getDefault().toPathParameter()}`} replace={true} /> },
      { path: "/marketing/:referenceDate/", element: <Daisy /> },
      { path: "/administration/:componentName/", element: <Administration /> },
      { path: "/nyannyan", element: <Nyan /> },
      { path: "/instrument-lists", element: <InstrumentLists /> },
      { path: "/instrument-lists/:id/", element: <InstrumentListItems /> },
      { path: "*", element: <NotFound /> },
    ],
  },
  { path: "*", element: <NotFound /> },
]

const router = (element: React.ReactNode) => createBrowserRouter(routerConfig(element));

export default function App_(props: { history: History }) {
  const dispatch = useDispatch();
  const { hash, softwareVersion, environment } = useSelector((s: AppState) => s.masterData);
  const history = React.useRef<History>(props.history);

  React.useEffect(() => {
    dispatch(userInfoRequest());
    dispatch(advancedUserInfoRequest());
    dispatch(masterDataRequest());

    const piwikHistory = configuredPiwik().connectToHistory(history.current);
    if (piwikHistory) {
      history.current = piwikHistory;
    }
  }, []);

  return (
      <ErrorBoundary environment={environment} softwareVersion={softwareVersion}>
        <RouterProvider router={router(<AppLayout softwareVersion={softwareVersion} />)} />
      </ErrorBoundary>
  );
}
