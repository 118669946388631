export class ServerError extends Error {
  response: any;

  constructor(message: string) {
    super(message);

    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error().stack;
    }

    this.name = "ServerError";
    this.response = {};

    return this;
  }
}

export const parseError = (error: any) => error || "Something went wrong";

export const handleRequest = async (url: string, params: any = {}): Promise<any> => {
  const response = await fetch(url, params);
  const contentType = response.headers.get("content-type");

  if (response.status > 299) {
    const error = new ServerError(response.statusText);

    if (contentType && contentType.includes("application/json")) {
      error.response = {
        status: response.status,
        data: await response.json(),
      };
    } else {
      error.response = {
        status: response.status,
        data: await response.text(),
      };
    }

    throw error;
  } else {
    if (contentType && contentType.includes("application/json")) {
      return response.json();
    }

    return response.text();
  }
};
