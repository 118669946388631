import * as React from "react";
import { styled } from '@mui/material/styles';
import { Chip, Divider, MenuItem, Popover, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import deepEqual from "lodash/isEqual";
import cn from "classnames";
import KeyValuePair from "./KeyValuePair";
import usePrevious from "../../modules/usePrevious";

/**
 * A multi-select control.
 *
 * Renders as a box which looks like a normal select box.
 * Selected items are shown as chips below the box.
 * The menu pops up directly above the select box and shows
 * the chips as well.
 *
 * Is disabled automatically, if `data` prop is empty or all
 * items have been selected already. However, this behaviour
 * can be overridden by setting `disabled` prop explicitly.
 *
 * TODO - `React.memo` doesn't work as expected, a banner update
 * for example causes a re-render of all multi selects in the
 * ItemPanel (and probably of the whole page).
 */

const PREFIX = 'MultiSelect';
const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  buttonBorderInvisible: `${PREFIX}-buttonBorderInvisible`,
  menu: `${PREFIX}-menu`,
  selection: `${PREFIX}-selection`,
  popupContainer: `${PREFIX}-popupContainer`,
  popupWidth: `${PREFIX}-popupContainer`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    position: "relative",
  },

  [`& .${classes.button}`]: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    border: "1px solid " + theme.palette.divider,
    borderRadius: 2,
    "& > p": {
      marginRight: theme.spacing(1),
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .expandIcon": {
      transform: "rotate(0deg)",
      transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
    },
    "& .expandIcon-expanded": {
      transform: "rotate(180deg)",
    },
  },

  [`& .${classes.buttonBorderInvisible}`]: {
    border: "1px solid transparent",
    borderRadius: 2,
  },

  [`& .${classes.menu}`]: {
    padding: theme.spacing(1),
    maxHeight: theme.spacing(50), //"80vh",
    overflowY: "auto",
  },

  [`& .${classes.selection}`]: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.5),
    "& > .MuiChip-root": {
      margin: theme.spacing(0.25),
    },
  },

  [`& .${classes.popupContainer}`]: {
    "& > .MuiDivider-root": {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.popupWidth}`]: {

  }
}));

export default React.memo(function MultiSelect(props: {
  id: string;
  label: string;
  data: KeyValuePair[];
  selectedKeys?: any[];
  onChange: (id: string, keys: any[]) => void;
  disabled?: boolean;
}) {
  const { id, label, data, selectedKeys, onChange } = props;

  const [anchor, setAnchor] = React.useState(null as HTMLDivElement | null);
  const isOpen = !!anchor;

  // This is for direct (indexed) access of the data items.
  // Avoids repeated iterations (filtering) over data prop
  // when rendering selected items.
  const [dict, setDict] = React.useState<any>({});
  React.useEffect(
    () =>
      setDict(
        data.reduce((res, cur) => {
          res[cur.key as any] = cur.value;
          return res;
        }, {})
      ),
    [data]
  );

  // We hold the actual selection as internal state independendly from the
  // passed prop `selectedKeys` in order to overcome some performance penalties
  // which become obvious for complex, non-optimized (reg. `shouldComponentUpdate`)
  // view hierarchies.
  const initialSelection = selectedKeys || [];
  const prevInitialSelection = usePrevious(initialSelection);
  const [selection, setSelection] = React.useState(initialSelection);
  if (!deepEqual(prevInitialSelection, initialSelection) && !deepEqual(initialSelection, selection)) {
    setSelection(initialSelection);
  }

  const allSelected = isEverythingSelected(selection);
  const disabled = !!props.disabled || data.length === 0 || allSelected;

  // This aligns the menu popover's width with the width of its anchor.
  // Subtracting the spacing compensates the padding.
  // Note, that we have to apply the width style to each of the children
  // in .popupContainer separately, otherwise (applying it to the container)
  // is not working :/

  const popoverWidth = `${(anchor?.offsetWidth || 0) - 16}px`;

  const selectionChips = (
    <div className={classes.selection}>
      {selection.map((id) => {
        const deselectItem = () => deselect(id);
        return <Chip key={id} size="small" color="primary" label={dict[id]} onDelete={deselectItem} onClick={deselectItem} />;
      })}
    </div>
  );

  const buttonContent = (
    <React.Fragment>
      <Typography variant="body2" color={disabled ? "textSecondary" : "textPrimary"}>
        {label}
      </Typography>
      {allSelected ? (
        <ClearIcon color="primary" />
      ) : (
        <ExpandMoreIcon className={cn("expandIcon", isOpen && "expandIcon-expanded")} color={disabled ? "disabled" : "primary"} />
      )}
    </React.Fragment>
  );

  function open(e: React.MouseEvent<HTMLDivElement>) {
    setAnchor(e.currentTarget);
  }

  function close() {
    setAnchor(null);
  }

  function select(key: string | number) {
    const newSelection = [...selection, key];
    setSelection(newSelection);
    onChange(id, newSelection);
    if (isEverythingSelected(newSelection)) {
      close();
    }
  }

  function deselect(key: string | number) {
    const newSelection = selection.filter((i) => i !== key);
    setSelection(newSelection);
    onChange(id, newSelection);
  }

  function reset() {
    const newSelection: string[] = [];
    setSelection(newSelection);
    onChange(id, newSelection);
  }

  function isEverythingSelected(keys: any[]) {
    return data.every((d) => keys.includes(d.key));
  }

  return (
    <Root id={"multiselect-" + id} className={cn("multiselect-root", classes.root)}>
      <div className={classes.button} onClick={allSelected ? reset : disabled ? noop : open}>
        {buttonContent}
      </div>
      {selectionChips}
      <Popover
        open={isOpen}
        anchorEl={anchor}
        onClose={close}
        anchorOrigin={{
          vertical: -1, // compensate border
          horizontal: -1, // compensate border
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 0,
        }}
        transitionDuration={0} // looks strange otherwise
      >
        <Root>
          <div className={classes.popupContainer}>
            <div className={cn(classes.button, classes.buttonBorderInvisible)} style={{ width: popoverWidth }} onClick={close}>
              {buttonContent}
            </div>
            <div style={{ width: popoverWidth }}>{selectionChips}</div>
            {selection.length > 0 && <Divider light />}
            <div className={classes.menu} style={{ width: popoverWidth }}>
              {data
                .filter((d) => !selection.includes(d.key))
                .map((d) => (
                  <MenuItem key={d.key} onClick={() => select(d.key)}>
                    <Typography variant="body2">{d.value}</Typography>
                  </MenuItem>
                ))}
            </div>
          </div>
        </Root>
      </Popover>
    </Root>
  );
});

function noop() {}
