import * as React from "react";
import AaaStrategyNature from "../../types/AaaStrategyNature";
import Allocation from "../../types/Allocation";
import Destination from "../../types/Destination";
import DistributionTable from "./DistributionTable";
import MasterDataEntry from "../../types/MasterDataEntry";
import ModelPortfolio from "../../types/ModelPortfolio";
import { Checkbox, Link } from "@mui/material";
import { AppState } from "../../store/AppState";
import { useDispatch, useSelector } from "react-redux";
import { transformLabel } from "../../modules/transformLabels";
import EditAllocationSettingsDialog from "./EditAllocationSettingsDialog";
import AllocationLinksSection from "./AllocationLinksSection";
import AllocationType, { allocationTypeToResultKey } from "../../types/AllocationType";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Row from "./Row";
import EditButton from "./EditButton";
import AllocationSource, { allocationSourceString } from "../../types/AllocationSource";
import { hideLoadingScreen, showLoadingScreen } from "../../store/loading/loading-slice";
import { request } from "../../modules/client";
import { showAlert } from "../../store/app/app-slice";
import { baseUrl } from "../../store/apiUrlProvider";
import { detailPageDataRequest, detailPageUpdateSuccess } from "../../store/pages/pages-slice";
import FrequencyUnit from "../../types/FrequencyUnit";
import AuretoRebalancingMethod from "../../types/AuretoRebalancingMethod";

/**
 * Component displays information about an allocation (Productline, Ref. Currency, etc. pp.)
 * and it's settings (e.g. Level-Out instrument).
 */

export default function BasicInformation(props: {
  enableEditing: boolean;
  allocation: Allocation;
  marketStructures: MasterDataEntry[];
  aaaStrategyNatures: AaaStrategyNature[];
  destinations: Destination[];
}) {
  const masterData = useSelector((state: AppState) => state.masterData);
  const lookupLongName = (data: MasterDataEntry[], search: MasterDataEntry) => {
    const value = data.find((it) => it.Id === search.Id);
    return value?.Name || search.Name;
  };

  const [showEditDialog, setShowEditDialog] = React.useState(false);

  const { isAdmin, canChangePortfolios } = useSelector((state: AppState) => state.user);
  // effectively semantic equal to isAdmin || (isOwner && isSuperUser)
  const hasPermissionToChangeProperties = canChangePortfolios && (props.allocation.IsOwner || isAdmin);
  const canEdit = props.enableEditing && hasPermissionToChangeProperties;

  // TCAs can only use the currency market structure (not e.g. the AA (IM AA))
  const applicableStrategyNatures =
    props.allocation.AllocationType == AllocationType.Tca
      ? props.aaaStrategyNatures.filter((it) => it.AaaStrategyNatureShort == "AA (IM CA)")
      : props.aaaStrategyNatures;

  return (
    <div>
      <Section>
        <SectionHeader title="Properties" />

        <Row label="Product (Distinction)">
          {lookupLongName(masterData.productLines, props.allocation.ProductLine)} (
          {lookupLongName(masterData.productDistinctions, props.allocation.ProductDistinction)})
        </Row>

        <Row label="Reference Currency">{props.allocation.ReferenceCurrency.Name}</Row>
        <Row label="Investment Strategy">{lookupLongName(masterData.riskProfiles, props.allocation.RiskProfile)}</Row>

        {props.allocation.hasOwnProperty("BmoContext") && (
          <Row label="BMO Context">{lookupLongName(masterData.bmoContexts, (props.allocation as ModelPortfolio).BmoContext)}</Row>
        )}

        <CurrentStateRow allocation={props.allocation} canEdit={canEdit} />
        <Row label="Source">{allocationSourceString(props.allocation.AllocationSource)}</Row>
      </Section>

      <Section>
        <SectionHeader title="Settings">
          {canEdit && <EditButton tooltip="Edit settings" onClick={() => setShowEditDialog(true)} />}
        </SectionHeader>
        {isAdmin && (
          <Row label="Deleted">
            <Checkbox checked={props.allocation.IsDeleted} disabled readOnly style={{ marginLeft: -5 }} />
          </Row>
        )}
        <Row label="Description">{props.allocation.Description}</Row>
        <Row label="Owner">{props.allocation.Owner.Name}</Row>
        <Row label="Level-Out Instrument">{props.allocation.LevelOutInstrument?.ShortName}</Row>
        <Row label="CASH/CAFT netting for TripleA">
          <Checkbox checked={props.allocation.EnableCashPositionNetting} disabled readOnly style={{ marginLeft: -5 }} />
        </Row>
        <Row label="Frequency Unit">{`${props.allocation.FrequencyUnit} - ${FrequencyUnit[props.allocation.FrequencyUnit]}`}</Row>
        <Row label="AuReTo Rebalancing Method">{AuretoRebalancingMethod[props.allocation.AuretoRebalancingMethod]}</Row>
      </Section>

      {props.allocation.AllocationType === AllocationType.ModelPortfolio && (
        <AllocationLinksSection allocation={props.allocation} canEdit={canEdit} />
      )}

      <Section>
        <DistributionTable
          allocation={props.allocation}
          isAdmin={isAdmin}
          isEditable={props.enableEditing}
          marketStructures={props.marketStructures}
          aaaStrategyNatures={applicableStrategyNatures}
          destinations={props.destinations}
        />
      </Section>

      {showEditDialog && (
        <EditAllocationSettingsDialog
          allowOwnerEditing={isAdmin}
          allowDeletion={isAdmin}
          allocation={props.allocation}
          closeHandle={() => setShowEditDialog(false)}
        />
      )}
    </div>
  );
}

function CurrentStateRow(props: { allocation: Allocation; canEdit: boolean }) {
  const { allocation, canEdit } = props;
  const state = props.allocation.State?.ShortName;
  const isPendingState = state?.endsWith("Pending");
  const isNonAlpima = allocation.AllocationSource !== AllocationSource.Alpima;
  const dispatch = useDispatch();

  async function abort() {
    try {
      dispatch(showLoadingScreen());
      await request(baseUrl() + "api/publish/abort/" + allocation.Id, { method: "POST" });
      dispatch(detailPageDataRequest(allocation.Id));
      dispatch(showAlert("Abort publish succeeded", { type: "success" }));
    } catch (err) {
      dispatch(showAlert("Abort publish failed", { type: "error" }));
      console.error("Abort publish failed", err);
    } finally {
      dispatch(hideLoadingScreen());
    }
  }

  async function retry() {
    try {
      dispatch(showLoadingScreen());
      await request(baseUrl() + "api/publish/retry/" + allocation.Id, { method: "POST" });
      dispatch(detailPageDataRequest(allocation.Id));
      dispatch(showAlert("Retry publish succeeded", { type: "success" }));
    } catch (err) {
      dispatch(showAlert("Retry publish failed", { type: "error" }));
      console.error("Retry publish failed", err);
    } finally {
      dispatch(hideLoadingScreen());
    }
  }

  return (
    <Row label="Current State">
      {transformLabel("state", state)}
      {canEdit && isPendingState && (
        <>
          {" ("}
          <Link style={{ cursor: "pointer" }} onClick={retry}>
            Retry
          </Link>
          {isNonAlpima && (
            <>
              {" or "}
              <Link style={{ cursor: "pointer" }} onClick={abort}>
                Abort
              </Link>
            </>
          )}
          {")"}
        </>
      )}
    </Row>
  );
}
