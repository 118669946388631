import * as React from "react";
import { styled, createTheme } from "@mui/material/styles";
import { IconButton, Slide } from "@mui/material";
import { ClearIcon } from "../../shared/Icons";
import { first, sortBy } from "lodash";
import { Banner } from "../../../store/app/AppState";
import { useDispatch, useSelector } from "react-redux";
import { dismissBanner } from "../../../store/app/app-slice";
import { AppState } from "../../../store/AppState";

/**
 * Component showing a banner on top of the browser window.
 */

const palette = createTheme().palette;
const colors = [palette.error.main, palette.warning.main, palette.info.main];

export const bannerStyle = {
  globalCss: { fontSize: 14, "h1,h2,h3,h4,h5,h6,div,p": { color: "white" }, a: { color: "white", textDecoration: "underline" } },
  bannerCss: { opacity: 0.9, width: "56rem", color: "white" },

  getColor(importance?: number) {
    return colors[importance || 0];
  },
};

const PREFIX = 'BannerSlide';
const classes = {
  root: `${PREFIX}-root`,
  banner: `${PREFIX}-banner`,
  clearIcon: `${PREFIX}-clear-icon`
}
const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    "@global": {
      ...bannerStyle.globalCss,
      button: { fontSize: 16, color: "white" },
    },
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 1000,
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  [`& .${classes.banner}`]: {
    ...bannerStyle.bannerCss,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  [`& .${classes.clearIcon}`]: {
    color: "inherit"
  }
}));

export default function BannerSlide() {
  const dispatch = useDispatch();
  const [current, setCurrent] = React.useState(undefined as Banner | undefined);
  const banners = useSelector((s: AppState) => s.app.banners);
  const active = getActiveBanner(banners);

  if (active && current !== active) {
    setCurrent(active);
  }

  const show = !!active;
  const message = { __html: current?.HtmlMessage || "" };
  const color = bannerStyle.getColor(current?.Importance);

  function dismiss() {
    if (current) {
      dispatch(dismissBanner({ bannerId: current.Id }));
    }
  }

  return (
    <Slide direction="down" in={show} mountOnEnter unmountOnExit>
      <Root className={classes.root} style={{ backgroundColor: color }}>
        <div id="uitest-banner" className={classes.banner}>
          <div dangerouslySetInnerHTML={message}></div>
          <IconButton className={classes.clearIcon} onClick={dismiss}>
            <ClearIcon />
          </IconButton>
        </div>
      </Root>
    </Slide>
  );
}

function getActiveBanner(banners: Banner[]) {
  const now = Date.now();
  const candidates = banners.filter((b) => !b.dismissed && Date.parse(b.StartDate) < now && now < Date.parse(b.EndDate));
  return first(sortBy(candidates, (b) => b.Importance));
}
