import React from "react";
import { useTheme } from "@mui/material";
import ServerMessageDisplay from "./ServerMessageDisplay";
import { ServerMessage } from "../../types/InstrumentList";
import BaseConfirmDialog from "../shared/ConfirmDialog";

/**
 * Extends the basic /shared/ConfirmDialog with the option to display a ServerMessage[].
 */

export default function ConfirmDialog(props: {
  open: boolean;
  title: string;
  children: React.ReactNode;
  /**
   * defaults to 'OK'
   */
  okButtonLabel?: string;
  handleOk: () => void;
  okButtonDisabled?: boolean;
  handleCancel?: () => void;
  serverMessages?: ServerMessage[];
  allowCommentsOnMessages: boolean;
  // defaults to true
  autoFocusOkButton?: boolean;
}) {
  const theme = useTheme();

  const allowComments = props.allowCommentsOnMessages === true || false;

  return (
    <BaseConfirmDialog
      open={props.open}
      title={props.title}
      okButtonLabel={props.okButtonLabel}
      handleOk={props.handleOk}
      okButtonDisabled={props.okButtonDisabled}
      handleCancel={props.handleCancel}
      autoFocusOkButton={props.autoFocusOkButton}
    >
      <div style={theme.typography.body1}>{props.children}</div>
      {props.serverMessages && props.serverMessages.length > 0 && (
        <ServerMessageDisplay messages={props.serverMessages} maxHeight="30em" allowComments={allowComments} />
      )}
    </BaseConfirmDialog>
  );
}
