import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { baseUrl, timestampParameter } from "../apiUrlProvider";
import {
  userInfoRequest,
  advancedUserInfoRequest,
  userInfoSuccess,
  advancedUserInfoSuccess,
  advancedUserInfoFailure,
  userInfoFailure,
  setPreferPrettyInstrumentNames,
} from "./user-slice";
import { request } from "../../modules/client";
import { showAlert, showServerDown, showUnauthorized } from "../app/app-slice";
import { AppState } from "../AppState";

export const info = function* () : any {
  try {
    const response = yield call(request, `${baseUrl()}api/user/info?${timestampParameter()}`);
    yield put(userInfoSuccess({ data: response }));
  } catch (err : any) {
    if (err && err.response && (err.response.status === 401 || err.response.status === 403)) {
      yield put(showUnauthorized());
    } else {
      yield put(showServerDown());
    }

    yield put(userInfoFailure(err));
  }
};

export const updatePreferences = function* () : any {
  try {
    const preferences = yield select((state: AppState) => state.user.preferences);
    yield call(request, `${baseUrl()}api/user/preferences`, { method: "POST", payload: preferences });
    yield put(showAlert("Updated your preference", { type: "success" }));
  } catch (err) {
    console.log("Failed to save preferences", err);
    yield put(showAlert(`Error saving your preference`, { type: "error" }));
  }
};

export const advanced = function* () : any {
  try {
    const response = yield call(request, `${baseUrl()}api/user/advanced?${timestampParameter()}`);
    yield put(advancedUserInfoSuccess({ data: response }));
  } catch (err : any) {
    yield put(advancedUserInfoFailure(err));
  }
};

export default function* root() {
  yield all([
    takeLatest(userInfoRequest.type, info),
    takeLatest(advancedUserInfoRequest.type, advanced),
    takeLatest(setPreferPrettyInstrumentNames.type, updatePreferences),
  ]);
}
