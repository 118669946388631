import { request } from "../../modules/client";
import { baseUrl, timestampParameter } from "../../store/apiUrlProvider";
import AaaStrategyNature from "../../types/AaaStrategyNature";
import Allocation from "../../types/Allocation";
import { AllocationReference } from "../../types/AllocationReference";
import { AssetTree } from "../../types/AssetTree";
import Destination from "../../types/Destination";
import { Environment } from "../../types/ConfigVariables";
import Instrument, { AssetTreeMapping } from "../../types/Instrument";
import parseAndPatchAllocationDates from "./utils/parseAndPatchAllocationDates";
import MasterDataEntry from "../../types/MasterDataEntry";
import { ProductLine } from "../../types/ProductLine";
import AllocationUploadRow from "../../types/AllocationUploadRow";
import { AllocationUploadValidationResponse } from "../../types/AllocationUploadValidationResponse";
import { AllocationDiffPayload } from "../../types/AllocationDiffPayload";
import { AllocationChangeResult } from "../../types/AllocationChangeResult";
import { AllocationQueuePayload } from "../../types/AllocationQueuePayload";
import { isString } from "lodash";
import parseISO from "date-fns/parseISO";

/**
 * All backend API requests required for compare view.
 *
 * TODO - maybe we could just have a central place for all requests, maybe structured by controllers..
 */

export async function getInstrument(instrumentId: string) {
  const response: Instrument = await request(`${baseUrl()}api/instrument/details/${instrumentId}`);
  return response;
}

export async function getInstruments(instrumentIds: string[]) {
  const response: Instrument[] = await request(`${baseUrl()}api/instrument/exists`, {
    method: "POST",
    payload: instrumentIds,
  });
  return response;
}

export async function getIcStructureMapping(assetClass: string | undefined, assetSubClass: string, riskCurrency: string) {
  const qs = new URLSearchParams();
  qs.append("assetSubClass", assetSubClass);
  qs.append("riskCurrency", riskCurrency);
  if (assetClass) {
    qs.append("assetClass", assetClass);
  }
  const response: AssetTreeMapping = await request(`${baseUrl()}api/instrument/ic-structure-mapping?${qs}`);
  return response;
}

export async function editSnapshot(allocationId: string) {
  const response: Allocation = await request(`${baseUrl()}api/snapshot/edit/${allocationId}?${timestampParameter()}`);
  return response;
}

export function keepSnapshotInEdit(allocationId: string) {
  return request(`${baseUrl()}api/snapshot/keepAliveInEdit/${allocationId}?${timestampParameter()}`);
}

export async function saveSnapshot(allocation: Allocation) {
  const response: Allocation = await request(`${baseUrl()}api/modelportfolio/save`, {
    method: "POST",
    payload: allocation,
  });
  return response;
}

export async function discardSnapshot(allocationId: string) {
  await request(`${baseUrl()}api/snapshot/discard/${allocationId}?${timestampParameter()}`);
}

export async function getAllocation(allocationId: string) {
  const response: Allocation = await request(`${baseUrl()}api/modelportfolio/${allocationId}`);
  parseAndPatchAllocationDates(response);
  return response;
}

export async function getDiff(allocation: Allocation) {
  const response: AllocationChangeResult = await request(`${baseUrl()}api/modelportfolio/diff`, {
    method: "POST",
    payload: allocation,
  });

  if (response?.AsOfDate && isString(response.AsOfDate)) {
    response.AsOfDate = parseISO(response.AsOfDate);
  }

  return response;
}

export async function getDiffMultiple(allocationDiffsPayload: AllocationDiffPayload[]) {
  const response: AllocationChangeResult[] = await request(`${baseUrl()}api/modelportfolio/diff-multiple`, {
    method: "POST",
    payload: allocationDiffsPayload,
  });
  response.forEach((r) => {
    if (r?.AsOfDate && isString(r.AsOfDate)) {
      r.AsOfDate = parseISO(r.AsOfDate);
    }
  });

  return response;
}

export async function cancelEditForMultipleAllocations(allocationCodes: string[]) {
  return await request(`${baseUrl()}api/modelportfolio/cancel-multiple-edit`, {
    method: "POST",
    payload: allocationCodes,
  });
}

export async function validateMultipleAllocationsUpload(allocationUploadRows: AllocationUploadRow[]) {
  const response: AllocationUploadValidationResponse[] = await request(`${baseUrl()}api/modelportfolio/validate-multiple`, {
    method: "POST",
    payload: allocationUploadRows,
  });
  return response;
}

export async function getDiffCsv(allocationChangeResults: AllocationChangeResult[]) {
  return await request(`${baseUrl()}api/modelportfolio/diff-multiple-csv`, {
    method: "POST",
    payload: allocationChangeResults,
  });
}

export async function queueMultipleAllocations(allocationQueuePayload: AllocationQueuePayload) {
  return await request(`${baseUrl()}api/modelportfolio/queue-multiple`, {
    method: "POST",
    payload: allocationQueuePayload,
  });
}

export async function queue(allocation: Allocation) {
  const response: Allocation = await request(`${baseUrl()}api/modelportfolio/queue`, {
    method: "POST",
    payload: allocation,
  });
  return response;
}

export async function getPublishedExposure(allocationId: string) {
  const response = await request(`${baseUrl()}api/modelportfolio/${allocationId}/exposure`);
  return {
    sector: response.Sector.map((i: any) => [i.Weight, i.Category]) as [number, string][],
    currency: response.Currency.map((i: any) => [i.Weight, i.Category]) as [number, string][],
  };
}

export async function getDraftExposure(weights: { instrumentId: string; weight: number }[]) {
  const response = await request(`${baseUrl()}api/modelportfolio/exposure`, { method: "POST", payload: { positions: weights } });
  return {
    sector: response.Sector.map((i: any) => [i.Weight, i.Category]) as [number, string][],
    currency: response.Currency.map((i: any) => [i.Weight, i.Category]) as [number, string][],
  };
}

/**
 * Candidates for shared requests...
 */

export async function getMasterData() {
  const response: {
    Currencies: MasterDataEntry[];
    ProductLines: ProductLine[];
    ProductDistinctions: MasterDataEntry[];
    RiskProfiles: MasterDataEntry[];
    BmoContexts: MasterDataEntry[];
    MarketStructures: MasterDataEntry[];
    AaaStrategyNatures: AaaStrategyNature[];
    Destinations: Destination[];
    Settings: { [key: string]: string };
    Trees: AssetTree[];
    IcCurrencyInstruments: Instrument[];
    SoftwareVersion: string;
    Environment: Environment;
    DmcUrl: string;
    TstCoreUrl: string;
  } = await request(`${baseUrl()}api/masterdata`);
  return response;
}

export async function getUserInfo() {
  const response: {
    Username?: string;
    FirstName?: string;
    LastName?: string;
    UserLocation?: string;
    Preferences: {
      PreferPrettyInstrumentNames?: boolean;
    };
    Groups: string[];
    Favorites: AllocationReference[];
  } = await request(`${baseUrl()}api/user/info?${timestampParameter()}`);
  return response;
}
