import * as React from "react";
import { AlertIcon, CloseIcon } from "./Icons";
import { IconButton, Paper, Tooltip } from "@mui/material";
import { styled } from '@mui/material/styles';

const PREFIX = 'ErrorBox';
const classes = {
  root: `${PREFIX}-root`,
  iconContainer: `${PREFIX}-iconContainer`
};
const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "row",
    flexFlow: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.error.light,
    color: "white",
    padding: theme.spacing(1),
  },

  [`& .${classes.iconContainer}`]: {
    // paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }
}));

/**
 *
 * Very simple component, that displays an error string.
 * Intented to be used where an error is to be shown without much formatting on the caller side.
 *
 * If no dismissCallback is provided will not show an 'x' to dismiss the message will be shown.
 *
 * Example usage:
 * {!!errorMessage &&
 * <ErrorBox  dismissCallback={()=>setErrorMsg(undefined)}>
 *    {errorMsg}
 * </ErrorBox>
 * }
 */
export default React.memo(function (props: { children: React.ReactNode; onClose?: () => void }) {
  return (
    // <div className={classes.root}>
    <StyledPaper variant="outlined" className={classes.root}>
      <div className={classes.iconContainer}>
        {/* <AlertIcon color="error" /> */}
        <AlertIcon />
      </div>
      <div>
        <div>
          <strong>Error</strong>
        </div>
        <div>{props.children}</div>
      </div>
      {!!props.onClose && (
        <div>
          <Tooltip title="Hide message">
            <IconButton onClick={props.onClose}>
              <CloseIcon htmlColor="black" />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </StyledPaper>
  );
});
