import { InstrumentListState } from "./InstrumentListState";
import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import {
  InstrumentList,
  InstrumentListItem,
  InstrumentResult,
  ServerMessage,
  ServerMessageComment,
} from "../../types/InstrumentList";

const initialState: InstrumentListState = {
  lists: [],
  categories: [],
  fetchStatus: {
    success: undefined,
    error: undefined,
  },
  itemFilter: {
    status: undefined,
  },
  itemSearch: undefined,
};

const instrumentListSlice = createSlice({
  name: "instrumentLists",
  initialState,
  reducers: {
    setFetchError(state, action: PayloadAction<{ error: string }>) {
      state.fetchStatus.error = action.payload.error;
      state.fetchStatus.success = false;
    },
    setLists(state, action: PayloadAction<{ data: InstrumentList[] }>) {
      state.lists = action.payload.data;
      state.fetchStatus.error = undefined;
      state.fetchStatus.success = true;
    },
    setCategories(state, action: PayloadAction<{ data: [{ id: string; name: string }] }>) {
      state.categories = action.payload.data.map((pair) => {
        return {
          key: pair.id,
          value: pair.name,
        };
      });
    },
    /**
     * Sets the Comment on the relevant ServerMessage
     */
    setServerMessageComment(state, action: PayloadAction<ServerMessageComment>) {
      const comment = action.payload;

      state.lists.forEach((lst) => {
        const item = lst.items?.find((it) => it.instrumentId == comment.instrumentId);
        if (item) {
          const msg = item.validationMessages?.find((msg) => msg.messageTypeId == comment.messageTypeId);
          if (msg) {
            msg.comment = comment;
          }
        }
      });

      state.lists = [...state.lists];
    },
    removeServerMessageComment(state, action: PayloadAction<ServerMessageComment>) {
      const comment = action.payload;

      state.lists.forEach((lst) => {
        const item = lst.items?.find((it) => it.instrumentId == comment.instrumentId);
        if (item) {
          const msg = item.validationMessages?.find((msg) => msg.messageTypeId == comment.messageTypeId);
          if (msg) {
            msg.comment = undefined;
          }
        }
      });

      state.lists = [...state.lists];
    },
    setListItems(
      state,
      action: PayloadAction<{
        listId: string;
        items: InstrumentListItem[];
        validationMessages: ServerMessage[];
        resolvedInstruments: InstrumentResult[];
      }>
    ) {
      if (!action || !action.payload) {
        throw new Error("expected a payload.");
      }

      let list = state.lists.find((lst) => lst.instrumentListId === action.payload.listId);
      if (!list) {
        throw new Error(`Could not set listItems because list: '${action.payload.listId}' wasn't found in the store.`);
      }

      list.items = action.payload.items;
      // Associate the resolved instrument with an list-item, if possible
      action.payload.resolvedInstruments.forEach((inst) => {
        const item = list?.items?.find((it) => it.instrumentId === inst.instrumentId);
        if (item) {
          item.resolved = inst;
        }
      });

      // Associate a validation messages with an list-item, if possible
      list.items.forEach((item) => {
        item.validationMessages = action.payload.validationMessages?.filter((msg) => msg.instrumentId === item.instrumentId);
      });
    },
  },
});

export const instrumentListsRequest = createAction("instrumentLists/get_lists_request");
export const listItemRequest = createAction<{ listId: string }>("instrumentLists/get_list_items_request");

export const {
  setLists,
  setListItems,
  setCategories,
  setFetchError,
  setServerMessageComment,
  removeServerMessageComment,
} = instrumentListSlice.actions;
export default instrumentListSlice.reducer;
