import format from "date-fns/format";

export type MaybeDate = Date | string | number | undefined | null;
export type DateRange = { start: string; end: string };

export function toShortDateString(d: MaybeDate) {
  return safeFormat(d, "dd.MM.yyyy");
}

export function toShortTimeString(d: MaybeDate) {
  return safeFormat(d, "HH:mm");
}

export function toDateTimeString(d: MaybeDate, includeComma?: boolean) {
  if (includeComma) {
    return safeFormat(d, "dd.MM.yyyy, HH:mm");
  } else {
    return safeFormat(d, "dd.MM.yyyy  HH:mm");
  }
}

// This format is required by input fields of type "datetime-local".
export function toShortIsoDateTimeString(d: MaybeDate) {
  return safeFormat(d, "yyyy-MM-dd'T'HH:mm");
}

export function toShortIsoDateString(d: MaybeDate) {
  return safeFormat(d, "yyyy-MM-dd");
}

export function endOfTomorrow() {
  const date = new Date(Date.now() + 1000 * 60 * 60 * 24);
  date.setHours(23);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

const prettyDateFormatter = new Intl.DateTimeFormat(["en-GB"], {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
});

export function prettyDateTime(d: MaybeDate) {
  if (!d) {
    return "";
  }

  return prettyDateFormatter.format(new Date(d));
}

function safeFormat(d: MaybeDate, spec: string) {
  if (!d) {
    return "";
  }

  return format(new Date(d), spec);
}
