import { AllocationColumn, AllocationRowDescriptor } from "../types";
import { zeroWeights } from "./weights";

/**
 * Returns a virgin column.
 */
export function emptyColumn(): AllocationColumn {
  return {
    sum: zeroWeights(),
    dates: { ic: "", published: "", dynamic: "", average: "", saved: "" },
    cells: {},
    _invalidCells: new Set<string>(),
    _changedCells: new Set<string>(),
  };
}

/**
 * Joins the given rows with the column cells and gives the matching cells back.
 * Normally, I'd just use `Object.values(column.cells)` in order to iterate over cells,
 * but this isn't working reliably. I suspect immer.js's proxies forbid this.
 *
 * TODO - return [cell, row] tuple
 */
export function cellsInRowOrder(column: AllocationColumn, rows: AllocationRowDescriptor[], instrumentsOnly: boolean = false) {
  return rows
    .filter((r) => (instrumentsOnly ? r.isInstrument : true))
    .map((r) => column.cells[r.rowId])
    .filter((c) => c !== undefined);
}
