import * as React from "react";
import { AppState } from "../../store/AppState";
import { useSelector } from "react-redux";
import { prettyPrintAllocationType } from "../../types/AllocationType";
import Allocation, { allocationPageTypeFromAllocations } from "../../types/Allocation";
import { Box, Typography } from "@mui/material";
import BackLink, { toOverview } from "../shared/BackLink";

/**
 * The header of the meta (details) view.
 */

export default function MetaViewHeader(props: { allocationId?: string }) {
  const allocations = useSelector(
    (s: AppState) =>
      ({
        ...s.pages.allocations,
        ...s.pages.modelPortfolios,
      } as { [id: string]: Allocation })
  );

  if(props.allocationId == null) {
     return <></>;
  }

  const alloc: Allocation = allocations[props.allocationId];
  const name = alloc?.Name || "";

  return (
    <>
      <Box mb={2}>
        <BackLink targetPath={(alloc && toOverview(allocationPageTypeFromAllocations([alloc]))) || "/"} />
      </Box>
      <Typography variant="h2">
        {alloc && prettyPrintAllocationType(alloc.AllocationType)} | {name}
      </Typography>
    </>
  );
}
