import React from "react";
import { Theme, styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers';
import { DateValidationError } from '@mui/x-date-pickers/models';
import isValidAsOfDate from "../utils/isValidAsOfDate";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { red } from "@mui/material/colors";
import { transformLabel } from "../../../modules/transformLabels";
import PublishDiffTable from "./PublishDiffTable";
import { useAllocation } from "../useAllocation";
import { ChangeResult } from "../../../types/ChangeResult";
import LoadingIndicator from "../../shared/LoadingIndicator";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/AppState";
import AllocationType from "../../../types/AllocationType";
import WarningIcon from '@mui/icons-material/Warning';
import theme from "../../../theme";
import { toShortDateString } from "../../../modules/date";

const PREFIX = 'PublishDialog';

const classes = {
  root: `${PREFIX}-root`,
  dateInput: `${PREFIX}-dateInput`
};

const RootDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    "& .MuiDialogContent-root": {
      overflowY: "auto", // keeps the title and action bar in place if the content exceeds height
    },
  },

  [`& .${classes.dateInput}`]: {
    width: "100%",
    "& > div:first-child": {
      width: "180px",
    },
  }
}));

export default function PublishDialog(props: { allocationId: string; close: () => void }) {
  const { allocationId, close } = props;
  const space = useTheme().spacing;

  const { getDiff, queue } = useAllocation(allocationId);

  const allocation = useSelector((app: AppState) => app.compare.allocations.find((alloc) => alloc.Id === allocationId));
  const [asOfDate, setAsOfDate] = React.useState<Date | null>(allocation?.SavedSnapshot?.AsOfDate || new Date());
  const minDate = allocation?.PublishedSnapshot?.AsOfDate;
  const [isAsOfDateValid, setIsAsOfDateValid] = React.useState<boolean>(allocation?.AllocationType != AllocationType.ModelPortfolio || isValidAsOfDate(minDate, asOfDate));
  const [error, setError] = React.useState<DateValidationError | null>(null);

  const errorMessage = React.useMemo(() => {
    switch (error) {
      case 'minDate': {
        return `The date must be greater or equal to the AsOfDate of the last published snapshot: ${minDate?.toLocaleDateString()}.`;
      }

      case 'invalidDate': {
        return 'Date is not valid';
      }

      default: {
        return '';
      }
    }
  }, [error, minDate]);

  const [lastQueueFailure, setLastQueueFailure] = React.useState<any>();
  const [diff, setDiff] = React.useState<ChangeResult | undefined>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isChangesExpanded, setIsChangesExpanded] = React.useState(false);
  const [isInsertsExpanded, setIsInsertsExpanded] = React.useState(false);
  const [isDeletesExpanded, setIsDeletesExpanded] = React.useState(false);

  const publish = () => {
    if (!asOfDate) {
      throw "Queuing/Publish only allowed with an asOfDate.";
    }

    queue(asOfDate).then(close).catch(setLastQueueFailure);
  };

  React.useEffect(() => {
    getDiff()
      .then((diff) => {
        setDiff(diff?.ChangeResult);
        setIsChangesExpanded(getCount(diff?.ChangeResult, "Changed") > 0);
        setIsInsertsExpanded(getCount(diff?.ChangeResult, "Inserted") > 0);
        setIsDeletesExpanded(getCount(diff?.ChangeResult, "Deleted") > 0);
        setIsLoading(false);
      })
      .catch(close);
  }, []);

  let asOfDateWarning = null;
  if(asOfDate && minDate && asOfDate < minDate){
    asOfDateWarning = (
      <Typography variant="body2" display="inline" style={{ minWidth: "22em", textAlign: 'right', color: theme.palette.warning.main }} marginRight={3}>
        <WarningIcon color="warning" style={{ marginRight: "0.5em", verticalAlign: 'text-bottom' }} />
        <label>The latest publication date is { minDate ? toShortDateString(minDate) : "N/A" }</label>
      </Typography>
    )
  }

  return (
    <RootDialog open={true} onClose={close} maxWidth={false} className={classes.root}>
      <DialogTitle>
        {transformLabel("action", "Publish")} | <span style={{ textTransform: "none" }}>{allocation?.Name}</span>
      </DialogTitle>
      <DialogContent style={{ width: 900 }}>
        {lastQueueFailure && <ErrorPanel error={lastQueueFailure} />}
        <Box marginBottom={0} marginTop={2} marginLeft={3} minHeight={space(9)} display="flex" alignItems="baseline">
          <Typography variant="body1" display="inline" style={{ minWidth: "7em" }}>
            <label htmlFor="publishAsOfDate">As-of date: </label>
          </Typography>
          <DatePicker
              className={classes.dateInput}
              value={asOfDate}
              onError={(newError) => setError(newError)}
              slotProps={{
                textField: {
                  helperText: errorMessage,
                },
              }}
              format="dd.MM.yyyy"
              minDate={allocation?.AllocationType !== AllocationType.ModelPortfolio ? undefined : minDate}
              onChange={(value) => {
                setAsOfDate(value);
                setIsAsOfDateValid(allocation?.AllocationType !== AllocationType.ModelPortfolio || isValidAsOfDate(minDate, value));
              }}
          />
          {asOfDateWarning}
        </Box>
        <LoadingIndicator size="24" show={isLoading}>
          <Accordion expanded={isChangesExpanded} onChange={(_, value) => setIsChangesExpanded(value)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Changed {!isLoading && `(${getCount(diff, "Changed")})`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PublishDiffTable data={diff?.Changed} />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={isInsertsExpanded} onChange={(_, value) => setIsInsertsExpanded(value)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Inserted {!isLoading && `(${getCount(diff, "Inserted")})`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PublishDiffTable data={diff?.Inserted} />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={isDeletesExpanded} onChange={(_, value) => setIsDeletesExpanded(value)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Deleted {!isLoading && `(${getCount(diff, "Deleted")})`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PublishDiffTable data={diff?.Deleted} />
            </AccordionDetails>
          </Accordion>
        </LoadingIndicator>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button
          style={{ marginLeft: space(2) }}
          color="primary"
          variant="contained"
          onClick={publish}
          disabled={!isAsOfDateValid || !diff}
        >
          {transformLabel("action", "Publish")}
        </Button>
      </DialogActions>
    </RootDialog>
  );
}

function ErrorPanel(props: { error: any }) {
  const { error } = props;

  let msg = "";
  if (typeof error === "object") {
    if (error?.response?.data?.Message) {
      msg = error.response.data.Message;
    } else {
      msg = JSON.stringify(error);
    }
  } else {
    msg = `${error}`;
  }

  return (
    <Paper elevation={4}>
      {typeof error === "string" ? (
        <div>error</div>
      ) : (
        <div style={{ color: red[600], marginBottom: "1em", padding: "1em" }}>
          <h3>Error</h3>
          {msg.split("\r").map((line, index) => (
            <div key={index}>{line}</div>
          ))}
        </div>
      )}
    </Paper>
  );
}

function getCount(diff: Maybe<ChangeResult>, section: keyof ChangeResult) {
  return (diff && diff[section].length) || 0;
}
