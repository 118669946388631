import {
  pageRequest,
  pageSuccess,
  pageFailure,
  toggleStarRequest,
  reverseLookupInstrumentRequest,
  updateFilter,
  detailPageDataRequest,
  detailPageUpdateSuccess,
  detailPageUpdateFailure,
  removeAllFilter,
  applyQueryString,
  setSortOrder,
  changePage,
  changePageSize,
} from "./pages-slice";
import { all, call, put, takeLatest, select, take } from "redux-saga/effects";
import { baseUrl } from "../apiUrlProvider";
import { hideLoadingScreen, showLoadingScreen } from "../loading/loading-slice";
import { showAlert } from "../app/app-slice";
import { usersFavoritePortfoliosSuccess } from "../user/user-slice";
import { request } from "../../modules/client";
import AllocationType from "../../types/AllocationType";
import { AllocationReference } from "../../types/AllocationReference";
import { AppState } from "../AppState";
import { masterDataSuccess } from "../masterData/masterData-slice";
import querystring from "./pages-querystring";
import { allocationPageTypeToResultKey, getAllocationPageTypeFromPath } from "../../types/AllocationPageType";

export const requestPage = function* ({ payload }: any): any {
  //yield delay(750)
  try {
    yield put(showLoadingScreen());
    const options = {
      method: "GET",
    };
    const response = yield call(request, `${baseUrl()}api/${payload.endpoint}`, options);
    yield put(
      pageSuccess({
        data: response.Result,
        resultKey: payload.resultKey,
        page: payload.page,
        totalCount: response.TotalCount,
      })
    );
    yield put(hideLoadingScreen());
  } catch (err) {
    yield put(hideLoadingScreen());
    yield put(showAlert("Can't fetch page", { type: "error" }));

    yield put(pageFailure(err));
  }
};

export const requestDetailPage = function* ({ payload }: any): any {
  try {
    yield put(showLoadingScreen());

    const response = yield call(request, `${baseUrl()}api/${payload.endpoint}/${payload.id}`);

    // Based on the allocation type (TAA, SAA, Mopo) use the appropriate 'resultKey' (-> gets stored under allocations or modelPortfolios)
    let resultKey = "allocations";
    if (response && response.AllocationType == AllocationType.ModelPortfolio) {
      resultKey = "modelPortfolios";
    }

    yield put(detailPageUpdateSuccess({ data: response, resultKey }));

    yield put(hideLoadingScreen());
  } catch (err) {
    yield put(hideLoadingScreen());
    yield put(showAlert("Can't fetch detail page", { type: "error" }));

    yield put(detailPageUpdateFailure(err));
  }
};

export const reverseLookupInstrument = function* ({ payload }: any): any {
  try {
    if (payload.ids === undefined || payload.ids.length === 0) {
      yield put(updateFilter({ id: "Id", state: undefined, pageType: payload.allocationPageType }));
      return;
    }

    const options = {
      method: "POST",
      payload: {
        instrumentIds: payload.ids,
      },
    };

    const response = yield call(request, `${baseUrl()}api/${payload.endpoint}/reverseLookup`, options);
    yield put(updateFilter({ id: "Id", state: response, pageType: payload.allocationPageType }));
    //  yield put(detailPageSuccess({ data: response, resultKey: payload.resultKey }));
  } catch (err) {
    yield put(showAlert("Failed to update star", { type: "error" }));
  }
};

export const toggleStar = function* ({ payload }: any): any {
  try {
    const options = {
      method: "POST",
      payload: {
        id: payload.id,
      },
    };

    const allocationReference: AllocationReference = {
      Id: payload.id,
      Name: payload.allocationName,
      AllocationType: payload.allocationType,
      AllocationSource: payload.allocationSource,
    };

    const response = yield call(request, `${baseUrl()}api/favorite/star`, options);
    yield put(showAlert(response.Starred ? "Starred" : "Unstarred", { type: "success" }));
    yield put(detailPageUpdateSuccess({ data: response, resultKey: payload.resultKey }));
    yield put(usersFavoritePortfoliosSuccess({ data: { isFavorite: response.Starred, allocationReference } }));
  } catch (err) {
    yield put(showAlert("Failed to update star", { type: "error" }));
  }
};

function* queryStringToState() {
  yield take(masterDataSuccess); // fetched masterdata is a prerequisite
  yield call(_queryStringToState); // first page load / browser refresh

  function* _queryStringToState() {
    const pageType = getAllocationPageTypeFromPath(window.location.pathname);
    if (pageType === undefined) return;
    yield put(applyQueryString({ pageType, location: window.location }));
  }
}

function* stateToQueryString() {
  yield takeLatest(
    [
      updateFilter,
      removeAllFilter,
      setSortOrder,
      changePage,
      changePageSize,
      // If `lastState` query parameter is found, it's our sign to update the URL from the state.
      // This is useful for in-app navigation as it happens for example in `Header`.
    ],
    _stateToQueryString
  );

  function* _stateToQueryString(): any {
    const pageType = getAllocationPageTypeFromPath(window.location.pathname);
    if (pageType === undefined) return;
    const key = allocationPageTypeToResultKey(pageType); // this wouldn't be necessary, if pageType itself would be the key
    const state = yield select((state: AppState) => state.pages.pagination[key]);
    querystring.setParams(state, window.location);
  }
}

export default function* root() {
  yield all([
    takeLatest(pageRequest, requestPage),
    takeLatest(detailPageDataRequest, requestDetailPage),
    takeLatest(toggleStarRequest, toggleStar),
    takeLatest(reverseLookupInstrumentRequest, reverseLookupInstrument),

    call(queryStringToState),
    call(stateToQueryString),
  ]);
}
