import React from "react"
import { unstable_usePrompt } from "react-router-dom";
import useCompareSelector from "./useCompareSelector";

/**
 * Brings up a default browser confirmation dialog if the user
 * wants to navigate away from TST app with allocations still
 * locked for edit or even unsaved changes.
 * Additionally returns a prompt component (react-router) for
 * confirmation of in-app navigations.
 */
export default function useConfirmNavigation() {
  const inEdit = useCompareSelector((s) => s.editInfo.some((e: { isInEditByUser: any; }) => e.isInEditByUser));
  const hasChanges = useCompareSelector((s) => s.editInfo.some((e: { hasChanges: any; }) => e.hasChanges));

  React.useEffect(() => {
    if (inEdit) {
      window.onbeforeunload = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = "";
      };
    } else {
      window.onbeforeunload = null;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [inEdit]);

  // https://github.com/remix-run/react-router/issues/8139
  // https://github.com/remix-run/react-router/issues/8139#issuecomment-1396078490
  // TODO: HAASH-2208: replace as soon as there is a better way
  return unstable_usePrompt({
    when: inEdit || hasChanges,
    message: hasChanges
        ? "There are unsaved changes. Do you really want to leave this page?"
        : "One or more allocations are still in edit mode. Do you really want to leave this page?"
  });
}
