import React from "react";
import { useTheme } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import MasterDataEntry from "../../../types/MasterDataEntry";

export type SelectionIndex = number | "";

export default function Selector(props: {
  options: MasterDataEntry[];
  id: string;
  label: string;
  width: number;
  marginRight?: number;
  getMenuValue?: (v: MasterDataEntry) => string;
  getSelectionValue?: (v: MasterDataEntry) => string;
  value: SelectionIndex;
  changed: (v: number) => void;
  disabled: boolean;
  dividersAt?: number[];
  disabledOptionsAt?: number[];
}) {
  const { options, id, label, value, changed, disabled } = props;
  const dividersAt = props.dividersAt ?? [];
  const disabledOptionsAt = props.disabledOptionsAt ?? [];
  const getMenuValue = props.getMenuValue ?? ((x) => x.ShortName);
  const getSelectionValue = props.getSelectionValue ?? ((x) => x.ShortName);
  const theme = useTheme();
  const spacing = theme.spacing(props.marginRight ?? 0);

  return (
    <FormControl style={{ width: props.width, marginRight: spacing }}>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        onChange={((e: any) => changed(e.target.value)) as any}
        renderValue={((value: number) => getSelectionValue(options[value])) as any}
        disabled={disabled}
      >
        {options.map((_, i) => (
          <MenuItem key={i} value={i} disabled={disabledOptionsAt.includes(i)} divider={dividersAt.includes(i)}>
            {getMenuValue(options[i])}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText style={{ overflowX: "visible", whiteSpace: "nowrap" }}>{label}</FormHelperText>
    </FormControl>
  );
}
