import * as React from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store/AppState";
import { useNavigate } from "react-router";
import { instrumentListsRequest, listItemRequest } from "../../store/instrumentLists/instrumentlist-slice";
import BackLink from "../shared/BackLink";
import ItemSearch from "./ItemSearch";
import ItemFilter from "./ItemFilter";
import ItemTable, { ItemPageination, ItemSortColumn, ItemSortDirection } from "./ItemTable";
import ItemDialog from "./ItemDialog";
import { Grid, Typography, Button, Tooltip, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CloudUploadOutlined } from "@mui/icons-material";
import LinkIcon from "@mui/icons-material/Link";
import { filterItems } from "./utils";
import { FilterSettings, ServerMessageSeverity } from "../../types/InstrumentList";
import PublishDialog from "./PublishDialog";
import useQuery from "../../modules/useQuery";

/*
 * Displays the items (constituents) of an instrument list.
 *
 * This component is also responsible for orchestrating the filter, search & pageination-state
 * and storing/retrieving it from the URL query.
 */

const PREFIX = 'InstrumentListItems';
const classes = {
  header: `${PREFIX}-header`,
  tableHat: `${PREFIX}-tableHat`,
  iconSmall: `${PREFIX}-iconSmall`,
  publishButton: `${PREFIX}-publishButton`,
};
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.header}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  [`& .${classes.tableHat}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.iconSmall}`]: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  [`& .${classes.publishButton}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
}));

function splitByComma(value: string | undefined | null) {
  if (!value) {
    return [];
  }
  return value.split(",");
}

export default function InstrumentListItems() {
  const { id } = useParams<{ id?: string | undefined }>();
  const dispatch = useDispatch();

  // get filtering &  pageination info from the URL query - and fallback to useful defaults!
  const query = useQuery();
  const navigate = useNavigate();

  const [showItemDialog, setShowItemDialog] = React.useState(false);
  const [showPublishDialog, setShowPublishDialog] = React.useState(false);

  // load the instrumentLists, if we don't already have them (e.g. when directly hitting the URL)
  const lists = useSelector((state: AppState) => state.instrumentLists.lists);

  const tstCoreBaseUrl = useSelector((state: AppState) => state.masterData.tstCoreUrl);
  const instrumentsUrl = `${tstCoreBaseUrl}${tstCoreBaseUrl.endsWith("/") ? "" : "/"}api/instruments?instrumentListId=${id}`;
  const iqyUrl = `${instrumentsUrl}&format=text/x-ms-iqy`;

  React.useEffect(() => {
    if (!lists || lists.length === 0) {
      dispatch(instrumentListsRequest());
    }
  }, [lists]);

  // load the list items for our listId
  React.useEffect(() => {
    if (id) {
      dispatch(listItemRequest({ listId: id }));
    } else {
      throw new Error("No listId. Aborting.");
    }
  }, [id]);

  const list = lists?.find((lst) => lst.instrumentListId === id);

  // Get Pageination and Filter-State from the URL.
  const pageination: ItemPageination = {
    sortColumn: (query.get("sortBolumn") || "status") as ItemSortColumn,
    sortDirection: (query.get("sortDirection") || "desc") as ItemSortDirection,
    currentPage: parseInt(query.get("currentPage") || "0", 10) as number,
    rowsPerPage: parseInt(query.get("rowsPerPage") || "25", 10) as number,
  };

  const filter: FilterSettings = {
    assetSubClass: splitByComma(query.get("assetSubClass")),
    status: splitByComma(query.get("status")) as ServerMessageSeverity[],
    search: query.get("search") || "",
  };

  const updateFilter = (filter: Partial<FilterSettings>) => {
    pushStateToHistory({
      status: filter.status?.join(","),
      assetSubClass: filter.assetSubClass?.join(","),
      currentPage: 0, //always reset the page to the start, because we might no longer have data on the current page
    });
  };
  const filteredItems = filterItems(list?.items || [], filter);

  const updateSearch = (searchValue: string) => {
    pushStateToHistory({
      search: searchValue,
      currentPage: 0,
    });
  };

  // Updates the Query-String of the URL (via history push) with the provided values.
  const pushStateToHistory = (data: Object): void => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        query.set(key, data[key]);
      }
    }
    navigate("?" + query.toString(), { replace: true });
  };

  return (
    <Root>
      <BackLink targetPath="/instrument-lists" />
      <Typography variant="h2" className={classes.header}>
        {list?.name}
      </Typography>
      {list && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <ItemSearch value={filter.search || ""} updateValue={updateSearch} />
            <ItemFilter filter={filter} updateFilter={updateFilter} list={list} />
          </Grid>
          <Grid item xs={12} sm={9}>
            <div className={classes.tableHat}>
              <Typography variant="body1">{filteredItems.length} Instruments</Typography>
              <div>
                <Tooltip
                  title={
                    list.canCreateListItem
                      ? "Request a new instrument."
                      : "Cannot add instruments to system managed lists or missing permissions."
                  }
                >
                  <span>
                    {/* span needed for the tooltip on disabled button to work */}
                    <Button
                      color="primary"
                      disabled={!list.canCreateListItem}
                      variant="contained"
                      onClick={() => {
                        setShowItemDialog(true);
                      }}
                    >
                      Add Instrument
                    </Button>
                  </span>
                </Tooltip>
                {showItemDialog && (
                  <ItemDialog
                    open={showItemDialog}
                    list={list}
                    closeHandle={() => {
                      setShowItemDialog(false);
                    }}
                  />
                )}

                <Tooltip title={list.canPublish ? "Publish list" : "Cannot publish due to missing permissions."}>
                  <span>
                    {/* span needed for the tooltip on disabled button to work */}
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.publishButton}
                      disabled={!list.canPublish}
                      onClick={() => {
                        setShowPublishDialog(true);
                      }}
                    >
                      <CloudUploadOutlined className={classes.iconSmall} /> Publish
                    </Button>
                  </span>
                </Tooltip>
                {showPublishDialog && (
                  <PublishDialog
                    list={list}
                    closeDialog={() => {
                      setShowPublishDialog(false);
                    }}
                  />
                )}

                <Tooltip title="Download instrument list as Excel Web Query">
                  <Box ml={1}>
                    <Button href={iqyUrl} color="primary">
                      <LinkIcon className={classes.iconSmall} />
                      Excel
                    </Button>
                  </Box>
                </Tooltip>
              </div>
            </div>
            <ItemTable
              list={list}
              filteredItems={filteredItems}
              pageination={pageination}
              pushStateToHistory={pushStateToHistory}
            />
          </Grid>
        </Grid>
      )}
    </Root>
  );
}
