import * as React from "react";
import { styled } from '@mui/material/styles';
import { InstrumentList, ServerMessage } from "../../types/InstrumentList";
import { countBySeverity } from "./utils";
import ConfirmDialog from "./ConfirmDialog";
import { Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import InfoBox from "../shared/InfoBox";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/AppState";
import { showAlert } from "../../store/app/app-slice";
import { errorResponseToServerMessages } from "../../store/instrumentLists/InstrumentListState";
import { request } from "../../modules/client";
import formatISO from "date-fns/formatISO";
import startOfDay from "date-fns/startOfDay";

const PREFIX = 'PublishDialog';
const classes = {
  infoText: `${PREFIX}-infoText`,
  checkBox: `${PREFIX}-checkBox`
};
const StyledConfirmDialog = styled(ConfirmDialog)((
  {
    theme
  }
) => ({
  [`& .${classes.infoText}`]: {
    fontSize: "1.25rem",
    // TODO: change to body2, once the new theme is merged.
    //   fontSize: theme.typography.body2.fontSize,
  },

  [`& .${classes.checkBox}`]: {
    marginLeft: theme.spacing(1),
  }
})) as typeof ConfirmDialog;

/**
 * Dialog component used to publish InstrumentLists to Alpima.
 * Wraps <ConfirmDialog/> with the necessary logic.
 */

type PublishScope = "today" | "list";

// The response from the backend
interface PublishResult {
  correlationId: string;
  result: {
    statusCode: number; // HTTP status, e.g. 200 for OK
    statusText: string; // e.g. "OK"
    body: string;
  };
}

async function publishList(baseUrl: string, list: InstrumentList, scope: PublishScope) {
  const params = new URLSearchParams({
    instrumentListId: list.instrumentListId,
  });
  if (scope === "today") {
    const value = formatISO(startOfDay(new Date()));
    params.append("ifModifiedSince", value);
  }

  return await request(`${baseUrl}api/instruments/publish?${params}`, {
    method: "POST",
  });
}

export default function PublishDialog(props: { list: InstrumentList; closeDialog: () => void }) {
  let errorsInItems = countBySeverity("Error", props.list.items) > 0;
  let warningsInItems = countBySeverity("Warning", props.list.items) > 0;

  const dispatch = useDispatch();

  const tstCoreUrl = useSelector((state: AppState) => state.masterData.tstCoreUrl);
  let [publishScope, setPublishScope] = React.useState<PublishScope>("today");
  let [force, setForce] = React.useState(false);

  const [serverMessages, setServerMessages] = React.useState<ServerMessage[]>([]);
  const [isRequestRunning, setIsRequestRunning] = React.useState(false);

  const onPublish = () => {
    setServerMessages([]);
    setIsRequestRunning(true);

    publishList(tstCoreUrl, props.list, publishScope)
      .then((publishResult: PublishResult) => {
        setIsRequestRunning(false);
        if (publishResult.result.statusCode === 200) {
          dispatch(showAlert("Successfully published list.", { type: "success" }));
          props.closeDialog();
        } else {
          dispatch(
            showAlert(
              `Publish not successfull. Response from Alpima is: ${publishResult.result?.statusCode} / ${publishResult.result?.statusText}. CorrelationId is: ${publishResult.correlationId}.`,
              { type: "error" }
            )
          );
        }
      })
      .catch((e: any) => {
        setIsRequestRunning(false);
        showAlert(`Publish not successfull, an error occurred.`, { type: "error" });

        setServerMessages(errorResponseToServerMessages(e));
      });
  };



  return (
    <StyledConfirmDialog
      open={true}
      title={`Publish List: ${props.list.name}`}
      handleOk={onPublish}
      okButtonLabel="Publish"
      okButtonDisabled={errorsInItems || isRequestRunning || (warningsInItems && !force)}
      handleCancel={props.closeDialog}
      serverMessages={serverMessages}
      allowCommentsOnMessages
    >
      {errorsInItems && <div>Due to errors on this list you can not publish this list. Please correct the errors first .</div>}
      {!errorsInItems && (
        <div>
          <RadioGroup
            aria-label="scope"
            name="scope"
            value={publishScope}
            onChange={(evt) => {
              setPublishScope(evt.target.value as any);
            }}
          >
            <FormControlLabel
              value="today"
              disabled={isRequestRunning}
              control={<Radio color="primary" />}
              label="Publish todays list changes to Alpima (default, recommended)"
            />
            <div className={classes.infoText}>
              This will only publish list items modified today. It's recommended for intraday changes as it should have the
              smallest impact upon the Alpima platform.
            </div>
            <FormControlLabel
              value="list"
              disabled={isRequestRunning}
              control={<Radio color="primary" />}
              label="Publish the entire list to Alpima"
            />
            <div className={classes.infoText}>
              Choose this if you are unsure whether Alpima already covers this entire list. Mind that the full universe will be
              published upon every end-of-day processing.
            </div>
          </RadioGroup>

          <hr />
          <InfoBox containerClassName={classes.infoText}>
            If you want to publish the whole universe to Alpima, please publish the <i>LI_ALP_ALL</i> list. <br />
            Please be aware, that this already happens in every end-of-day processing.
          </InfoBox>
          {warningsInItems && (
            <div>
              <hr />
              <Typography variant="body1">
                There are warnings in this list. You may cancel the publication and review the warnings.
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkBox}
                    checked={force}
                    onChange={() => {
                      setForce(!force);
                    }}
                    name="force"
                    color="primary"
                  />
                }
                label="Force publication"
              />
            </div>
          )}
        </div>
      )}
    </StyledConfirmDialog>
  );
}
