/* global process,window,require,module */
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { enableMapSet } from "immer";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./root-reducer";
import rootSaga from "./root-saga";
enableMapSet();

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
    ...rootReducer,
});

const middleware = [sagaMiddleware];

if (process.env.NODE_ENV === "development") {
  const { createLogger } = require("redux-logger");

  middleware.push(createLogger({ collapsed: true }));
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; //eslint-disable-line no-underscore-dangle

const createStoreWithMiddleware = composeEnhancers(applyMiddleware(...middleware))(createStore);
const store = createStoreWithMiddleware(reducer, {});

sagaMiddleware.run(rootSaga);

if (module.hot) {
  module.hot.accept("./root-reducer", () => {
    store.replaceReducer(reducer /*require('reducers').default*/);
  });
}

export { store };
