import * as React from "react";
import { InstrumentDiff } from "../../../types/ChangeResult";
import { isBigNumber } from "../../../modules/asset-tree-builder";
import { styled } from "@mui/material/styles";
import Big from "big.js";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

const PREFIX = 'PublishDiffTable';
const classes = {
  valueCell: `${PREFIX}-valueCell`
};
const StyledTable = styled(Table)(() => ({
  [`& .${classes.valueCell}`]: {
    textAlign: "right" as "right",
    width: 100,
  }
})) as typeof Table;

type PublishDiffTableProps = {
  data?: InstrumentDiff[];
};

export const PublishDiffTable = ({ data }: PublishDiffTableProps) => (
  <StyledTable>
    <TableHead>
      <TableRow>
        <TableCell style={{ width: 100 }}>Valor</TableCell>
        <TableCell style={{ width: 200 }}>ISIN</TableCell>
        <TableCell style={{ width: 200 }}>Name</TableCell>
        <TableCell className={classes?.valueCell}>Old</TableCell>
        <TableCell className={classes?.valueCell}>New</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {data &&
        data.map((row) => {
          return (
            <TableRow key={row.Instrument.Id}>
              <TableCell>{row.Instrument.Id}</TableCell>
              <TableCell>{row.Instrument.Isin}</TableCell>
              <TableCell>{row.Instrument.ShortName}</TableCell>
              <TableCell className={classes?.valueCell}>
                {isBigNumber(row.OldWeight) && `${new Big(row.OldWeight).times(100).toFixed(2)}%`}
              </TableCell>
              <TableCell className={classes?.valueCell}>
                {isBigNumber(row.NewWeight) && `${new Big(row.NewWeight).times(100).toFixed(2)}%`}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  </StyledTable>
);

export default PublishDiffTable;
