import * as React from "react";
import { styled } from '@mui/material/styles';
import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import { ColumnData } from "../../types/AllocationTable";

const PREFIX = 'AllocationSortToolbar';
const classes = {
  cell: `${PREFIX}-cell`
};
const RootTableHead = styled(TableHead)({
  [`& .${classes.cell}`]: {
    position: "sticky" as any, // sticky is unknown to the type def
    top: 0,
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    zIndex: 10,
    backgroundColor: "inherit",
  },
}) as typeof TableHead;

export default function AllocationSortToolbar(props: {
  numSelected: number;
  onRequestSort: (property: string) => void;
  changeSelection: () => void;
  sortOrder?: "asc" | "desc";
  sortedColumnId?: string;
  rowCount: number;
  columnData: ColumnData;
}) {
  const { columnData, sortOrder, sortedColumnId, numSelected, changeSelection, onRequestSort } = props;

  return (
    <RootTableHead>
      <TableRow>
        <TableCell className={classes.cell}>
          <Tooltip title={numSelected > 0 ? "Deselect all" : "Select all"}>
            <Checkbox indeterminate={numSelected > 0} onClick={changeSelection} checked={false} />
          </Tooltip>
        </TableCell>
        {columnData.map((column) => (
          <TableCell
            className={classes.cell}
            style={{ width: columnWidths[column.id] }}
            key={column.id}
            sortDirection={sortedColumnId === column.id ? sortOrder : false}
          >
            <TableSortLabel active={sortedColumnId === column.id} direction={sortOrder} onClick={() => onRequestSort(column.id)}>
              {column.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </RootTableHead>
  );
}

const columnWidths = {
  AllocationType: "4em",
  ProductLine: "7em",
  ProductDistinction: "4em",
  ReferenceCurrency: "3em",
  RiskProfile: "5em",
  BmoContext: "3em",
  Name: undefined, // name column takes all the remaining width
  "PublishedSnapshot.AsOfDate": "7em",
  "PublishedSnapshot.DateTime": "7em",
  Owner: "20em",
  buttons: 50,
};
