import { all, call, put, takeLatest } from "redux-saga/effects";
import { baseUrl } from "../apiUrlProvider";
import { hideLoadingScreen, showLoadingScreen } from "../loading/loading-slice";
import { masterDataSuccess, masterDataFailure, masterDataRequest } from "./masterData-slice";
import { request } from "../../modules/client";

export const getMasterData = function* () : any {
  try {
    yield put(showLoadingScreen());
    const response = yield call(request, `${baseUrl()}api/masterdata`);
    const env = response.Environment;
    document.title = env === "PRD" ? "TST" : "TST - " + env;
    yield put(masterDataSuccess({ data: response }));
    yield put(hideLoadingScreen());
  } catch (err : any) {
    yield put(hideLoadingScreen());
    yield put(masterDataFailure(err));
  }
};

export default function* root() {
  yield all([takeLatest(masterDataRequest, getMasterData)]);
}
