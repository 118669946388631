import React from "react";
import { Box, Typography } from "@mui/material";

/**
 * Section header.
 */
const SectionHeader: React.FC<{ title: string, children?: React.ReactNode }> = (props) => (
  <Box mb={1} display="flex" alignItems="center" justifyContent="space-between">
    <Typography variant="h3">{props.title}</Typography>
    {props.children}
  </Box>
);

export default SectionHeader;
