import React from "react";
import { styled } from '@mui/material/styles';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
  Zoom,
} from "@mui/material";
import {
  ChartIconOutlined,
  CheckIcon,
  CloseIcon,
  EditIconOutlined,
  PublishIcon,
  InfoIconThin,
  TailoringIconOutlined,
  MoreIcon,
} from "../shared/Icons";
import ClearIcon from "@mui/icons-material/Clear";
import CopyPasteIcon from "@mui/icons-material/FilterNone";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import TuneIcon from "@mui/icons-material/Tune";
import { AllocationColumn, EditInfo } from "./types";
import Allocation from "../../types/Allocation";
import editActionTooltipText from "./utils/editActionTooltipText";
import { useAllocation } from "./useAllocation";
import deduceLevelOutInstrument from "./utils/deduceLevelOutInstrument";
import { isZeroOrUnset } from "./utils/weights";
import { useDispatch } from "react-redux";
import { copyWeights, clearWeights, scaleWeights100, setDialog } from "./slice";
import ConfirmDialog from "../shared/ConfirmDialog";
import useCompareSelector from "./useCompareSelector";
import AllocationType from "../../types/AllocationType";

const PREFIX = 'ActionBar';
const classes = {
  root: `${PREFIX}-root`
};
const Root = styled('div')(() =>
  ({
    [`&.${classes.root}`]: {
      position: "relative",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      "& > div": {
        display: "flex",
        flexDirection: "row",
      },
    }
  }));

export default React.memo(function ActionBar(props: { allocation: Allocation; column: AllocationColumn; editInfo: EditInfo }) {
  const dispatch = useDispatch();
  const { allocation, column, editInfo } = props;
  const { hasEditPermission, isEditable, isInEdit, isInEditByUser, hasInvalidCell, isTailoringEnabled, hasChanges } = editInfo;
  const isEditDisabled = allocation?.IsDeleted || !isEditable || isInEdit;
  const [menuAnchor, setMenuAnchor] = React.useState<any>(null);
  const [confirmDiscard, setConfirmDiscard] = React.useState(false);
  const levelOutInstrument = deduceLevelOutInstrument(allocation)?.ShortName;
  const isEmptySavedSnapshot = !Object.values(column.cells).find((c) => !isZeroOrUnset(c.saved));
  const allocationId = allocation.Id;
  const { lockForEdit, save, discardChanges, levelOut } = useAllocation(allocationId);
  const isTca = useCompareSelector((s) => s.allocationType === AllocationType.Tca);

  const [isPublishDisabled, publishTooltip] = publishButtonState(editInfo);
  const [isSaveDisabled, saveTooltip] = saveButtonState(editInfo);

  const discard = () => {
    if (hasChanges) {
      setConfirmDiscard(true);
    } else {
      discardChanges();
    }
  };
  const discardConfirmed = () => {
    discardChanges();
    setConfirmDiscard(false);
  };
  const discardCancelled = () => {
    setConfirmDiscard(false);
  };

  const showExposureDialog = () => dispatch(setDialog({ dialog: "exposure", allocation }));
  const showTailoringDialog = () => dispatch(setDialog({ dialog: "tailoring", allocation }));
  const showMetaViewDialog = () => dispatch(setDialog({ dialog: "metaView", allocation }));
  const showPublishDialog = () => dispatch(setDialog({ dialog: "publish", allocation }));

  const openMenu = (e: React.MouseEvent) => setMenuAnchor(e.currentTarget);
  const closeMenu = () => setMenuAnchor(null);

  const clearSavedWeights = () => {
    dispatch(clearWeights({ allocationId }));
    closeMenu();
  };
  const copyPublishedWeights = () => {
    dispatch(copyWeights({ allocationId, source: "published" }));
    closeMenu();
  };
  const copyDynamicWeights = () => {
    dispatch(copyWeights({ allocationId, source: "dynamic" }));
    closeMenu();
  };
  const copyAverageWeights = () => {
    dispatch(copyWeights({ allocationId, source: "average" }));
    closeMenu();
  };
  const pasteWeights = () => {
    dispatch(setDialog({ dialog: "paste", allocation }));
    closeMenu();
  };
  const levelOutWeights = () => {
    levelOut('').then(closeMenu);
  };
  const scaleWeightsTo100 = () => {
    dispatch(scaleWeights100({ allocationId }));
    closeMenu();
  };

  return (
    <Root className={classes.root}>
      <div>
        {/**
         * Invisible dummy button.
         * Ensures, that the height doesn't mess up, if no buttons are shown
         * on the left side, which is the case for TCAs.
         */}
        <div style={{ maxWidth: 0, visibility: "hidden" }}>
          <ActionButton id="dummy" tooltip="Dummy" render={InfoIconThin} onClick={() => {}} />
        </div>
        {!isTca && (
          <ActionButton id="show-exposure-btn" tooltip="Show exposure" render={ChartIconOutlined} onClick={showExposureDialog} />
        )}
        {isTailoringEnabled && (
          <ActionButton
            id="show-tailoring-btn"
            tooltip="Show tailoring"
            render={TailoringIconOutlined}
            onClick={showTailoringDialog}
          />
        )}
        <Show if={!isInEditByUser}>
          <ActionButton
            id="show-metaview-btn"
            tooltip="Show meta information"
            render={InfoIconThin}
            onClick={showMetaViewDialog}
          />
        </Show>
        <Show if={hasEditPermission && !isInEditByUser}>
          <ActionButton
            id="edit-btn"
            tooltip={editActionTooltipText(allocation, editInfo)}
            render={EditIconOutlined}
            onClick={lockForEdit}
            disabled={isEditDisabled}
          />
        </Show>
      </div>
      <Show if={isInEditByUser} absolutePosition>
        <ActionButton
          id="publish-btn"
          tooltip={publishTooltip}
          render={() => <PublishIcon fontSize="small" style={{ padding: "0.25rem" }} />}
          onClick={showPublishDialog}
          disabled={isPublishDisabled}
        />
        <ActionButton id="save-btn" tooltip={saveTooltip} render={() => <CheckIcon />} onClick={save} disabled={isSaveDisabled} />
        <ActionButton id="discard-btn" tooltip="Discard changes" render={() => <CloseIcon />} onClick={discard} />
        <ActionButton
          id="more-btn"
          tooltip=""
          render={() => <MoreIcon fontSize="small" style={{ padding: "0.25rem" }} />}
          onClick={openMenu}
        />
        <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={closeMenu}>
          <MenuAction id="clear-all-btn" label="Clear all weights" icon={() => <ClearIcon />} onClick={clearSavedWeights} />
          <Divider />
          <MenuAction
            id="copy-published-btn"
            label="Copy published weights"
            icon={() => <CopyPasteIcon />}
            onClick={copyPublishedWeights}
          />
          <MenuAction
            id="copy-dynamic-btn"
            label="Copy dynamic weights"
            icon={() => <CopyPasteIcon />}
            onClick={copyDynamicWeights}
          />
          <MenuAction
            id="copy-average-btn"
            label="Copy average weights"
            icon={() => <CopyPasteIcon />}
            onClick={copyAverageWeights}
          />
          <Divider />
          <MenuAction id="paste-btn" label="Paste weights" icon={() => <FileCopyIcon />} onClick={pasteWeights} />
          <Divider />
          {!isTca && (
            <MenuAction
              id="levelout-btn"
              label={!!levelOutInstrument ? "Level out to " + levelOutInstrument : "Level out"}
              icon={() => <TuneIcon />}
              onClick={levelOutWeights}
              disabled={!levelOutInstrument}
            />
          )}
          <MenuAction
            id="scale-btn"
            label="Scale to 100 %"
            icon={() => <TuneIcon />}
            onClick={scaleWeightsTo100}
            disabled={isEmptySavedSnapshot}
          />
        </Menu>
      </Show>
      {confirmDiscard && (
        <ConfirmDialog
          open={true}
          title="Warning"
          okButtonLabel="Yes, discard changes"
          handleOk={discardConfirmed}
          handleCancel={discardCancelled}
        >
          There are unsaved changes. Are you sure you want to discard them?
        </ConfirmDialog>
      )}
    </Root>
  );
});

function ActionButton(props: {
  id: string; // identifier for TOSCA test automation
  tooltip: string;
  onClick: (e: React.MouseEvent) => void;
  disabled?: boolean;
  render: (props: any) => JSX.Element;
}) {
  const { id, tooltip, onClick, render } = props;
  const disabled = !!props.disabled;
  return (
    <Tooltip title={tooltip}>
      <div>
        <IconButton className={id} size="small" color="primary" onClick={onClick} disabled={disabled}>
          {render({ fontSize: "large", color: disabled ? "disabled" : "primary" })}
        </IconButton>
      </div>
    </Tooltip>
  );
}

// Why forwardRef? See here: https://github.com/reactjs/reactjs.org/issues/2120#issuecomment-589207766
const MenuAction = React.forwardRef(function (
  props: {
    id: string; // identifier for TOSCA test automation
    label: string;
    icon: () => JSX.Element;
    onClick: () => void;
    disabled?: boolean;
  },
  ref: any
) {
  const { id, label, icon, onClick, disabled } = props;
  const space = useTheme().spacing;
  return (
    <MenuItem ref={ref} id={id} onClick={onClick} disabled={disabled}>
      <ListItemIcon style={{ fontSize: 20, marginRight: space(1) }}>{icon()}</ListItemIcon>
      <ListItemText inset primary={label} />
    </MenuItem>
  );
});

const Show: React.FC<{ if: boolean; absolutePosition?: boolean, children?: React.ReactNode }> = (props) => {
  return (
    <Zoom in={props.if} unmountOnExit>
      <div style={{ position: props.absolutePosition ? "absolute" : "inherit", right: 0 }}>{props.children}</div>
    </Zoom>
  );
};

function publishButtonState({ hasInvalidCell, savedSumOk, hasDeletedInstruments }: EditInfo): [boolean, string] {
  const reasons = [
    hasInvalidCell && "invalid input",
    !savedSumOk && "sum of weights not equal to 100%",
    hasDeletedInstruments && "deleted instruments",
  ].filter((r) => !!r);

  if (reasons.length > 0) {
    return [true, "Publish not possible due to " + reasons.join(" and ")];
  }
  return [false, "Queue for publish to Triple'A"];
}

function saveButtonState({ hasInvalidCell, hasChanges }: EditInfo): [boolean, string] {
  if (hasInvalidCell) return [true, "Invalid input prevents saving"];
  if (!hasChanges) return [true, "No changes to save"];
  return [false, "Save changes"];
}
