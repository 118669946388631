import { request } from "../../../modules/client";
import { baseUrl } from "../../../store/apiUrlProvider";
import { Distribution } from "./Distribution";
import { DynamicWeightRequestInfo } from "./DynamicWeightRequestInfo";

export async function getAllDistributions() {
  const response = await request(`${baseUrl()}api/distributions`);
  return <Distribution[]>response;
}

export function patchDistribution(id: number, generateDynWeightRequest: boolean) {
  return request(`${baseUrl()}api/distributions/${id}`, {
    method: "PATCH",
    payload: { generateDynWeightRequest: generateDynWeightRequest },
  });
}

export async function getAllRequestsInfo(lastDayOnly?: boolean) {
  const queryParam = (lastDayOnly && "?lastDayOnly=true") || "";
  const response = await request(`${baseUrl()}api/dynamic-weights/requests-info${queryParam}`);
  return <DynamicWeightRequestInfo[]>response;
}

export function retriggerFailed(processId: string) {
  return request(`${baseUrl()}api/dynamic-weights/request-dynamic-weights/${processId}`, {
    method: "POST",
  });
}
