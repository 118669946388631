import * as api from "./apiRequests";
import { generateRowId } from "../../modules/asset-tree-builder";
import { hideLoadingScreen, showLoadingScreen } from "../../store/loading/loading-slice";
import { levelOutWeightDifference, prepareForSave, setAsOfDateOnSavedSnapshot, updateAllocation } from "./slice";
import { prettyPrintAllocationType } from "../../types/AllocationType";
import { showAlert } from "../../store/app/app-slice";
import { transformLabel } from "../../modules/transformLabels";
import { useDispatch } from "react-redux";
import deduceLevelOutInstrument from "./utils/deduceLevelOutInstrument";
import useCompareState from "./useCompareState";

/**
 * Hook encapsulating async functions around allocations.
 */
export function useAllocation(allocationId: string) {
  const dispatch = useDispatch();
  const { select, getAllocation } = useCompareState();
  return {
    name: getAllocation(allocationId).Name,

    /**
     * Tries to set the allocation into edit state.
     * Returns the latest allocation from backend.
     */
    async lockForEdit() {
      try {
        dispatch(showLoadingScreen());
        const response = await api.editSnapshot(allocationId);
        dispatch(
          showAlert(
            response.IsInEditableState
              ? `${transformLabel("action", "Edit")} successful`
              : `Switch to ${transformLabel("state", "InEdit")} mode successful`,
            {
              type: "success",
            }
          )
        );
        const current = getAllocation(allocationId);
        dispatch(
          updateAllocation({
            // TODO - necessary? why not simply use the returned allo?
            ...current,
            SavedSnapshot: current.SavedSnapshot || { Instruments: [], Version: "0" },
            IsInEditableState: response.IsInEditableState,
            State: response.State,
            StateHistory: response.StateHistory,
          })
        );
      } catch (err) {
        dispatch(showAlert(`Failed switching to ${transformLabel("state", "InEdit")} mode`, { type: "error" }));
        console.error(err);
      } finally {
        dispatch(hideLoadingScreen());
      }
    },

    /**
     * Save allocation changes and remove the edit lock.
     */
    async save() {
      try {
        dispatch(showLoadingScreen());
        dispatch(prepareForSave({ allocationId }));
        const response = await api.saveSnapshot(getAllocation(allocationId));
        dispatch(updateAllocation(response));
        dispatch(showAlert(`${transformLabel("action", "Save")} successful`, { type: "success" }));
      } catch (err) {
        dispatch(showAlert("Can't save changes", { type: "error" }));
        console.error(err);
      } finally {
        dispatch(hideLoadingScreen());
      }
    },

    /**
     * Discards allocation changes and removes the edit lock.
     */
    async discardChanges() {
      let discardOk = false;
      try {
        dispatch(showLoadingScreen());
        // TODO - consolidate two backend roundtrips
        await api.discardSnapshot(allocationId);
        discardOk = true;
        const response = await api.getAllocation(allocationId);
        dispatch(updateAllocation(response));
        dispatch(showAlert(`${transformLabel("action", "Discard")} successful`, { type: "success" }));
      } catch (err) {
        if (discardOk) {
          dispatch(showAlert("Please reload page for updated data.", { type: "error" }));
        } else {
          dispatch(
            showAlert(`Failed to ${transformLabel("action", "Lock")}/${transformLabel("action", "Unlock")}`, { type: "error" })
          );
        }
        console.error(err);
      } finally {
        dispatch(hideLoadingScreen());
      }
    },

    /**
     * Returns the difference between last published and saved snapshots.
     */
    async getDiff() {
      try {
        dispatch(showLoadingScreen());
        dispatch(prepareForSave({ allocationId }));
        return await api.getDiff(getAllocation(allocationId));
      } catch (err) {
        dispatch(showAlert("Could not calculate changes from last state", { type: "error" }));
        console.error(err);
      } finally {
        dispatch(hideLoadingScreen());
      }
    },

    /**
     * Queues an allocation snapshot for publish.
     */
    async queue(asOfDate: Date) {
      try {
        // Make sure we only send the date part to the backend without adjustments of local time to UTC.
        asOfDate.toJSON = function () {
          return `${asOfDate.getFullYear()}-${asOfDate.getMonth() + 1}-${asOfDate.getDate()}T00:00:00Z`;
        };

        dispatch(showLoadingScreen());
        dispatch(prepareForSave({ allocationId }));
        dispatch(setAsOfDateOnSavedSnapshot({ allocationId, asOfDate }));
        const response = await api.queue(getAllocation(allocationId));
        dispatch(updateAllocation(response));
        dispatch(showAlert(`${prettyPrintAllocationType(response.AllocationType)} queued successfully`, { type: "success" }));
        return response;
      } catch (err) {
        dispatch(showAlert("Can't queue allocation", { type: "error" }));
        console.error(err);
      } finally {
        dispatch(hideLoadingScreen());
      }
    },

    /**
     * Determines the level-out-instrument and (if found) performs the level-out.
     */
    async levelOut(assetTreeName: string) {
      const allocation = getAllocation(allocationId);
      const deduced = deduceLevelOutInstrument(allocation);
      if (!deduced) {
        return;
      }

      const levelOutRowId = generateRowId(deduced, assetTreeName);
      const instruments = select((s) => s.instruments);
      let levelOutInstrument = instruments[levelOutRowId];

      if (!levelOutInstrument) {
        try {
          dispatch(showLoadingScreen());
          levelOutInstrument = await api.getInstrument(deduced.Id);
        } catch (err) {
          dispatch(showAlert("Error fetching level-out instrument", { type: "error" }));
          console.error(err);
        } finally {
          dispatch(hideLoadingScreen());
        }
      }

      dispatch(levelOutWeightDifference({ allocationId, levelOutInstrument }));
    },
  };
}
