import * as React from "react";
import { AppState } from "../../store/AppState";
import { connect, ConnectedProps } from "react-redux";
import { allocationPageRequest, updateFilter } from "../../store/pages/pages-slice";
import AllocationTableContainer from "./AllocationTableContainer";
import AllocationTableHeader from "./AllocationTableHeader";
import { updateCurrentAllocationPageType } from "../../store/app/app-slice";
import { AllocationPageType } from "../../types/AllocationPageType";

const mapStateToProps = (state: AppState) => {
  return {
    masterData: state.masterData,
    pagination: state.pages.pagination.allocations,
    allocations: state.pages.allocations,
    page: state.pages.pagination.allocations.currentPage,
    pageSize: state.pages.pagination.allocations.pageSize,
    totalCount: state.pages.pagination.allocations.totalCount,
    selection: state.pages.selection.allocations,
    filters: state.pages.pagination.allocations.filter,
    sort: state.pages.pagination.allocations.order,
  };
};

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

/**
 * This route/page displays the IcAllocations (SAA,TAA,TCA) using the AllocationTableHeader and -TableContainer components.
 */
class IcAllocations extends React.PureComponent<ReduxProps> {
  constructor(props: ReduxProps) {
    super(props);

    this.props.dispatch(updateCurrentAllocationPageType(AllocationPageType.Allocation));
  }

  render() {
    const props = this.props;
    return (
      <>
        <AllocationTableHeader
          title="Allocation"
          onFilterChange={(filterId, filterState) => {
            props.dispatch(updateFilter({ id: filterId, state: filterState, pageType: AllocationPageType.Allocation }));
          }}
          filters={this.props.filters}
          allocationPasteType="icAllocation"
        />
        <AllocationTableContainer
          {...this.props}
          allocationPageType={AllocationPageType.Allocation}
          loadPage={(page: number) => {
            props.dispatch(allocationPageRequest(page, props.pageSize, props.sort, props.filters));
          }}
          columnData={columns}
        />
      </>
    );
  }
}

export default connector(IcAllocations);

const columns = [
  {
    id: "AllocationType",
    label: "Type",
  },
  {
    id: "ProductLine",
    label: "Product",
  },
  {
    id: "ProductDistinction",
    label: "Dist.",
  },
  {
    id: "ReferenceCurrency",
    label: "CCY",
  },
  {
    id: "RiskProfile",
    label: "Inv. Strategy",
  },
  {
    id: "Name",
    label: "Code",
  },
  {
    id: "PublishedSnapshot.AsOfDate",
    label: "As-of Date",
  },
  {
    id: "Owner",
    label: "Owner",
  },
  {
    id: "buttons",
    label: "",
  },
];
