import React from "react";
import { styled } from '@mui/material/styles';

const PREFIX = 'Row';

const classes = {
  row: `${PREFIX}-row`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.row}`]: {
    display: "flex",
    fontSize: theme.typography.body2.fontSize,
    justifyContent: "flex-start",
    marginBottom: theme.spacing(1),
    "& .label": {
      width: "26rem",
      marginRight: theme.spacing(2),
    },
    "& .value": {
      justifyContent: "flex-start",
    },
  }
}));

const Row: React.FC<{ label: string, children?: React.ReactNode }> = (props) => {
  return (
    <Root className={classes.row}>
      <div className="label">{props.label}</div>
      <div className="value">{props.children}</div>
    </Root>
  );
};

export default Row;
