import { Dictionary, flatten, groupBy } from "lodash";
import { Distribution } from "./Distribution";
import { getAllDistributions, patchDistribution } from "./apiRequests";
import { showAlert } from "../../../store/app/app-slice";
import { useDispatch } from "react-redux";
import React from "react";
import { ColumnDefinition, ColumnType } from "../../../types/GenericTable";
import { GenericTable } from "./GenericTable";

export default function DistributionsTable() {
  const dispatch = useDispatch();
  const colDefinitions: ColumnDefinition<Distribution>[] = [
    {
      propName: "Code",
      label: "Distribution Code",
      columnType: ColumnType.ReadOnly,
    },
    {
      propName: "GenerateDynWeightRequest",
      label: "Request",
      columnType: ColumnType.Editable,
      editCallBack: async (dist: Distribution) => {
        try {
          const warning = checkSelectionIsAllowed(dist);
          if (warning) {
            dispatch(showAlert(warning, { type: "warning" }));
          }
          await patchDistribution(dist.Id, dist.GenerateDynWeightRequest);
        } catch {
          dispatch(showAlert("Failed to save", { type: "error" }));
          doRefresh();
        }
      },
    },
  ];

  const [data, setData] = React.useState<Dictionary<Distribution[]>>({});

  const doRefresh = async () => {
    const allDist = await getAllDistributions();
    const map = groupBy(allDist, (item) => item.ContainerId);
    setData(map);
  };

  React.useEffect(() => {
    doRefresh();
  }, []);

  const checkSelectionIsAllowed = (dist: Distribution) => {
    if (!dist.GenerateDynWeightRequest) {
      return;
    }

    const selectedRelatives = data[dist.ContainerId]
      .filter((item) => item.GenerateDynWeightRequest && item.Id !== dist.Id)
      .map((item) => item.Code)
      .join(", ");
    return selectedRelatives && `Multiple distributions for the same allocation are enabled (${selectedRelatives})`;
  };

  const codeColumn = "Code";

  return (
    <div>
      <GenericTable
        data={flatten(Object.values(data) || [])}
        colDefinitions={colDefinitions}
        sortBy={codeColumn}
        keyColName="Id"
        filterColumn={codeColumn}
        title="Distributions"
      />
    </div>
  );
}
