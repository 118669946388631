import { Theme } from "@mui/material";
import { AllocationRowDescriptor } from "./types";

export const cellWidth = "6rem";

const indentSpace = 2;
const valorWidthUnits = 23;
const isinWidthUnits = 16;
const nameWidthUnits = 31;
const riskCcyWidthUnits = 6;
const tradeCcyWidthUnits = 6;
const totalWidthUnits = valorWidthUnits + isinWidthUnits + nameWidthUnits + riskCcyWidthUnits + tradeCcyWidthUnits;

/**
 * Widths of row descriptor constituents in theme.spacing units.
 */
export function descriptorWidths(theme: Theme, maxLevel: number) {
  const maxIndent = maxLevel * indentSpace;
  return {
    valor: { width: theme.spacing(valorWidthUnits + maxIndent) },
    isin: { width: theme.spacing(isinWidthUnits) },
    name: { width: theme.spacing(nameWidthUnits) },
    riskCcy: { width: theme.spacing(riskCcyWidthUnits) },
    tradeCcy: { width: theme.spacing(tradeCcyWidthUnits) },
    category: { width: theme.spacing(totalWidthUnits + maxIndent) },
  };
}

export function levelIndent(theme: Theme, level: number) {
  return {
    paddingLeft: theme.spacing(level * indentSpace),
  };
}

export function rowStyle(theme: Theme) {
  return {
    paddingTop: theme.spacing(0.4),
    paddingBottom: theme.spacing(0.4),
    lineHeight: "2.5rem",
    borderBottom: "1px solid " + theme.palette.grey[300],
    "& > div": {
      marginTop: 1, // compensate input border, there is a (very) small offset without
    },
  };
}

export function cellStyle(theme: Theme) {
  return {
    paddingRight: theme.spacing(1),
    textAlign: "right",
    width: cellWidth,
    overflowX: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
}

export function rowBackground(row: AllocationRowDescriptor) {
  return {
    backgroundColor: row.isInstrument ? "white" : row.level === 0 ? "#ccd0e7" : "#f2f3f9",
  };
}
