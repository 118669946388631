import * as React from "react";
import { AppState } from "../../store/AppState";
import AllocationsPanel from "./allocations/AllocationsPanel";
import { Box, Paper, Typography } from "@mui/material";
import SettingsPanel from "./SettingsPanel";
import TstExcelIntegration from "./integration/TstExcelIntegration";
import NavigationPanel from "./NavigationPanel";
import flatten from "lodash/flatten";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import AlpimaIntegration from "./integration/AlpimaIntegration";
import BannerPanel from "./banners/BannerPanel";
import { useParams } from "react-router";
import DashboardPanel from "./DashboardPanel";
import PublishPanel from "./PublishPanel";
import CachePanel from "./CachePanel";
import GroupPanel from "./groups/GroupPanel";
import InstrumentStatusPanel from "./InstrumentStatusPanel";
import ProductLinePanel from "./ProductLinePanel";
import { updateCurrentAllocationPageType } from "../../store/app/app-slice";
import { Adjust, FitnessCenter, ListAlt } from "@mui/icons-material";
import {
  AlpimaIcon,
  BrushIcon,
  DashboardIcon,
  ExcelFileIcon,
  FlagIcon,
  GaugeDashboardIcon,
  HierarchyIcon,
  SettingsIcon,
  UploadBoxIcon,
  UsersIcon,
  EditIcon,
} from "../shared/Icons";
import { DynamicWeights } from "./dynamic-weights/DynamicWeights";

const PREFIX = 'Administration';
const classes = {
  root: `${PREFIX}-root`,
  navContainer: `${PREFIX}-navContainer`,
  mainContainer: `${PREFIX}-mainContainer`
};
const Root = styled('div')(({ theme}) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    display: "flex",
  },

  [`& .${classes.navContainer}`]: {
    width: 248,
    marginRight: theme.spacing(4),
  },

  [`& .${classes.mainContainer}`]: {
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }
}));

/*
 * helper to create elements for the NavigationPanel.
 */
const nav = (name: string, render: () => JSX.Element, icon?: JSX.Element) => {
  return {
    name,
    render,
    icon: icon || <Adjust color="primary" />,
  };
};

export default function Administration() {
  /* get the component name to display from the URL params */
  const { componentName } = useParams() as any;

  const canViewAdministration = useSelector((state: AppState) => state.user.canViewAdmin);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(updateCurrentAllocationPageType(undefined));
  }, []);

  const iconColor = "primary";

  // this is a two dimensional array to define the various navigation elements.
  // the outer 'layer' separates the admin groups, while the inner array defines the group elements.
  const components = [
    [nav("Dashboard", () => <DashboardPanel />, <DashboardIcon color={iconColor} />)],
    [
      nav("TAP Publish", () => <PublishPanel />, <UploadBoxIcon color={iconColor} />),
      nav("Alpima Integration", () => <AlpimaIntegration />, <AlpimaIcon color={iconColor} />),
      nav("TST-Excel Integration", () => <TstExcelIntegration />, <ExcelFileIcon color={iconColor} />),
    ],
    [nav("Allocations", () => <AllocationsPanel />, <HierarchyIcon color={iconColor} />)],
    [nav("Dynamic Weights", () => <DynamicWeights />, <FitnessCenter color={iconColor} />)],
    [nav("Banner", () => <BannerPanel />, <FlagIcon color={iconColor} />)],
    [nav("Groups", () => <GroupPanel />, <UsersIcon color={iconColor} />)],
    [
      nav("Settings", () => <SettingsPanel />, <SettingsIcon color={iconColor} />),
      nav("Cache", () => <CachePanel />, <BrushIcon color={iconColor} />),
    ],
    [nav("Product Lines", () => <ProductLinePanel />, <ListAlt color={iconColor} />)],
    [nav("Instrument Status", () => <InstrumentStatusPanel />, <GaugeDashboardIcon color={iconColor} />)],
  ];

  // fallback, when the component was not found.
  let activeComponent = {
    name: "Not found",
    render: () => {
      return <h1>Could not load the requested admin component.</h1>;
    },
  };

  // find the component to display
  flatten(components).forEach((it) => {
    if (encodeURIComponent(it.name.toLowerCase()) == encodeURIComponent(componentName?.toLowerCase())) {
      it["active"] = true;
      activeComponent = it;
    }
  });

  return (
    (<>
      <Box mb={2}>
        <Typography variant="h2">Administration</Typography>
      </Box>
      {(canViewAdministration && (
        <Root className={classes.root}>
          <div className={classes.navContainer}>
            <NavigationPanel components={components} />
          </div>
          <Paper className={classes.mainContainer}>{activeComponent.render()}</Paper>
        </Root>
      )) || <h1>You do not have the necessary privileges to view this page.</h1>}
    </>)
  );
}
