import {
  AVERAGE_WEIGHT_KEY,
  DYNAMIC_WEIGHT_KEY,
  PUBLISHED_WEIGHT_KEY,
  IC_WEIGHT_KEY,
  SAVED_WEIGHT_KEY,
  Weights,
  isBigNumber,
} from "../../../modules/asset-tree-builder";

type WeightKey =
  | typeof AVERAGE_WEIGHT_KEY
  | typeof DYNAMIC_WEIGHT_KEY
  | typeof PUBLISHED_WEIGHT_KEY
  | typeof IC_WEIGHT_KEY
  | typeof SAVED_WEIGHT_KEY;

const allWeightKeys: WeightKey[] = [
  AVERAGE_WEIGHT_KEY,
  DYNAMIC_WEIGHT_KEY,
  PUBLISHED_WEIGHT_KEY,
  IC_WEIGHT_KEY,
  SAVED_WEIGHT_KEY,
];

/**
 * Returns `true`, if the specified weights are set and non-zero, otherwise `false`.
 *
 * @param weights The weights to inspect.
 * @param keys The weights keys to inspect.
 */
export default function hasWeights(weights: Weights, keys: WeightKey[] = allWeightKeys) {
  return keys.filter((k) => isBigNumber(weights[k]) && !weights[k].eq(0)).length === keys.length;
}
