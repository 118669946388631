import {
  Button,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import classNames from "classnames";
import * as React from "react";
import formatDate from "date-fns/format";
import { Link } from "react-router-dom";
import IntegrationHistoryEntry from "./types/IntegrationHistoryEntry";

const PREFIX = 'IntegrationHistoryArea';
const classes = {
  root: `${PREFIX}-root`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    "& .section-header": {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      "& > h2": {
        marginRight: theme.spacing(5),
      },
    },
    "& .item": {
      width: "70vw",
      "& .item-header": {
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > div": {
          minWidth: 0,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        "& > div:nth-child(1)": { flex: "0 16rem" },
        "& > div:nth-child(2)": { flex: "0 7rem" },
        "& > div:nth-child(3)": { flex: 1 },
        "& .chip": {
          color: "white",
          width: "fit-content",
          paddingLeft: theme.spacing(0.75),
          paddingRight: theme.spacing(0.75),
          borderRadius: theme.spacing(1),
        },
        "& .success": {
          backgroundColor: theme.palette.success.main,
        },
        "& .warning": {
          backgroundColor: theme.palette.warning.main,
        },
        "& .error": {
          backgroundColor: theme.palette.error.main,
        },
        "& a:hover": {
          textDecoration: "underline",
        },
      },
      "& .item-details": {
        fontFamily: "monospace",
        width: "100%",
        overflowX: "auto",
        marginLeft: theme.spacing(2),
        "& > *": {
          marginTop: theme.spacing(0.5),
          marginBottom: 0,
        },
        "& > *:first-child": {
          marginTop: theme.spacing(1.5),
        },
        "& > *:last-child": {
          marginBottom: theme.spacing(1.5),
        },
      },
      "& .item-details-hidden": {
        display: "none",
      },
    },
    "& button": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    "& li:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)", // same as `<ListItem button>`
    },
    "& .dash": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  }
}));

export default React.memo(function IntegrationHistoryArea(props: {
  title: string;
  history: IntegrationHistoryEntry[];
  canShowMore: boolean;
  loadMore: () => void;
}) {
  const [errorsAndWarningsOnly, setErrorsAndWarningsOnly] = React.useState(false);

  const filtered = errorsAndWarningsOnly ? props.history.filter((e) => e.Outcome != "Success") : props.history;

  return (
    <Root className={classes.root}>
      <div className="section-header">
        <Typography variant="h2">{props.title}</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={errorsAndWarningsOnly}
              onChange={() => setErrorsAndWarningsOnly(!errorsAndWarningsOnly)}
              color="secondary"
              disableRipple
            />
          }
          label="errors &amp; warnings only"
        />
      </div>
      <List dense>
        {filtered.map((e, i) => (
          <ListItem key={i}>
            <HistoryItem {...e} />
          </ListItem>
        ))}
      </List>
      <Button variant="outlined" onClick={props.loadMore} disabled={!props.canShowMore}>
        {props.canShowMore ? "Show More" : "Nothing more to show"}
      </Button>
    </Root>
  );
});

/**
 * Display of a single item in the history.
 */
function HistoryItem(props: IntegrationHistoryEntry) {
  const { RequestId, UploadId, Outcome, CreatedAt } = props;
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div className="item">
      <div className="item-header" onClick={() => setIsExpanded(!isExpanded)}>
        <div>{formatDate(CreatedAt, "yyyy-MM-dd HH:mm:ss.SSS")}</div>
        <div>
          <div className={classNames("chip", Outcome.toLowerCase())}>integrate</div>
        </div>
        <div>
          {Outcome === "Success" ? (
            <span>
              <Link to={"/modelportfolio/" + props.TargetAllocationId}> -&gt;{props.TargetAllocationCode}</Link>
            </span>
          ) : (
            props.Message
          )}
        </div>
      </div>
      <div className={classNames("item-details", !isExpanded && "item-details-hidden")}>
        {RequestId && <div>RequestId: {RequestId}</div>}
        {UploadId && <div>UploadId: {UploadId}</div>}

        <div>Source Allocation: {props.SourceAllocationCode}</div>
        <div>
          Target AllocationCode: <Text v={props.TargetAllocationCode} />
        </div>
        <div>
          Target Allocation Id:{" "}
          {(props.TargetAllocationId && (
            <Link to={"/modelportfolio/" + props.TargetAllocationId}>{props.TargetAllocationId}</Link>
          )) || <Text v={props.TargetAllocationId} />}
        </div>
        <div>Action: {props.IntegrationAction}</div>
        {Outcome === "Error" && (
          <div>
            Exception: <Text v={props.Exception} />
          </div>
        )}
        <div>
          Message: <Text v={props.Message} />
        </div>
      </div>
    </div>
  );
}

function Text({ v }: { v: Maybe<string> }) {
  return (
    <>
      {v === null
        ? "null"
        : v === undefined
        ? "undefined"
        : v.split("\n").map((x, i) => (
            <React.Fragment key={i}>
              {x}
              <br />
            </React.Fragment>
          ))}
    </>
  );
}
