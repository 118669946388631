import * as React from "react";
import { AppState } from "../../store/AppState";
import { Button, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PanelDescriptionHeader from "./PanelDescriptionHeader";
import { baseUrl } from "../../store/apiUrlProvider";
import { request } from "../../modules/client";
import { hideLoadingScreen, showLoadingScreen } from "../../store/loading/loading-slice";
import { showAlert } from "../../store/app/app-slice";
import { masterDataRequest } from "../../store/masterData/masterData-slice";

export default function CachePanel() {
  const canWriteAdministration = useSelector((state: AppState) => state.user.isAdmin);
  const dispatch = useDispatch();
  const theme = useTheme();

  async function clearMasterDataCache() {
    try {
      dispatch(showLoadingScreen());
      await request(`${baseUrl()}api/cache`, { method: "DELETE" });
      dispatch(masterDataRequest());
    } catch (err) {
      console.log(`Error occurred on Clear Masterdata: ${err}`);
      dispatch(showAlert(`Failed to clear cache ${err}`, { type: "error" }));
    } finally {
      dispatch(hideLoadingScreen());
    }
  }

  return (
    <div>
      <PanelDescriptionHeader>Clear cache of TST e.g. after manual changes to the database.</PanelDescriptionHeader>
      <Button style={{ marginRight: theme.spacing(1) }} disabled={!canWriteAdministration} onClick={clearMasterDataCache}>
        Clear masterdata
      </Button>
    </div>
  );
}
