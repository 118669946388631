import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterDataState } from "./MasterDataState";
import { AssetTree, AssetTreeRow } from "../../types/AssetTree";
import { ProductLine } from "../../types/ProductLine";
import { parseError } from "../../modules/http-request-utils";

export const initialState: MasterDataState = {
  currencies: [],
  productLines: [],
  productDistinctions: [],
  riskProfiles: [],
  bmoContexts: [],
  marketStructures: [],
  aaaStrategyNatures: [],
  destinations: [],
  settings: {
    DefaultTree: "",
    AllocationSumTolerance: 0,
    ThresholdAvgDyn: 0,
    FinanceDocRequestMailRecipient: "",
    TstMailbox: "",
    PublishRetryCount: 3,
  },
  status: "idle",
  message: undefined,
  trees: [],
  instrumentCurrencies: [],
  allocationSubAssetClasses: [],
  softwareVersion: "",
  environment: "",
  dmcUrl: "",
  tstCoreUrl: "",
  userGuideUrl: "",
  hash: "undefined",
};

const masterDataSlice = createSlice({
  name: "masterData",
  initialState,
  reducers: {
    masterDataRequest(state) {
      state.status = "running";
      state.message = "";
    },
    masterDataSuccess(state, action: PayloadAction<{ data: any }>) {
      const data = action.payload.data;

      state.currencies = data.Currencies;
      state.productLines = data.ProductLines;
      state.productDistinctions = data.ProductDistinctions;
      state.riskProfiles = data.RiskProfiles;
      state.bmoContexts = data.BmoContexts;
      state.settings = data.Settings;
      state.environment = data.Environment;
      state.softwareVersion = data.SoftwareVersion;
      state.marketStructures = data.MarketStructures;
      state.aaaStrategyNatures = data.AaaStrategyNatures;
      state.destinations = data.Destinations;
      state.trees = data.Trees;
      state.instrumentCurrencies = data.InstrumentCurrencies;
      state.allocationSubAssetClasses = extractSubAssetClasses("AA (IM AA)", state.trees);
      state.hash = data.Hash;
      state.dmcUrl = data.DmcUrl;
      state.tstCoreUrl = data.TstCoreUrl;
      state.userGuideUrl = data.UserGuideUrl;

      state.status = "loaded";
      state.message = "";
    },
    masterDataFailure(state, action: PayloadAction<{ message: any }>) {
      state.status = "error";
      state.message = parseError(action.payload.message);
    },
    updateProductLine(state, action: PayloadAction<{ productLine: ProductLine }>) {
      const updated = action.payload.productLine;
      const idx = state.productLines.findIndex((it) => it.Id == updated.Id);
      state.productLines[idx] = updated;
    },
  },
});

/**
 * TODO: consider killing this, as it makes assumptions about the label in the AssetTree.
 * -> Return this information in the MasterData.
 */
function extractSubAssetClasses(structureId: string, trees: AssetTree[]): string[] {
  const tree = trees.find((tree) => tree.Structure == structureId);
  if (!tree) {
    const msg = `Could not find the AssetTree '${structureId}' to extract the SubAssetClasses from.`;
    console.error(msg);
    throw new Error(msg);
  }
  const result: string[] = [];
  collectSubAssetClasses(tree.Tree, 0, result);

  ["CASH", "CAFT", "OTHERS"].forEach((it) => {
    if (result.indexOf(it) < 0) {
      result.push(it);
    }
  });

  return result;
}

function collectSubAssetClasses(tree: AssetTreeRow[], level: number, bucket: string[]) {
  if (!tree) {
    return;
  }
  tree.forEach((element) => {
    // if (level == 1 && element.Label.length === 4) {
    if (element.Label.length === 4) {
      bucket.push(element.Label);
    }
    if (element.Tree) {
      collectSubAssetClasses(element.Tree, level + 1, bucket);
    }
  });
}

export const { masterDataFailure, masterDataRequest, masterDataSuccess, updateProductLine } = masterDataSlice.actions;

export default masterDataSlice.reducer;
