import { requestOidcConfig } from "../auth/oidcClient";
import { AppOidcConfig } from "../types/ConfigVariables";

let oidcConfig: AppOidcConfig | null = null;

async function loadAppOidcConfig(): Promise<AppOidcConfig> {
  const result = await requestOidcConfig();
  return result as AppOidcConfig;
}

export async function getAppOidcConfig(): Promise<AppOidcConfig> {
  if (!oidcConfig) oidcConfig = await loadAppOidcConfig();
  return oidcConfig;
}
