import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import { AppState, Message, Banner } from "./AppState";
import { v4 as uuid } from "uuid";
import { AllocationPageType } from "../../types/AllocationPageType";

let initialState: AppState = {
  alerts: [],
  displayedError: undefined,
  showUnauthorized: false,
  showServerDown: false,
  currentAllocationPageType: undefined,
  banners: [],
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    showErrorDialog(state, action: PayloadAction<{ reason: any }>) {
      state.displayedError = action.payload.reason;
    },
    showUnauthorized(state) {
      state.showUnauthorized = true;
    },
    showServerDown(state) {
      state.showServerDown = true;
    },
    hideAlert(state, action: PayloadAction<{ id: string }>) {
      state.alerts = state.alerts.filter((d) => d.id !== action.payload.id);
    },
    showMessage(state, action: PayloadAction<Message>) {
      state.alerts = [action.payload]; // this does not make much sense but is take from the 'original' app-reducer
    },
    updateCurrentAllocationPageType(state, action: PayloadAction<AllocationPageType | undefined>) {
      state.currentAllocationPageType = action.payload;
    },
    updateBanner(state, action: PayloadAction<Banner>) {
      const idx = state.banners.findIndex((b) => b.Id === action.payload.Id);
      if (idx < 0) {
        state.banners = [...state.banners, action.payload];
      } else {
        const updated = { ...action.payload, dismissed: state.banners[idx].dismissed };
        state.banners.splice(idx, 1, updated);
      }
    },
    updateBanners(state, action: PayloadAction<{ banners: Banner[] }>) {
      state.banners = action.payload.banners;
    },
    dismissBanner(state, action: PayloadAction<{ bannerId: number }>) {
      const banner = state.banners.find((b) => b.Id === action.payload.bannerId);
      if (banner) {
        banner.dismissed = true;
      }
    },
    deleteBanner(state, action: PayloadAction<{ bannerId: number }>) {
      const id = action.payload.bannerId;
      state.banners = state.banners.filter((b) => b.Id !== id);
    },
  },
});

export const showAlert = createAction(
  appSlice.actions.showMessage.type,
  (
    message: string,
    options: {
      id?: string;
      icon?: string;
      position?: string;
      type: "success" | "warning" | "error" | "info";
      timeout?: number; // Delay in seconds for the notification go away. Set this to 0 to not auto-dismiss the notification
    }
  ) => {
    const timeout = options.type === "error" ? 0 : 5;
    return {
      payload: {
        id: options.id || uuid(),
        icon: options.icon,
        message: message,
        position: options.position || "bottom-right",
        type: options.type,
        timeout: options.timeout && !isNaN(options.timeout) ? options.timeout : timeout,
      },
    };
  }
);

export const {
  hideAlert,
  showServerDown,
  showUnauthorized,
  showErrorDialog,
  updateCurrentAllocationPageType,
  updateBanner,
  updateBanners,
  dismissBanner,
  deleteBanner,
} = appSlice.actions;

export default appSlice.reducer;
