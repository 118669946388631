import * as React from "react";
import { Table, TableRow, TableBody, TableCell, TableHead, TextField, Tooltip, Icon, useTheme } from "@mui/material";
import { InstrumentListItem } from "../../types/InstrumentList";
import { InfoIcon } from "../shared/Icons";
import classNames from "classnames";

/**
 * because the resolved column can both show the result of the Search and the resolved instruments
 * we introduce an new interface here which only contains the actually shown values.
 * With all values being optional. Both search & resolved instruments should be able to satify this interface.
 */
export interface ResolvedValues {
  imsInstrumentId?: string;
  isin?: string;
  bloombergTicker?: string;
  bloombergGlobalId?: string;
  bloombergMarketSector?: string;
  dataStreamTicker?: string;
  name?: string;
  defaultTradingCurrency?: string;
  riskCurrency?: string;
  assetSubClass?: string;
}

/**
 * Trivial component to display an ItemSearchResult as a Table.
 */
export default function ItemDialogTable(props: {
  requested: InstrumentListItem;
  onBlur: (key: string, value: string) => void;
  resolved?: ResolvedValues;
  classes: { keyColumn: any; noMarginInput: any; inputDisabled: any };
}) {
  const { requested, resolved, classes } = props;
  const theme = useTheme();

  const hasResolved = !!resolved;

  // small helper to create consistent <TextField>s.
  const textField = (name: string, maxLength?: number, disabled?: boolean) => {
    let defaultValue = (props.requested as any)[name];
    return (
      <TextField
        variant="outlined"
        name={name}
        className={classNames(classes.noMarginInput, { [classes.inputDisabled]: disabled })}
        margin="dense"
        disabled={disabled}
        inputProps={{ maxLength: maxLength }}
        defaultValue={defaultValue}
        onBlur={(evt) => {
          props.onBlur(evt.currentTarget.name, evt.target.value);
        }}
      />
    );
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Requested</TableCell>
          {hasResolved && (
            <TableCell>
              Resolved
              <Tooltip title="The resolved values do not get automatically updated by changing values in the 'Requested' column.">
                <Icon style={{ marginLeft: theme.spacing(1) }}>
                  <InfoIcon width={16} height={16} color="primary" />
                </Icon>
              </Tooltip>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className={classes.keyColumn}>Instrument ID</TableCell>
          <TableCell>{requested.instrumentId}</TableCell>
          {hasResolved && <TableCell>{resolved?.imsInstrumentId}</TableCell>}
        </TableRow>
        <TableRow>
          <TableCell className={classes.keyColumn}>Name</TableCell>
          <TableCell />
          {hasResolved && <TableCell>{resolved?.name}</TableCell>}
        </TableRow>
        <TableRow>
          <TableCell className={classes.keyColumn}>ISIN</TableCell>
          <TableCell>{textField("isin", 12, hasResolved)}</TableCell>
          {hasResolved && <TableCell>{resolved?.isin}</TableCell>}
        </TableRow>
        <TableRow>
          <TableCell className={classes.keyColumn}>Trade CCY</TableCell>
          {/* TODO: is this the correct currency to use here? */}
          <TableCell>{textField("currency", 3, hasResolved)}</TableCell>
          {hasResolved && <TableCell>{resolved?.defaultTradingCurrency}</TableCell>}
        </TableRow>
        <TableRow>
          <TableCell className={classes.keyColumn}>Bloomberg Ticker</TableCell>
          <TableCell>{textField("bloombergTicker", 20)}</TableCell>
          {hasResolved && <TableCell>{resolved?.bloombergTicker}</TableCell>}
        </TableRow>
        <TableRow>
          <TableCell className={classes.keyColumn}>Bloomberg Global ID</TableCell>
          <TableCell>{textField("bloombergGlobalId", 12)}</TableCell>
          {hasResolved && <TableCell>{resolved?.bloombergGlobalId}</TableCell>}
        </TableRow>
        <TableRow>
          <TableCell className={classes.keyColumn}>Bloomberg Market Sector</TableCell>
          <TableCell>{textField("bloombergMarketSector", 8)}</TableCell>
          {hasResolved && <TableCell>{resolved?.bloombergMarketSector}</TableCell>}
        </TableRow>
        <TableRow>
          <TableCell className={classes.keyColumn}>Data Stream Ticker</TableCell>
          <TableCell>{textField("dataStreamTicker", 20)}</TableCell>
          {hasResolved && <TableCell>{resolved?.dataStreamTicker}</TableCell>}
        </TableRow>
        <TableRow>
          <TableCell className={classes.keyColumn}>Risk Currency</TableCell>
          <TableCell />
          {hasResolved && <TableCell>{resolved?.riskCurrency}</TableCell>}
        </TableRow>
        <TableRow>
          <TableCell className={classes.keyColumn}>Asset Sub Class</TableCell>
          <TableCell>{textField("subAssetClass", 10)}</TableCell>
          {hasResolved && <TableCell>{resolved?.assetSubClass}</TableCell>}
        </TableRow>
        <TableRow>
          <TableCell className={classes.keyColumn}>Inception date</TableCell>
          <TableCell />
          {hasResolved && <TableCell>n.a.</TableCell>}
        </TableRow>
        <TableRow>
          <TableCell className={classes.keyColumn}>Weight</TableCell>
          <TableCell>{textField("weight")}</TableCell>
          {hasResolved && <TableCell />}
        </TableRow>
      </TableBody>
    </Table>
  );
}
