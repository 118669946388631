import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isArrayLike from "lodash/isArrayLike";
import { Location as RouterLocation } from "@remix-run/router";
import { Pageination, ModelPortfolioFilters, AllocationFilters } from "./PagesState";
import { initialState } from "./pages-commons";
import { allocationPageTypeToResultKey, getAllocationPageTypeFromPath } from "../../types/AllocationPageType";

type FilterType = AllocationFilters | ModelPortfolioFilters;

export default { getState, setParams };

export function getState(location: Location | RouterLocation) {
  try {
    const allocationPageType = getAllocationPageTypeFromPath(location.pathname);
    if (allocationPageType !== undefined) {
      const key = allocationPageTypeToResultKey(allocationPageType);
      const initial: Pageination<FilterType> = initialState.pagination[key];

      const searchParams = new URLSearchParams(location.search);

      const result: Partial<Pageination<FilterType>> = getPage(searchParams, initial);
      result.filter = getFilter(searchParams, initial.filter);
      result.order = getOrder(searchParams, initial.order);

      return result;
    } else {
      return {};
    }
  } catch (error) {
    console.error("deserialization of state from query string failed", error);
    clearQueryString();
    return {};
  }
}

export function setParams(paginationState: Pageination<FilterType>, location: Location | RouterLocation) {
  const searchParams = new URLSearchParams(location.search);
  searchParams.delete("lastState");

  setFilter(searchParams, paginationState);
  setOrder(searchParams, paginationState);
  setPage(searchParams, paginationState);

  const queryString = "?" + searchParams.toString();
  if (queryString === "?") {
    clearQueryString();
  } else {
    window.history.replaceState(null, "", "?" + searchParams.toString());
  }
}

function setPage(searchParams: URLSearchParams, paginationState: Pageination<FilterType>) {
  const defaultState = initialState.pagination.allocations;

  setSearchParam(searchParams, "rowsPerPage", paginationState.pageSize, defaultState.pageSize);
  setSearchParam(searchParams, "page", paginationState.currentPage, defaultState.currentPage);
}

function getPage(searchParams: URLSearchParams, defaultState: Pageination<AllocationFilters>) {
  return {
    pageSize: parseInt(searchParams.get("rowsPerPage") || "0") || defaultState.pageSize,
    currentPage: parseInt(searchParams.get("page") || "0") || defaultState.currentPage,
  };
}

function setFilter(searchParams: URLSearchParams, paginationState: Pageination<FilterType>) {
  const filterState: any = cloneDeep(paginationState.filter);
  delete filterState.Id;
  delete filterState.reverseLookup;

  if (paginationState.filter.reverseLookup) {
    filterState.reverseLookup = paginationState.filter.reverseLookup.map((i: any) => ({
      instrumentId: i.instrumentId,
      name: i.name,
    }));
  }

  // consider only props with actual values
  const filter = Object.keys(filterState)
    //the result of these filters are user specific and are not included in the querystring so the URL can be shared without causing confusion
    .filter((key) => key !== "Favorite" && key !== "Owner")
    .reduce((obj, key) => {
      const value = filterState[key];
      if ((isArrayLike(value) && !isEmpty(value)) || (value !== null && value !== undefined)) {
        obj[key] = value;
      }
      return obj;
    }, {});

  const serializedFilter = btoa(JSON.stringify(filter));

  if (isEmpty(filter)) {
    searchParams.delete("filter");
  } else {
    searchParams.set("filter", serializedFilter);
  }
}

function getFilter(searchParams: URLSearchParams, defaultState: AllocationFilters) {
  const filter = searchParams.get("filter");
  if (!filter) {
    return defaultState;
  }

  const filterJson = atob(filter);
  return JSON.parse(filterJson) as FilterType;
}

function setOrder(searchParams: URLSearchParams, paginationState: Pageination<FilterType>) {
  const defaultState = initialState.pagination.allocations.order;

  setSearchParam(searchParams, "sortBy", paginationState.order?.columnId, defaultState.columnId);
  setSearchParam(searchParams, "sortDirection", paginationState.order?.order, defaultState.order);
}

function getOrder(searchParams: URLSearchParams, defaultState: { columnId: string; order: "asc" | "desc" }) {
  return {
    columnId: searchParams.get("sortBy") || defaultState.columnId,
    order: (searchParams.get("sortDirection") as any) || defaultState.order,
  };
}

function setSearchParam(searchParams: URLSearchParams, key: string, value?: string | number, defaultValue?: string | number) {
  if (value === undefined || value === null || value === "" || value === defaultValue) {
    searchParams.delete(key);
  } else {
    searchParams.set(key, value as string);
  }
}

function clearQueryString() {
  window.history.replaceState(null, "", window.location.pathname);
}
