import { AppState } from "../../store/AppState";
import { styled } from '@mui/material/styles';
import { useSelector } from "react-redux";
import { toDateTimeString, toShortDateString } from "../../modules/date";
import { transformLabel } from "../../modules/transformLabels";
import { Typography, Button } from "@mui/material";
import Allocation from "../../types/Allocation";
import React from "react";
import take from "lodash/take";
import StateHistory, { DestinationState } from "../../types/StateHistory";

const PREFIX = 'AllocationHistory';
const classes = {
  card: `${PREFIX}-card`,
  title: `${PREFIX}-title`,
  headerGutter: `${PREFIX}-headerGutter`,
  showMoreButton: `${PREFIX}-showMoreButton`,
  historyElementContainer: `${PREFIX}-historyElementContainer`,
  historyElementVerticalLine: `${PREFIX}-historyElementVerticalLine`,
  historyElement: `${PREFIX}-historyElement`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.card}`]: {
    minWidth: 275,
    overflow: "auto",
  },

  [`& .${classes.title}`]: {
    marginBottom: 16,
    fontSize: 14,
  },

  [`& .${classes.headerGutter}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.showMoreButton}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.historyElementContainer}`]: {
    position: "relative",
  },

  [`& .${classes.historyElementVerticalLine}`]: {
    position: "absolute",
    height: "100%",
    top: 5,
    bottom: 5,
    left: 9.25,
    borderLeft: "solid 1.5px #001489",
  },

  [`& .${classes.historyElement}`]: {
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignContent: "space-between",
    position: "relative",
    "& :first-child": {
      paddingRight: theme.spacing(1),
    },
  }
}));

const defaultNumberOfDisplayedItems = 8;

export default function History(props: { id: string }) {
  const [showFullHistory, setShowFullHistory] = React.useState<boolean>(false);

  const allocations = useSelector((state: AppState) => {
    return {
      ...state.pages.allocations,
      ...state.pages.modelPortfolios,
    } as { [id: string]: Allocation };
  });

  const allocation = allocations[props.id];
  if (allocation === undefined) {
    return <React.Fragment />;
  }

  const stateHistory = allocation.StateHistory || [];
  const displayHistory = showFullHistory ? stateHistory : take(stateHistory, defaultNumberOfDisplayedItems);
  const provideShowMoreButton = stateHistory.length > defaultNumberOfDisplayedItems && !showFullHistory;

  return (
    <Root>
      <Typography variant="h3" className={classes.headerGutter}>
        Version history
      </Typography>
      <div
        style={{
          overflow: "auto",
          height: 700,
          marginBottom: 40,
        }}
      >
        {displayHistory && (
          <div>
            {displayHistory.map((element, i) => {
              return (
                <div key={i} className={classes.historyElementContainer}>
                  <div className={classes.historyElementVerticalLine}></div>
                  <div className={classes.historyElement}>
                    <svg height="30" width="30">
                      <circle cx="10" cy="10" r="9.5" fill="#001489" />
                      <circle cx="10" cy="10" r="8.5" fill="white" />
                      <circle cx="10" cy="10" r="7" fill="#001489" />
                    </svg>
                    <div>
                      <strong>{toDateTimeString(`${element.ExecutedAt}`, true)}</strong>
                      <br />
                      {displayLabel(element)}
                      <br />
                      {element.ExecutedBy}
                      <br />
                      {element.DestinationStates && <Destinations states={element.DestinationStates} />}
                    </div>
                  </div>
                </div>
              );
            })}
            <div key="statestart" className={classes.historyElement}>
              {provideShowMoreButton && (
                <Button size="medium" className={classes.showMoreButton} onClick={() => setShowFullHistory(true)}>
                  Show more
                </Button>
              )}
              {!provideShowMoreButton && (
                <React.Fragment>
                  <svg height="20" width="30">
                    <circle cx="10" cy="10" r="10" fill="#001489" />
                  </svg>
                  <div>
                    <strong>Allocation initialized</strong>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      </div>
    </Root>
  );
}

function displayLabel(element: StateHistory): string {
  if (element.State === "Published" && element.SnapshotAsOfDate) {
    return "Published with as of date: " + toShortDateString(element.SnapshotAsOfDate);
  }

  if (element.State === "Draft" && element.Trigger === "Reject_publish") {
    const asOfDate = element.SnapshotAsOfDate ? " with as of date: " + toShortDateString(element.SnapshotAsOfDate) : "";
    const partialSuccess = (element.DestinationStates || []).some((s) => s.Success);
    return partialSuccess ? `Published (partially)${asOfDate}` : "Publish attempt failed";
  }

  if (element.State === "Draft" && element.Trigger === "Save") {
    return "Edited";
  }

  return transformLabel("state", element.State);
}

function Destinations(props: { states: DestinationState[] }) {
  const { states } = props;

  return (
    <>
      {states.map((s) => (
        <React.Fragment key={s.Destination}>
          {s.Destination}: {s.Success ? "success" : "failure"}
          <br />
        </React.Fragment>
      ))}
    </>
  );
}
