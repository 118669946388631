import React from "react";
import DistributionsTable from "./DistributionsTable";
import { RequestsInfo } from "./RequestsInfo";

export function DynamicWeights() {
  return (
    <>
      <RequestsInfo />
      <DistributionsTable />
    </>
  );
}
