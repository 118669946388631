import React from "react";
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import Instrument from "../../../types/Instrument";
import SimpleAutocomplete from "../../shared/SimpleAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/AppState";
import cloneDeep from "lodash/fp/cloneDeep";
import { addInstrument } from "../slice";
import { getIcStructureMapping } from "../apiRequests";

const PREFIX = 'AddInstrumentDialog';

const classes = {
  dialog: `${PREFIX}-dialog`,
  dialogPaper: `${PREFIX}-dialogPaper`,
  actions: `${PREFIX}-actions`
};

const RootDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.dialog}`]: {
    minWidth: "20rem",
  },

  [`& .${classes.dialogPaper}`]: {
    // needed, so the dropdowns from the autocomplete are not clipped
    overflow: "visible",
  },

  [`& .${classes.actions}`]: {
    paddingRight: "24px", // align with content padding
    paddingBottom: theme.spacing(2),
  }
})) as typeof Dialog;

export default function AddInstrumentDialog(props: { instrument: Instrument; close: () => void }) {
  const dispatch = useDispatch();
  const riskCurrencies = useSelector((state: AppState) => state.masterData.instrumentCurrencies);
  const assetSubClasses = useSelector((state: AppState) => state.masterData.allocationSubAssetClasses);
  const [selectedRiskCurrency, setSelectedRiskCurrency] = React.useState<string | undefined>();
  const [selectedAssetSubClass, setSelectedAssetSubClass] = React.useState<string | undefined>();

  const isValid = selectedRiskCurrency !== undefined && selectedAssetSubClass !== undefined;

  React.useEffect(() => {
    if (props.instrument.RiskCurrency && riskCurrencies.includes(props.instrument.RiskCurrency)) {
      setSelectedRiskCurrency(props.instrument.RiskCurrency);
    }
    if (props.instrument.AssetSubClass && assetSubClasses.includes(props.instrument.AssetSubClass)) {
      setSelectedAssetSubClass(props.instrument.AssetSubClass);
    }
  }, []);

  async function addInstrumentToCompareView() {
    const assetSubClassOverride = selectedAssetSubClass !== props.instrument.AssetSubClass ? selectedAssetSubClass : undefined;
    const riskCurrencyOverride = selectedRiskCurrency !== props.instrument.RiskCurrency ? selectedRiskCurrency : undefined;

    let assetTreeMapping = props.instrument.AssetTreeMapping;

    if (!!assetSubClassOverride || !!riskCurrencyOverride) {
      const assetClass = assetSubClassOverride?.substr(0, 2);
      const effectiveAssetSubClass = assetSubClassOverride || props.instrument.AssetSubClass!;
      const effectiveRiskCurrency = riskCurrencyOverride || props.instrument.RiskCurrency!;
      const icStructureMapping = await getIcStructureMapping(assetClass, effectiveAssetSubClass, effectiveRiskCurrency);
      assetTreeMapping = { ...props.instrument.AssetTreeMapping, ...icStructureMapping };
    }

    dispatch(
      addInstrument({
        ...props.instrument,
        AssetTreeMapping: assetTreeMapping,
        assetSubClassOverride,
        riskCurrencyOverride,
      })
    );
    props.close();
  }

  return (
    <RootDialog open={true} onClose={props.close} className={classes.dialog} PaperProps={{ className: classes.dialogPaper }}>
      <DialogTitle>
        Add <strong>- {props.instrument.ShortName} -</strong>
        <br />
        <Typography color="textSecondary">{props.instrument.LongNamePretty || props.instrument.LongName}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <Typography variant="body2">
            Please confirm the Asset Sub Class and Risk Currency or
            <br /> adjust the values.
          </Typography>
        </Box>
        <Box mt={2}>
          <SimpleAutocomplete
            label="Asset Sub Class"
            items={assetSubClasses}
            onSelect={setSelectedAssetSubClass}
            initialSelectedItem={selectedAssetSubClass}
            fullWidth
          />
        </Box>
        <Box mt={2}>
          <SimpleAutocomplete
            label="Risk Currency"
            items={riskCurrencies}
            onSelect={setSelectedRiskCurrency}
            initialSelectedItem={selectedRiskCurrency}
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={props.close} disableFocusRipple>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={addInstrumentToCompareView}
          disabled={!isValid}
          autoFocus
          disableFocusRipple
        >
          Add
        </Button>
      </DialogActions>
    </RootDialog>
  );
}
