import React from "react";
import useCompareState from "./useCompareState";
import { keepSnapshotInEdit } from "./apiRequests";

/**
 * Simple hook that periodically ensures that allocations in edit state do not timeout.
 */
export default function useKeepEditAlive() {
  const { select } = useCompareState();

  React.useEffect(() => {
    let timer: any;
    async function keepAlive() {
      const inEditIds = select((s) => s.allocations.filter((_: any, idx: string | number) => s.editInfo[idx].isInEditByUser)).map((a: { Id: any; }) => a.Id);
      try {
        await Promise.all(inEditIds.map(keepSnapshotInEdit));
      } catch (err) {
        console.error(err);
      }
      timer = setTimeout(keepAlive, 60 * 1000);
    }

    keepAlive();
    return () => clearTimeout(timer);
  }, []);
}
