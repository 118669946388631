import Allocation from "../../../types/Allocation";
import { prettyPrintAllocationType } from "../../../types/AllocationType";
import { EditInfo } from "../types";

/**
 * Explains the reason why an allocation cannot be edited, or that it can be edited.
 */
export default function editActionTooltipText(allocation: Allocation, editInfo: EditInfo) {
  const { isInEditByUser, isEditable, isInEdit } = editInfo;
  const allocationLabel = prettyPrintAllocationType(allocation.AllocationType);

  if (allocation.IsDeleted) {
    return "The allocation is set to deleted and is not editable. You must reactivate the allocation in order to edit.";
  } else if (isInEditByUser) {
    return "";
  } else if (allocation.IsEditable === false && isEditable === false) {
    return `${allocationLabel} cannot be edited due to being maintained in Alpima.`;
  } else if (isInEdit && !isEditable && allocation.IsEditable) {
    return `${allocationLabel} is currently in edit by another user.`;
  } else if (allocation.IsInEditableState == false && allocation.State?.ShortName === "Queued") {
    return `${allocationLabel} can not be edited, because it is already queued for publish.`;
  } else if (allocation.State?.ShortName === "PublishPending") {
    return `${allocationLabel} can not be edited, because it's being published.`;
  } else if (!isEditable && !allocation.IsOwner && allocation.IsEditable) {
    return `${allocationLabel} is owned by another user or group and may not be edited by you.`;
  } else if (isEditable) {
    return `Edit ${allocationLabel} exclusively.`;
  } else {
    return "Unkown state. Should not happen.";
  }
}
