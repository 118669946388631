import { AllocationPageType, allocationPageTypeToResultKey } from "./AllocationPageType";


/**
 * The different allocation types in TST, aligned with backend and DB.
 */
enum AllocationType {
  Unknown = 0,
  ModelPortfolio = 1,
  Taa = 2,
  Saa = 3,
  Tca = 5,
}

/**
 * Utility function to create the 'resultKey' used in the reducers baded on the given AllocationType.
 * @returns either 'modelPortolios' or 'allocations'
 */
export const allocationTypeToResultKey = (value: AllocationType): string => {
  const pageType =
    value == AllocationType.ModelPortfolio
      ? AllocationPageType.ModelPortfolio
      : AllocationPageType.Allocation;
  return allocationPageTypeToResultKey(pageType);
};

export const prettyPrintAllocationType = (value: AllocationType): string => {
  if (value == null || value == undefined) {
    return "";
  }

  if (value == AllocationType.ModelPortfolio) {
    return "Model Portfolio";
  } else {
    return AllocationType[value].toUpperCase();
  }
};

export default AllocationType;
