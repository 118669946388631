import React from "react";
import { styled } from '@mui/material/styles';
import { FormattedWeightDates, ViewOptions } from "./types";
import { cellStyle } from "./commonStyles";

const PREFIX = 'WeightHeaders';
const classes = {
  root: `${PREFIX}-root`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    "& > div": cellStyle(theme),
    "& span": {
      fontSize: "smaller",
    },
  }
}));

export default function WeightHeaders(props: { dates: FormattedWeightDates; options: ViewOptions; showSaved: boolean }) {
  const { dates, showSaved } = props;
  const { showIc, showDyn, showAvg } = props.options;
  return (
    <Root className={classes.root}>
      {showIc && (
        <div>
          IC %<br />
          <span>{dates.ic}</span>
        </div>
      )}
      <div>
        Pub %<br />
        <span>{dates.published}</span>
      </div>
      {showDyn && (
        <div>
          Dyn %<br />
          <span>{dates.dynamic}</span>
        </div>
      )}
      {showAvg && (
        <div>
          Avg %<br />
          <span>{dates.average}</span>
        </div>
      )}
      {showSaved && (
        <div>
          Saved %<br />
          <span>{dates.saved}</span>
        </div>
      )}
    </Root>
  );
}
