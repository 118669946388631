import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import { Location as RouterLocation } from "@remix-run/router";
import { PagesState, Pageination, AllocationFilters, ModelPortfolioFilters, SelectedAllocation } from "./PagesState";
import { filterAndSortIds } from "./pages-selector";
import { chunk } from "../../modules/array-helper";
import { removeSortKeyFromId } from "../../modules/helpers";
import AllocationType, { allocationTypeToResultKey } from "../../types/AllocationType";
import Distribution from "../../types/Distribution";
import ModelPortfolio from "../../types/ModelPortfolio";
import { createAllocationCreateSuccess } from "../admin/admin-slice";
import queryString from "./pages-querystring";
import isEqual from "lodash/isEqual";
import AllocationSource from "../../types/AllocationSource";
import { initialState } from "./pages-commons";
import { AllocationPageType, allocationPageTypeToResultKey } from "../../types/AllocationPageType";

const pagesSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    pageRequest(state, action: PayloadAction<any>) {
      state.pagination[action.payload.resultKey].pages[action.payload.page] = {
        ids: [],
        fetching: true,
      };
    },
    pageSuccess(state, action: PayloadAction<any>) {
      const { payload } = action;
      const data = Object.assign(
        {},
        ...Object.keys(payload.data).map((key) => ({
          [removeSortKeyFromId(key)]: payload.data[key],
        }))
      );

      let target: Pageination<AllocationFilters> | Pageination<ModelPortfolioFilters> = state.pagination[payload.resultKey];

      target.totalCount = payload.totalCount;
      target.currentPage = payload.page;
      target.pages[payload.page] = {
        ids: Object.keys(payload.data),
      };
      state[payload.resultKey] = data;
      updatePagesState(state, payload);
    },
    pageFailure(state, action: PayloadAction<any>) {
      // NoOp
    },
    // data can either be a full allocation or only parts of it (e.g. when updating a comment).
    detailPageUpdateSuccess(state, action: PayloadAction<{ data: any; resultKey: any }>) {
      let allocation = state[action.payload.resultKey][action.payload.data.Id];
      state[action.payload.resultKey][action.payload.data.Id] = {
        ...allocation,
        ...action.payload.data,
      };
      updatePagesState(state, action.payload);
    },
    detailPageUpdateFailure(state, action: PayloadAction<any>) {
      // NoOp
    },
    changePageSize(state, action: PayloadAction<{ pageSize: number; pageType: AllocationPageType }>) {
      let resultKey = allocationPageTypeToResultKey(action.payload.pageType);
      let target = state.pagination[resultKey];
      target.currentPage = 0;
      target.pageSize = action.payload.pageSize;
      target.totalCount = 0;
      // TODO: WHY?
      target.pages = [];

      updatePagesState(state, action.payload);
    },
    changePage(state, action: PayloadAction<{ page: number; pageType: AllocationPageType }>) {
      const resultKey = allocationPageTypeToResultKey(action.payload.pageType);
      state.pagination[resultKey].currentPage = action.payload.page;
    },
    setSortOrder(state, action: PayloadAction<{ columnId: string; pageType: AllocationPageType }>) {
      const resultKey = allocationPageTypeToResultKey(action.payload.pageType);
      const { payload } = action;

      let currentOrder: "asc" | "desc" = "desc";
      if (state.pagination[resultKey].order !== undefined && state.pagination[resultKey].order.order !== undefined) {
        if (state.pagination[resultKey].order.columnId === payload.columnId) {
          currentOrder = state.pagination[resultKey].order.order;
        }
      }
      currentOrder = currentOrder === "desc" ? "asc" : "desc";

      state.pagination[resultKey].order.columnId = payload.columnId;
      state.pagination[resultKey].order.order = currentOrder;

      updatePagesState(state, payload);
    },
    removeAllFilter(state, action: PayloadAction<AllocationPageType>) {
      const resultKey = allocationPageTypeToResultKey(action.payload);
      const pagination = state.pagination[resultKey];

      // search box remains untouched (IMSC-6028)
      // TODO - extract search box state from filter state
      pagination.filter = { Name: pagination.filter.Name, Deleted: false };

      updatePagesState(state, { resultKey });
    },
    updateFilter(state, action: PayloadAction<{ id: string; state: any; pageType: AllocationPageType }>) {
      const resultKey = allocationPageTypeToResultKey(action.payload.pageType);
      state.pagination[resultKey].filter[action.payload.id] = action.payload.state;

      updatePagesState(state, action.payload);
    },
    // move reducer somewhere else?! not sure if this is the best place.
    changeDistributionSuccess(state, action: PayloadAction<{ id: string; resultKey: string; distributions: Distribution[] }>) {
      state[action.payload.resultKey][action.payload.id].Distributions = action.payload.distributions;
    },
    applyQueryString(state, action: PayloadAction<{ pageType: AllocationPageType; location: RouterLocation | Location }>) {
      const fromQueryString = queryString.getState(action.payload.location);
      const key = allocationPageTypeToResultKey(action.payload.pageType);
      const curPageState = state.pagination[key];

      state.pagination[key] = { ...curPageState, ...fromQueryString };

      // Since 'updatePages' resets current page to 0,
      // we suppress 'updatePages' if just current pages changes.
      const needsUpdate = !isEqual(
        { ...fromQueryString.filter, ...fromQueryString.order, pageSize: fromQueryString.pageSize },
        { ...curPageState.filter, ...curPageState.order, pageSize: curPageState.pageSize }
      );

      if (needsUpdate) {
        updatePages(state, key);
      }
    },
    addToSelection(state, action: PayloadAction<{ allocation: SelectedAllocation; type: AllocationPageType }>) {
      const resultKey = allocationPageTypeToResultKey(action.payload.type);
      state.selection[resultKey].push(action.payload.allocation);
    },
    removeFromSelection(state, action: PayloadAction<{ allocationId: string; type: AllocationPageType }>) {
      const resultKey = allocationPageTypeToResultKey(action.payload.type);
      state.selection[resultKey] = state.selection[resultKey].filter(
        (selection) => selection.allocationId !== action.payload.allocationId
      );
    },
    /**
     * Toggles the selection within the current page.
     * That means, if nothing is selected, then all items will be selected.
     * And likewise, if one or more items are selected, then all items will be deselected.
     */
    togglePageSelection(state, action: PayloadAction<AllocationPageType>) {
      const resultKey = allocationPageTypeToResultKey(action.payload);
      const selection = state.selection[resultKey];
      const pagination = state.pagination[resultKey];
      const currentPageIds = pagination.pages[pagination.currentPage].ids;
      const selectedPageIds = selection.filter((s) => currentPageIds.includes(s.allocationId)).map((s) => s.allocationId);

      if (selectedPageIds.length > 0) {
        state.selection[resultKey] = selection.filter((s) => !selectedPageIds.includes(s.allocationId));
      } else {
        state.selection[resultKey] = [
          ...selection,
          ...currentPageIds.map((i) => ({
            allocationId: i,
            allocationType: state[resultKey][i].AllocationType,
          })),
        ];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createAllocationCreateSuccess, (state, action) => {
      action.payload.forEach((allocation) => {
        if (allocation.AllocationType === AllocationType.ModelPortfolio) {
          state.modelPortfolios[allocation.Id] = allocation as ModelPortfolio;
          state.pagination.modelPortfolios.totalCount += 1;
          updatePages(state, "modelPortfolios");
        } else {
          state.allocations[allocation.Id] = allocation;
          state.pagination.allocations.totalCount += 1;
          updatePages(state, "allocations");
        }
      });
    });
  },
});

function updatePagesState(state: PagesState, payload: any) {
  let resultKey = payload.resultKey;
  if (!resultKey && payload.pageType !== null && payload.pageType !== undefined) {
    resultKey = allocationPageTypeToResultKey(payload.pageType);
  }
  updatePages(state, resultKey);
}

function updatePages(state: PagesState, resultKey: string) {
  const ids = filterAndSortIds(state[resultKey], state.pagination[resultKey].filter, state.pagination[resultKey].order);
  const idsChunks = chunk(ids, state.pagination[resultKey].pageSize);
  let counter = 0;
  const pages = idsChunks.reduce((map, obj) => {
    map[counter++] = { ids: obj };
    return map;
  }, {});

  let target = state.pagination[resultKey];
  target.pages = pages;
  target.currentPage = 0;
  target.totalCount = ids.length;
}

// Actions to trigger sagas.
export const modelPortfolioPageRequest = (page: number, pagesize: number, order: any, filter: any) => {
  return pagesSlice.actions.pageRequest({
    page,
    pagesize,
    order,
    filter,
    resultKey: "modelPortfolios",
    endpoint: "modelportfolio?allocationTypes=ModelPortfolio",
  });
};

export const allocationPageRequest = (page: number, pagesize: number, order: any, filter: any) => {
  return pagesSlice.actions.pageRequest({
    page,
    pagesize,
    order,
    filter,
    resultKey: "allocations",
    endpoint: "modelportfolio?allocationTypes=Taa&allocationTypes=Saa&allocationTypes=Tca",
  });
};

export const reverseLookupInstrumentRequest = createAction(
  "pages/reverse_lookup_instrument_request",
  (ids: string[], allocationPageType: AllocationPageType) => {
    return {
      payload: {
        ids,
        endpoint: "modelportfolio",
        allocationPageType: allocationPageType,
      },
    };
  }
);

export const toggleStarRequest = createAction(
  "pages/toggle_star_request",
  (id: string, allocationName: string, allocationType: AllocationType, allocationSource: AllocationSource) => {
    const resultKey = allocationTypeToResultKey(allocationType);
    return {
      payload: {
        id,
        allocationName,
        allocationSource,
        allocationType,
        resultKey,
      },
    };
  }
);

export const detailPageDataRequest = createAction("pages/detail_page_data_request", (id: string) => {
  return {
    payload: {
      id,
      endpoint: "modelportfolio",
    },
  };
});

export const {
  pageRequest,
  pageSuccess,
  pageFailure,
  setSortOrder,
  changePageSize,
  changePage,
  updateFilter,
  removeAllFilter,
  detailPageUpdateSuccess,
  detailPageUpdateFailure,
  changeDistributionSuccess,
  applyQueryString,
  addToSelection,
  removeFromSelection,
  togglePageSelection,
} = pagesSlice.actions;

export default pagesSlice.reducer;
