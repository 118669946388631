import React from "react";
import { styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import { ProductStatus, ProductDataStatus } from "../utils";
import StatusLabel from "../StatusLabel";
import InfoBox from "../../shared/InfoBox";

const PREFIX = 'ProductStatusPopup';
const classes = {
  dialog: `${PREFIX}-dialog`,
  title: `${PREFIX}-title`,
  actions: `${PREFIX}-actions`,
  details: `${PREFIX}-details`
};
const StyledDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`&.${classes.dialog}`]: {
    maxHeight: "75vh",
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "70rem",
    },
    "& .MuiDialogContent-root": {
      overflowY: "auto", // keeps the title and action bar in place if the content exceeds height
    },
  },

  [`& .${classes.title}`]: {
    paddingBottom: theme.spacing(2),
  },

  [`& .${classes.actions}`]: {
    paddingRight: theme.spacing(3), // align with content padding
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    justifyContent: "space-between",
  },

  [`& .${classes.details}`]: {
    color: theme.palette.text.secondary,
    paddingInlineStart: theme.spacing(2) + "px",
    "& li": {
      paddingBottom: theme.spacing(1),
    },
  }
}));

/**
 * Popup showing the validation messages for one or more products.
 */
export default function ProductStatusPopup(props: { data: ProductStatus[]; onClose: () => void }) {
  return (
    <StyledDialog open className={classes.dialog}>
      <DialogTitle color="primary" className={classes.title}>
        Validation Errors &amp; Warnings
      </DialogTitle>
      <DialogContent>
        {props.data.map((product) => (
          <Box marginBottom={3}>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {product.ProductLine} {product.Currency} {product.InvestmentStrategy}
            </Typography>
            {product.ValidationMessages.map((m) => (
              <Box display="flex" marginY={1}>
                <Typography variant="body2" style={{ width: "17rem", flex: "none" }}>
                  <StatusLabel status={m.IsError ? ProductDataStatus.Error : ProductDataStatus.Warning} />
                </Typography>
                <Box paddingLeft={4}>
                  <Typography variant="body2">{m.Message}</Typography>
                  {m.Hint && (
                    <Typography variant="body2" color="textSecondary">
                      {m.Hint}
                    </Typography>
                  )}
                  <ul className={classes.details}>
                    {m.Details &&
                      m.Details.map((d) => (
                        <li>
                          <Typography variant="body2" color="textSecondary">
                            {d}
                          </Typography>
                        </li>
                      ))}
                  </ul>
                </Box>
              </Box>
            ))}
          </Box>
        ))}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <InfoBox>
          Further details on these issues and how to resolve them
          <br />
          can be found{" "}
          <a href="https://wiki.juliusbaer.com/x/vTPuEw" target="_blank" style={{ textDecoration: "underline" }}>
            here
          </a>
          .
        </InfoBox>
        <Button onClick={props.onClose} disableFocusRipple>
          Close
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
