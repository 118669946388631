import { AllocationRowDescriptor } from "../types";
import traversePostOrder from "./traversePostOrder";

/**
 * Sets the instrument count in each category node.
 * Presumes that categories have initial instrument count of 0.
 *
 * @param rows Traversed (depth-first, pre-order) nodes of an `ProjectedAssetTree`.
 */
export default function setInstrumentCount(rows: AllocationRowDescriptor[]) {
  const count: { [level: number]: number } = {}; // keeps track of instruments per level in a subtree

  traversePostOrder(rows, {
    onNewSubtree: (level, row) => {
      count[level] = row.isInstrument ? 1 : 0;
    },
    onSibling: (level, row) => {
      count[level] += row.isInstrument ? 1 : 0;
    },
    onParent: (level, row) => {
      // add the children's count to the overall level count
      count[level] = (count[level] || 0) + count[level + 1];
      // set the node instrument count
      if (!row.isInstrument) {
        row.instrumentCount = count[level + 1];
      }
      // and then reset the children's count
      count[level + 1] = 0;
    },
  });

  return rows;
}
