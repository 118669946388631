import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Big, { BigType } from "big.js";
import { clear, setAllocations } from "./slice";
import Allocation from "../../types/Allocation";
import { AssetTree } from "../../types/AssetTree";
import { UserState } from "../../store/user/UserState";
import { hideLoadingScreen, showLoadingScreen } from "../../store/loading/loading-slice";
import { showAlert } from "../../store/app/app-slice";
import * as api from "./apiRequests";
import Instrument from "../../types/Instrument";

/**
 * Hook encapsulating the initial data fetch for the compare view.
 */
export default function useInitialLoad() {
  const dispatch = useDispatch();
  const ids = useParams<{ ids?: string }>().ids;

  React.useEffect(() => {
    async function doFetch() {
      try {
        dispatch(showLoadingScreen());

        const allocationIds = ids?.split(",") || [];
        const { allocations, trees, icCurrencyInstruments, allocationSumTolerance, thresholdAvgDyn, user } = await fetch(
          allocationIds
        );

        dispatch(
          setAllocations({
            allocations,
            trees,
            icCurrencyInstruments,
            user,
            allocationSumTolerance,
            thresholdAvgDyn,
          })
        );
      } catch (err) {
        dispatch(showAlert("Loading allocations failed", { type: "error" }));
        console.error(err);
      } finally {
        dispatch(hideLoadingScreen());
      }
    }

    doFetch();
    return () => {
      // this is required so we don't see the old state during fetch when we come back to the compare view
      dispatch(clear());
    };
  }, [ids]);
}

async function fetch(allocationIds: string[]) {
  const masterDataRequest = api.getMasterData();
  const userRequest = api.getUserInfo();
  const allocationRequests = allocationIds.map(api.getAllocation);

  const [masterData, user, ...allocations] = await Promise.all<any>([masterDataRequest, userRequest, ...allocationRequests]);
  const allocationSumTolerance = new Big(masterData.Settings.AllocationSumTolerance) as BigType;
  const thresholdAvgDyn = new Big(masterData.Settings.ThresholdAvgDyn).div(100) as BigType;

  return {
    allocations: allocations as Allocation[],
    trees: masterData.Trees as AssetTree[],
    icCurrencyInstruments: masterData.IcCurrencyInstruments as Instrument[],
    allocationSumTolerance,
    thresholdAvgDyn,
    user: {
      userLocation: user.UserLocation,
      isAdmin: user.IsAdmin,
      canChangePortfolios: user.IsAdmin || user.IsSuperUser,
    } as UserState,
  };
}
