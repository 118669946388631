/* global window, document */

interface PiwikConfig {
  url: string;
  siteId: number;
  trackErrors: boolean;
}

interface PiwikSettings extends PiwikConfig {
  enableLinkTracking: boolean;
  trackDocumentTitle: boolean;
  jsFilename: string;
  phpFilename: string;
}

const devConfig: PiwikConfig = {
  url: "//analytics-dev.juliusbaer.com/",
  siteId: 16,
  trackErrors: true,
};

const prodConfig: PiwikConfig = {
  url: "//analytics.juliusbaer.com/",
  siteId: 71,
  trackErrors: true,
};

const uatConfig: PiwikConfig = {
  url: "//analytics-test.juliusbaer.com/",
  siteId: 47,
  trackErrors: true,
};

export const configuredPiwik = () => {
  let { hostname } = document.location;
  hostname = hostname.toLowerCase();
  let piwik = null;
  if (
    hostname === "tst-fe-web" ||
    hostname === "tst-fe-web.juliusbaer.com" ||
    hostname === "tst" ||
    hostname === "tst.juliusbaer.com"
  ) {
    piwik = new Piwik(prodConfig);
  } else if (
    hostname.includes("tst-fe-web-at") ||
    hostname.includes("tst-fe-web-uat") ||
    hostname.includes("tst-at") ||
    hostname.includes("tst-uat")
  ) {
    piwik = new Piwik(uatConfig);
  } else {
    piwik = new Piwik(devConfig);
  }

  piwik.initPiwik();

  Piwik.push(["enableHeartBeatTimer"]);
  Piwik.push(["trackPageView"]);

  return piwik;
};

export class Piwik {
  options: PiwikSettings;

  previousPath!: string;

  unlistenFromHistory: any;

  constructor(opts: PiwikConfig) {
    this.options = {
      enableLinkTracking: true,
      trackDocumentTitle: true,
      jsFilename: "piwik.js",
      phpFilename: "piwik.php",
      ...opts,
    };

    if (this.options.url === undefined || this.options.siteId === undefined) {
      throw new Error("PiwikTracker cannot be initialized! SiteId and url are mandatory.");
    }
  }

  initPiwik() {
    if (typeof window !== "undefined") {
      let { url } = this.options;

      if (url.endsWith("/") === false) {
        url = `${url}/`;
      }

      if (url.startsWith("http://") === false || url.startsWith("https://") === false) {
        url = document.location.protocol === "https:" ? `https://${url}` : `http://${url}`;
      }

      (window as any)._paq = (window as any)._paq || []; // eslint-disable-line  no-underscore-dangle

      Piwik.push(["setSiteId", this.options.siteId]);
      Piwik.push(["setTrackerUrl", `${url + this.options.phpFilename}`]);
      if (this.options.enableLinkTracking) {
        Piwik.push(["enableLinkTracking"]);
      }

      const scriptElement = document.createElement("script");
      const [refElement] = document.getElementsByTagName("script") as any;
      scriptElement.type = "text/javascript";
      scriptElement.defer = true;
      scriptElement.async = true;

      let { jsFilename } = this.options;

      if (jsFilename.startsWith("http://") === false && jsFilename.startsWith("https://") === false) {
        jsFilename = url + jsFilename;
      }

      scriptElement.src = jsFilename;
      refElement.parentNode.insertBefore(scriptElement, refElement);
    }

    return {
      //@ts-ignore  - ignore type error, so we don't change the behaviour during the typescript migration
      push: this.push,
      track: this.track,
      connectToHistory: this.connectToHistory,
      disconnectFromHistory: this.disconnectFromHistory,
    };
  }

  static push(args: any) {
    ((window as any)._paq as any).push(args); // eslint-disable-line  no-underscore-dangle
  }

  connectToHistory(history: any) {
    const prevLoc = typeof history.getCurrentLocation === "undefined" ? history.location : history.getCurrentLocation();
    this.previousPath = prevLoc.path || (prevLoc.pathname + prevLoc.search).replace(/^\//, "");
    this.unlistenFromHistory = history.listen((loc: any) => {
      this.track(loc);
    });

    return history;
  }

  disconnectFromHistory() {
    if (this.unlistenFromHistory) {
      this.unlistenFromHistory();
      return true;
    }
    return false;
  }

  track(loc: any) {
    if (typeof window === "undefined") {
      return;
    }

    const currentPath = loc.path || (loc.pathname + loc.search).replace(/^\//, "");

    if (this.previousPath === currentPath) {
      return;
    }

    if (this.options.trackDocumentTitle) {
      Piwik.push(["setDocumentTitle", document.title]);
    }

    if (this.previousPath) {
      Piwik.push(["setReferrerUrl", `${window.location.origin}/${this.previousPath}`]);
    }

    Piwik.push(["setCustomUrl", `${window.location.origin}/${currentPath}`]);
    Piwik.push(["trackPageView"]);
    this.previousPath = currentPath;
  }
}
