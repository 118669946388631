import * as React from "react";
import Big from "big.js";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { isBigNumber } from "../../../../modules/asset-tree-builder";
import { CheckIcon, AlertIcon } from "../../../shared/Icons";
import { ResolvedInstrument } from "../../../shared/types";

export default React.memo(function (props: { data: ResolvedInstrument[] }) {
  const theme = useTheme();
  const space = theme.spacing(1);
  const resolvedColor = theme.palette.text.secondary;
  const cellStyle = { width: 100, paddingLeft: space, paddingRight: space };
  const total = props.data.length;
  const errors = props.data.filter((r) => !r.hasRiskCcy).length;
  const valid = total - errors;
  const allValid = errors === 0;

  return (
    <TableContainer style={{ maxHeight: "60vh", overflowY: "scroll" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="left" style={cellStyle}>
              Valor / ISIN
            </TableCell>
            <TableCell align="left" style={cellStyle}>
              Weight
            </TableCell>
            <TableCell>{allValid ? "Resolved Completely" : `Resolved ${valid} / ${total}`}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => {
            return (
              <TableRow key={row.pasteId}>
                <TableCell align="left" style={cellStyle}>
                  {row.pasteId}
                </TableCell>
                <TableCell align="left" style={cellStyle}>
                  {isBigNumber(row.weight) && `${new Big(row.weight).times(100).toFixed(2)}%`}
                </TableCell>
                <TableCell style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: space }}>
                    {(row.hasRiskCcy && <CheckIcon htmlColor="green" />) || <AlertIcon color="error" />}
                  </div>
                  <div style={{ color: row.hasRiskCcy ? resolvedColor : "inherit" }}>
                    {row.hasRiskCcy
                      ? `${row.instrument?.ShortName} / ${row.instrument?.RiskCurrency}`
                      : row.resolved
                      ? "Risk currency missing"
                      : "Instrument not found"}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
