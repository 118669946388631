import React from "react";
import { Box, Typography } from "@mui/material";
import BackLink from "../shared/BackLink";
import useCompareSelector from "./useCompareSelector";
import AllocationType from "../../types/AllocationType";

/**
 * Simple page header with backlink.
 */

export default function PageHeader() {
  const isMopo = useCompareSelector((s) => s.allocationType === AllocationType.ModelPortfolio);
  const multiple = useCompareSelector((s) => s.allocations.length > 1);

  const target = isMopo ? "/modelportfolios" : "/allocations";
  let header = !isMopo ? "Allocation" : "Model Portfolio";
  if (multiple) {
    header += "s";
  }

  return (
    <>
      <Box mb={2}>
        <BackLink targetPath={target} />
      </Box>
      <Typography variant="h2">{header} | View &amp; Compare</Typography>
    </>
  );
}
