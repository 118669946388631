import * as React from "react";
import { CommonFilters, Pageination, SelectedAllocation } from "../../store/pages/PagesState";
import { Grid, Table, TableBody, TableCell, TablePagination, TableRow } from "@mui/material";
import { MasterDataState } from "../../store/masterData/MasterDataState";
import { setSortOrder, changePage, changePageSize, togglePageSelection } from "../../store/pages/pages-slice";
import { styled } from "@mui/material/styles";
import Allocation from "../../types/Allocation";
import AllocationFilterPanel from "./AllocationFilterPanel";
import AllocationSortToolbar from "./AllocationSortToolbar";
import AllocationComparePanel from "./AllocationComparePanel";
import AllocationTableRow from "./AllocationTableRow";
import { useDispatch } from "react-redux";
import { AllocationPageType } from "../../types/AllocationPageType";
import { ColumnData } from "../../types/AllocationTable";

const PREFIX = 'AllocationTableContainer';
const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  firstGrid: `${PREFIX}-firstGrid`,
  panelContainer: `${PREFIX}-panelContainer`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    marginTop: theme.spacing(3),
  },

  [`& .${classes.table}`]: {
    minWidth: 1200,
    width: "100%",
  },

  [`& .${classes.firstGrid}`]: {
    paddingLeft: "0 !important",
    marginLeft: "0 !important",
  },

  [`& .${classes.panelContainer}`]: {
    display: "flex",
    flexWrap: "wrap" as "wrap",
    paddingRight: theme.spacing(3),
    "& > div": {
      marginBottom: theme.spacing(4),
    },
  }
}));

export default function AllocationTableContainer(props: {
  masterData: MasterDataState;
  pagination: Pageination<CommonFilters>;
  allocations: Record<string, Allocation>;
  page: number;
  pageSize: number;
  totalCount: number;
  selection: SelectedAllocation[];
  filters: CommonFilters;
  sort: { columnId: string; order: "asc" | "desc" };
  loadPage: (page: number) => void;
  columnData: ColumnData;
  allocationPageType: AllocationPageType;
}) {
  const dispatch = useDispatch();
  const {
    allocations,
    pagination,
    pageSize,
    page,
    totalCount,
    selection,
    sort,
    columnData,
    masterData,
    filters,
    allocationPageType,
  } = props;

  const allos = (pagination.pages[page]?.ids || []).map((id) => allocations[id]).filter((a) => !!a);
  const isEmpty = allos.length === 0;

  const handleChangePage = React.useCallback(
    (_event: any, page: number) => dispatch(changePage({ page, pageType: allocationPageType })),
    [allocationPageType]
  );

  const handleChangeRowsPerPage = React.useCallback(
    (event: any) => dispatch(changePageSize({ pageSize: event.target.value, pageType: allocationPageType })),
    [allocationPageType]
  );

  const toggleSelection = React.useCallback(() => dispatch(togglePageSelection(allocationPageType)), [allocationPageType]);

  const handleRequestSort = React.useCallback(
    (property: string) => dispatch(setSortOrder({ columnId: property, pageType: allocationPageType })),
    [allocationPageType]
  );

  React.useEffect(() => {
    if (pagination[page]?.ids?.length || 0 === 0) {
      props.loadPage(page);
    }
  }, []);

  return (
    <Root className={classes.root}>
      <Grid container spacing={0}>
        <Grid item sm={2} xs={12} className={classes.firstGrid}>
          <div className={classes.panelContainer}>
            <AllocationComparePanel allocationPageType={allocationPageType} />
            <AllocationFilterPanel masterData={masterData} filters={filters} allocationPageType={allocationPageType} />
          </div>
        </Grid>
        <Grid item sm={10} xs={12}>
          <div>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <AllocationSortToolbar
                numSelected={selection.length}
                sortOrder={sort.order}
                sortedColumnId={sort.columnId}
                onRequestSort={handleRequestSort}
                rowCount={totalCount}
                columnData={columnData}
                changeSelection={toggleSelection}
              />
              <TableBody>
                {isEmpty ? (
                  <TableRow key="empty">
                    <TableCell style={{ textAlign: "center" }} colSpan={14}>
                      <h3>No records</h3>
                    </TableCell>
                  </TableRow>
                ) : (
                  allos.map((a) => (
                    <AllocationTableRow
                      key={a.Id}
                      allocation={a}
                      columnData={columnData}
                      isSelected={isSelected(selection, a.Id)}
                      allocationPageType={allocationPageType}
                    />
                  ))
                )}
              </TableBody>
            </Table>
          </div>
          {!isEmpty && (
            <TablePagination
              component="div"
              count={totalCount}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[25, 50, 100, 250, 500]}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Grid>
    </Root>
  );
}

function isSelected(selection: SelectedAllocation[], allocationId: string) {
  return selection.map((i) => i.allocationId).includes(allocationId);
}
