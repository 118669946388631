import * as React from "react";
import { Button, Tab, Tabs, DialogActions, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useDispatch } from "react-redux";
import BarChart from "./ExposureChart";
import TabPanel from "../../../shared/TabPanel";
import { alignExposures } from "./utility";
import { AllocationExposure } from "./types";
import Allocation from "../../../../types/Allocation";
import useCompareSelector from "../../useCompareSelector";
import { hideLoadingScreen, showLoadingScreen } from "../../../../store/loading/loading-slice";
import { showAlert } from "../../../../store/app/app-slice";
import { getPublishedExposure, getDraftExposure } from "../../apiRequests";

const empty: AllocationExposure = { published: [], draft: [] };

export default function ExposureDialog(props: { close: () => void; allocation: Allocation }) {
  const { allocation, close } = props;
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = React.useState(0);
  const [exposures, setExposures] = React.useState<{ currency: AllocationExposure; sector: AllocationExposure }>({
    currency: empty,
    sector: empty,
  });

  const savedWeights = useCompareSelector((s) => {
    const allocationIdx = s.allocations.findIndex((a: { Id: string; }) => a.Id === allocation.Id);
    return s.visibleRows
      .filter((r: { isInstrument: any; }) => r.isInstrument)
      .map((r) => ({ instrumentId: (r.isInstrument && r.id) || "", weight: s.columns[allocationIdx].cells[r.rowId].saved }));
  });

  React.useEffect(() => {
    async function doFetch() {
      try {
        dispatch(showLoadingScreen());
        const [published, draft] = await Promise.all([getPublishedExposure(allocation.Id), getDraftExposure(savedWeights)]);

        setExposures({
          currency: alignExposures({ published: published.currency, draft: draft.currency }),
          sector: alignExposures({ published: published.sector, draft: draft.sector }),
        });
      } catch (err) {
        dispatch(showAlert("Fetching allocation exposure data failed", { type: "error" }));
        console.error(err);
      } finally {
        dispatch(hideLoadingScreen());
      }
    }

    doFetch();
  }, []);

  return (
    <Dialog open={true} onClose={close} maxWidth={false}>
      <DialogTitle>{allocation.Name}</DialogTitle>
      <DialogContent>
        <Tabs value={currentTab} onChange={(_, value) => setCurrentTab(value)}>
          <Tab label="Currency Exposure" />
          <Tab label="Sector Exposure" />
        </Tabs>
        <div style={{ display: "flex" }}>
          <TabPanel value={currentTab} index={0}>
            <BarChart data={exposures.currency} />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <BarChart data={exposures.sector} />
          </TabPanel>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
