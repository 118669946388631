import * as React from "react";
import classNames from "classnames";
import { styled } from "@mui/material/styles";
import nyan from "../../../assets/images/nyan.gif";

/**
 * Wait, whoot?
 */

const PREFIX = 'Nyan';
const classes = {
    nyan: `${PREFIX}-nyan`,
    reverse: `${PREFIX}-reverse`,
    mirrored: `${PREFIX}-mirrored`,
    growing: `${PREFIX}-mirrored`,
    rotating: `${PREFIX}-searchInputContainer`,
    leftToRightAnim: `@keyframes left-to-right-anim`,
    growingAnim: `@keyframes growing-anim`,
    rotatingAnim: `@keyframes rotating-anim`,
}
const Root = styled('div')(({ theme }) => ({
    [`&.${classes.nyan}`]: {
        position: "fixed",
        width: "100%",
        left: "-100px",
        animation: "$left-to-right-anim 10s",
        zIndex: 100,
    },
    [`&.${classes.reverse}`]: {
        animationDirection: "reverse",
    },
    [`&.${classes.mirrored}`]: {
        transform: "scaleX(-1)"
    },
    [`&.${classes.growing}`]: {
        width: "100px",
        animation: "$growing-anim 10s",
    },
    [`&.${classes.rotating}`]: {
        animation: "$rotating-anim 10s",
    },
    [`&.${classes.leftToRightAnim}`]: {
        from: { left: "-100px" },
        to: { left: "100%" },
    },
    [`&.${classes.growingAnim}`]: {
        from: { width: "50px" },
        to: { width: "200px" },
    },
    [`&.${classes.rotatingAnim}`]: {
        from: { transform: "rotate(0deg)" },
        "20%": { transform: "rotate(360deg)" },
        "40%": { transform: "rotate(0deg)" },
        "60%": { transform: "rotate(360deg)" },
        to: { transform: "rotate(360deg)" },
    },
}));

export default function Nyan() {
  const [cats, emitCat] = useCats();
  const [isStarted, setIsStarted] = React.useState(false);

  return (
    <>
      <div>
        <img style={{ width: 400 }} src={nyan} onClick={startNyan} />
        developed by Nyan Nyan Cat
      </div>
      <div style={{ width: "100%" }}>{cats}</div>
    </>
  );

  function startNyan() {
    if (!isStarted) {
      setIsStarted(true);
      run();
    }
  }

  function run() {
    emitCat("body");
    setTimeout(run, randomBytes(1) * 2 + 500);
  }
}

export function useCats(): [JSX.Element[], (target: "header" | "body") => void] {
  const [cats, setCats] = React.useState<JSX.Element[]>([]);
  return [
    cats,
    function emitCat(target: "header" | "body") {
      setCats((prev) => [
        ...prev,
        target === "header" ? (
          <ACat key={nextCatKey()} special="rotate" />
        ) : (
          <ACat key={nextCatKey()} special="reverse" randomTop randomWidth />
        ),
      ]);
      setTimeout(() => setCats((prev) => prev.slice(1)), 10000);
    },
  ];
}

function ACat(props: { randomWidth?: boolean; randomTop?: boolean; special: "reverse" | "rotate" }) {
  const { randomWidth, randomTop, special } = props;
  const isSpecial = randomBool(special === "reverse" ? 0.4 : 0.2);
  const isReverse = isSpecial && special === "reverse";
  const isRotate = isSpecial && special === "rotate";
  const random = randomBytes(2);
  const top = randomTop ? random[0] * 4 : undefined;
  const width = randomWidth ? random[1] * 1.5 : undefined;
  return (
    <Root style={{ top }} className={classNames(classes.nyan, isSpecial && classes[special])}>
      <img
        src={nyan}
        style={{ width }}
        className={classNames(isReverse && classes.mirrored, !width && classes.growing, isRotate && classes.rotating)}
      />
    </Root>
  );
}

function nextCatKey() {
  return new Date().getMilliseconds();
}

function randomBool(trueProbability: number = 0.5) {
  return Math.random() <= trueProbability;
}

function randomBytes(count: number) {
  const nativeArr = new Uint8Array(count);
  const crypto = window.crypto || (window as any).msCrypto;
  crypto.getRandomValues(nativeArr);
  return [].slice.call(nativeArr) as any;
}
