import React from "react";
import { Box } from "@mui/material";

type SectionProps = {
    children?: React.ReactNode;
}

/**
 * Section box. Usually contains a header and some rows.
 */
const Section: React.FC<SectionProps> = ({ children }) => <Box mb={6}>{children}</Box>;

export default Section;
