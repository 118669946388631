import * as React from "react";
import { styled } from '@mui/material/styles';

const PREFIX = 'LabelValue';
const classes = {
    label: `${PREFIX}-label`,
    value: `${PREFIX}-value`,
}
const Root = styled('div')(({ theme }) => ({
    [`& .${classes.label}`]: {
        display: "block",
        opacity: 0.6,
        fontSize: theme.typography.fontSize * 0.8,
        marginTop: theme.spacing(0.5)
    },
    [`& .${classes.value}`]: {
        display: "block",
        fontSize: theme.typography.fontSize
    },
}));

export default React.memo(function LabelValue({ label, value }: { label: string; value: string }) {
  return (
    <Root className="labelvalue">
      <div className={classes.value}>{value}</div>
      <div className={classes.label}>{label}</div>
    </Root>
  );
});
