import * as React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import cn from "classnames";
import sortBy from "lodash/sortBy";
import { hideLoadingScreen, showLoadingScreen } from "../../../store/loading/loading-slice";
import { showAlert } from "../../../store/app/app-slice";
import PanelDescriptionHeader from "../PanelDescriptionHeader";
import AddGroupMemberInput from "./AddGroupMemberInput";
import AddOrRenameGroupPopup from "./AddOrRenameGroupPopup";
import GroupActiveChangeButton from "./GroupActiveChangeButton";
import { Group } from "./types";
import * as api from "./apiRequests";

const PREFIX = 'GroupPanel';
const classes = {
  root: `${PREFIX}-root`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    "& a": {
      color: theme.palette.primary.main,
    },
    "& > hr": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    "& .group-list": {
      marginBottom: theme.spacing(2),
    },
    "& .group-header": {
      width: "100%",
      "& > div": {
        paddingTop: theme.spacing(1),
        display: "flex",
        flexDirection: "row",
        "& > div": {
          marginRight: theme.spacing(1.5),
        },
      },
    },
    "& .user-list": {
      borderCollapse: "collapse",
      "& body:after": {
        // overwrite global style
        content: "none",
      },
    },
    "& .user-item": {
      verticalAlign: "middle",
      "& td": {
        borderRight: theme.spacing(1) + "px solid rgba(0,0,0,0)",
      },
      "& .user-loginaccount": {
        color: theme.palette.text.secondary,
      },
      "& .user-remove": {
        fontSize: "1.3rem",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: 0,
        paddingBottom: 0,
        visibility: "hidden",
      },
      "&:hover .user-remove": {
        visibility: "visible",
      },
    },
    "& .chip": {
      color: "white",
      width: "fit-content",
      paddingLeft: theme.spacing(0.75),
      paddingRight: theme.spacing(0.75),
      borderRadius: theme.spacing(1),
    },
    "& .chip-info": {
      backgroundColor: theme.palette.info.main,
    },
    "& .chip-warn": {
      backgroundColor: theme.palette.warning.main,
    },
    "& .chip-ok": {
      backgroundColor: theme.palette.success.main,
    },
    "& .metric": {
      color: theme.palette.text.secondary,
      "& > strong": {
        color: theme.palette.text.primary,
      },
    },
  }
}));

export default function GroupPanel() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTwoColumnLayout = useMediaQuery(theme.breakpoints.up("lg"));
  const [groups, setGroups] = React.useState<Group[]>([]);
  const [expandedGroupName, setExpandedGroupName] = React.useState<Maybe<string>>();

  const groupOrder: { [groupName: string]: number } = getGroupOrder(groups, expandedGroupName, isTwoColumnLayout);

  const onExpansionChange = (groupName: string, expanded: boolean) => {
    if (expanded) {
      setExpandedGroupName(groupName);
    } else {
      setExpandedGroupName(undefined);
    }
  };

  const doRefresh = async (newExpandedGroupName?: string) => {
    dispatch(showLoadingScreen());
    try {
      const groups = await api.fetchGroups();
      setGroups(sortBy(groups, (g) => g.GroupName));
      if (newExpandedGroupName) {
        setExpandedGroupName(newExpandedGroupName);
      }
    } catch (e) {
      console.error("doRefresh failed", e);
      dispatch(showAlert("Failed to load groups", { type: "error" }));
    } finally {
      dispatch(hideLoadingScreen());
    }
  };

  const doRemoveGroupMember = async (groupId: number, userId: number) => {
    dispatch(showLoadingScreen());
    try {
      await api.removeGroupMember(groupId, userId);
      dispatch(showAlert("Removed user from group", { type: "success" }));
      await doRefresh();
    } catch (e) {
      console.error("doRemoveGroupMember failed", e);
      dispatch(showAlert("Failed to remove group member", { type: "error" }));
    } finally {
      dispatch(hideLoadingScreen());
    }
  };

  React.useEffect(() => {
    doRefresh();
  }, []);

  return (
    <Root className={classes.root}>
      <PanelDescriptionHeader button={<AddOrRenameGroupPopup groups={groups} onChange={(groupName) => doRefresh(groupName)} />}>
        Manage user groups and group membership.
      </PanelDescriptionHeader>
      <div className="group-list">
        <Grid container spacing={2}>
          {groups.map((g) => (
            <Grid
              item
              xs={12}
              lg={g.GroupName === expandedGroupName ? 12 : 6}
              key={g.GroupId}
              style={{ order: groupOrder[g.GroupName] }}
            >
              <Accordion
                expanded={g.GroupName === expandedGroupName}
                onChange={(_, expanded) => onExpansionChange(g.GroupName, expanded)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="group-header">
                    <Typography variant="body2">
                      {g.GroupName} {g.HasDeletedMembers && groupContainsDeletedMembersWarning}
                    </Typography>
                    <div>
                      {g.IsGroupDeleted ? groupIsInactiveWarning : groupIsActive}
                      {g.Users.length > 0 ? (
                        <Chip>
                          <Metric count={g.Users.length} label="member" />
                        </Chip>
                      ) : (
                        emptyGroupWarning
                      )}
                      {g.AllocationCount > 0 ? (
                        <Chip>
                          <Metric count={g.AllocationCount} label="allocation" />
                        </Chip>
                      ) : (
                        unusedGroupWarning
                      )}
                      {g.CreatedBy && <div>created by {g.CreatedBy}</div>}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <table className="user-list">
                    <tbody>
                      {g.Users.map((u) => (
                        <tr key={u.UserId} className="user-item">
                          <td>{[u.UserLastName, u.UserFirstName].join(", ")}</td>
                          <td className="user-loginaccount">{u.UserLoginAccount}</td>
                          <td>{u.IsUserDeleted && userIsDeletedWarning}</td>
                          <td>
                            <Button
                              variant="text"
                              color="primary"
                              className="user-remove"
                              //startIcon={<LinkOffIcon />}
                              onClick={() => doRemoveGroupMember(g.GroupId, u.UserId)}
                            >
                              Remove
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </AccordionDetails>
                <AccordionActions>
                  <GroupActiveChangeButton {...g} onChange={doRefresh} />
                  <AddOrRenameGroupPopup groupId={g.GroupId} groups={groups} onChange={(groupName) => doRefresh(groupName)} />
                  <AddGroupMemberInput groupId={g.GroupId} onAdd={doRefresh} />
                </AccordionActions>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </div>
    </Root>
  );
}

const Chip: React.FC<{ type?: "info" | "ok" | "warn", children?: React.ReactNode }> = ({ type = "info", children }) => (
  <div className={cn("chip", "chip-" + type)}>{children}</div>
);

function Metric(props: { count: number; label: string }) {
  const { count, label } = props;
  return (
    <div>
      <strong>{count}</strong> {label}
      {count > 1 ? "s" : ""}
    </div>
  );
}

const groupContainsDeletedMembersWarning = (
  <Tooltip title="One or more members in this group are deleted.">
    <span>⚠️</span>
  </Tooltip>
);

const userIsDeletedWarning = <Chip type="warn">deleted</Chip>;

const groupIsInactiveWarning = <Chip type="warn">inactive</Chip>;

const groupIsActive = <Chip type="ok">active</Chip>;

const unusedGroupWarning = (
  <Chip type="warn">
    <Tooltip title="There are no allocations associated with this group.">
      <span>unused</span>
    </Tooltip>
  </Chip>
);

const emptyGroupWarning = (
  <Chip type="warn">
    <Tooltip title="This group has no members.">
      <span>empty</span>
    </Tooltip>
  </Chip>
);

/**
 * If we have a two-column layout, then a selected (resp. expanded) grid cell will be rendered
 * in full width covering both columns.
 * This function ensures that in this situation an expanded grid cell in the right column stays in the same row and
 * the left neighbor grid cell is instead moved one row down.
 * Without, an expanded right grid cell would move one row down resulting in a hole and the mouse pointer would
 * no longer pointing at the just clicked grid cell.
 */
function getGroupOrder(groups: Group[], expandedGroupName: Maybe<string>, isTwoColumnLayout: boolean) {
  const groupNames = groups.map((g) => g.GroupName);
  const order = groupNames.reduce((result, groupName, i) => {
    result[groupName] = i;
    return result;
  }, {} as { [groupName: string]: number });

  // for one-column layout we just return the given group order
  if (!isTwoColumnLayout) {
    return order;
  }

  // if nothing is expanded we just return the given order group
  if (expandedGroupName === null || expandedGroupName === undefined) {
    return order;
  }

  // for two-column layout we switch the order of two groups in a row, if the right one is expanded
  const expandedIndex = order[expandedGroupName];
  if (expandedIndex % 2 === 1 /*odd index means right column*/) {
    const leftNeighborGroupName = groupNames[expandedIndex - 1];
    order[expandedGroupName] = expandedIndex - 1;
    order[leftNeighborGroupName] = expandedIndex;
  }

  return order;
}
