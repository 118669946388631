import * as React from "react";
import { connect } from "react-redux";
import Allocation from "../../types/Allocation";
import { AppState } from "../../store/AppState";
import { Dispatch } from "redux";
import { request } from "../../modules/client";
import { showAlert } from "../../store/app/app-slice";
import { CircularProgress } from "@mui/material";
import AllocationSource from "../../types/AllocationSource";
import AllocationType from "../../types/AllocationType";

type AllocationTailoring = { category: string; options: string[] };
type AllocationTailoringsOrUndefined = AllocationTailoring[] | undefined;
type StringOrUndefined = string | undefined;

/**
 * Checks if the provided allocation can be tailored.
 * The implementation simply checks the allocation.AllocationSource for Alpima, except for PREMIUM and MULTIMA Modelportfolios.
 * This also returns true for the e.g. 'standard' INDEX+ and SELECT allocations - this however was deemed ok.
 */
export function isTailoringEnabled(allocation: Allocation) {
  return (
    allocation.AllocationSource == AllocationSource.Alpima &&
    (allocation.AllocationType !== AllocationType.ModelPortfolio ||
      (allocation.ProductLine.ShortName !== "PREMIUM" && allocation.ProductLine.ShortName !== "MULTIMA"))
  );
}

interface TailoringInformationProps {
  dispatch: Dispatch;
  dmcUrl: string;
  objectCode: string;
  embedded?: boolean;
}

export default connect((state: AppState) => ({
  dmcUrl: state.masterData.dmcUrl,
}))(function TailoringInformation(props: TailoringInformationProps) {
  const { dmcUrl, objectCode, dispatch, embedded } = props;
  const [tailoring, setTailoring] = React.useState(undefined as AllocationTailoringsOrUndefined);
  const [failure, setFailure] = React.useState(undefined as StringOrUndefined);

  React.useEffect(() => {
    setTailoring(undefined);
    setFailure(undefined);
    fetchTailoring(dmcUrl, objectCode)
      .then(setTailoring)
      .catch((e: any) => {
        setFailure(e?.response?.status === 404 ? "Allocation not found in DMC." : "Fetching tailoring data failed.");
        dispatch(showAlert("Fetching tailoring data failed", { type: "error" }));
      });
  }, [dmcUrl, objectCode, dispatch]);

  return (
    <div>
      {!embedded && (
        <h2 style={{ marginBottom: "2rem" }}>
          Tailoring
          {tailoring === undefined && !failure && <CircularProgress size={"1.8rem"} style={{ marginLeft: "2rem" }} />}
        </h2>
      )}
      {!!failure ? (
        failure
      ) : tailoring === undefined ? (
        embedded ? (
          <CircularProgress size={"1.8rem"} style={{ marginLeft: "2rem" }} />
        ) : (
          ""
        )
      ) : (tailoring?.length || 0) === 0 ? (
        <span>&mdash;</span>
      ) : (
        tailoring.map((t: AllocationTailoring) => (
          <div key={t.category} style={{ marginTop: "2rem" }}>
            <strong>{t.category}</strong> &mdash; {t.options.join(", ")}
          </div>
        ))
      )}
    </div>
  );
});

async function fetchTailoring(dmcUrl: string, objectCode: string) {
  const response = await request(`${dmcUrl}api/asset-allocations/${objectCode}`);
  const tailoring =
    response.tailoringCategories?.reduce((tailoring: AllocationTailoring[], c: any) => {
      const category: AllocationTailoring = {
        category: c.key,
        options: c.tailoringOptions?.map((o: any) => o.key) || [],
      };
      tailoring.push(category);
      return tailoring;
    }, []) || [];
  return tailoring as AllocationTailoring[];
}
