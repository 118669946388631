import Allocation from "../../../types/Allocation";
import Instrument from "../../../types/Instrument";
import { InstrumentWithOverrides } from "../types";

/**
 * Returns the level-out instrument.
 * This is either the defined level-out instrument or a fallback to the cash instrument (using the reference currency).
 * If both are invalid/missing, undefined will be returned.
 */
export default function deduceLevelOutInstrument(allocation: Allocation): InstrumentWithOverrides | undefined {
  if (allocation?.LevelOutInstrument) {
    return allocation.LevelOutInstrument;
  }

  const refCcy = getValidReferenceCurrency(allocation);
  if (!!refCcy) {
    const ccyShort = `Cash Account (${allocation.ReferenceCurrency.ShortName})`;
    return {
      Id: `N_CA${allocation.ReferenceCurrency.ShortName.substr(0, 2)}`,
      ShortName: ccyShort,
      LongName: ccyShort,
      LongNamePretty: ccyShort,
      Isin: undefined,
      AssetTreeMapping: {},
      AssetSubClass: "CASH",
      RiskCurrency: allocation.ReferenceCurrency.ShortName,
      IsDeleted: false,
    };
  }

  return undefined;
}

function getValidReferenceCurrency(allocation: Allocation) {
  const isInvalid =
    !allocation?.ReferenceCurrency ||
    !allocation.ReferenceCurrency.Id ||
    allocation.ReferenceCurrency.ShortName === "xxx" ||
    allocation.ReferenceCurrency.ShortName === "XXX";
  return isInvalid ? undefined : allocation.ReferenceCurrency;
}
