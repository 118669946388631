import * as React from "react";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { toShortDateString, toShortTimeString } from "../../modules/date";
import { PublishIcon } from "../shared/Icons";
import UploadDialog from "./dialogs/UploadDialog";
import ProductStatusPopup from "./dialogs/ProductStatusPopup";
import StatusLabel from "./StatusLabel";
import ReferenceDate from "./ReferenceDate";
import ProductDownloadButton from "./ProductDownloadButton";
import { ProductLinePublicationData, ProductDataStatus, ProductLineStatusMap, ProductStatus, getProductDataStatus, ProductLineStatusApi, dataIconsMapper } from "./utils";
import { isEqual as deepEqual } from "lodash";
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../../store/app/app-slice";

const PREFIX = 'DaisyProductLines';
const classes = {
    stickyTableHeader: `${PREFIX}-stickyTableHeader`,
    borderTop: `${PREFIX}-borderTop`,
    borderless: `${PREFIX}-borderless`,
    hoverVisible: `${PREFIX}-hoverVisible`,
}
const TableRoot = styled(Table)(({ theme }) => ({
    [`& .${classes.stickyTableHeader}`]: {
        position: "sticky" as any, // sticky is unknown to the type def
        top: 0,
        zIndex: 10,
        backgroundColor: "inherit",
    },
    [`& .${classes.borderTop}`]: {
        borderTop: "1px solid " + theme.palette.text.disabled,
        "& *": { borderBottom: "unset" },
    },
    [`& .${classes.borderless}`]: {
        borderBottom: "unset",
        "& *": { borderBottom: "unset" },
    },
    [`& .${classes.hoverVisible}`]: {
        "& .showOnHover": {
            visibility: "hidden",
        },
        "&:hover .showOnHover": {
            visibility: "visible",
        },
    },
})) as typeof Table;

export default function DaisyProductLines(props: {
  data: ProductLineStatusMap;
  filters: Set<ProductDataStatus | ProductLineStatusApi>;
  referenceDate: ReferenceDate;
  reloadProductStatus: () => void;
  publishDisabled: boolean;
}) {
  
  const productLines = props.data;
  const [allOpen, setAllOpen] = React.useState(false);
  const [productLineToggles, setProductLineToggles] = React.useState(uniformToggles(allOpen));
  const [showUploadDialog, setShowUploadDialog] = React.useState(false);
  const [selectedUploadProductLine, setSelectedUploadProductLine] = React.useState<ProductLinePublicationData | null>(null);
  const someUploadableProductLines = Object.values(productLines).some((pl) => pl.canPublish);
  const [statusPopupData, setStatusPopupData] = React.useState<ProductStatus[]>([]);



  function uniformToggles(value: boolean) {
    return Object.keys(productLines).reduce((toggles, p) => {
      toggles[p] = value;
      return toggles;
    }, {} as { [productLine: string]: boolean });
  }

  function isAnyOpen(toggles: { [productLine: string]: boolean }) {
    return Object.keys(toggles).some((p) => toggles[p]);
  }

  function onProductLineToggle(productLine: string) {
    const toggles = {
      ...productLineToggles,
      [productLine]: !productLineToggles[productLine],
    };
    setProductLineToggles(toggles);
    setAllOpen(isAnyOpen(toggles));
  }

  React.useEffect(() => {
    console.log("productLines changed");
    setProductLineToggles(uniformToggles(allOpen));
  }, [productLines]);

  // performance optimization
  // if we would React handle this, toggling all product lines on/off would take half a second minimum
  // (tested with 31 product lines and 146 product rows overall)
  React.useLayoutEffect(() => {
    Object.keys(productLineToggles).forEach((k) => {
      const rowIds = productLines[k].rows.map((r) => r.ProductId);
      const isOpen = productLineToggles[k];
      const isFilterMatch = props.filters.size === 0 || props.filters.has(productLines[k].productDataStatus) || props.filters.has(productLines[k].daisyPublicationStatus) ;     
      const hide = !isOpen || !isFilterMatch;
      rowIds.forEach((rid) => {
        const elem = document.getElementById(rid);
        if (elem) {
          elem.style.visibility = (hide && "collapse") || "";
          elem.style.height = ((hide && "0px") || undefined) as any;
        }
      });
    });
  }, [productLineToggles, productLines, props.filters]);

  return (
    <TableRoot>
      {showUploadDialog && (
        <UploadDialog
          productLines={
            selectedUploadProductLine ? [selectedUploadProductLine] : Object.values(productLines).filter((pl) => pl.canPublish)
          }
          referenceDate={props.referenceDate}
          onClose={(reload: boolean) => {
            setShowUploadDialog(false);
            if (reload) {
              props.reloadProductStatus();
            }
          }}
          onStatusPopup={setStatusPopupData}
        />
      )}
      {statusPopupData.length > 0 && <ProductStatusPopup data={statusPopupData} onClose={() => setStatusPopupData([])} />}
      <Header
        isOpen={allOpen}
        openToggle={() => {
          setAllOpen(!allOpen);
          setProductLineToggles(uniformToggles(!allOpen));
        }}
        disableUploadButton={!someUploadableProductLines || props.publishDisabled}
        openUploadDialog={() => {
          setSelectedUploadProductLine(null);
          setShowUploadDialog(true);
        }}
      />
      <TableBody>
        {Object.keys(productLines)
          .map((p) => ({
            ...productLines[p],
            isShown: props.filters.size === 0 || props.filters.has(productLines[p].productDataStatus) || props.filters.has(productLines[p].daisyPublicationStatus),
            isOpen: productLineToggles[p],
          }))
          .map((p) => (
            <React.Fragment key={p.productLine}>
              <ProductLineRow
                data={p}
                isOpen={p.isOpen}
                isShown={p.isShown}
                openToggle={onProductLineToggle}
                openUploadDialog={() => {
                  setSelectedUploadProductLine(p);
                  setShowUploadDialog(true);
                }}
                openStatusPopup={setStatusPopupData}
                publishDisabled={props.publishDisabled}
              />

              {p.rows.map((r) => (
                <ProductRowMemo key={r.ProductId} data={r} productLineData={p} openStatusPopup={setStatusPopupData} />
              ))}
            </React.Fragment>
          ))}
      </TableBody>
    </TableRoot>
  );
}

function ProductLineRow(props: {
  isOpen: boolean;
  isShown: boolean;
  openToggle: (productLine: string) => void;
  data: ProductLinePublicationData;
  openUploadDialog: () => void;
  openStatusPopup: (data: ProductStatus[]) => void;
  publishDisabled: boolean;
}) {
  const { isOpen, isShown, openToggle, data, openStatusPopup } = props;
  const dispatch = useDispatch();

  function showError(){
    const title = data.daisyPublicationStatus === ProductLineStatusApi.Uploaded && data.uploadErrors ? "Operation completed with warnings" : "Operation failed";
    dispatch(showErrorDialog({ reason: {message: data.uploadErrors, title: title} }));
  }

  let uploadDisabledReason: Maybe<string>;
  if (!data.isProductLinePublishingEnabled) {
    uploadDisabledReason = "Censhare upload is disabled for this product line";
  } else if (props.publishDisabled) {
    uploadDisabledReason = "Data source errors prevent upload";
  }

  return (
    <TableRow className={classes.borderTop} style={{ visibility: (!isShown && "collapse") || undefined }}>
      <Cell>
        <IconButton size="small" onClick={() => openToggle(data.productLine)}>
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Cell>
      <Cell>
        <Button variant="text" size="small" onClick={() => openToggle(data.productLine)}>
          {data.productLine}
        </Button>
      </Cell>
      <Cell hidden>-</Cell>
      <Cell hidden>-</Cell>
      <Cell hidden>-</Cell>
      <Cell hidden>-</Cell>
      <Cell hidden>-</Cell>
      <Cell hidden>-</Cell>
      <Cell>{formatDate(data.referenceDate)}</Cell>
      <Cell>
        {data.daisyPublicationStatus && 
        (
          <div title={`${toShortDateString(data.uploadDate)} ${toShortTimeString(data.uploadDate)}`}>
            <Button
              variant="text"
              onClick={showError}
              disabled={!data.uploadErrors}>
              <StatusLabel status={data.daisyPublicationStatus} hasErrors ={!!data.uploadErrors}>{
                (data.daisyPublicationStatus === ProductLineStatusApi.Uploaded) && formatDate(data.uploadDate)
              }</StatusLabel>
            </Button>
          </div>
        )}
      </Cell>
      <Cell>
          { (!data.uploadDate || data.daisyPublicationStatus === ProductLineStatusApi.Failed) && <Button
            variant="text"
            onClick={() => openStatusPopup(data.rows.filter((r) => r.ValidationMessages.length))}
            disabled={!data.hasWarningsOrErrors}
          >
            <StatusLabel status={data.productDataStatus} />
          </Button>}
      </Cell>
      <Cell>
        {(!!uploadDisabledReason && (
          <Tooltip title={uploadDisabledReason}>
            <Box ml={2}>
              <Typography color="textSecondary" variant="body1">
                Disabled
              </Typography>
            </Box>
          </Tooltip>
        )) || (
          <Box
            ml={1}
            className={classes.hoverVisible}
            style={{ visibility: (isShown && data.canPublish && "visible") || "hidden" }}
          >
            <Button variant="text" color="secondary" onClick={props.openUploadDialog}>
              <PublishIcon fontSize="small" />
              <span className="showOnHover">&nbsp;&nbsp;Upload&nbsp;...</span>
            </Button>
          </Box>
        )}
      </Cell>
    </TableRow>
  );
}

const ProductRowMemo = React.memo(function ProductRow(props: {
  data: ProductStatus;
  productLineData: ProductLinePublicationData;
  openStatusPopup: (data: ProductStatus[]) => void;
}) {
  const theme = useTheme();
  const { data, productLineData, openStatusPopup } = props;
  const dataStatus = getProductDataStatus([data]);
  return (
    <TableRow id={data.ProductId} style={{ color: theme.palette.text.secondary }} className={classes.borderless}>
      <Cell hidden>
        <IconButton size="small">
          <KeyboardArrowUpIcon />
        </IconButton>
      </Cell>
      <Cell hidden>{data.ProductLine}</Cell>
      <Cell>{data.Currency}</Cell>
      <Cell>{data.InvestmentStrategy}</Cell>
      <Cell>
        <ProductDownloadButton date={productLineData.referenceDate} productId={data.ProductId} type="figures" />
      </Cell>
      <Cell>
        <ProductDownloadButton date={productLineData.referenceDate} productId={data.ProductId} type="timeseries" />
      </Cell>
      <Cell>
        <ProductDownloadButton date={productLineData.referenceDate} productId={data.ProductId} type="samplemopo" />
      </Cell>
      <Cell>
        <ProductDownloadButton date={productLineData.referenceDate} productId={data.ProductId} type="breakdowns" />
      </Cell>
      <Cell></Cell>
      <Cell></Cell>
      <Cell>
        { dataStatus !== ProductDataStatus.ReadyForPublish && (
          <Button variant="text" onClick={() => openStatusPopup([data])}>
            <StatusLabel status={dataStatus} />
          </Button>
        )}
      </Cell>
      <Cell hidden>-</Cell>
    </TableRow>
  );
},
deepEqual);

const Cell: React.FC<{ width?: string; hidden?: boolean, children?: React.ReactNode }> = (props) => {
  const visibility = (!!props.hidden && "hidden") || undefined;
  const width = props.width;
  return (
    // explicit `nohover` class is required here, because mui's hover flag on TableRow level is not working
    <TableCell style={{ visibility, width }} className="nohover">
      {props.children}
    </TableCell>
  );
};

function Header(props: {
  isOpen?: boolean;
  openToggle?: () => void;
  openUploadDialog: () => void;
  disableUploadButton: boolean;
}) {
  return (
    // mui's `stickyHeader` prop on table level messes with borders and background, so we make it sticky with a custom style
    <TableHead className={classes.stickyTableHeader}>
      <TableRow>
        <Cell width="6rem">
          <IconButton size="small" onClick={props.openToggle || (() => {})}>
            {!!props.isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Cell>
        <Cell width="14rem">Product Line</Cell>
        <Cell width="9rem">Currency</Cell>
        <Cell width="11rem">
          Investment
          <br />
          Strategy
        </Cell>
        <Cell width="10rem">
          Key
          <br />
          Figures
        </Cell>
        <Cell width="10rem">
          Performance
          <br />
          Time Series
        </Cell>
        <Cell width="10rem">
          Sample
          <br />
          MoPo
        </Cell>
        <Cell width="10rem">Breakdowns</Cell>
        <Cell width="14rem">
          Reference
          <br />
          Date
        </Cell>
        <Cell width="16rem">
          Upload
          <br />
          Status
        </Cell>
        <Cell>
          Data
          <br />
          Status
        </Cell>
        <Cell width="10rem">
          <Button variant="contained" color="primary" disabled={props.disableUploadButton} onClick={props.openUploadDialog}>
            <PublishIcon fontSize="small" />
            &nbsp;&nbsp;Upload&nbsp;...
          </Button>
        </Cell>
      </TableRow>
    </TableHead>
  );
}

function formatDate(date: Maybe<string>) {
  return (date && toShortDateString(date)) || "-";
}
