import * as React from "react";
import Downshift, { ControllerStateAndHelpers } from "downshift";
import cn from "classnames";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { CloseIcon, SearchIcon } from "./Icons";
import LoadingIndicator from "./LoadingIndicator";

const PREFIX = 'DropdownBase';
const classes = {
  search: `${PREFIX}-search`,
  menu: `${PREFIX}-menu`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.search}`]: {
    "& .MuiInput-input": {
      fontSize: theme.typography.body2.fontSize,
    },
  },

  [`& .${classes.menu}`]: {
    position: "absolute",
    zIndex: 2,
    left: 0,
  }
}));

export type DropdownConfig<TItem> = {
  label: string;
  items: TItem[];
  onSelect: (selection: TItem) => void;
};

export type DropdownOptions<TItem> = {
  focusPlaceholder?: string;
  blurPlaceholder?: string;
  initialSelectedItem?: TItem;
  preventAutoClear?: boolean;
  preventAutoBlur?: boolean;
  isLoading?: boolean;
  showNoResults?: boolean;
  showSearchIcon?: boolean;
  fullWidth?: boolean;
};

/**
 * Convenience wrapper around Downshift
 * using Material UI components for rendering.
 * Intended to get wrapped in more specific components.
 *
 * If the styling of the textfield or menu container needs
 * some special adjustments, then the wrapper component
 * can define the classes `dropdownbase-input` respectively
 * `dropdownbase-menu` (see `AddGroupMemberInput` for example).
 */
export default function DropdownBase<TItem>(
  props: DropdownConfig<TItem> &
    DropdownOptions<TItem> & {
      formatItem: (item: TItem) => string;
      onInputChange?: (input: Maybe<string>) => void;
      renderMenuItemContent?: (item: TItem) => JSX.Element;
      itemToKey?: (item: TItem) => string | number;
    }
) {
  const initialSelectedItem = props.initialSelectedItem;
  const preventAutoBlur = !!props.preventAutoBlur;
  const preventAutoClear = !!props.preventAutoClear;
  const showNoResults = !!props.showNoResults;
  const isLoading = !!props.isLoading;
  const showSearchIcon = !!props.showSearchIcon;

  const space = useTheme().spacing;

  function onChange(item: TItem | null, state: ControllerStateAndHelpers<TItem>) {
    console.log("onChange", { item, state });

    if (item) {
      props.onSelect(item);
    }

    // blur input per default when selection changed
    if (!preventAutoBlur) {
      const inputId = state.getInputProps().id;
      if (inputId) {
        document.getElementById(inputId)?.blur();
      }
    }
  }

  function itemToString(item: TItem | null) {
    return (preventAutoClear && item && props.formatItem(item)) || "";
  }

  return (
    <Downshift
      onChange={onChange}
      onInputValueChange={props.onInputChange}
      itemToString={itemToString}
      initialSelectedItem={initialSelectedItem}
    >
      {(down) => (
        <Root style={{ position: "relative" }} {...down.getRootProps(undefined, { suppressRefError: true })}>
          <TextField
            label={props.label}
            {...down.getInputProps()}
            className={cn(classes.search, "dropdownbase-input")}
            fullWidth={!!props.fullWidth}
            InputProps={{
              onFocus: (e) => {
                if (props.focusPlaceholder) {
                  e.target.placeholder = props.focusPlaceholder;
                }
              },
              onBlur: (e) => {
                if (props.blurPlaceholder) {
                  e.target.placeholder = props.blurPlaceholder;
                }
              },
              endAdornment: (
                <LoadingIndicator show={isLoading} size={space(2)} delayInMillis={0}>
                  <InputAdornment position="end">
                    {isLoading && <>&nbsp;&nbsp;</>}
                    {!isLoading &&
                      showSearchIcon &&
                      (down.inputValue ? (
                        <IconButton onClick={() => down.clearSelection()} size="small" style={{ margin: 0, padding: 0 }}>
                          <CloseIcon />
                        </IconButton>
                      ) : (
                        <SearchIcon color="primary" />
                      ))}
                  </InputAdornment>
                </LoadingIndicator>
              ),
            }}
            InputLabelProps={down.getLabelProps()}
          />
          <div {...down.getMenuProps()}>
            {down.isOpen && (
              <Paper className={cn(classes.menu, "dropdownbase-menu")}>
                {props.items.map((item, index) => (
                  <MenuItem
                    key={(props.itemToKey && props.itemToKey(item)) || index}
                    {...down.getItemProps({ index, item })}
                    selected={down.highlightedIndex === index}
                    component="div"
                  >
                    {(props.renderMenuItemContent && props.renderMenuItemContent(item)) || itemToString(item)}
                  </MenuItem>
                ))}
              </Paper>
            )}
          </div>
          {showNoResults && props.items.length === 0 && (
            <Paper className={cn(classes.menu, "dropdownbase-menu")}>
              <Typography variant="body2" color="secondary" style={{ padding: space(2) }}>
                No results
              </Typography>
            </Paper>
          )}
        </Root>
      )}
    </Downshift>
  );
}
