import * as React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Grid, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import sortBy from "lodash/sortBy";
import { useNavigate } from "react-router";
import InstrumentListContainer from "./InstrumentListContainer";
import InstrumentListFilterPanel from "./InstrumentListFilterPanel";
import { showAlert } from "../../store/app/app-slice";
import { AppState } from "../../store/AppState";
import { instrumentListsRequest } from "../../store/instrumentLists/instrumentlist-slice";
import { updateCurrentAllocationPageType } from "../../store/app/app-slice";
import { InstrumentLabel } from "../shared/MultiInstrumentSelect";
import { request } from "../../modules/client";
import useQuery from "../../modules/useQuery";
import { filterByInstrumentListCategory, pushStateToHistory, searchInInstrumentLists, uniqueInstrumentFilter } from "./utils";
import SearchInput from "../shared/SearchInput";

type InstrumentListSortOrder = "date" | "name";

/**
 * Component/route triggers the fetch of the instrument lists and displays them via <InstrumentListContainer/>
 * and provides filters via <InstrumentListFilterPanel/>.
 *
 * Data and URL-State management is done though this 'container' component.
 */

const PREFIX = 'InstrumentLists';
const classes = {
  searchInputContainer: `${PREFIX}-searchInputContainer`,
  filterWrapper: `${PREFIX}-filterWrapper`,
  listContainerWrapper: `${PREFIX}-listContainerWrapper`,
}
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.searchInputContainer}`]: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up("xl")]: {
      width: "1100px !important",
    },
  },
  [`& .${classes.filterWrapper}`]: {
    marginTop: theme.spacing(1),
    height: "100%",
  },
  [`& .${classes.listContainerWrapper}`]: {
    marginTop: theme.spacing(1),
  },
}));

const separator = "|";

function encodeIncludedInstrumentForUrl(data: InstrumentLabel[]): string[] {
  return data.map((pair) => pair.instrumentId + separator + pair.name);
}

function decodeIncludedInstrumentsFromUrl(parts: string[] | null | undefined): InstrumentLabel[] {
  if (!parts) {
    return [];
  } else {
    const instrumentFilter = parts
      .map((str) => str.split(separator))
      .filter((pair) => pair.length === 1 || pair.length === 2)
      .map((pair) => {
        if (pair.length === 1) {
          return { instrumentId: pair[0], name: pair[0] };
        } else {
          return {
            instrumentId: pair[0],
            name: pair[1],
          };
        }
      });
    return uniqueInstrumentFilter(instrumentFilter);
  }
}

/**
 * Calls the backend to get which InstrumentLists contain the given instruments.
 */
async function queryByIncludedInstruments(baseUrl: string, instrumentIds: string[]) {
  let url = `${baseUrl}api/instrument-lists/listIdsByIncludedInstruments?`;
  url += instrumentIds.map((instrumentId) => "instrumentID=" + encodeURIComponent(instrumentId)).join("&");
  return request(url);
}

export default function InstrumentLists(props: any) {
  const navigate = useNavigate();
  const query = useQuery();
  const dispatch = useDispatch();

  const sorting = (query.get("sortBy") || "date") as InstrumentListSortOrder;
  const includedInstrumentsFilter = decodeIncludedInstrumentsFromUrl(query.getAll("includedInstrument"));
  const searchString = query.get("search");
  const categoriesFilterValues = query.getAll("category") || [];

  // Only include these List-Ids in the display. Filter is disabled, if set to undefined.
  const [filterListIds, setFilterListIds] = React.useState<string[] | undefined>(undefined);

  const fetchStatus = useSelector((store: AppState) => store.instrumentLists.fetchStatus);
  const tstCoreUrl = useSelector((state: AppState) => state.masterData.tstCoreUrl);

  // Creates the actually displayed list, including filters & sorting
  const lists = useSelector((store: AppState) => {
    let list = store.instrumentLists.lists;

    if (filterListIds !== undefined && includedInstrumentsFilter && includedInstrumentsFilter.length > 0) {
      list = list.filter((lst) => {
        return filterListIds.includes(lst.instrumentListId);
      });
    }

    list = filterByInstrumentListCategory(list, categoriesFilterValues);

    if (searchString) {
      list = searchInInstrumentLists(list, searchString);
    }

    if (!sorting) {
      return list;
    } else {
      return sortBy(list, [sorting]);
    }
  }, shallowEqual);

  const categories = useSelector((store: AppState) => store.instrumentLists.categories, shallowEqual);

  React.useEffect(() => {
    dispatch(updateCurrentAllocationPageType(undefined));
  }, []);

  React.useEffect(() => {
    if (fetchStatus.success === undefined) {
      dispatch(instrumentListsRequest());
    }
  }, []);

  const updateFiltering = (reverseLookup: string[] | undefined) => {
    setFilterListIds(reverseLookup);
  };

  // Query the backend for the instrument-lists for the 'included instruments' filter.
  React.useEffect(() => {
    if (tstCoreUrl) {
      queryByIncludedInstruments(
        tstCoreUrl,
        includedInstrumentsFilter.map((it) => it.instrumentId)
      )
        .then((response: string[]) => {
          updateFiltering(response);
        })
        .catch((reason) => {
          const msg = "Filter could not be applied due to an request error.";
          console.log(msg, reason);
          dispatch(showAlert(msg, { type: "error" }));
        });
    }
  }, [tstCoreUrl, includedInstrumentsFilter.map((pair) => pair.instrumentId).join("")]);

  const updateSorting = (newValue: string) => {
    pushStateToHistory(
      {
        sortBy: newValue,
      },
      navigate,
      query
    );
  };

  const updateIncludedInstrumentFilter = (values: InstrumentLabel[]) => {
    pushStateToHistory(
      {
        includedInstrument: encodeIncludedInstrumentForUrl(values),
      },
      navigate,
      query
    );
  };

  const updateSearchString = (newValue: string) => {
    pushStateToHistory(
      {
        search: newValue,
      },
      navigate,
      query
    );
  };

  const updateCategories = (newValues: string[]) => {
    pushStateToHistory(
      {
        category: newValues,
      },
      navigate,
      query
    );
  };

  const canCreateList = true; // For now any authenticated user can create an own list.

  return (
      <Root>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={2}>
            <Typography variant="h2">Instrument Lists</Typography>
          </Grid>
          <Grid item xs={12} lg={10}>
            <div className={classes.searchInputContainer}>
              <SearchInput
                  onValueChange={updateSearchString}
                  placeholder="Search for ID, Name or Description"
                  value={searchString || ""}
                  style={{ width: "40rem" }}
              />
            </div>
          </Grid>
          <Grid item xs={12} lg={2} className={classes.filterWrapper}>
            <InstrumentListFilterPanel
                reverseLookupValues={includedInstrumentsFilter}
                updateIncludedInstrumentsFilter={updateIncludedInstrumentFilter}
                categories={categories}
                categoryValues={categoriesFilterValues}
                updateCategoriesFilter={updateCategories}
            />
          </Grid>
          <Grid item xs={12} lg={10} className={classes.listContainerWrapper}>
            {fetchStatus.error && (
                <div>
                  Could not load instrument lists. Cause: {fetchStatus.error} <br />
                  <br />
                  <Button
                      color="primary"
                      onClick={() => {
                        dispatch(instrumentListsRequest());
                      }}
                  >
                    Try again.
                  </Button>
                </div>
            )}
            {!fetchStatus.error && (
                <InstrumentListContainer lists={lists} sortBy={sorting} updateSorting={updateSorting} canCreateList={canCreateList} />
            )}
          </Grid>
        </Grid>
      </Root>
  );
}
