import { GenericTableCell } from "./GenericTableCell";
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@mui/material";
import React from "react";
import SearchInput from "../../shared/SearchInput";
import { ColumnDefinition } from "../../../types/GenericTable";

export interface Sort {
  propName: string;
  order: "asc" | "desc";
}

interface Paging {
  rowsPerPage: number;
  pageNumber: number;
}

export function GenericTable<TRowValue>(props: {
  colDefinitions: ColumnDefinition<TRowValue>[];
  data: Array<TRowValue>;
  sortBy: string;
  sortByOrder?: "desc" | "asc";
  keyColName: string;
  filterColumn: string;
  title: string;
  hideSearch?: boolean;
  hidePagination?: boolean;
  rowsPerPage?: number;
}) {
  const [sort, setSort] = React.useState<Sort>({
    propName: props.sortBy,
    order: props.sortByOrder || "asc",
  });
  const [paging, setPaging] = React.useState<Paging>({
    rowsPerPage: props.rowsPerPage || 25,
    pageNumber: 0,
  });
  const [filter, setFilter] = React.useState("");

  const onSortChange = (propName: string) => {
    if (!propName) {
      return;
    }

    const retval = { ...sort };
    if (propName === sort.propName) {
      retval.order = retval.order === "asc" ? "desc" : "asc";
    } else {
      retval.propName = propName;
      retval.order = "asc";
    }
    setSort(retval);
  };

  const dataView = getCurrentPage(props.data, paging, sort, props.filterColumn, filter);

  const itemEdited = (item: any, column: ColumnDefinition<TRowValue>, newValue: any) => {
    const oldValue = item[column.propName];
    if (oldValue === newValue) {
      return;
    }

    item[column.propName] = newValue;
    if (column.editCallBack) {
      column.editCallBack(item);
    }
  };

  const filterChanged = (value: string) => {
    paging.pageNumber = 0;
    setFilter(value);
  };

  return (
    <>
      <Typography variant="h2">{props.title}</Typography>
      {!props.hideSearch && (
        <SearchInput
          style={{ width: "50rem" }}
          placeholder="Search for code"
          value={filter}
          onValueChange={(value) => filterChanged(value)}
          clearButtonDisabled={false}
        />
      )}
      <Table>
        <TableHead>
          <TableRow>
            {props.colDefinitions.map((column) => (
              <TableCell align={column.align} key={column.propName} sortDirection={sort.order}>
                <TableSortLabel
                  active={sort.propName === column.propName}
                  direction={sort.order}
                  onClick={() => onSortChange(column.propName)}
                  disabled={!column.propName}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {dataView.page.map((item) => (
            <TableRow key={item[props.keyColName]}>
              {props.colDefinitions.map((column) => (
                <GenericTableCell
                  key={item[props.keyColName] + column.propName}
                  rowValue={item}
                  column={column}
                  onValueChanged={(val) => {
                    itemEdited(item, column, val);
                  }}
                />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!props.hidePagination && (
        <TablePagination
          component="div"
          count={dataView.totalCount}
          rowsPerPage={paging.rowsPerPage}
          rowsPerPageOptions={[15, 25, 50, 100, 250, 500]}
          page={paging.pageNumber}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onPageChange={(ev, pageNumber) => {
            setPaging({
              ...paging,
              pageNumber: pageNumber,
            });
          }}
          onRowsPerPageChange={(ev) => {
            setPaging({
              ...paging,
              rowsPerPage: parseInt(ev.target.value),
            });
          }}
        />
      )}
    </>
  );
}

function getCurrentPage(data: Array<any>, paging: Paging, sort: Sort, filterColumn: string, filter: string) {
  const filteredData = data.filter((item) => item[filterColumn].toString().toLowerCase().includes(filter.toLowerCase().trim()));

  const sortStrings = (a: string, b: string) => a.localeCompare(b);
  const sortNumbers = (a: number, b: number) => a - b;

  const sortAny = (a: any, b: any) => {
    const sign = sort.order === "asc" ? 1 : -1;
    if ((a && b === undefined) || (a && b === null)) {
      return 0;
    }
    if (a === undefined || a === null) {
      return 1 * sign;
    }
    if (b === undefined || b === null) {
      return -1 * sign;
    }
    if (typeof a === "number") {
      return sortNumbers(a, b) * sign;
    }
    if (typeof a === "boolean" || typeof a === "string") {
      return sortStrings(a.toString(), b.toString()) * sign;
    }
    throw new Error("Not implemented");
  };

  const sortedData = filteredData.sort((objA, objB) => sortAny(objA[sort.propName], objB[sort.propName]));

  const page = sortedData.slice(paging.pageNumber * paging.rowsPerPage, (paging.pageNumber + 1) * paging.rowsPerPage);

  return {
    page: page,
    totalCount: sortedData.length,
  };
}
