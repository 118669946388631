import addMonths from "date-fns/addMonths";
import formateDate from "date-fns/format";
import lastDayOfMonth from "date-fns/lastDayOfMonth";

/**
 * Represents a reference date in the context of product line publishing (DAISY).
 */
export default class ReferenceDate {
  static today = () => new Date();
  private readonly date: Date;

  static getDefault() {
    return new ReferenceDate(ReferenceDate.today()).previous();
  }

  static fromPathParam(pathParam: string) {
    const date = new Date(pathParam);
    if (date.toString().toLowerCase().startsWith("invalid date")) {
      return ReferenceDate.getDefault();
    }
    if (lastDayOfMonth(date) > lastDayOfMonth(ReferenceDate.today())) {
      return ReferenceDate.getDefault();
    }
    return new ReferenceDate(date);
  }

  private constructor(date: Date) {
    this.date = date;
    this.date = this.normalize().date;
  }

  value() {
    return this.date;
  }

  previous() {
    return new ReferenceDate(this.normalize(-1).date);
  }

  next() {
    const { isValid, date } = this.normalize(1);
    return isValid ? new ReferenceDate(date) : this;
  }

  hasNext() {
    return this.normalize(1).isValid;
  }

  toPathParameter() {
    return formateDate(this.date, "yyyy-MM");
  }

  toLabel() {
    return formateDate(this.date, "MMMM yyyy");
  }

  toUploadParameter() {
    return formateDate(this.date, "yyyy-MM-dd");
  }

  // adjusts the current date to last-day-of-month and optionally applies a month offset
  private normalize(monthOffset: number = 0) {
    const normalized = lastDayOfMonth(addMonths(this.date, monthOffset));
    return {
      isValid: normalized <= lastDayOfMonth(ReferenceDate.today()), // prevent future reference dates (month-wise)
      date: new Date(normalized.toDateString()), // clears the time part
    };
  }
}
