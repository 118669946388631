import * as React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { InfoIcon } from "../shared/Icons";

const PREFIX = 'PanelDescriptionHeader';
const classes = {
  root: `${PREFIX}-root`,
  descriptionContainer: `${PREFIX}-descriptionContainer`,
  icon: `${PREFIX}-icon`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },

  [`& .${classes.descriptionContainer}`]: {
    display: "flex",
    alignItems: "top",
  },

  [`& .${classes.icon}`]: {
    marginRight: theme.spacing(1),
    marginBottom: 3,
  }
}));

/**
 * Trivial component to describe a panel and shows an optional button on the upper right
 */
export default function PanelDescriptionHeader(props: { children: React.ReactNode; button?: JSX.Element }) {
  return (
    <Root className={classes.root}>
      <div className={classes.descriptionContainer}>
        <InfoIcon className={classes.icon} />
        <Typography variant="body1">{props.children}</Typography>
      </div>
      {props.button && <div>{props.button}</div>}
    </Root>
  );
}
