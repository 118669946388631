import * as React from "react";
import { styled } from '@mui/material/styles';
import { FormControlLabel, Button, Checkbox, TextField, Typography } from "@mui/material";
import { ServerMessageComment } from "../../types/InstrumentList";
import { toShortDateString } from "../../modules/date";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/AppState";
import { request } from "../../modules/client";
import ConfirmDialog from "../shared/ConfirmDialog";
import { removeServerMessageComment, setServerMessageComment } from "../../store/instrumentLists/instrumentlist-slice";
import ErrorBox from "../../components/shared/ErrorBox";
import { hideLoadingScreen, showLoadingScreen } from "../../store/loading/loading-slice";

const PREFIX = 'CommentBox';
const classes = {
  commentBox: `${PREFIX}-commentBox`,
  buttonBar: `${PREFIX}-buttonBar`,
  inputBox: `${PREFIX}-inputBox`,
  checkBox: `${PREFIX}-checkBox`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.commentBox}`]: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "28em",
  },

  [`& .${classes.buttonBar}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  [`& .${classes.inputBox}`]: {
    width: "100%",
  },

  [`& .${classes.checkBox}`]: {
    marginLeft: theme.spacing(0.5) as any * -1,
  }
}));

function postComment(baseUrl: string, comment: ServerMessageComment) {
  return request(`${baseUrl}api/comments`, {
    method: "POST",
    payload: comment,
  });
}

function deleteComment(baseUrl: string, comment: ServerMessageComment) {
  return request(`${baseUrl}api/comments/${comment.instrumentId}-${comment.messageTypeId}`, {
    method: "DELETE",
  });
}

/**
 * Component to Create/View/Update/Delete a Comment on a ServerMessage.
 */
export default function CommentBox(props: {
  instrumentId: string;
  messageTypeId: number;
  comment: Maybe<ServerMessageComment>;
  setComment: (comment?: ServerMessageComment) => void;
  hide: () => void;
  allowDowngrade: boolean;
}) {
  const dispatch = useDispatch();
  const tstCoreUrl = useSelector((state: AppState) => state.masterData.tstCoreUrl);

  // both are used for the form state
  const [downgrade, setDowngrade] = React.useState(!!props.comment?.severityOverride);
  const [text, setText] = React.useState(props.comment?.text);

  // the form is 'dirty' (changed) when either downgrade or the text changed.
  const isDirty = downgrade !== !!props.comment?.severityOverride || text !== props.comment?.text;

  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

  const isValid = text && text.length > 0 && text.length < 4000;

  async function onDelete() {
    if (!props.comment) {
      return;
    }

    setShowDeleteConfirmation(false);
    dispatch(showLoadingScreen());
    setErrorMessage(undefined);

    try {
      await deleteComment(tstCoreUrl, props.comment);
      dispatch(removeServerMessageComment(props.comment));
      props.hide();
      props.setComment(undefined);
      dispatch(hideLoadingScreen());
    } catch (error) {
      console.error("delete failed for CommentBox", error);
      setErrorMessage("Delete failed.");
      dispatch(hideLoadingScreen());
    }
  }

  async function onSave() {
    const fresh: ServerMessageComment = {
      instrumentId: props.instrumentId,
      messageTypeId: props.messageTypeId,
      severityOverride: downgrade ? "Information" : undefined,
      text: text,
    };

    dispatch(showLoadingScreen());
    setErrorMessage(undefined);

    try {
      const comment = await postComment(tstCoreUrl, fresh);
      dispatch(setServerMessageComment(comment));
      // make sure we get re-rendered with the comment provided by the server (hack?)
      props.setComment(comment);
      dispatch(hideLoadingScreen());
    } catch (error) {
      console.error("save failed for CommentBox", error);
      setErrorMessage("Save failed.");
      dispatch(hideLoadingScreen());
    }
  }

  return (
    <Root className={classes.commentBox}>
      <ConfirmDialog
        open={showDeleteConfirmation}
        title="Please confirm"
        handleOk={onDelete}
        handleCancel={() => setShowDeleteConfirmation(false)}
      >
        Do you really want to delete this comment?
      </ConfirmDialog>
      <div className={classes.buttonBar}>
        <Typography variant="body2">Comment</Typography>
        {props.comment?.modifiedBy && (
          <Typography variant="body2">
            (by {props.comment.modifiedBy}, {toShortDateString(props.comment.modified)})
          </Typography>
        )}
      </div>
      <TextField
        multiline
        variant="outlined"
        placeholder="Add comment"
        fullWidth
        error={!isValid && isDirty}
        helperText={!isValid && isDirty ? "The comment must be between 1 and 4000 characters." : ""}
        rows={3}
        defaultValue={props.comment?.text}
        onChange={(evt) => {
          setText(evt.target.value);
        }}
      ></TextField>
      {props.allowDowngrade && (
        <FormControlLabel
          className={classes.checkBox}
          control={
            <Checkbox
              name="overrideWarning"
              checked={downgrade}
              onChange={() => {
                setDowngrade(!downgrade);
              }}
            />
          }
          label="Downgrade Warning to Information"
        />
      )}
      <div className={classes.buttonBar}>
        <Button color="primary" size="small" variant="contained" disabled={!isDirty || !isValid} onClick={onSave}>
          Save
        </Button>
        {props.comment?.text && (
          <Button color="secondary" size="small" variant="contained" onClick={() => setShowDeleteConfirmation(true)}>
            Delete
          </Button>
        )}
        {true && (
          <Button color="secondary" size="small" onClick={props.hide}>
            Cancel
          </Button>
        )}
      </div>
      {errorMessage && <ErrorBox onClose={() => setErrorMessage(undefined)}>{errorMessage}</ErrorBox>}
    </Root>
  );
}
