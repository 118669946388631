import * as React from "react";
import Big from "big.js";
import Highcharts from "highcharts/highcharts";
import HighchartsReact from "highcharts-react-official";
import { AllocationExposure } from "./types";

export default function ExposureChart(props: { data: AllocationExposure }) {
  return (
    <div className="bjb-bar-chart-two-colours-in-sequential-order">
      <HighchartsReact highcharts={Highcharts} options={getChartOptions(props.data)} />
    </div>
  );
}

function getChartOptions(data: AllocationExposure): any {
  return {
    chart: { styledMode: true },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
      series: {
        dataLabels: {
          enabled: true,
          allowOverlap: false,
        },
        groupPadding: 0.1,
        pointPadding: 0.05,
      },
    },
    legend: {
      align: "left",
      itemDistance: 50,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: data.published.map((serie) => serie[1]),
    },
    yAxis: {
      title: {
        text: "Exposure [%]",
      },
    },
    series: [
      {
        name: "Published",
        data: data.published.map((serie) => parseFloat(new Big(serie[0]).times(100).toFixed(2))),
        keys: ["y", "name"],
        type: "column",
      },
      {
        name: "Saved",
        data: data.draft.map((serie) => parseFloat(new Big(serie[0]).times(100).toFixed(2))),
        keys: ["y", "name"],
        type: "column",
      },
    ],

    tooltip: {
      formatter: function () {
        return `${this.x}: ${new Big(this.y)}%`;
      },
    },
  };
}
