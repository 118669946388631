import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import { AllocationReference } from "../../types/AllocationReference";
import { UserState } from "./UserState";

let initialState: UserState = {
  username: undefined,
  firstName: undefined,
  lastName: undefined,
  userLocation: undefined,
  preferences: {
    preferPrettyInstrumentNames: false,
  },
  groups: [],
  isAdmin: false,
  canViewAdmin: false,
  canViewDaisy: false,
  canChangePortfolios: false,
  favorites: [],
  advanced: { Owned: [] },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    usersFavoritePortfoliosSuccess(state, action: PayloadAction<{ data: any }>) {
      let { data } = action.payload;
      let isFavorite: Boolean = data.isFavorite;
      let allocationReference: AllocationReference = data.allocationReference;

      if (!isFavorite) {
        state.favorites = state.favorites.filter((x) => x.Id != allocationReference.Id);
      } else {
        state.favorites = [...state.favorites, allocationReference];
      }
    },
    userInfoSuccess(state, action: PayloadAction<{ data: any }>) {
      let { data } = action.payload;

      state.username = data.Username;
      state.firstName = data.Firstname;
      state.lastName = data.Lastname;
      state.userLocation = data.UserLocation;
      state.groups = data.Groups;

      /**
       * It is sometimes useful to override the permissions, when developing against localhost
       * (e.g. when your user is in none of the groups).
       * This can be enabled/disabled here while developing/debugging.
       */
      const localhostPermissionOverride = false && document.location.hostname === "localhost";
      state.isAdmin = data.IsAdmin || localhostPermissionOverride;

      state.canViewAdmin = data.IsAdmin || data.IsAuditUser;
      state.canViewDaisy = data.IsAdmin || data.IsDaisyUser;
      state.canChangePortfolios = data.IsAdmin || data.IsSuperUser;

      state.favorites = data.Favorites;

      if (!state.preferences) {
        state.preferences = {};
      }
      state.preferences.preferPrettyInstrumentNames = data.Preferences?.PreferPrettyInstrumentNames;
    },
    advancedUserInfoSuccess(state, action: PayloadAction<{ data: any }>) {
      state.advanced = action.payload.data;
    },
    setPreferPrettyInstrumentNames(state, action: PayloadAction<{ value: boolean }>) {
      if (!state.preferences) {
        state.preferences = {};
      }
      state.preferences.preferPrettyInstrumentNames = action.payload.value;
    },
    usersFavoritePortfoliosFailure(state, action: PayloadAction<Error>) {
      // NoOp, only here so the saga can call something.
    },
    userInfoFailure(state, action: PayloadAction<Error>) {
      // NoOp, only here so the saga can call something.
    },
    advancedUserInfoFailure(state, action: PayloadAction<Error>) {
      // NoOp, only here so the saga can call something.
    },
  },
});

// Actions to trigger sagas.
export const userInfoRequest = createAction("user/info_request");
export const advancedUserInfoRequest = createAction("user/advanced_info_request");

export const {
  userInfoSuccess,
  userInfoFailure,
  advancedUserInfoSuccess,
  advancedUserInfoFailure,
  setPreferPrettyInstrumentNames,
  usersFavoritePortfoliosSuccess,
  usersFavoritePortfoliosFailure,
} = userSlice.actions;

export default userSlice.reducer;
