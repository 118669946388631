import Big from "big.js";
import { CSVToArray } from "../../../../modules/csv-parser";
import {
  AllocationParseResult,
  AllocationPasteType,
  MultipleAllocationParseResult, MultipleAllocationRowResult,
  SingleAllocationParseResult, SingleAllocationRowResult
} from '../../../shared/types';

export function parseSingleAllocationData(data: string): SingleAllocationParseResult {
  return parseData(data) as SingleAllocationParseResult;
}

export function parseMultipleAllocationData(data: string, allocationPasteType: AllocationPasteType): MultipleAllocationParseResult {
  return parseData(data, allocationPasteType) as MultipleAllocationParseResult;
}

export function parseData(data?: string, allocationPasteType?: AllocationPasteType): AllocationParseResult {
  if (!data) return { error: "No data." };

  const isTripleA = data.includes("Objectives;Instrument;Weight");
  const delimiter = isTripleA ? ";" : "\t";
  const rows = CSVToArray(data, delimiter);

  if (rows.length < 2) {
    return { error: "No data." };
  }

  const header = rows[0];
  const headerLowerCase = header.map(r => r.toLowerCase());

  const singleHeaders = ["instrument", "weight"];
  const validMopoHeaders = ["allocationCode", "instrument", "weight"];
  const validIcAllocationHeaders = [...validMopoHeaders, "riskCurrency", "assetSubClass"];

  const allocationCodeIdx = headerLowerCase.indexOf("allocationcode");
  const instrumentIdx = headerLowerCase.indexOf("instrument");
  const weightIdx = headerLowerCase.indexOf("weight");
  const riskCurrencyIdx = headerLowerCase.indexOf("riskcurrency");
  const assetSubClassIdx = headerLowerCase.indexOf("assetsubclass");

  let missingColumns;
  if (allocationPasteType === 'mopo') {
    missingColumns =  validMopoHeaders.filter(h => headerLowerCase.indexOf(h.toLowerCase()) === -1);
  } else if(allocationPasteType === 'icAllocation') {
    missingColumns = validIcAllocationHeaders.filter(h => headerLowerCase.indexOf(h.toLowerCase()) === -1);
  } else {
    missingColumns = singleHeaders.filter(h => headerLowerCase.indexOf(h.toLowerCase()) === -1);
  }

  if (missingColumns.length > 0) {
    return { error: `One or more columns are missing (${missingColumns.join(", ")}).` };
  }

  let invalidColumns: string[] = [];
  if (allocationPasteType === 'mopo') {
    invalidColumns = header.reduce<string[]>((acc, h) => {
        if (validMopoHeaders.map(v => v.toLowerCase()).indexOf(h.toLowerCase()) === -1)
        {
            acc.push(h);
        }

        return acc;
    }, []);
  } else if(allocationPasteType === 'icAllocation') {
    invalidColumns = header.reduce<string[]>((acc, h) => {
      if (validIcAllocationHeaders.map(v => v.toLowerCase()).indexOf(h.toLowerCase()) === -1)
      {
        acc.push(h);
      }

      return acc;
    }, []);
  }

  if (invalidColumns.length > 0) {
    return { error: `One or more columns are invalid (${invalidColumns.join(", ")}).` };
  }

  const result: (MultipleAllocationRowResult | SingleAllocationRowResult)[] = [];
  for (let i = 1; i < rows.length; i++) {
    const allocationCode = rows[i][allocationCodeIdx];
    const riskCurrency = rows[i][riskCurrencyIdx];
    const assetSubClass = rows[i][assetSubClassIdx];
    const id = rows[i][instrumentIdx]?.trim() || "";
    let weight = rows[i][weightIdx];
    if (weight === "" || id === "") {
      continue;
    }

    if (weight.includes("%")) {
      weight = new Big(weight.substring(0, weight.indexOf("%"))).div(100);
    } else if (isTripleA) {
      weight = new Big(weight).div(100);
    } else {
      weight = new Big(weight);
    }

    if (allocationPasteType === 'mopo') {
      result.push({ allocationCode, pasteId: id, weight });
    } else if (allocationPasteType === 'icAllocation') {
      result.push({ allocationCode, pasteId: id, weight, riskCurrency, assetSubClass });
    } else {
      result.push({ pasteId: id, weight });
    }
  }

  return result;
}
