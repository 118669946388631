import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import { AdminState, QueuedInfo } from "./AdminState";
import AllocationSpecifier from "../../types/AllocationSpecifier";
import Allocation from "../../types/Allocation";

const initialState: AdminState = {
  publishServiceInfo: {
    Queued: [],
    Update: "",
  },
  createAllocationState: {
    isLoading: false,
    dialog: "none",
    error: undefined,
    checkResult: undefined,
    createResult: undefined,
  },
  componentInView: "dashboard",
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    publishServiceInfoSuccess(state, action: PayloadAction<{ data: QueuedInfo }>) {
      state.publishServiceInfo = action.payload.data;
    },
    createAllocationOpen(state) {
      state.createAllocationState = {
        isLoading: false,
        dialog: "selection",
        error: undefined,
        checkResult: undefined,
        createResult: undefined,
      };
    },
    createAllocationClose(state) {
      state.createAllocationState = {
        isLoading: false,
        dialog: "none",
        error: undefined,
        checkResult: undefined,
        createResult: undefined,
      };
    },
    createAllocationCheckRequest(state, action: PayloadAction<AllocationSpecifier>) {
      state.createAllocationState = {
        isLoading: true,
        dialog: "selection",
        error: undefined,
        checkResult: undefined,
        createResult: undefined,
      };
    },
    createAllocationCheckSuccess(state, action: PayloadAction<{ allocationId: string; objectName: string } | null>) {
      state.createAllocationState = {
        isLoading: false,
        dialog: "selection",
        error: undefined,
        checkResult: action.payload === null ? "ok" : action.payload,
        createResult: undefined,
      };
    },
    createAllocationCreateRequest(state, action: PayloadAction<AllocationSpecifier>) {
      state.createAllocationState = {
        isLoading: true,
        dialog: "selection",
        error: undefined,
        checkResult: "ok",
        createResult: undefined,
      };
    },
    createAllocationCreateSuccess(state, action: PayloadAction<Array<Allocation>>) {
      state.createAllocationState = {
        isLoading: false,
        dialog: "success",
        error: undefined,
        checkResult: "ok",
        createResult: action.payload
          ? action.payload.map((it) => {
              return { allocationId: it.Id, objectName: it.Name };
            })
          : [],
      };
    },
    createAllocationCreateFailure(state, action: PayloadAction<{ error: string }>) {
      state.createAllocationState = {
        isLoading: false,
        dialog: "selection",
        error: action.payload.error,
        checkResult: "ok",
        createResult: undefined,
      };
    },
    updateComponentInView(state, action: PayloadAction<string>) {
      state.componentInView = action.payload;
    },
  },
});

// Actions to trigger sagas.
export const runServiceRequest = createAction("admin/run_service_request", (service: "timeout" | "publish") => {
  return {
    payload: { service: service },
  };
});
export const runAlpimaIntegrationRequest = createAction(runServiceRequest.type, (requestId?: string) => {
  const payload: any = {
    service: "integration/allocation",
    options: {
      method: "POST",
    },
  };
  if (requestId) {
    payload.options.payload = {
      requestId: requestId,
      force: true,
    };
  }
  return {
    payload: payload,
  };
});

export const publishServiceInfoRequest = createAction("admin/publish_service_info_request");
export const allocationIntegrationInfoRequest = createAction("admin/allocation_integration_info_request");

export const {
  publishServiceInfoSuccess,
  createAllocationOpen,
  createAllocationClose,
  createAllocationCheckRequest,
  createAllocationCheckSuccess,
  createAllocationCreateRequest,
  createAllocationCreateSuccess,
  createAllocationCreateFailure,
  updateComponentInView,
} = adminSlice.actions;

export default adminSlice.reducer;
