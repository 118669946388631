import * as React from "react";
import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';

const PREFIX = 'ConfirmButton';
const classes = {
  warning: `${PREFIX}-warning`,
}
const RootButton = styled(Button)(({ theme }) => ({
  [`&.${classes.warning}`]: {
    color: theme.palette.warning.main,
  },
})) as typeof Button;

const ConfirmButton = (props: any) => {
  const { onConfirm, normalLabel, confirmLabel, ...rest } = props;
  const [secondsLeft, setSecondsLeft] = React.useState(0);
  const [timer, setTimer] = React.useState(undefined);

  function onClick() {
    if (secondsLeft > 0) {
      setSecondsLeft(0);
      clearTimeout(timer);
      onConfirm();
    } else {
      countDown(3);
    }
  }

  function countDown(counter: number) {
    setSecondsLeft(counter);
    if (counter < 1) return;
    setTimer(
      setTimeout(() => {
        countDown(counter - 1);
      }, 1000) as any
    );
  }

  return (
    <RootButton onClick={onClick} {...rest} className={secondsLeft > 0 ? classes.warning : undefined}>
      {secondsLeft === 0 ? normalLabel : confirmLabel(secondsLeft)}
    </RootButton>
  );
};

export default React.memo(ConfirmButton);
