import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingState } from "./LoadingState";

let initialState: LoadingState = {
  loading: 0,
  instruments: {
    loading: false,
  },
  owners: {
    loading: false,
  },
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    showLoadingScreen(state) {
      state.loading = state.loading + 1;
    },
    hideLoadingScreen(state) {
      if (state.loading > 0) {
        state.loading = state.loading - 1;
      }
    },
    showLoadingIcon(state, action: PayloadAction<{ resultKey: "instruments" | "owners" }>) {
      state[action.payload.resultKey].loading = true;
    },
    hideLoadingIcon(state, action: PayloadAction<{ resultKey: "instruments" | "owners" }>) {
      state[action.payload.resultKey].loading = false;
    },
  },
});

export const { showLoadingIcon, hideLoadingIcon, showLoadingScreen, hideLoadingScreen } = loadingSlice.actions;

export default loadingSlice.reducer;
