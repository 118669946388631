import { generateWeightedInstrumentRowId, getInstrumentWithOverrides } from "../../../modules/asset-tree-builder";
import Allocation from "../../../types/Allocation";
import { InstrumentMap } from "../types";

/**
 * Returns distinct instruments contained in any snapshot of given allocations.
 */
export default function getInstruments(allocations: Allocation[], assetTreeName: string): InstrumentMap {
  const instruments: InstrumentMap = {};
  const snapshots = [...allocations.map((a) => a.PublishedSnapshot), ...allocations.map((a) => a.SavedSnapshot)];

  snapshots.forEach((snap) => {
    if (!snap) return;
    snap.Instruments.forEach((wi) => {
      const rowId = generateWeightedInstrumentRowId(wi, assetTreeName);
      if (!instruments[rowId]) {
        instruments[rowId] = getInstrumentWithOverrides(wi);
      }
    });
  });

  return instruments;
}
