import React from "react";
import { styled } from '@mui/material/styles';

/**
 * A scrollable table with fixed columns on the left and sticky header on top.
 *
 * Tries to be data-agnostic and provides only the structure .Data in terms of
 * headers, columns, and cells has to be injected.
 *
 * HTML is built up first vertically (header, body, footer) and inside each of
 * those sections column-driven from left to right.
 *
 * Structure as shown below. The footer just provides an always visible horizontal
 * scrollbar, which synces its offset with the scrollable columns.
 *
 *              fixed              scrollable
 *              column(s)          columns
 *
 *              +--------+         +----------------------------------+
 *   header     |  fix   |         |      <--------------------->     |
 *              +--------+         +----------------------------------+
 *              |        |         |                                  |
 *     body     |   ^    |         |                ^                 |
 *              |   |    |         |                |                 |
 *              |   |    |         |      <--------------------->     |
 *              |   |    |         |                |                 |
 *              |   v    | ...     |                v                 |
 *              |        |         |                                  |
 *              +--------+         +----------------------------------+
 *   footer     |        |         |      <--------------------->     |
 *              +--------+         +----------------------------------+
 *
 *
 * LIMITATIONS
 *
 * 1) Relies on width-aligned columns and headers (see ABANDONED APPROACHES 2).
 *
 * 2) Relies on height-aligned rows (see ABANDONED APPROACHES 3).
 *
 *
 * POSSIBLE ALTERNATIVE APPROACHES (yet unchecked)
 *
 * 1) HTML <table> as CSS Grid -> https://codepen.io/adam-lynch/pen/XwKWdG
 *    My favourite so far (from reading), yet unsure about browser support in JB environment.
 *
 * 2) Uncontrolled (React-wise) dynamic CSS for controlling styles of certain cells.
 *    Admittedly, not a topic for the generic scrolling table, but worth mentioning.
 *    Gut feeling: notable performance improvement over React rendering.
 *
 * 3) Render an offscreen table with only raw layout (no aligned row heights and column widhts)
 *    for measuring purposes (determining max cell heights per row, and max cell widths per column).
 *    Had done it years ago - and don't liked it, because of its complexity.
 *
 *
 * ABANDONED APPROACHES
 *
 * 1) Instead of having a dedicated sticky div holding the headers, consider making the
 *    first cell in each column sticky! A dedicated width sync would no longer be necessary then!
 *    Doesn't work because of position-sticky limitations in conjunction with overflow <> visible.
 *    Sticky elements are no longer sticky if one of its ancestors has an overflow set to "scroll"
 *    or "auto".
 *
 * 2) Automatic sync of column widths between header, body and footer areas.
 *    This turned out to rely heavily on the timing of the finished table layout in order to measure
 *    the reference cells. `setTimeout` helped in this regard, but the consequences were visible
 *    layout changes and sometimes wrong widths if the browser rendered not fast enough.
 *    Additionally, the toggling of weight types (editor options) requires
 *    ad-hoc reset and re-evaluation of column widths for the allocations, which adds significantly
 *    to its implementation complexity.
 *
 * 3) Automatic sync of row heights.
 *    Again, depends on browser layout (see point 2), and not worth the effort / complexity, since we
 *    have homogenous row contents for our use case (all one-line-texts in compare view).
 *
 */

const PREFIX = 'ScrollTable';
const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  fixHeaderContainer: `${PREFIX}-fixHeaderContainer`,
  scrollHeaderContainer: `${PREFIX}-scrollHeaderContainer`,
  body: `${PREFIX}-body`,
  fixColumnContainer: `${PREFIX}-fixColumnContainer`,
  scrollColumnContainer: `${PREFIX}-scrollColumnContainer`,
  column: `${PREFIX}-column`,
  footer: `${PREFIX}-footer`,
  fixFooterContainer: `${PREFIX}-fixFooterContainer`,
  scrollFooterContainer: `${PREFIX}-scrollFooterContainer`,
  footerCell: `${PREFIX}-footerCell`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    position: "relative",
  },

  [`& .${classes.header}`]: {
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
  },

  [`& .${classes.fixHeaderContainer}`]: {
    display: "flex",
    flexDirection: "row",
  },

  [`& .${classes.scrollHeaderContainer}`]: {
    overflowX: "hidden",
    display: "flex",
    flexDirection: "row",
  },

  [`& .${classes.body}`]: {
    display: "flex",
    flexDirection: "row",
  },

  [`& .${classes.fixColumnContainer}`]: {
    display: "flex",
    flexDirection: "row",
  },

  [`& .${classes.scrollColumnContainer}`]: {
    overflowX: "hidden",
    display: "flex",
    flexDirection: "row",
  },

  [`& .${classes.column}`]: {
    position: "relative",
  },

  [`& .${classes.footer}`]: {
    position: "sticky",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
  },

  [`& .${classes.fixFooterContainer}`]: {
    display: "flex",
    flexDirection: "row",
  },

  [`& .${classes.scrollFooterContainer}`]: {
    overflowX: "auto",
    overflowY: "hidden", // prevents vertical scrollbar which would be shown otherwise (as square, hardly to recognize as such) since we limit the footercell's height
    display: "flex",
    flexDirection: "row",
  },

  [`& .${classes.footerCell}`]: {
    visibility: "hidden",
    height: theme.spacing(0.5), // aka - margin-top
  }
}));

export default React.memo(function ScrollTable(props: {
  fixedHeaders: any[];
  fixedColumns: any[][];
  scrollHeaders: any[];
  scrollColumns: any[][];
  gutter?: string;
  toscaPrefix: string; // prefix for all TOSCA (test automation) relevant HTML IDs
}) {
  const { fixedHeaders, fixedColumns, scrollHeaders, scrollColumns, toscaPrefix } = props;
  const gutterStyle = props.gutter ? { marginRight: props.gutter } : {};
  if (fixedHeaders.length !== fixedColumns.length) {
    throw new Error("fixed headers and columns must match");
  }
  if (scrollHeaders.length !== scrollColumns.length) {
    throw new Error("scroll headers and columns must match");
  }
  const rowCounts = new Set([...fixedColumns.map((c) => c.length), ...scrollColumns.map((c) => c.length)]);
  if (rowCounts.size !== 1) {
    throw new Error("all columns must have same number of rows");
  }

  // TODO - wrap in React.useRef?
  const scrollHeaderContainerRef = React.createRef<HTMLDivElement>();
  const scrollBodyContainerRef = React.createRef<any>();

  function syncHorizontalScrollOffset(e: React.UIEvent<HTMLDivElement>) {
    const header = scrollHeaderContainerRef?.current;
    if (!!header) {
      header.scrollLeft = e.currentTarget.scrollLeft;
    }
    const body = scrollBodyContainerRef?.current;
    if (!!body) {
      body.scrollLeft = e.currentTarget.scrollLeft;
    }
  }

  return (
    <Root id={toscaPrefix + "-root"} className={classes.root}>
      <div className={classes.header}>
        <div className={classes.fixHeaderContainer}>
          {fixedHeaders.map((h, i) => (
            <div id={`${toscaPrefix}-fixheader-${i}`} key={i} style={gutterStyle}>
              {h}
            </div>
          ))}
        </div>
        <div className={classes.scrollHeaderContainer} ref={scrollHeaderContainerRef}>
          {scrollHeaders.map((h, i) => (
            <div id={`${toscaPrefix}-scrollheader-${i}`} key={i} style={gutterStyle}>
              {h}
            </div>
          ))}
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.fixColumnContainer}>
          {fixedColumns.map((rows, i) => (
            <Column key={i} rows={rows} css={classes} gutterStyle={gutterStyle} toscaPrefix={`${toscaPrefix}-fixcolumn-${i}`} />
          ))}
        </div>
        <div className={classes.scrollColumnContainer} ref={scrollBodyContainerRef}>
          {scrollColumns.map((rows, i) => (
            <Column
              key={i}
              rows={rows}
              css={classes}
              gutterStyle={gutterStyle}
              toscaPrefix={`${toscaPrefix}-scrollcolumn-${i}`}
            />
          ))}
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.fixFooterContainer}>
          {fixedColumns.map((rows, i) => (
            <div key={i} className={classes.footerCell} style={gutterStyle}>
              {rows[0] /* just a sample to have the proper width */}
            </div>
          ))}
        </div>
        <div className={classes.scrollFooterContainer} onScroll={syncHorizontalScrollOffset}>
          {scrollColumns.map((rows, i) => (
            <div key={i} className={classes.footerCell} style={gutterStyle}>
              {rows[0] /* just a sample to have the proper width */}
            </div>
          ))}
        </div>
      </div>
    </Root>
  );
});

function Column(props: { rows: any[]; css: any; gutterStyle?: React.CSSProperties; toscaPrefix: string }) {
  const { rows, css, gutterStyle, toscaPrefix } = props;
  return (
    <div id={toscaPrefix} className={css.column} style={gutterStyle}>
      {rows.map((cell, i) => (
        <div id={`${toscaPrefix}-row-${i}`} key={i} className={css.cell}>
          {cell}
        </div>
      ))}
    </div>
  );
}
