import { baseUrl } from "../store/apiUrlProvider";
import { v4 as uuid } from "uuid";
import { handleRequest } from "../modules/http-request-utils";

export const requestOidcConfig = async (): Promise<any> => {
  const OIDCCONFIGURL: string = baseUrl() + "api/masterdata/oidcConfig";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "JB-Correlation-Request-Id": uuid(), //generate a new correlation request id for every request
  };

  const params: any = {
    headers,
    method: "GET",
    credentials: "include",
  };

  return await handleRequest(OIDCCONFIGURL, params);
};
