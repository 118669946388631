import * as React from "react";
import { InstrumentList, InstrumentListItem, ServerMessage } from "../../types/InstrumentList";
import { getSortSeverity } from "./utils";
import {
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableHead,
  IconButton,
  TablePagination,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DeleteIcon } from "../shared/Icons";
import drop from "lodash/drop";
import take from "lodash/take";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import ConfirmDialog from "./ConfirmDialog";
import { request } from "../../modules/client";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store/AppState";
import { listItemRequest } from "../../store/instrumentLists/instrumentlist-slice";
import { errorResponseToServerMessages } from "../../store/instrumentLists/InstrumentListState";
import SeverityCell from "./ValidationSeverityButton";
import { showAlert } from "../../store/app/app-slice";
import classNames from "classnames";
import ItemDialog from "./ItemDialog";

/**
 * Component to display the InstrumentList Items in an table.
 * This contains functionality to delete items in the list and to open an edit dialog (ItemDialog.tsx).
 */

const PREFIX = 'ItemTable';
const classes = {
  resolved: `${PREFIX}-resolved`,
  itemValue: `${PREFIX}-itemValue`,
  table: `${PREFIX}-table`,
  firstColumn: `${PREFIX}-firstColumn`,
  tableRow: `${PREFIX}-tableRow`,
  tableRowEditable: `${PREFIX}-tableRowEditable`,
  buttonContainer: `${PREFIX}-buttonContainer`,
}
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.resolved}`]: {
    color: theme.palette.text.secondary,
    minHeight: "1.25em",
    lineHeight: 1.25,
  },
  [`& .${classes.itemValue}`]: {
    minHeight: "1.25em",
    lineHeight: 1.25,
    fontWeight: "bold",
  },
  [`& .${classes.table}`]: {
    width: "100%",
  },
  [`& .${classes.firstColumn}`]: {
    paddingLeft: theme.spacing(1),
  },
  [`& .${classes.tableRow}`]: {
    "&:hover .MuiIconButton-root": {
      visibility: "visible",
    },
  },
  [`& .${classes.tableRowEditable}`]: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  [`& .${classes.buttonContainer}`]: {
    visibility: "hidden",
  },
}));

// Returns the item list shown on the current page
const pageSlice = (filtered: InstrumentListItem[], pageination: ItemPageination) => {
  let sorted = sortBy(filtered, (it) => {
    if (pageination.sortColumn == "status") {
      return getSortSeverity(it.validationMessages);
    } else if (pageination.sortColumn == "shortName") {
      return it.resolved?.shortName || "";
    } else {
      return "";
    }
  });
  if (pageination.sortDirection === "desc") {
    sorted = reverse(sorted);
  }
  return take(drop(sorted, pageination.currentPage * pageination.rowsPerPage), pageination.rowsPerPage);
};

async function deleteItemRequest(baseUrl: string, item: InstrumentListItem) {
  if (!item.instrumentId || !item.instrumentListId) {
    throw new Error("Can't delete items without instrumentListId or InstrumentId");
  }
  const response = await request(
    `${baseUrl}api/instrument-lists/${encodeURIComponent(item.instrumentListId)}/item/${encodeURIComponent(item.instrumentId)}`,
    {
      method: "DELETE",
    }
  );
}

export type ItemSortColumn = "shortName" | "status";
export type ItemSortDirection = "asc" | "desc";

export interface ItemPageination {
  sortColumn: ItemSortColumn;
  sortDirection: ItemSortDirection;
  currentPage: number;
  rowsPerPage: number;
}

export default function ItemTable(props: {
  list: InstrumentList;
  pushStateToHistory: (data: Partial<ItemPageination>) => void;
  pageination: ItemPageination;
  filteredItems: InstrumentListItem[];
}) {
  const dispatch = useDispatch(); // needed to reload list after an delete
  const { pageination, pushStateToHistory } = props;

  const tstCoreUrl = useSelector((state: AppState) => state.masterData.tstCoreUrl);

  const [showValidationMessageDialog, setShowValidationMessageDialog] = React.useState(false);

  const [showEditDialog, setShowEditDialog] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<InstrumentListItem | undefined>(undefined);

  // The return message(s) from the server, after the Delete-Request.
  const [deleteServerMessages, setDeleteServerMessages] = React.useState<ServerMessage[]>([]);

  const pageItems = pageSlice(props.filteredItems, pageination);

  // helper for the 'name' cell.
  const formatNameCell = (item: InstrumentListItem) => {
    return item.resolved?.shortName ? (
      <React.Fragment>
        <div className={classes.resolved}>{item.resolved.shortName}</div>
        <div className={classes.resolved} style={{ fontStyle: "italic" }}>
          {item.resolved.marketingShortName}
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className={classes.itemValue}>{"Id: " + item.instrumentId}</div>
        <div className={classes.resolved}></div>
      </React.Fragment>
    );
  };

  // helper to format values for the table.
  const formatValues = (itemValue: any, resolvedValue: any) => {
    return (
      <React.Fragment>
        <div className={classes.itemValue}>{itemValue || ""}</div>
        <div className={classes.resolved}>{resolvedValue || ""}</div>
      </React.Fragment>
    );
  };

  const updateSorting = (columnId: ItemSortColumn) => {
    const newDirection = pageination.sortDirection == "asc" ? "desc" : "asc";
    pushStateToHistory({ sortColumn: columnId, sortDirection: newDirection });
  };

  const resetState = () => {
    setSelectedItem(undefined);
    setShowDeleteDialog(false);
    setShowValidationMessageDialog(false);
    setDeleteServerMessages([]);
  };

  const onSelectItem = (item: InstrumentListItem) => {
    setSelectedItem(item);
    setShowEditDialog(true);
  };

  const onDeleteItem = () => {
    if (selectedItem == undefined) {
      return;
    }

    deleteItemRequest(tstCoreUrl, selectedItem)
      .then(() => {
        resetState();
        dispatch(showAlert("Successfully deleted item.", { type: "success" }));
        // Reload the list
        dispatch(listItemRequest({ listId: props.list.instrumentListId }));
      })
      .catch((e: any) => {
        setDeleteServerMessages(errorResponseToServerMessages(e));
      });
  };

  return (
    <Root>
      {showDeleteDialog && (
        <ConfirmDialog
          open={showDeleteDialog}
          serverMessages={deleteServerMessages}
          allowCommentsOnMessages={false}
          handleCancel={() => {
            resetState();
          }}
          handleOk={onDeleteItem}
          title="Are you sure?"
        >
          Do you really want to delete this list item?
        </ConfirmDialog>
      )}
      {showValidationMessageDialog && (
        <ConfirmDialog
          open={showValidationMessageDialog}
          serverMessages={selectedItem?.validationMessages}
          allowCommentsOnMessages
          handleOk={() => {
            resetState();
          }}
          title="Validations"
        >
          There are currently the following validation messages on this list item:
        </ConfirmDialog>
      )}
      {showEditDialog && (
        <ItemDialog
          open={showEditDialog}
          list={props.list}
          item={selectedItem}
          closeHandle={() => {
            setShowEditDialog(false);
          }}
        />
      )}

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: "20rem" }}>
              <TableSortLabel
                className={classes.firstColumn}
                active={pageination.sortColumn === "shortName"}
                direction={pageination.sortDirection}
                onClick={() => updateSorting("shortName")}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell style={{ minWidth: "12rem" }}>ISIN</TableCell>
            <TableCell style={{ minWidth: "10rem" }}>Valor</TableCell>
            <TableCell style={{ minWidth: "10rem" }}>Bloomberg Ticker</TableCell>
            <TableCell style={{ minWidth: "10rem" }}>Bloomberg Global ID</TableCell>
            <TableCell style={{ minWidth: "10rem" }}>Bloomberg Market Sector</TableCell>
            <TableCell style={{ minWidth: "10rem" }}>Data Stream Ticker</TableCell>
            <TableCell style={{ minWidth: "10rem" }}>JB Global ID</TableCell>
            <TableCell style={{ minWidth: "8rem" }}>Trade currency</TableCell>
            <TableCell style={{ minWidth: "10rem" }}>Asset sub class</TableCell>
            <TableCell style={{ minWidth: "8rem" }}>
              <TableSortLabel
                active={pageination.sortColumn === "status"}
                direction={pageination.sortDirection}
                onClick={() => updateSorting("status")}
              >
                Status
              </TableSortLabel>
            </TableCell>
            <TableCell style={{ minWidth: 40 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {pageItems.map((item) => (
            <TableRow
              className={classNames(classes.tableRow, { [classes.tableRowEditable]: props.list.canUpdateListItem })}
              key={item.instrumentId}
              onClick={() => {
                if (props.list.canUpdateListItem) {
                  onSelectItem(item);
                }
              }}
            >
              <TableCell className={classes.firstColumn}>{formatNameCell(item)} </TableCell>
              <TableCell>{formatValues(item.isin, item.resolved?.isin)}</TableCell>
              <TableCell>{formatValues(item.valor, item.resolved?.valor)}</TableCell>
              <TableCell>{formatValues(item.bloombergTicker, item.resolved?.bloombergTicker)}</TableCell>
              <TableCell>{formatValues(item.bloombergGlobalId, item.resolved?.bloombergGlobalId)}</TableCell>
              <TableCell>{formatValues(item.bloombergMarketSector, item.resolved?.bloombergMarketSector)}</TableCell>
              <TableCell>{formatValues(item.dataStreamTicker, undefined)}</TableCell>
              <TableCell>{formatValues(item.jbGlobalId, undefined)}</TableCell>
              <TableCell>{formatValues(item.currency, item.resolved?.refCurrency)}</TableCell>
              <TableCell>{formatValues(item.subAssetClass, item.resolved?.assetSubClass)}</TableCell>
              <TableCell>
                <SeverityCell
                  messages={item.validationMessages}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    setSelectedItem(item);
                    setShowValidationMessageDialog(true);
                  }}
                />
              </TableCell>
              <TableCell>
                <div className={classes.buttonContainer}>
                  <Tooltip title="Delete item from list.">
                    <IconButton
                      disableRipple
                      disableTouchRipple
                      onClick={(evt) => {
                        evt.stopPropagation();
                        setSelectedItem(item);
                        if (props.list.canDeleteListItem) {
                          setShowDeleteDialog(true);
                        }
                      }}
                    >
                      <DeleteIcon color={props.list.canDeleteListItem ? "primary" : "disabled"} />
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={props.filteredItems.length}
        onPageChange={(_, page) => {
          pushStateToHistory({ currentPage: page });
        }}
        page={pageination.currentPage}
        rowsPerPage={pageination.rowsPerPage}
        onRowsPerPageChange={(evt) => {
          pushStateToHistory({ rowsPerPage: parseInt(evt.target.value, 10), currentPage: 0 });
        }}
      />
    </Root>
  );
}
