/**
 * Information about where the allocation snapshots are mastered.
 * Values mimic dbo.enumAllocationSource.
 */
enum AllocationSource {
  TstExcel = 0,
  TstWeb = 1,
  Alpima = 2,
}

export default AllocationSource;

export function allocationSourceString(src: AllocationSource) {
  switch (src) {
    case AllocationSource.TstExcel:
      return "TST Excel";
    case AllocationSource.TstWeb:
      return "TST Web";
    case AllocationSource.Alpima:
      return "Alpima";
    default:
      return "unknown";
  }
}
