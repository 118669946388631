import * as React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AppState } from '../../store/AppState';
import { CommonFilters } from "../../store/pages/PagesState";
import SearchInput from "../shared/SearchInput";
import { setDialog } from '../compare-view/slice';
import { AllocationPasteType } from '../shared/types';

/**
 * Displays a title and a text input to execute searches.
 */

const PREFIX = 'AllocationTableHeader';
const classes = {
    root: `${PREFIX}-root`,
    allocationsButton: `${PREFIX}-allocationsButton`,
}
const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        alignItems: "center",
    },
    [`& .${classes.allocationsButton}`]: {
        marginRight: theme.spacing(2),
    },
}));

export default function AllocationTableHeader(props: {
  filters: CommonFilters;
  title: string;
  /**
   * function to execute, when input in the text filter changes.
   * TODO - IMHO it's not a good idea to spread the knowledge about filter IDs etc.
   */
  onFilterChange: (filterId: string, filterState: any) => void;
  allocationPasteType: AllocationPasteType;
}) {
  const { filters, allocationPasteType, title, onFilterChange } = props;

  const dispatch = useDispatch();

  const userState = useSelector((state: AppState) => state.user);
  const hasEditRights = userState.canChangePortfolios;

  const pasteWeights = () => {
    dispatch(setDialog({ dialog: "paste-multiple", allocationPasteType: allocationPasteType }));
  }

  function setFilter(value: string) {
    onFilterChange("Name", value);
  }

  return (
    <Root className={classes.root}>
      <Typography variant="h2">{title}</Typography>
      <div>
        { hasEditRights && <Button className={classes.allocationsButton} onClick={pasteWeights}>
            { allocationPasteType === "mopo" ? "Upload Model Portfolios" : "Upload TAAs / SAAs" }
        </Button> }
        <SearchInput
          style={{ width: "50rem" }}
          placeholder="Search for owner or code"
          value={filters.Name || ""}
          onValueChange={(value) => setFilter(value)}
          clearButtonDisabled={!filters.Name}
        />
      </div>
    </Root>
  );
}
