export enum ColumnType {
    ReadOnly,
    Editable,
    Dummy,
  }
  
export interface ColumnDefinition<TRowValue> {
  propName: string;
  label: string;
  columnType: ColumnType;
  editCallBack?: (rowValue: TRowValue) => void;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  actions?: {
    icon: JSX.Element;
    iconClickCallback: (rowValue: TRowValue) => void;
    tooltip: string;
    isVisible: (rowValue: TRowValue) => boolean;
  }[];
}