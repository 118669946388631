import { all, fork } from "redux-saga/effects";
import app from "./app/app-saga";
import admin from "./admin/admin-saga";
import instrumentLists from "./instrumentLists/instrumentlist-saga";
import masterData from "./masterData/masterData-saga";
import pages from "./pages/pages-saga";
import user from "./user/user-saga";

export default function* root() {
  yield all([fork(app), fork(admin), fork(masterData), fork(user), fork(pages), fork(instrumentLists)]);
}
