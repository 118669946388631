import { useDispatch } from "react-redux";
import { showAlert } from "../../store/app/app-slice";
import { hideLoadingScreen, showLoadingScreen } from "../../store/loading/loading-slice";
import { addInstrument, setDialog } from "./slice";
import * as api from "./apiRequests";

/**
 * Hook encapsulating async functions around instruments.
 */
export default function useInstruments() {
  const dispatch = useDispatch();

  return {
    /**
     * Fetches an instrument by the given ID and adds it to the table.
     */
    async addInstrument(instrumentId: string) {
      try {
        dispatch(showLoadingScreen());
        const instrument = await api.getInstrument(instrumentId);
        dispatch(addInstrument(instrument));
      } catch (err) {
        dispatch(showAlert(`Failed to fetch instrument '${instrumentId}'`, { type: "error" }));
        console.error(err);
      } finally {
        dispatch(hideLoadingScreen());
      }
    },

    /**
     * Fetches an instrument by the given ID and shows a dialog to add it to the table.
     */
    async addInstrumentViaDialog(instrumentId: string) {
      try {
        dispatch(showLoadingScreen());
        const instrument = await api.getInstrument(instrumentId);
        dispatch(setDialog({ dialog: "instrument", instrument }));
      } catch (err) {
        dispatch(showAlert(`Failed to fetch instrument '${instrumentId}'`, { type: "error" }));
        console.error(err);
      } finally {
        dispatch(hideLoadingScreen());
      }
    },

    /**
     * Fetches the IC structure mapping based on relevant instrument attributes.
     */
    async getIcStructureMapping(assetClass: string | undefined, assetSubClass: string, riskCurrency: string) {
      try {
        dispatch(showLoadingScreen());
        const result = await api.getIcStructureMapping(assetClass, assetSubClass, riskCurrency);
        return result;
      } catch (err) {
        dispatch(showAlert(`Failed to fetch IC structure mapping`, { type: "error" }));
        console.error(err);
      } finally {
        dispatch(hideLoadingScreen());
      }
    },
  };
}
