import { BigType } from "big.js";
import Allocation from "../../../types/Allocation";
import { isTailoringEnabled } from "../../meta-view/TailoringInformation";
import { AllocationColumn, AllocationRowDescriptor, EditInfo } from "../types";
import { UserState } from "../../../store/user/UserState";
import { isZeroOrUnset, ZERO } from "./weights";

/**
 * Determines edit info for allocations.
 * TODO - call this in reducers (slice) instead of update* methods,
 * because of duplication (e.g. for hasDeletedInstruments). We could
 * make this callable per allocation to avoid unnecessary work when
 * editing...
 */
export default function getEditInfo(
  user: UserState,
  allocationSumTolerance: BigType,
  allocations: Allocation[],
  columns: AllocationColumn[],
  rows: AllocationRowDescriptor[]
): EditInfo[] {
  if (allocations.length !== columns.length) {
    throw new Error("lengths of allocations and columns must match");
  }

  const weightSums = columns.map((c) => c.sum);
  const deletedInstrumentRowIds = rows.filter((r) => r.isInstrument && r.isDeleted).map((r) => r.rowId);

  return allocations
    .map((a) => ({
      allocation: a,
      hasEditPermission: user.canChangePortfolios,
      isInEdit: a.State?.ShortName === "InEdit",
      isEditable: a.IsInEditableState && (user.isAdmin || (a.IsOwner && a.IsEditable)),
      hasInvalidCell: false,
      isTailoringEnabled: isTailoringEnabled(a),
      hasChanges: false,
    }))
    .map((a, i) => ({
      ...a,
      isInEditByUser: a.isInEdit && a.isEditable,
      savedSumOk: !!weightSums[i] && weightSums[i].saved.minus(1.0).abs().lte(allocationSumTolerance),
      hasDeletedInstruments: Object.keys(columns[i].cells).some(
        (rowId) => deletedInstrumentRowIds.includes(rowId) && !isZeroOrUnset(columns[i].cells[rowId].saved)
      ),
      /**
       * It's sufficient, to evaluate this here, because:
       * a) we need this flag to get updated only if one or more allocations refresh
       * b) we don't care about the strange behaviour (duplicates) caused by Object.values over immutable.js proxy
       *    (remember, that this function is executed in reducer context -> immer.js infrastructure is present)
       */
      hasSavedWeights: Object.values(columns[i].cells).some((c) => !(c.saved || ZERO).eq(ZERO)),
    }));
}
