/* global process */
export const baseUrl = (): string => {
  // Please not, that 'process.env.NODE_ENV' gets replaced by babel
  // with the actual value (taken from the environment).
  // -> The actual value returned by the function depends on the environment during the build (!)
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    /*
      directly call the API in IIS Express and don't go through the proxy of the webpack development server
      this is necessary, so windows authentification also works in DEV
    */

    // see env.js
    const port = process.env.TST_WEB_API_PORT || 59657;
    return `http://localhost:${port}/`;
  }
  //although not ideal, tests are calling the fetch method
  //as a temporary fix (only absolute URLs are supported by node-fetch)
  else if (process.env.NODE_ENV == "test") {
    return "http://localhost/";
  }
  return "/";
};

export function timestampParameter() {
  return `time=${new Date().getTime().toString()}`;
}
