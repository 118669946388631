import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { request } from "../../modules/client";
import { showAlert } from "../../store/app/app-slice";
import { AppState } from "../../store/AppState";
import { StatsPanelEntry } from "./StatsPanel";

export default function useInstrumentStatus() {
  const dispatch = useDispatch();
  const statusUrl = useSelector((state: AppState) => state.masterData.tstCoreUrl) + "api/status";

  return React.useMemo(
    () =>
      async function getInstrumentStatus(): Promise<StatsPanelEntry[]> {
        if (!statusUrl.startsWith("http")) {
          // masterdata not loaded yet -> wrong url
          return [];
        }
        try {
          const result: any = await request(statusUrl);
          return result?.nodes
            .find((n: any) => n.nodeId === "coreservice")
            ?.properties.map((p: any) => ({
              ...labelValue[p.propertyId](p.value),
              isError: p.severityId !== "information",
            }))
            .filter((e: any) => e.value !== undefined);
        } catch (e) {
          dispatch(showAlert("Could not fetch instrument status", { type: "error" }));
          console.error("getInstrumentStatus failed", e);
          return [];
        }
      },
    [statusUrl]
  );
}

const labelValue = {
  Sources: (value: any) => ({
    ...split(value, "/"),
    label: "available instrument sources",
  }),
  Coverage: (value: any) => ({
    ...split(value, "/"),
    label: "expected instruments covered",
  }),
  Age: (value: any) => {
    if (value === "Prev Closing") {
      return { value: "fresh", label: "data is from prev closing" };
    }
    return {
      ...split(value, " "),
      label: "age of instrument data",
    };
  },
  Issues: (value: any) => ({
    value: shortenLargeNumber(value),
    label: "instrument quality issues",
  }),
  Quantity: (value: any) => ({
    value: shortenLargeNumber(value),
    label: "rows imported over all sources",
  }),
};

function split(value: any, separator: string) {
  const ratio = ((typeof value === "string" && value) || "").split(separator);
  return {
    value: ratio[0],
    complementaryValue: separator + (separator.endsWith(" ") ? "" : " ") + ratio[1],
  };
}

//https://stackoverflow.com/a/28608086
function shortenLargeNumber(num: number, digits?: number) {
  const units = ["k", "M"];
  for (var i = units.length - 1; i >= 0; i--) {
    const decimal = Math.pow(1000, i + 1);
    if (num <= -decimal || num >= decimal) {
      return +(num / decimal).toFixed(digits) + units[i];
    }
  }
  return num;
}
