import * as React from "react";
import { styled } from '@mui/material/styles';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import StatsPanel from "./StatsPanel";
import PanelDescriptionHeader from "./PanelDescriptionHeader";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import formatDate from "date-fns/format";
import sortBy from "lodash/sortBy";
import cn from "classnames";
import { request } from "../../modules/client";
import { baseUrl } from "../../store/apiUrlProvider";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoadingScreen, showLoadingScreen } from "../../store/loading/loading-slice";
import { showAlert } from "../../store/app/app-slice";
import format from "date-fns/format";
import parseJSON from "date-fns/parseJSON";

const PREFIX = 'PublishPanel';
const classes = {
  root: `${PREFIX}-root`,
  queuedContainer: `${PREFIX}-queuedContainer`,
  publishHistory: `${PREFIX}-publishHistory`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    "& *": {
      boxSizing: "border-box",
    },
    "& a": {
      color: theme.palette.primary.main,
    },
    "& > hr": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    "& .section-header": {
      marginBottom: theme.spacing(2),
    },
  },

  [`& .${classes.queuedContainer}`]: {
    "& > ul": {
      width: "70rem",
      maxHeight: theme.spacing(40),
      overflowY: "auto",
      marginBottom: theme.spacing(2),
    },
    "& .blocked-item": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
      "& .blocked-item-unblock": {
        fontSize: "1.3rem",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: 0,
        paddingBottom: 0,
        visibility: "hidden",
      },
      "& .blocked-item-delete": {
        color: theme.palette.error.main,
      },
      "&:hover .blocked-item-unblock": {
        visibility: "visible",
      },
    },
  },

  [`& .${classes.publishHistory}`]: {
    "& .section-header": {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      "& > h2": {
        marginRight: theme.spacing(5),
      },
    },
    "& .item": {
      width: "70vw",
      "& .item-header": {
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > div": {
          minWidth: 0,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        "& > div:nth-child(1)": { flex: "0 16rem" },
        "& > div:nth-child(2)": { flex: "0 7rem" },
        "& > div:nth-child(3)": { flex: 1 },
        "& .chip": {
          color: "white",
          width: "fit-content",
          paddingLeft: theme.spacing(0.75),
          paddingRight: theme.spacing(0.75),
          borderRadius: theme.spacing(1),
        },
        "& .success": {
          backgroundColor: theme.palette.success.main,
        },
        "& .pending": {
          backgroundColor: theme.palette.info.main,
        },
        "& .warning": {
          backgroundColor: theme.palette.warning.main,
        },
        "& .error": {
          backgroundColor: theme.palette.error.main,
        },
        "& a:hover": {
          textDecoration: "underline",
        },
      },
      "& .item-details": {
        fontFamily: "monospace",
        width: "100%",
        overflowX: "auto",
        marginLeft: theme.spacing(2),
        "& > *": {
          marginTop: theme.spacing(0.5),
          marginBottom: 0,
        },
        "& > *:first-child": {
          marginTop: theme.spacing(1.5),
        },
        "& > *:last-child": {
          marginBottom: theme.spacing(1.5),
        },
      },
      "& .item-details-hidden": {
        display: "none",
      },
    },
    "& button": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    "& li:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)", // same as `<ListItem button>`
    },
    "& .dash": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  }
}));

export default function PublishPanel() {

  const dispatch = useDispatch();
  const [errorsAndWarningsOnly, setErrorsAndWarningsOnly] = React.useState(false);
  const [runPublishResult, setRunPublishResult] = React.useState<Maybe<RunPublishResult>>();
  const [displayedDataTimestamp, setDisplayedDataTimestamp] = React.useState<Date | undefined>(undefined);
  const [visibleHistoryCount, setVisibleHistoryCount] = React.useState(0);

  const [status, setStatus] = React.useState<PublishStatus>({
    LastProcessingDate: undefined,
    LastPublishRequest: undefined,
    QueuedAllocations: [],
    QueuedLinkRequests: [],
    PublishedAllocationsInLast24h: 0,
    PublishedLinksInLast24h: 0,
    History: [],
    TotalAllocationPublishHistoryCount: 0,
    TotalStrategyLinkHistoryCount: 0,
  });

  const { stats, queuedAllocations, blockedAllocations, queuedLinkRequests, blockedLinkRequests } = getPublishInfo(status);
  const filteredHistory = status.History.filter((e) => !errorsAndWarningsOnly || isErrorOrWarning(e.Outcome));
  const visibleHistory = filteredHistory.slice(0, visibleHistoryCount);
  const canRunPublish = queuedAllocations.length > 0 || queuedLinkRequests.length > 0;
  const canShowMore = visibleHistoryCount < filteredHistory.length;

  const doRefresh = async () => {
    dispatch(showLoadingScreen());
    try {
      setVisibleHistoryCount(100);
      const status = await fetchPublishStatus();
      setStatus(status);
      setDisplayedDataTimestamp(new Date());
    } catch (e) {
      dispatch(showAlert("Could not load data from backend", { type: "error" }));
      console.error("doRefresh failed", e);
    } finally {
      dispatch(hideLoadingScreen());
    }
  };

  const doRunPublish = async () => {
    dispatch(showLoadingScreen());
    try {
      const response = await request(`${baseUrl()}api/publish/run?skipIntegration=true`);
      setRunPublishResult(response);
      if (response.OverallOutcome === "Success") {
        dispatch(showAlert("Publish succeeded", { type: "success" }));
      } else if (response.OverallOutcome === "Warning") {
        dispatch(showAlert("Publish finished with warnings", { type: "warning" }));
      } else {
        dispatch(showAlert("Publish failed", { type: "error" }));
      }
    } catch (e) {
      console.error("doRunPublish failed", e);
      dispatch(showAlert("Failed to publish", { type: "error" }));
    } finally {
      await doRefresh();
      dispatch(hideLoadingScreen());
    }
  };

  const doUnblock = async (allocationIds: string[], linkRequestIds: string[]) => {
    dispatch(showLoadingScreen());
    try {
      await request(`${baseUrl()}api/publish/unblock`, {
        method: "POST",
        payload: {
          allocationIds,
          linkRequestIds,
        },
      });
      dispatch(showAlert("Unblock succeeded", { type: "success" }));
    } catch (e) {
      console.error("doUnblock failed", e);
      dispatch(showAlert("Failed to unblock", { type: "error" }));
    } finally {
      await doRefresh();
      dispatch(hideLoadingScreen());
    }
  };

  const doDelete = async (linkRequestId: string) => {
    dispatch(showLoadingScreen());
    try {
      await request(`${baseUrl()}api/publish/delete-link-request/${linkRequestId}`, { method: "POST" });
      dispatch(showAlert("Deleted link request", { type: "success" }));
    } catch (e) {
      console.error("doDelete failed", e);
      dispatch(showAlert("Failed to delete link request", { type: "error" }));
    } finally {
      await doRefresh();
      dispatch(hideLoadingScreen());
    }
  };

  const showMore = () => {
    setVisibleHistoryCount((x) => x + 100);
  };

  React.useEffect(() => {
    doRefresh();
  }, []);

  return (
    <Root className={classes.root}>
      <PanelDescriptionHeader>Status and details of TripleA publishing operations.</PanelDescriptionHeader>
      <StatsPanel entries={stats} refreshCallback={doRefresh} />
      {status.LastProcessingDate && (
        <List dense>
          {displayedDataTimestamp && (
            <ListItem>
              Last refresh of displayed data at <strong>&nbsp;{format(displayedDataTimestamp, "HH:mm")}</strong>.
            </ListItem>
          )}
          <ListItem>
            Last processing happened <strong>&nbsp;{formatDistanceToNow(status.LastProcessingDate)}&nbsp;</strong> ago.
          </ListItem>
          <ListItem>
            Received last request{" "}
            <strong>&nbsp;{formatDistanceToNow(status.LastPublishRequest || status.LastProcessingDate)}&nbsp;</strong> ago.
          </ListItem>
        </List>
      )}
      <Divider />
      <div className={classes.queuedContainer}>
        <Typography className="section-header" variant="h2" color={canRunPublish ? "primary" : "textSecondary"}>
          {canRunPublish ? (
            "Queued Items"
          ) : (
            <>
              Empty Queues <small>&mdash; nothing to publish</small>
            </>
          )}
        </Typography>
        <List dense>
          {queuedAllocations.map((a, i) => (
            <ListItem key={i} button>
              <ListItemText disableTypography>
                <Link to={"/modelportfolio/" + a.ContainerId}>{a.ObjectName}</Link>
              </ListItemText>
            </ListItem>
          ))}
          {queuedLinkRequests.length > 0 && (
            <List dense>
              <ListItem>
                <ListItemText disableTypography>
                  {queuedLinkRequests.length} Link-Request{queuedLinkRequests.length > 1 ? "s" : ""}
                </ListItemText>
              </ListItem>
            </List>
          )}
        </List>
        <Button variant="outlined" disabled={!canRunPublish} onClick={doRunPublish}>
          Run Publish
        </Button>
        <List dense>
          {runPublishResult?.OverallOutcome && (
            <ListItem>Last processing outcome - {runPublishResult.OverallOutcome.toLowerCase()}:</ListItem>
          )}
          {runPublishResult?.Message && runPublishResult?.Message?.split("\n").map((l, i) => <ListItem key={i}>{l}</ListItem>)}
        </List>
      </div>
      {blockedAllocations.length + blockedLinkRequests.length > 0 && (
        <>
          <Divider />
          <div className={classes.queuedContainer}>
            <Typography variant="h2">Blocked Items</Typography>
            <List dense>
              {blockedAllocations.map((a, i) => (
                <ListItem key={i} button>
                  <ListItemText disableTypography className="blocked-item">
                    <Link to={"/modelportfolio/" + a.ContainerId}>{a.ObjectName}</Link>
                    <Button variant="text" className="blocked-item-unblock" onClick={() => doUnblock([a.ContainerId], [])}>
                      Unblock
                    </Button>
                  </ListItemText>
                </ListItem>
              ))}
              {blockedLinkRequests.map((r) => (
                <ListItem key={r.Id} button>
                  <ListItemText disableTypography className="blocked-item">
                    <div>
                      Link ID {r.Id} - {r.MoPoCode || r.TaaCode || r.SaaCode}
                    </div>
                    <div>
                      <Button variant="text" className="blocked-item-unblock" onClick={() => doUnblock([], [r.RequestId])}>
                        Unblock
                      </Button>
                      <Button
                        variant="text"
                        className="blocked-item-unblock blocked-item-delete"
                        onClick={() => doDelete(r.RequestId)}
                      >
                        Delete
                      </Button>
                    </div>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
            <Button
              variant="outlined"
              onClick={() =>
                doUnblock(
                  blockedAllocations.map((a) => a.ContainerId),
                  blockedLinkRequests.map((r) => r.RequestId)
                )
              }
            >
              Unblock All
            </Button>
          </div>
        </>
      )}
      <Divider />
      <div className={classes.publishHistory}>
        <div className="section-header">
          <Typography variant="h2">Publish History</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={errorsAndWarningsOnly}
                onChange={() => setErrorsAndWarningsOnly(!errorsAndWarningsOnly)}
                color="secondary"
                disableRipple
              />
            }
            label="errors &amp; warnings only"
          />
        </div>
        <List dense>
          {visibleHistory.map((e) => (
            <ListItem key={e.Id}>
              <PublishHistoryItem {...e} />
            </ListItem>
          ))}
        </List>
        <Button variant="outlined" onClick={showMore} disabled={!canShowMore}>
          {canShowMore ? "Show More" : "Nothing more to show"}
        </Button>
      </div>
    </Root>
  );
}

function PublishHistoryItem(props: PublishHistoryEntry) {
  const { CorrelationId, Destination, Outcome, CreatedAt, CreatedBy, Type } = props;
  const [isExpanded, setIsExpanded] = React.useState(false);
  const condensedOutcome = toErrorWarningOrSuccess(Outcome);
  const isSuccess = condensedOutcome == "success";
  const isPending = condensedOutcome == "pending";

  return (
    <div className="item">
      <div className="item-header" onClick={() => setIsExpanded(!isExpanded)}>
        <div>{formatDate(CreatedAt, "yyyy-MM-dd HH:mm:ss.SSS")}</div>
        <div>
          <div className={cn("chip", condensedOutcome)}>{Type}</div>
        </div>
        <div>
          <span>
            {props.Type === "publish" ? (
              <Link to={"/modelportfolio/" + props.AllocationId}>{props.ObjectCode}</Link>
            ) : (
              props.MopoCode || props.TaaCode || props.SaaCode
            )}
            {isSuccess ? (
              <>&nbsp;&rarr;&nbsp;{props.Destination}</>
            ) : isPending ? (
              <>
                &nbsp;&rarr;&nbsp;{props.Destination} ({Outcome})
              </>
            ) : (
              ": " + props.Message
            )}
          </span>
        </div>
      </div>
      <div className={cn("item-details", !isExpanded && "item-details-hidden")}>
        <div>CorrelationId: {CorrelationId}</div>
        <div>
          Destination: <Text v={Destination} />
        </div>
        {props.Type === "publish" && (
          <>
            <div>
              AllocationId:{" "}
              {(props.AllocationId && <Link to={"/modelportfolio/" + props.AllocationId}>{props.AllocationId}</Link>) || (
                <Text v={props.AllocationId} />
              )}
            </div>
            <div>ObjectCode: {props.ObjectCode}</div>
            <div>
              DistributionCode: <Text v={props.DistributionCode} />
            </div>
            <div>Outcome: {Outcome}</div>
            <div>
              FilePath: <Text v={props.FilePath} />
            </div>
            <div>
              CallbackId: <Text v={props.CallbackId} />
            </div>
          </>
        )}
        {props.Type === "link" && (
          <>
            <div>RequestId: {props.RequestId}</div>
            <div>
              ProductLine: <Text v={props.ProductLine} />
            </div>
            <div>BookingCentre: {props.BookingCentre}</div>
            <div>BeginDate: {props.BeginDate}</div>
            <div>CallbackId: {props.CallbackId}</div>
            <div>Outcome: {props.Outcome}</div>
            <div>
              MopoCode: <Text v={props.MopoCode} />
            </div>
            <div>
              TaaCode: <Text v={props.TaaCode} />
            </div>
            <div>
              TcaCode: <Text v={props.TcaCode} />
            </div>
            <div>
              SaaCode: <Text v={props.SaaCode} />
            </div>
            <div>
              TargetMopoCode: <Text v={props.TargetMopoCode} />
            </div>
            <div>
              TargetTaaCode: <Text v={props.TargetTaaCode} />
            </div>
            <div>
              TargetTcaCode: <Text v={props.TargetTcaCode} />
            </div>
            <div>
              TargetSaaCode: <Text v={props.TargetSaaCode} />
            </div>
          </>
        )}
        <div>CreatedBy: {CreatedBy}</div>
        <div>
          Message: <Text v={props.Message} />
        </div>
        <div>
          Exception: <Text v={props.Exception} />
        </div>
      </div>
    </div>
  );
}

function Text({ v }: { v: Maybe<string> }) {
  return (
    <>
      {v === null
        ? "null"
        : v === undefined
        ? "undefined"
        : v.split("\n").map((x, i) => (
            <React.Fragment key={i}>
              {x}
              <br />
            </React.Fragment>
          ))}
    </>
  );
}

export async function fetchPublishStatus() {
  // no paging, instead just fetch last 1000 entries per history which should be sufficient
  // since we're not expecting users travel far into past
  const response = await request(`${baseUrl()}api/publish/info?count=1000`);

  const allocationHistory = response.AllocationPublishHistory.map((i: any) => ({
    ...i,
    Type: "publish",
    Id: "p" + i.TGPublishHistoryId,
    CreatedAt: parseIncomingDateAsLocal(i.CreatedAt),
  })) as any[];

  const linkHistory = response.StrategyLinkHistory.map((i: any) => ({
    ...i,
    Type: "link",
    Id: "l" + i.TGAllocationLinkHistoryId,
    CreatedAt: parseIncomingDateAsLocal(i.CreatedAt),
  })) as any[];

  const items = sortBy([...allocationHistory, ...linkHistory], (i) => i.CreatedAt).reverse();
  const LastPublishRequest = response.LastPublishRequest || response.LastProcessingDate;

  return {
    ...response,
    LastProcessingDate: items[0]?.CreatedAt,
    LastPublishRequest: (LastPublishRequest && parseJSON(LastPublishRequest)) || undefined,
    History: items,
  } as PublishStatus;
}

export function getPublishInfo(status: PublishStatus) {
  const queuedAllocations = status.QueuedAllocations.filter((q) => !q.IsBlocked);
  const blockedAllocations = status.QueuedAllocations.filter((q) => q.IsBlocked);
  const queuedLinkRequests = status.QueuedLinkRequests.filter((q) => !q.IsBlocked);
  const blockedLinkRequests = status.QueuedLinkRequests.filter((q) => q.IsBlocked);

  const stats = [
    { value: queuedAllocations.length, label: "queued allocations for publishing" },
    {
      value: blockedAllocations.length,
      label: "blocked allocations for publishing",
      isError: blockedAllocations.length > 0,
    },
    { value: queuedLinkRequests.length, label: "queued strategy link requests" },
    {
      value: blockedLinkRequests.length,
      label: "blocked strategy link requests",
      isError: blockedLinkRequests.length > 0,
    },
    { value: status.PublishedAllocationsInLast24h, label: "allocations published to T'A in last 24h" },
    { value: status.PublishedLinksInLast24h, label: "strategy links published to T'A in last 24h" },
  ];

  return { stats, queuedAllocations, blockedAllocations, queuedLinkRequests, blockedLinkRequests };
}

/**
 * The date string we receive from the backend has a TZ offset of zero (alas we don't save UTC there).
 * Since date-fns/parseJson converts the received date to the local time, it would add an offset which is
 * wrong.
 * TODO / DISCUSS - better solution is to consequently story UTC dates in DB.
 */
function parseIncomingDateAsLocal(value: string) {
  return new Date(value.replace(/Z$/, ""));
}

type RunPublishResult = {
  CorrelationId: string;
  OverallOutcome: "Success" | "Warning" | "Error";
  Message: string;
};

export type PublishStatus = {
  LastProcessingDate: Maybe<Date>;
  LastPublishRequest: Maybe<Date>;
  QueuedAllocations: { ContainerId: string; ObjectName: string; IsBlocked: boolean }[];
  QueuedLinkRequests: {
    Id: number;
    RequestId: string;
    MoPoCode: string;
    TaaCode: string;
    SaaCode: string;
    BookingCentre: string;
    BeginDate: string;
    IsBlocked: boolean;
  }[];
  PublishedAllocationsInLast24h: number;
  PublishedLinksInLast24h: number;
  History: PublishHistoryEntry[];
  TotalAllocationPublishHistoryCount: number;
  TotalStrategyLinkHistoryCount: number;
};

export type PublishHistoryEntry = {
  Id: string;
  CorrelationId: string;
  Destination: Maybe<string>;
  Outcome: string;
  Message: Maybe<string>;
  Exception: Maybe<string>;
  CreatedAt: Date;
  CreatedBy: string;
  CallbackId: Maybe<string>;
} & (PublishAllocationHistoryEntry | PublishLinkHistoryEntry);

export type PublishAllocationHistoryEntry = {
  Type: "publish";
  TGPublishHistoryId: number;
  AllocationId: Maybe<string>;
  ObjectCode: string;
  DistributionCode: Maybe<string>;
  FilePath: Maybe<string>;
};

export type PublishLinkHistoryEntry = {
  Type: "link";
  TGAllocationLinkHistoryId: number;
  RequestId: string;
  ProductLine: Maybe<string>;
  BookingCentre: string;
  BeginDate: string;
  MopoCode: Maybe<string>;
  TaaCode: Maybe<string>;
  TcaCode: Maybe<string>;
  SaaCode: Maybe<string>;
  TargetMopoCode: Maybe<string>;
  TargetTaaCode: Maybe<string>;
  TargetTcaCode: Maybe<string>;
  TargetSaaCode: Maybe<string>;
};

function isErrorOrWarning(outcome: string) {
  const condensed = toErrorWarningOrSuccess(outcome);
  return condensed == "error" || condensed == "warning";
}

function toErrorWarningOrSuccess(outcome: string) {
  switch (outcome.toLowerCase()) {
    case "error":
    case "strategyrejected":
    case "linkrejected":
    case "linkrejectedmsdinformed":
    case "linkrejectedmsderror":
      return "error";

    case "compositepending":
    case "linkpending":
    case "strategypending":
      return "pending";

    case "success":
    case "fileexported":
    case "linkconfirmed":
    case "linkconfirmedmsdinformed":
    case "strategyconfirmed":
      return "success";

    default:
      return "warning";
  }
}
