import * as React from "react";
import { styled } from '@mui/material/styles';
import { Box, Button } from "@mui/material";
import classNames from "classnames";

const PREFIX = 'StatsPanel';
const classes = {
  mainContent: `${PREFIX}-mainContent`,
  refreshButton: `${PREFIX}-refreshButton`,
  contentElement: `${PREFIX}-contentElement`,
  regularContentSpacing: `${PREFIX}-regularContentSpacing`,
  denseContentSpacing: `${PREFIX}-denseContentSpacing`,
  titleContainer: `${PREFIX}-titleContainer`,
  errorStat: `${PREFIX}-errorStat`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.mainContent}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },

  [`& .${classes.refreshButton}`]: {
    height: "100%",
    maxWidth: "15rem",
  },

  [`& .${classes.contentElement}`]: {
    maxWidth: "15rem",
    textAlign: "center",
    "& > strong": {
      fontSize: "3rem",
    },
  },

  [`& .${classes.regularContentSpacing}`]: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.denseContentSpacing}`]: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.titleContainer}`]: {
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  [`& .${classes.errorStat}`]: {
    color: theme.palette.error.main,
  }
}));

export type StatsPanelEntry = {
  value: string | number;
  complementaryValue?: string;
  label: string;
  isError?: boolean;
};

export default function StatsPanel(props: {
  /*controls the spacing between the elements*/ dense?: boolean;
  entries: Array<StatsPanelEntry>;
  refreshCallback?: () => void;
}) {
  const sizeClass = props.dense ? classes.denseContentSpacing : classes.regularContentSpacing;
  return (
    <Root>
      <div className={classNames(classes.mainContent, sizeClass)}>
        {props.refreshCallback && (
          <Box mr={3}>
            <Button variant="outlined" className={classes.refreshButton} onClick={props.refreshCallback}>
              Refresh
            </Button>
          </Box>
        )}
        {props.entries.map((it, i) => {
          const valueClassName = it.isError ? classes.errorStat : undefined;
          return (
            <div key={i} className={classNames(classes.contentElement, sizeClass)}>
              <strong className={valueClassName}>{it.value}</strong>
              {it.complementaryValue && <span className={valueClassName}>&nbsp;{it.complementaryValue}</span>}
              <br />
              {it.label}
            </div>
          );
        })}
      </div>
    </Root>
  );
}
