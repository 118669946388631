import * as React from "react";
import { styled } from '@mui/material/styles';
import IntegrationHistory from "./IntegrationHistoryArea";
import * as api from "./apiRequests";
import { Button, Divider, List, ListItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/AppState";
import PanelDescriptionHeader from "../PanelDescriptionHeader";
import StatsPanel, { StatsPanelEntry } from "../StatsPanel";
import addDays from "date-fns/addDays";
import IntegrationStatus from "./types/IntegrationStatus";
import classNames from "classnames";
import { hideLoadingScreen, showLoadingScreen } from "../../../store/loading/loading-slice";
import { format, formatDistanceToNow } from "date-fns";
import { showAlert } from "../../../store/app/app-slice";

const PREFIX = 'TstExcelIntegration';
const classes = {
  smallTopMargin: `${PREFIX}-smallTopMargin`,
  mediumTopMargin: `${PREFIX}-mediumTopMargin`,
  mediumBottomMargin: `${PREFIX}-mediumBottomMargin`,
  noBottomPadding: `${PREFIX}-noBottomPadding`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.smallTopMargin}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.mediumTopMargin}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.mediumBottomMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.noBottomPadding}`]: {
    paddingBottom: 0,
  }
}));

export default function TstExcelIntegration() {
  const dispatch = useDispatch();
  const canWriteAdministration = useSelector((state: AppState) => state.user.isAdmin);

  const [historyStartDate, setHistoryStartDate] = React.useState(addDays(Date.now(), -7));
  const [displayedDataTimestamp, setDisplayedDataTimestamp] = React.useState<Date | undefined>(undefined);

  const [status, setStatus] = React.useState<IntegrationStatus>({
    LastRun: undefined,
    Count: 0,
    PendingAllocations: 0,
    IntegratedInLast7Days: 0,
    FailedInLast7Days: 0,
    RejectedAllocationsInLast7Days: 0,
    History: [],
  });

  const doRefresh = async (showMoreHistory: boolean = false) => {
    dispatch(showLoadingScreen());

    try {
      const startDate = addDays(historyStartDate, showMoreHistory ? -7 : 0);
      const status = await api.getIntegrationInfo("excel", startDate, true);
      setStatus(status);
      setHistoryStartDate(startDate);
      setDisplayedDataTimestamp(new Date());
    } catch (e) {
      dispatch(showAlert("Could not load data from backend", { type: "error" }));
      console.error("doRefresh failed", e);
    } finally {
      dispatch(hideLoadingScreen());
    }
  };

  const runIntegration = async () => {
    dispatch(showLoadingScreen());
    try {
      const runResponse = await api.runIntegration("excel");
      dispatch(showAlert(runResponse.Message, { type: runResponse.OverallOutcome.toLowerCase() }));
      doRefresh();
    } catch (e) {
      dispatch(showAlert("Could not trigger integration.", { type: "error" }));
      console.error("runIntegration failed", e);
    } finally {
      dispatch(hideLoadingScreen());
    }
  };

  React.useEffect(() => {
    doRefresh();
  }, []);

  const stats: StatsPanelEntry[] = [
    { value: status.PendingAllocations, label: "pending TST strategy uploads to integrate" },
    { value: status.IntegratedInLast7Days, label: "integrated allocations in the last 7 days" },
    { value: status.FailedInLast7Days, label: "failed allocations in the last 7 days", isError: status.FailedInLast7Days > 0 },
  ];

  return (
    <Root>
      <PanelDescriptionHeader>Status of allocation integration (TAAs, TCAs, MOPOs) from TST-Excel.</PanelDescriptionHeader>
      <StatsPanel entries={stats} refreshCallback={doRefresh} />
      <div className={classes.smallTopMargin}>
        <List dense className={classes.noBottomPadding}>
          {displayedDataTimestamp && (
            <ListItem>
              Last refresh of displayed data at <strong>&nbsp;{format(displayedDataTimestamp, "HH:mm")}</strong>.<br />
            </ListItem>
          )}
          {status.LastRun && (
            <ListItem>
              Received last integration request <strong>&nbsp;{formatDistanceToNow(status.LastRun)}&nbsp;</strong> ago.
            </ListItem>
          )}
          {!status.LastRun && <ListItem>Last integration request unknown (before service was restarted).</ListItem>}
        </List>
      </div>
      <Divider className={classNames(classes.mediumTopMargin, classes.mediumBottomMargin)} />
      <div>
        <Button disabled={!canWriteAdministration} onClick={runIntegration}>
          Run Integration
        </Button>
        <List dense className={classNames(classes.mediumTopMargin, classes.noBottomPadding)}>
          <ListItem>Runs the integration of updated allocations from TST-Excel.</ListItem>
          <ListItem>Only considers allocations, that are configured as coming from TST-Excel.</ListItem>
        </List>
        <Divider className={classNames(classes.mediumTopMargin, classes.mediumBottomMargin)} />
        <IntegrationHistory
          title="History"
          history={status.History}
          canShowMore={status.Count > status.History.length}
          loadMore={() => {
            doRefresh(true);
          }}
        />
      </div>
    </Root>
  );
}
