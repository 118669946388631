import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { baseUrl } from "../apiUrlProvider";
import { hideLoadingScreen, showLoadingScreen } from "../loading/loading-slice";
import { showAlert } from "../app/app-slice";
import {
  publishServiceInfoRequest,
  publishServiceInfoSuccess,
  runServiceRequest,
  createAllocationCreateRequest,
  createAllocationCheckRequest,
  createAllocationCheckSuccess,
  createAllocationCreateSuccess,
  createAllocationCreateFailure,
} from "./admin-slice";
import { request } from "../../modules/client";

export const publishServiceInfo = function* () : any {
  try {
    const response = yield call(request, `${baseUrl()}api/publish/info`);
    yield put(publishServiceInfoSuccess({ data: response }));
  } catch (err) {
    yield put(showAlert("Can't get publish service infos", { type: "error" }));
  }
};

export const runService = function* ({ payload }: any) : any {
  try {
    yield put(showLoadingScreen());
    const response = yield call(request, `${baseUrl()}api/${payload.service}/run`, payload.options);
    yield put(hideLoadingScreen());

    const warnPrefix = "WARNING:";
    if (response.startsWith(warnPrefix)) {
      yield put(showAlert(response.slice(warnPrefix.length), { type: "warning" }));
    } else {
      yield put(showAlert(`Success run ${payload.service}. Result: ${response}`, { type: "success" }));
    }
  } catch (err) {
    yield put(hideLoadingScreen());
    yield put(showAlert(`Failed to run ${payload.service}`, { type: "error" }));
  }
};

function* createAllocation(action: any) : any {
  try {
    const response = yield call(request, `${baseUrl()}api/modelportfolio/create`, {
      method: "POST",
      payload: action.payload,
    });
    yield put(createAllocationCreateSuccess(response));
  } catch (error : any) {
    const message = error?.response?.data?.error;
    yield put(createAllocationCreateFailure({ error: message }));
    yield put(showAlert(`Failed to create allocation: ${message ?? "-"}`, { type: "error" }));
  }
}

function* createAllocationCheck(action: any) : any {
  try {
    const response = yield call(request, `${baseUrl()}api/modelportfolio/check`, {
      method: "POST",
      payload: action.payload,
    });
    const { AllocationId: allocationId, ObjectName: objectName } = response;
    const exists = !!allocationId && !!objectName;
    yield put(
      createAllocationCheckSuccess(
        exists
          ? {
              allocationId: response.AllocationId,
              objectName: response.ObjectName,
            }
          : null
      )
    );
  } catch (error : any) {
    const message = error?.response?.data?.error;
    yield put(showAlert(`Failed to check allocation specifier: ${message ?? "-"}`, { type: "error" }));
  }
}

export default function* root() {
  yield all([
    takeLatest(publishServiceInfoRequest.type, publishServiceInfo),
    takeLatest(runServiceRequest.type, runService),
    takeEvery(createAllocationCreateRequest.type, createAllocation),
    takeEvery(createAllocationCheckRequest.type, createAllocationCheck),
  ]);
}
