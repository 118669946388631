import * as React from "react";
import { styled } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import PlusIcon from "@mui/icons-material/Add";
import { Group } from "./types";
import * as api from "./apiRequests";
import { useDispatch } from "react-redux";
import { hideLoadingScreen, showLoadingScreen } from "../../../store/loading/loading-slice";
import { showAlert } from "../../../store/app/app-slice";

const PREFIX = 'AddOrRenameGroupPopup';
const classes = {
  root: `${PREFIX}-root`
};
const RootDialog = styled(Dialog)({
  [`&.${classes.root}`]: {
    "& .MuiInputBase-root": {
      width: "50ch",
      lineHeight: "2", // wtf - otherwise lower part of letters are hidden
    },
  },
}) as typeof Dialog;

export default function AddOrRenameGroupPopup(props: {
  groups: Group[];
  groupId?: number;
  onChange: (groupName: string) => void;
}) {
  const { groups, groupId, onChange } = props;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  const thisGroup = groups.find((g) => g.GroupId === groupId);
  const isRename = thisGroup !== undefined;
  const [newName, setNewName] = React.useState(thisGroup?.GroupName || "");
  const otherGroupNames = groups.filter((g) => g.GroupId !== thisGroup?.GroupId).map((g) => g.GroupName.trim().toLowerCase());
  const otherGroupWithSameName = otherGroupNames.includes(newName.trim().toLowerCase());
  const sameAsCurrentName = thisGroup?.GroupName.trim().toLowerCase() === newName.trim().toLowerCase();
  const canSave = !otherGroupWithSameName && !sameAsCurrentName && newName.length > 0;

  const doSave = async () => {
    dispatch(showLoadingScreen());
    const newGroupName = newName.trim();
    try {
      if (thisGroup !== undefined) {
        await api.updateGroup(thisGroup.GroupId, newGroupName, thisGroup.IsGroupDeleted);
      } else {
        await api.createGroup(newGroupName);
      }
      doClose();
      onChange(newGroupName);
    } catch (e) {
      console.error("doSave failed", e);
      dispatch(showAlert(`Failed to ${isRename ? "rename" : "add"} group`, { type: "error" }));
    } finally {
      dispatch(hideLoadingScreen());
    }
  };

  const doOpen = () => {
    setIsOpen(true);
    setNewName(thisGroup?.GroupName || "");
  };

  const doClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        disableFocusRipple
        onClick={doOpen}
        variant={isRename ? "outlined" : "contained"}
        startIcon={!isRename && <PlusIcon />}
      >
        {isRename ? "Rename ..." : "New Group"}
      </Button>
      <RootDialog open={isOpen} onClose={doClose} className={classes.root}>
        <DialogTitle>{isRename ? "Rename Group" : "New Group"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Group Name"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            error={otherGroupWithSameName}
            helperText={
              otherGroupWithSameName ? "This name is already used." : sameAsCurrentName ? "This is the current name." : " "
            }
            onKeyUp={(e) => e.key === "Enter" && canSave && doSave()}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={doClose}>Cancel</Button>
          <Button onClick={doSave} disabled={!canSave}>
            {isRename ? "Rename" : "Create"}
          </Button>
        </DialogActions>
      </RootDialog>
    </>
  );
}
