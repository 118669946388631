import * as React from "react";
import { styled } from '@mui/material/styles';
import { Box, Grid } from "@mui/material";
import History from "./AllocationHistory";
import { useDispatch, useSelector } from "react-redux";
import { MetaViewContainer } from "./MetaViewContainer";
import MetaViewHeader from "./MetaViewHeader";
import { detailPageDataRequest } from "../../store/pages/pages-slice";
import { AppState } from "../../store/AppState";
import { updateCurrentAllocationPageType } from "../../store/app/app-slice";
import { useParams } from "react-router-dom";
import { allocationPageTypeFromAllocations } from "../../types/Allocation";

const PREFIX = 'MetaDetailView';
const classes = {
  sidebar: `${PREFIX}-sidebar`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.sidebar}`]: {
    backgroundColor: "#f7f6f2",
    paddingLeft: theme.spacing(2.5),
    paddingBottom: theme.spacing(5),
  }
}));

export default function MetaDetailView() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const allocations = {
    ...useSelector((s: AppState) => s.pages.allocations),
    ...useSelector((s: AppState) => s.pages.modelPortfolios),
  };
  const allocation = allocations[id!];

  React.useEffect(() => {
    dispatch(detailPageDataRequest(id!));
  }, [id]);

  React.useEffect(() => {
    if (allocation) {
      const pageType = allocationPageTypeFromAllocations([allocation]);
      dispatch(updateCurrentAllocationPageType(pageType));
    }
  }, [allocation]);

  return (
    (<Root>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={9}>
          <Box pb={5}>
            <MetaViewHeader allocationId={id!} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} className={classes.sidebar} />
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={9}>
          {allocation && <MetaViewContainer allocation={allocation} />}
        </Grid>
        <Grid item xs={12} sm={3} className={classes.sidebar}>
          <History id={id!} />
        </Grid>
      </Grid>
    </Root>)
  );
}
