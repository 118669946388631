import { TreeNode } from "../../../modules/asset-tree-builder";
import { AllocationColumn, AllocationRowDescriptor } from "../types";
import hasWeights from "./hasWeights";
import { fromApi, zeroWeights } from "./weights";

/**
 * Traverses a recursive `TreeNode` structure depth-first/pre-order and outputs
 * an array of rows (categories and instruments alike) with all information
 * required in the compare table.
 * Complements the cells in the passed columns.
 *
 * @param nodes The children nodes of the tree root.
 */
export default function flattenToRows(nodes: { [id: number]: TreeNode }, columns: AllocationColumn[]): AllocationRowDescriptor[] {
  const stack = Object.values(nodes).reverse();
  const rows: AllocationRowDescriptor[] = [];

  while (stack.length > 0) {
    const current = stack.pop();

    if (!(current != null && typeof current === "object" && Array.isArray(current) === false)) {
      //console.log("current node is not an object - wtf?", current);
      continue;
    }

    let categoryRowId = current.id;

    rows.push({
      rowId: categoryRowId,
      isInstrument: false,
      instrumentCount: 0,
      level: current.indent,
      category: current.label,
      hasWeights: current.weights.some((w) => hasWeights(w)),
    });

    columns.forEach((c) => {
      c.cells[categoryRowId] = {
        rowId: categoryRowId,
        dynamicExceedsThreshold: false,
        averageExceedsThreshold: false,
        savedOriginal: "",
        ...zeroWeights(), // we aggregate later
      };
    });

    if (current.instrumentTree) {
      for (let i = 0; i < current.instrumentTree.weights.length; i++) {
        const instrument = current.instrumentTree.weights[i];
        const instrumentRowId = instrument.id; // instrumentId augmented with effective assetsubclass and risk ccy

        rows.push({
          rowId: instrumentRowId,
          isInstrument: true,
          level: current.indent + 1,
          id: instrument.instrumentId,
          isInvalid: instrument.invalidInstrument || false,
          valor: instrument.instrumentId,
          isin: instrument.isin || instrument.subAssetClass,
          shortName: instrument.shortName,
          longNamePretty: instrument.longNamePretty,
          riskCurrency: instrument.riskCurrency,
          defaultTradingCurrency: instrument.defaultTradingCurrency,
          hasWeights: instrument.weights.some((w) => hasWeights(w)),
          isNotional: instrument.id.startsWith("N_"),
          isPartOfAnySnapshot: false,
          isDeleted: instrument.isDeleted,
        });

        columns.forEach((c, i) => {
          if (!c.cells[instrumentRowId]) {
            c.cells[instrumentRowId] = {
              rowId: instrumentRowId,
              ...fromApi(instrument.weights[i]),
              dynamicExceedsThreshold: false,
              averageExceedsThreshold: false,
              savedOriginal: "",
            };
          }
        });
      }
    }

    stack.push(...Object.values(current.tree || {}).reverse());
  }

  return rows;
}
