import React from "react";
import { styled } from '@mui/material/styles';
import { useDispatch } from "react-redux";
import {
  Button,
  DialogActions,
  useTheme,
  DialogContent,
  Dialog,
  DialogTitle,
  InputBase,
  Typography,
} from "@mui/material";
import uniqBy from "lodash/uniqBy";
import Allocation from "../../../../types/Allocation";
import LoadingIndicator from "../../../shared/LoadingIndicator";
import PasteTable from "./PasteTable";
import { parseSingleAllocationData } from "./parseData";
import { mergeWeights } from "../../slice";
import { getInstruments } from "../../apiRequests";
import { AllocationParseErrorResult, ResolvedInstrument, SingleAllocationRowResult } from '../../../shared/types';

const PREFIX = 'PasteDialog';
const classes = {
  subTitle: `${PREFIX}-subTitle`,
  pasteRoot: `${PREFIX}-pasteRoot`,
  pasteInput: `${PREFIX}-pasteInput`,
  pasteFocused: `${PREFIX}-pasteFocused`
};
const RootDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`& .${classes.subTitle}`]: {
    fontSize: "smaller",
    color: theme.palette.text.secondary,
  },

  [`& .${classes.pasteRoot}`]: {
    height: 100,
    width: "100%",
    border: "1px dotted grey",
  },

  [`& .${classes.pasteInput}`]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.pasteFocused}`]: {
    boxShadow: "0 0 10px #9ecaed",
  }
})) as typeof Dialog;

export default function PasteDialog(props: { allocation: Allocation; close: () => void }) {
  const { allocation, close } = props;
  const t = useTheme();
  const dispatch = useDispatch();
  const [resolved, setResolved] = React.useState([] as ResolvedInstrument[]);
  const [error, setError] = React.useState(undefined as string | undefined);
  const [isLoading, setIsLoading] = React.useState(false);

  const hasData = resolved.length > 0;
  const validCount = resolved.filter((r) => r.hasRiskCcy).length;
  const canInsert = validCount > 0;
  const hasSomeErrors = validCount > 0 && validCount < resolved.length;

  async function paste(e: React.ClipboardEvent) {
    setError(undefined);
    const data = e.clipboardData.getData("Text");
    let result = parseSingleAllocationData(data);

    if ((result as AllocationParseErrorResult).error) {
      result = result as AllocationParseErrorResult;
      setError(result.error);
      return;
    }

    const instruments = result as SingleAllocationRowResult[];

    if (!instruments) return;
    setIsLoading(true);

    try {
      setResolved(await resolveInstruments(instruments));
    } catch {
      setError("Resolving instruments failed.");
    } finally {
      setIsLoading(false);
    }
  }

  function insertInstruments() {
    const pasted = resolved
      .filter((r) => r.hasRiskCcy && r.instrument)
      .map((r) => ({ instrument: r.instrument!, weight: r.weight }));

    dispatch(mergeWeights({ allocationId: allocation.Id, weights: pasted }));
    close();
  }

  function focusInputField(input: any) {
    if (input) {
      setTimeout(() => input.focus(), 100);
    }
  }

  return (
    <RootDialog open={true} onClose={close} maxWidth={false}>
      <DialogTitle>
        Paste Instruments
        <br />
        <span className={classes.subTitle}>{allocation.Name}</span>
      </DialogTitle>
      <DialogContent style={{ width: 700 }}>
        {(hasData && <PasteTable data={resolved} />) || (
          <>
            <LoadingIndicator show={isLoading} size="48">
              <InputBase
                placeholder={isLoading ? "Resolving instruments..." : "Copy and paste Excel or Triple'A data here..."}
                onPaste={paste}
                value={""}
                inputRef={focusInputField}
                classes={{
                  root: classes.pasteRoot,
                  focused: classes.pasteFocused,
                  input: classes.pasteInput,
                }}
                disabled={isLoading}
              />
            </LoadingIndicator>
            <Typography color="error">{error}</Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button variant="contained" style={{ marginLeft: t.spacing(2) }} onClick={insertInstruments} disabled={!canInsert}>
          {hasSomeErrors ? "Insert Resolved" : "Insert"}
        </Button>
      </DialogActions>
    </RootDialog>
  );
}

async function resolveInstruments(instruments: SingleAllocationRowResult[]): Promise<ResolvedInstrument[]> {
  const response = await getInstruments(instruments.map((i) => i.pasteId));
  return uniqBy(
    instruments.map((i) => {
      const match = response.find((r) => r.Id === i.pasteId || r.Isin === i.pasteId)!;
      return {
        ...i,
        resolved: !!match,
        hasRiskCcy: !!match?.RiskCurrency,
        instrument: match,
      };
    }),
    (x) => x.instrument
  );
}
