import { Theme } from "@mui/material";

/**
 * CSS "mixin" to be used in header, main, and footer sections.
 * Adapts the left & right padding.
 *
 * QUESTION - Maybe it's better to have a shared component to enforce
 * this responsive padding?
 */
export function responsiveHorizontalPadding(theme: Theme) {
  return {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "70px",
      paddingRight: "70px",
    },
  };
}
