import adminReducer from "./admin/admin-slice";
import pagesReducer from "./pages/pages-slice";
import userReducer from "./user/user-slice";
import appReducer from "./app/app-slice";
import loadingReducer from "./loading/loading-slice";
import instrumentListReducer from "./instrumentLists/instrumentlist-slice";
import masterDataReducer from "./masterData/masterData-slice";
import compareReducer from "../components/compare-view/slice";

export default {
  app: appReducer,
  masterData: masterDataReducer,
  pages: pagesReducer,
  loading: loadingReducer,
  user: userReducer,
  admin: adminReducer,
  instrumentLists: instrumentListReducer,
  compare: compareReducer,
};
