import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Location } from '@remix-run/router';
import { applyQueryString } from '../store/pages/pages-slice';
import { useDispatch, useStore } from 'react-redux';
import { AppState } from '../store/AppState';
import querystring from '../store/pages/pages-querystring';
import { AllocationPageType, allocationPageTypeToResultKey } from '../types/AllocationPageType';

// https://reactrouter.com/en/main/hooks/use-location
export const useOnLocationChange = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const store = useStore<AppState>();

    const handleLocationChange = useCallback((location: Location) => {
        let pageType: AllocationPageType;
        if (location.pathname === "/modelportfolios") {
            pageType = AllocationPageType.ModelPortfolio;
        } else if (location.pathname === "/allocations") {
            pageType = AllocationPageType.Allocation;
        } else {
            return undefined;
        }

        if (pageType === undefined) return;

        dispatch(applyQueryString({ pageType, location: window.location }));

        // If `lastState` query parameter is found, it's our sign to update the URL from the state.
        // This is useful for in-app navigation as it happens for example in `Header`.
        if (location.search === "?lastState") {
            const key = allocationPageTypeToResultKey(pageType); // this wouldn't be necessary, if pageType itself would be the key
            const state = store.getState().pages.pagination[key];
            querystring.setParams(state, window.location);
        }
    }, []);

    useEffect(() => {
        handleLocationChange(location)
    }, [location, handleLocationChange]);
};