import React from "react";
import { styled } from '@mui/material/styles';
import { useTheme } from "@mui/material";
import ScrollTable from "./ScrollTable";
import AllocationHeader from "./AllocationHeader";
import OptionsPanel from "./OptionsPanel";
import PageHeader from "./PageHeader";
import RowDescriptionCell, { RowDescriptionHeader } from "./RowDescriptionCell";
import WeightCells from "./WeightCells";
import useKeepEditAlive from "./useKeepEditAlive";
import useInitialLoad from "./useInitialLoad";
import useConfirmNavigation from "./useConfirmNavigation";
import logRender from "./utils/logRender";
import useCompareSelector from "./useCompareSelector";
import { AllocationCell, AllocationColumn, AllocationRowDescriptor } from "./types";

const PREFIX = 'CompareView';
const classes = {
  root: `${PREFIX}-root`,
  structureHeader: `${PREFIX}-structureHeader`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    "& > *:not(:first-child)": { marginTop: theme.spacing(2) },
  },

  [`& .${classes.structureHeader}`]: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    "& > div + div": {
      marginTop: theme.spacing(1),
    },
  }
}));

export default function CompareView() {
  const space = useTheme().spacing;

  useInitialLoad();
  useKeepEditAlive();
  const navigationPrompt = useConfirmNavigation();

  const options = useCompareSelector((s) => s.options);
  const allocations = useCompareSelector((s) => s.allocations);
  const rows = useCompareSelector((s) => s.visibleRows);
  const columns = useCompareSelector((s) => s.columns);
  const editInfo = useCompareSelector((s) => s.editInfo);
  const maxLevel = Math.max(...rows.map((r: { level: any; }) => r.level));
  const firstInstrumentLevel = rows.find((r: { isInstrument: any; }) => r.isInstrument)?.level || 0;

  const structureHeader = (
    <div className={classes.structureHeader}>
      <OptionsPanel />
      <RowDescriptionHeader maxLevel={maxLevel} level={firstInstrumentLevel} />
    </div>
  );

  const structureColumn = rows.map((r: AllocationRowDescriptor) => <RowDescriptionCell key={r.rowId} row={r} maxLevel={maxLevel} />);

  const allocationHeaders = columns.map((c: AllocationColumn, i: string | number) => (
    <AllocationHeader key={allocations[i].Id} allocation={allocations[i]} editInfo={editInfo[i]} column={c} options={options} />
  ));

  const allocationColumns = columns.map((c: { cells: { [x: string]: AllocationCell; }; }, i: number) =>
    rows.map((row: AllocationRowDescriptor) => (
      <WeightCells
        key={row.rowId}
        row={row}
        cell={c.cells[row.rowId]}
        allocationIndex={i}
        options={options}
        inEdit={editInfo[i].isInEditByUser}
        showSaved={editInfo[i].isInEditByUser || editInfo[i].hasSavedWeights}
      />
    ))
  );

  logRender();

  return (
    <Root className={classes.root}>
      <PageHeader />
      {allocations.length > 0 && (
        <>
          <ScrollTable
            fixedHeaders={[structureHeader]}
            fixedColumns={[structureColumn]}
            scrollHeaders={allocationHeaders}
            scrollColumns={allocationColumns}
            gutter={space(2)}
            toscaPrefix="comparetable"
          />
          {navigationPrompt}
        </>
      )}
    </Root>
  );
}
