import * as React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AllocationChangeResult } from '../../../types/AllocationChangeResult';
import { ChangeResult } from '../../../types/ChangeResult';
import { PublishDiffTable } from '../../compare-view/dialogs/PublishDiffTable';

interface IMultiplePublishTableProps {
    allocationChangeResult: AllocationChangeResult;
}

const getCount = (diff: Maybe<ChangeResult>, section: keyof ChangeResult) => {
    return (diff && diff[section].length) || 0;
};

const PREFIX = 'MultiplePublishTable';
const classes = {
    allocationExpansionContent: `${PREFIX}-allocationExpansionContent`,
}
const AccordionRoot = styled(Accordion)(({ theme }) => ({
    [`& .${classes.allocationExpansionContent}`]: {
        flexDirection: "column"
    },
})) as typeof Accordion;

export const MultiplePublishTable: React.FC<IMultiplePublishTableProps> = ({ allocationChangeResult }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return <AccordionRoot expanded={isExpanded} onChange={(_, value) => setIsExpanded(value)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{allocationChangeResult.AllocationCode}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.allocationExpansionContent}>
            <Accordion expanded={true}>
                <AccordionSummary>
                    <Typography>Changed {`(${getCount(allocationChangeResult.ChangeResult, "Changed")})`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <PublishDiffTable data={allocationChangeResult.ChangeResult?.Changed} />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
                <AccordionSummary>
                    <Typography>Inserted {`(${getCount(allocationChangeResult.ChangeResult, "Inserted")})`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <PublishDiffTable data={allocationChangeResult.ChangeResult?.Inserted} />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
                <AccordionSummary>
                    <Typography>Deleted {`(${getCount(allocationChangeResult.ChangeResult, "Deleted")})`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <PublishDiffTable data={allocationChangeResult.ChangeResult?.Deleted} />
                </AccordionDetails>
            </Accordion>
        </AccordionDetails>
    </AccordionRoot>;
};