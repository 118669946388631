import React from "react";
import { styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import { DataSourceStatus, ProductDataStatus, sources } from "../utils";
import StatusLabel from "../StatusLabel";
import { toShortDateString } from "../../../modules/date";

const PREFIX = 'DataSourcePopup';
const classes = {
  dialog: `${PREFIX}-dialog`,
  title: `${PREFIX}-title`,
  actions: `${PREFIX}-actions`
};
const RootDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`&.${classes.dialog}`]: {
    maxHeight: "75vh",
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "70rem",
    },
    "& .MuiDialogContent-root": {
      overflowY: "auto", // keeps the title and action bar in place if the content exceeds height
    },
  },

  [`& .${classes.title}`]: {
    paddingBottom: theme.spacing(2),
  },

  [`& .${classes.actions}`]: {
    paddingRight: theme.spacing(3), // align with content padding
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    justifyContent: "row-end",
  }
})) as typeof Dialog;

/**
 * Popup showing some info about data sources.
 */
export default function ProductStatusPopup(props: { data: DataSourceStatus; onClose: () => void }) {
  const errors = props.data.ErrorMessages || [];
  const warnings = props.data.WarningMessages || [];

  return (
    <RootDialog open className={classes.dialog}>
      <DialogTitle color="primary" className={classes.title}>
        Data Sources
      </DialogTitle>
      <DialogContent>
        <Info dataSourceStatus={props.data}/>
        <SourceMessages status={ProductDataStatus.Error} sourceMessages={errors} />
        <SourceMessages status={ProductDataStatus.Warning} sourceMessages={warnings} />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={props.onClose} disableFocusRipple>
          Close
        </Button>
      </DialogActions>
    </RootDialog>
  );
}

function Info(props: { dataSourceStatus: DataSourceStatus }){
  const metrics = props.dataSourceStatus.Metrics;
  const infos = [
    metrics.FdocFundSplittingSnapshotValidFromDate &&
      `Fundsplitting snapshot from ${toShortDateString(metrics.FdocFundSplittingSnapshotValidFromDate)}`,
    metrics.IeAllocationLastDate && `SAA snapshots from ${toShortDateString(metrics.IeAllocationLastDate)}`,
    metrics.IeTimeseriesLastDate && `SAA timeseries from ${toShortDateString(metrics.IeTimeseriesLastDate)}`,
    metrics.TstEodLastDate && `TST EOD integration happened ${toShortDateString(metrics.TstEodLastDate)}`,
  ].filter((i) => !!i);
  
  return(
    <>   
      {infos.length > 0 && (
        <Box marginBottom={3}>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            Info
          </Typography>
          <List items={infos}/>
        </Box>
      )}
    </>
  );
}

function SourceMessages(props: { status: ProductDataStatus, sourceMessages: { [source: string]: string[] } }) {
  return(
    <>
      {Object.keys(props.sourceMessages).map((k) => (
        <Box key={k} marginBottom={3}>
          <StatusLabel status={props.status}>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {sources[k]}
            </Typography>
          </StatusLabel>
          <List items={props.sourceMessages[k]} />
        </Box>
      ))}
    </>
  );
}

function List(props: { items: any[] }) {
  return(
    <>
      <ul>
        {props.items.map((i, x) => (
          <li key={x}>
            <Typography variant="body2" color="textSecondary">
              {i}
            </Typography>
          </li>
        ))}
      </ul>
    </>
  );
}