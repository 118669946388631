import { TextField, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import * as React from "react";
import cloneDeep from "lodash/cloneDeep";
import { InstrumentList, ServerMessage } from "../../types/InstrumentList";
import ConfirmDialog from "./ConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/AppState";
import { request } from "../../modules/client";
import formatISO from "date-fns/formatISO";
import { instrumentListsRequest } from "../../store/instrumentLists/instrumentlist-slice";
import InfoBox from "../shared/InfoBox";

const PREFIX = 'EditInstrumentListDialog';
const classes = {
  row: `${PREFIX}-row`,
  input: `${PREFIX}-input`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.row}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
  },

  [`& .${classes.input}`]: {
    width: "44rem",
    marginLeft: theme.spacing(6),
  }
}));

function createEditList(list?: InstrumentList) {
  return !list
    ? {
        instrumentListId: "",
        instrumentListCategoryId: "User",
        name: "",
        description: "",
        date: formatISO(new Date()),
        isSystemList: false,
        isDependentList: false,
        items: [],
        numberOfErrors: 0,
        numberOfWarnings: 0,
        numberOfInformations: 0,
        canUpdateList: true,
        canDeleteList: true,
        canPublish: true,
        canCreateListItem: true,
        canUpdateListItem: true,
        canDeleteListItem: true,
      }
    : cloneDeep(list);
}

async function putListRequest(baseUrl: string, list: InstrumentList) {
  const options = {
    method: "PUT",
    payload: list,
  };
  return request(`${baseUrl}api/instrument-lists/`, options);
}

export default function EditInstrumentListDialog(props: {
  open: boolean;
  list?: InstrumentList;
  existingListIds: string[];
  closeHandle: () => void;
}) {
  const dispatch = useDispatch();
  const tstCoreUrl = useSelector((state: AppState) => state.masterData.tstCoreUrl);

  const isNewList = !props.list;

  const [editList, setEditList] = React.useState<InstrumentList>(createEditList(props.list));

  const [isValid, setIsValid] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [messages, setMessages] = React.useState<ServerMessage[] | undefined>(undefined);

  const resetAndClose = () => {
    setIsDirty(false);
    setIsValid(false);
    setMessages([]);
    props.closeHandle();
  };

  const validate = () => {
    let validationMessages: ServerMessage[] = [];
    if (isNewList && props.existingListIds.some((id) => id === editList.instrumentListId)) {
      validationMessages.push({
        severity: "Error",
        text: "The provided ID is already in use.",
      });
    }
    if (editList.instrumentListId?.length === 0 || editList.instrumentListId?.length > 60) {
      validationMessages.push({
        severity: "Information",
        text: "The ID must be provided and have less then 60 characters.",
      });
    }
    if (editList.name?.length === 0 || editList.name?.length > 50) {
      validationMessages.push({
        severity: "Information",
        text: "The Name must be provided and have less then 50 characters.",
      });
    }
    setMessages(validationMessages);
    setIsValid(validationMessages.length === 0);
  };

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    editList[evt.currentTarget.name] = evt.currentTarget.value;
    setIsDirty(true);
    setEditList(editList);
    validate();
  };

  const onSave = () => {
    putListRequest(tstCoreUrl, editList)
      .then(() => {
        dispatch(instrumentListsRequest());
        resetAndClose();
      })
      .catch((reason) => {
        setMessages([
          {
            severity: "Error",
            text: "Could not save list. Error:" + reason,
          },
        ]);
      });
  };

  return (
    <ConfirmDialog
      open={props.open}
      title={isNewList ? "Create new list" : `Edit list ${props.list?.name}`}
      serverMessages={messages}
      allowCommentsOnMessages={false}
      okButtonLabel="Save"
      handleOk={onSave}
      handleCancel={resetAndClose}
      autoFocusOkButton={false}
      okButtonDisabled={!(isValid && isDirty)}
    >
      <Root>
        <div className={classes.row}>
          <label>ID</label>
          <TextField
            className={classes.input}
            name="instrumentListId"
            disabled={!isNewList}
            variant="outlined"
            defaultValue={props.list?.instrumentListId}
            margin="dense"
            onChange={onChange}
            autoFocus
          />
        </div>
        <div className={classes.row}>
          <span></span>
          {isNewList && (
            <div className={classes.input}>
              <InfoBox>
                <Typography variant="body2">
                  List IDs should use the prefix <i>LI_</i>
                  <br />
                  Building blocks should use no prefix.
                </Typography>
              </InfoBox>
            </div>
          )}
        </div>
        <div className={classes.row}>
          <label>Name</label>
          <TextField
            name="name"
            defaultValue={props.list?.name}
            variant="outlined"
            margin="dense"
            onChange={onChange}
            className={classes.input}
          />
        </div>
        <div className={classes.row}>
          <label>Description</label>
          <TextField
            name="description"
            defaultValue={props.list?.description}
            multiline={true}
            rows={4}
            variant="outlined"
            margin="dense"
            onChange={onChange}
            className={classes.input}
          />
        </div>
      </Root>
    </ConfirmDialog>
  );
}
