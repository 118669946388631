import * as React from "react";
import { IconButton, Tooltip } from "@mui/material";
import CommentIcon from "@mui/icons-material/CommentOutlined";
import { ServerMessage, ServerMessageComment } from "../../types/InstrumentList";
import orderBy from "lodash/orderBy";
import CommentBox from "./CommentBox";
import { severityToNumber } from "./utils";
import { styled } from '@mui/material/styles';

const PREFIX = 'ServerMessageDisplay';
const classes = {
    validationContainer: `${PREFIX}-validationContainer`,
    validationItem: `${PREFIX}-validationItem`,
    validationHeader: `${PREFIX}-validationHeader`,
    error: `${PREFIX}-error`,
    warning: `${PREFIX}-warning`,
    information: `${PREFIX}-information`,
}
const Root = styled('div')(({ theme }) => ({
    [`& .${classes.validationContainer}`]: {
        boxShadow: theme.shadows[2],
        overflowY: "auto",
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontSize: theme.typography.body2.fontSize,
    },
    [`&.${classes.validationItem}`]: {
        marginBottom: theme.spacing(1),
    },
    [`& .${classes.validationHeader}`]: {
        "& > .MuiButtonBase-root": {
            marginLeft: theme.spacing(1),
        },
    },
    [`& .${classes.error}`]: {
        color: theme.palette.error.main,
        fontWeight: "bold",
    },
    [`& .${classes.warning}`]: {
        color: theme.palette.warning.main,
    },
    [`& .${classes.information}`]: {
        color: "green",
    },
}));

/**
 * Displays multiple ServerMessages (which can be commented on)
 */
export default function ServerMessageDisplay(props: { messages?: ServerMessage[]; allowComments: boolean; maxHeight?: string }) {
  // Order by severity (worst on top)
  const orderedMessages = orderBy(
    props.messages || [],
    [
      (msg) => {
        return severityToNumber(msg.severity);
      },
    ],
    ["desc"]
  );

  return (
    <div className={classes.validationContainer} style={{ maxHeight: props.maxHeight }}>
      {orderedMessages.map((message, i) => (
        <ServerMessageItem key={i} message={message} allowComments={props.allowComments} />
      ))}
    </div>
  );
}

/**
 * Component to display a single ServerMessage item.
 */
function ServerMessageItem(props: { message: ServerMessage; allowComments: boolean; instrumentListId?: string }) {
  const [showCommentBox, setShowCommentBox] = React.useState(!!props.message.comment);
  const [comment, setComment] = React.useState<Maybe<ServerMessageComment>>(props.message.comment);

  const severityToClassname = (message: ServerMessage) => {
    const severity = message.comment?.severityOverride || message.severity;
    return classes.validationHeader + " " + classes[severity.toLowerCase()];
  };

  return (
    <Root key={props.message.itemNo} className={classes.validationItem}>
      <div className={severityToClassname(props.message)}>
        {props.message.severity}
        {props.allowComments && (
          <Tooltip title="Add comment">
            <div>
              <IconButton disabled={showCommentBox} size="small" onClick={() => setShowCommentBox(!showCommentBox)}>
                <CommentIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </div>
      <div>{props.message.text}</div>
      {showCommentBox && props.allowComments && (
        <CommentBox
          instrumentId={props.message.instrumentId!}
          messageTypeId={props.message.messageTypeId!}
          comment={comment}
          setComment={setComment}
          hide={() => {
            setShowCommentBox(false);
          }}
          allowDowngrade={props.message.severity == "Warning"}
        />
      )}
    </Root>
  );
}
