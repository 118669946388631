import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../store/AppState";
import ErrorDialog from "./ErrorDialog";

/**
 * Component showing error dialogs depending on certain conditions.
 */
export default function ErrorDialogs() {
  const { showUnauthorized, showServerDown, displayedError } = useSelector((s: AppState) => s.app);
  return (
    <>
      {showUnauthorized && (
        <ErrorDialog
          error="Not authorzied. Please raise a SARA request for an appropriate role for the Target Setting Tool."
          hideCloseButton
        />
      )}
      {showServerDown && (
        <ErrorDialog error="Server is down or has a critical failure. Please contact support." hideCloseButton />
      )}
      <ErrorDialog error={displayedError} />
    </>
  );
}
