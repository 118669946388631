import { request } from "../../modules/client";
import { baseUrl } from "../../store/apiUrlProvider";
import AllocationSetMember from "../../types/AllocationSetMember";

export function getAllocationLinks(allocationId: string) {
  return request(`${baseUrl()}api/modelportfolio/${allocationId}/set-members`);
}

export async function updateAllocationLinks(allocationId: string, linkCodes: string[]) {
  const result: { Members: AllocationSetMember[]; DmcErrors: Maybe<string[]> } = await request(
    `${baseUrl()}api/modelportfolio/${allocationId}/set-members`,
    {
      method: "POST",
      payload: linkCodes,
    }
  );

  return result;
}
