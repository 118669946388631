import { Checkbox, TableCell, Tooltip, IconButton } from "@mui/material";
import { styled } from '@mui/material/styles';
import React from "react";
import { ColumnDefinition, ColumnType } from "../../../types/GenericTable";

const PREFIX = 'GenericTableCell';
const classes = {
  noMargin: `${PREFIX}-noMargin`
};
const StyledTableCell = styled(TableCell)((
  {
    theme
  }
) => ({
  [`& .${classes.noMargin}`]: {
    margin: "0",
    padding: "0",
  }
}));

export function GenericTableCell<TRowValue>(props: {
  rowValue: TRowValue;
  column: ColumnDefinition<TRowValue>;
  onValueChanged: (newValue: any) => void | undefined;
}) {
  const [value, setValue] = React.useState<any>(props.rowValue[props.column.propName]);

  const actions = props.column.actions;

  const getIconsView = () => (
    <StyledTableCell align={props.column.align}>
      {actions &&
        actions.map((action, i) => (
          <Tooltip title={action.isVisible(props.rowValue) ? action.tooltip : ""} key={i}>
            <span>
              <IconButton
                disabled={!action.isVisible(props.rowValue)}
                onClick={() => action.iconClickCallback(props.rowValue)}
                className={classes.noMargin}
              >
                {action.icon}
              </IconButton>
            </span>
          </Tooltip>
        ))}
    </StyledTableCell>
  );

  const getDisplayView = () => {
    return <TableCell align={props.column.align}>{props.rowValue[props.column.propName]}</TableCell>;
  };

  const getEditView = () => {
    if (typeof value === "boolean") {
      return (
        <TableCell padding="none">
          <Checkbox onChange={(_ev, val) => onValueChanged(val)} color={value ? "primary" : "default"} checked={value} />
        </TableCell>
      );
    } else {
      throw new Error("Not implemented");
    }
  };

  const onValueChanged = (val: any) => {
    setValue(val);
    props.onValueChanged(val);
  };

  if (props.column.columnType === ColumnType.Editable) {
    return getEditView();
  } else if (props.column.columnType === ColumnType.ReadOnly) {
    return getDisplayView();
  } else return getIconsView();
}
