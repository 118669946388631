import AllocationType from "../../types/AllocationType";
import { PagesState } from "./PagesState";

export const initialState: PagesState = {
    allocations: {},
    modelPortfolios: {},
    pagination: {
      allocations: {
        currentPage: 0,
        pageSize: 25,
        totalCount: 0,
        pages: [],
        order: {
          columnId: "Name",
          order: "asc",
        },
        filter: {
          AllocationType: [AllocationType.Saa.toString(), AllocationType.Taa.toString()],
          Deleted: false,
        },
      },
      modelPortfolios: {
        currentPage: 0,
        pageSize: 25,
        totalCount: 0,
        pages: [],
        order: {
          columnId: "Name",
          order: "asc",
        },
        filter: {
          Deleted: false,
        },
      },
    },
    selection: {
      allocations: [],
      modelPortfolios: [],
    },
  };