import parseDate from "date-fns/parseJSON";
import sortBy from "lodash/sortBy";
import throttle from "lodash/throttle";
import { baseUrl } from "../../../store/apiUrlProvider";
import { request } from "../../../modules/client";
import { Group, User } from "./types";

export async function fetchGroups() {
  const response = await request(`${baseUrl()}api/groups`);

  return response.groups.map((group: any) => ({
    ...group,
    CreatedDate: parseDate(group.CreatedDate),
    ModifiedDate: group.ModifiedDate ? parseDate(group.ModifiedDate) : undefined,
    Users: sortBy(
      group.Users.map((u: User) => ({ ...u, UserDisplayName: [u.UserLastName, u.UserFirstName].join(", ") || "n.a." })),
      (u) => u.UserDisplayName
    ),
    HasDeletedMembers: group.Users.some((u: User) => u.IsUserDeleted),
  })) as Group[];
}

export function createGroup(groupName: string) {
  return request(baseUrl() + "api/groups", {
    method: "POST",
    payload: groupName,
  });
}

export function updateGroup(groupId: number, groupName: string, isDeleted: boolean) {
  return request(baseUrl() + `api/groups/${groupId}`, {
    method: "PUT",
    payload: {
      groupName,
      isDeleted,
    },
  });
}

export function addGroupMember(groupId: number, userId: number) {
  return request(baseUrl() + `api/groups/${groupId}/members/${userId}`, { method: "POST" });
}

export function removeGroupMember(groupId: number, userId: number) {
  return request(baseUrl() + `api/groups/${groupId}/members/${userId}`, { method: "DELETE" });
}

export const throttledSearch = throttle(
  (groupId: number, searchTerm: string) => request(baseUrl() + `api/groups/${groupId}/potential-members?search=${searchTerm}`),
  200
);
