import * as React from "react";
import { useNavigate } from 'react-router';
import { useSelector } from "react-redux";
import { Button, Typography } from "@mui/material";
import { AppState } from "../../store/AppState";
import { AllocationPageType, allocationPageTypeToResultKey } from "../../types/AllocationPageType";

export default function AllocationComparePanel(props: { allocationPageType: AllocationPageType }) {
  const { allocationPageType } = props;
  const navigate = useNavigate();
  const selection = useSelector((s: AppState) => s.pages.selection[allocationPageTypeToResultKey(allocationPageType)]);
  const numSelected = selection.length;
  const numTypes = new Set(selection.map((i) => i.allocationType)).size;
  const canCompare = numSelected > 1 && numTypes === 1;
  const typePlural = allocationPageType === AllocationPageType.Allocation ? " Allocations" : " Model Portfolios";
  const showWarning = canCompare && numSelected > 20;

  let compareHint: string;
  if (canCompare) {
    compareHint = `${numSelected} ${typePlural} selected`;
  } else if (numTypes > 1) {
    compareHint = "Different allocation types are not supported";
  } else {
    compareHint = "Select min 2 " + typePlural;
  }

  function goToCompareView() {
    navigate(`/compare/${selection.map((i) => i.allocationId).join(",")}`);
  }

  return (
    <div>
      <Typography variant="h3" gutterBottom>
        Comparison
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {compareHint}
      </Typography>
      <Button variant="outlined" color="primary" disabled={!canCompare} onClick={goToCompareView}>
        Compare
      </Button>
      <Typography variant="body2" color="error" gutterBottom>
        {showWarning && "Beware, more than 20 objects selected, this might impact performance and functionality."}
      </Typography>
    </div>
  );
}
