import { useState } from "react";
import { useNavigate, useLocation } from "react-router";

export default function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/**
 * A hook encapsulating a set-based query parameter, that means
 * the same key occurs multiple times with different values in the
 * URL search string.
 */
export function useQueryParameterSet<T extends { toString(): string }>(
  key: string,
  fromQueryParameterValue: (q: string) => T,
  toQueryParameterValue: (v: T) => string = (t: T) => t.toString(),
  defaultSet: Set<T> = new Set<T>()
) {
  const query = getQuery();
  const navigate = useNavigate();
  let initial = defaultSet;

  if (query.has(key)) {
    try {
      initial = new Set(query.getAll(key).map(fromQueryParameterValue));
    } catch (err) {
      console.error("Extracting query parameters failed", err);
    }
  }

  const [current, setCurrent] = useState(initial);

  return [
    current,
    function setQueryParameterSet(set: Set<T>) {
      const query = getQuery();
      query.delete(key);
      [...set.values()].forEach((i) => query.append(key, toQueryParameterValue(i)));
      navigate("?" + query.toString(), { replace: true });
      setCurrent(new Set(set));
    },
  ] as [Set<T>, (set: Set<T>) => void];
}

function getQuery() {
  return new URLSearchParams(window.location.search);
}
