/**
 * The different frequency types in TST, aligned with Triple-A.
 */
enum FrequencyUnit {
  Undefined = 0,
  Day = 1,
  Week = 3,
  Month = 4,
  Quarter = 5,
  Semester = 6,
  Year = 7
}


export const getFrequencyUnitSelectionItems = (): FrequencyUnit[] => {
  const frequencyUnits: FrequencyUnit[] = Object.values(FrequencyUnit).map(x => FrequencyUnit[x]);
  const enumValuesOnly: FrequencyUnit[] = frequencyUnits.filter(value => typeof value === 'number');
  
  return enumValuesOnly;
};

export default FrequencyUnit;
