import * as React from "react";
import { styled } from '@mui/material/styles';
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { Checkbox, IconButton, TableCell, TableRow } from "@mui/material";
import Allocation from "../../types/Allocation";
import AllocationType from "../../types/AllocationType";
import MasterDataEntry from "../../types/MasterDataEntry";
import ModelPortfolio from "../../types/ModelPortfolio";
import { useNavigate } from 'react-router';
import { InfoIcon, StarFilledIcon, StarIcon } from "../shared/Icons";
import { toShortDateString } from "../../modules/date";
import { addToSelection, removeFromSelection, toggleStarRequest } from "../../store/pages/pages-slice";
import { AllocationPageType } from "../../types/AllocationPageType";
import { ColumnData } from "../../types/AllocationTable";

const PREFIX = 'AllocationTableRow';
const classes = {
  root: `${PREFIX}-root`,
  row: `${PREFIX}-row`,
  lastCell: `${PREFIX}-lastCell`,
  iconSmall: `${PREFIX}-iconSmall`,
  deletedAllocation: `${PREFIX}-deletedAllocation`,
  forceVisibility: `${PREFIX}-forceVisibility`
};
const RootTableRow = styled(TableRow)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    marginTop: theme.spacing(3),
    cursor: "pointer",
  },

  [`&.${classes.row}`]: {
    "& .buttons > *": {
      visibility: "hidden",
    },
    "&:hover .buttons > *": {
      visibility: "visible",
    },
  },

  [`& .${classes.lastCell}`]: {
    paddingRight: "0px !important",
  },

  [`& .${classes.iconSmall}`]: {
    // TODO - utility-class candidate?
    fontSize: 20,
  },

  [`&.${classes.deletedAllocation}`]: {
    textDecorationLine: "line-through",
  },

  [`& .${classes.forceVisibility}`]: {
    // TODO - utility-class candidate?
    visibility: "visible !important" as any,
  }
})) as typeof TableRow;

export default function AllocationTableRow(props: {
  allocation: Allocation;
  columnData: ColumnData;
  isSelected: boolean;
  allocationPageType: AllocationPageType;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allocation, columnData, isSelected } = props;
  const allocationId = allocation.Id;

  const openTreeView = React.useCallback(
    (e: React.MouseEvent) => {
      navigate(`/compare/${allocationId}`);
      e.stopPropagation();
    },
    [allocationId]
  );

  const openDetails = React.useCallback(
    (e: React.MouseEvent) => {
      navigate(`/modelportfolio/${allocationId}`);
      e.stopPropagation();
    },
    [allocationId]
  );

  const toggleStar = React.useCallback(
    (e: React.MouseEvent) => {
      dispatch(toggleStarRequest(allocationId, allocation.Name, allocation.AllocationType, allocation.AllocationSource));
      e.stopPropagation();
    },
    [allocationId]
  );

  const toggleSelected = React.useCallback(
    (e: React.MouseEvent) => {
      const allocationType = allocation.AllocationType;
      const type = props.allocationPageType;
      if (isSelected) {
        dispatch(removeFromSelection({ allocationId, type }));
      } else {
        dispatch(addToSelection({ allocation: { allocationId, allocationType }, type }));
      }
      e.stopPropagation();
    },
    [allocationId, isSelected]
  );

  return (
    <RootTableRow
      className={classNames(classes.root, classes.row, {
        [classes.deletedAllocation as string]: allocation.IsDeleted,
      })}
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      key={allocation.Id}
      selected={isSelected}
      onClick={openTreeView}
    >
      <TableCell padding="none">
        <Checkbox onClick={toggleSelected} color={isSelected ? "primary" : "default"} checked={isSelected} />
      </TableCell>

      {hasColumnDefinition(columnData, "AllocationType") && (
        <TableCell>{AllocationType[allocation.AllocationType].toUpperCase()}</TableCell>
      )}
      <TableCell style={{ minWidth: "100px" }}>{displayName(allocation.ProductLine)}</TableCell>
      <TableCell>{displayName(allocation.ProductDistinction)}</TableCell>
      <TableCell>{displayName(allocation.ReferenceCurrency)}</TableCell>
      {hasColumnDefinition(columnData, "RiskProfile") && <TableCell>{displayName(allocation.RiskProfile)}</TableCell>}
      {hasColumnDefinition(columnData, "BmoContext") && (
        <TableCell>{displayName((allocation as ModelPortfolio).BmoContext)}</TableCell>
      )}
      <TableCell>{allocation.Name}</TableCell>
      <TableCell>{toShortDateString(allocation.PublishedSnapshot?.AsOfDate)}</TableCell>
      <TableCell>{displayName(allocation.Owner)}</TableCell>
      <TableCell className={classNames("buttons", classes.lastCell)}>
        <div style={{ display: "flex" }}>
          <IconButton size="small" disableRipple disableTouchRipple style={{ marginRight: 5 }} onClick={openDetails}>
            <InfoIcon color="primary" />
          </IconButton>
          <IconButton
            size="small"
            disableRipple
            disableTouchRipple
            onClick={toggleStar}
            className={classNames(allocation.Starred && classes.forceVisibility)}
          >
            {allocation.Starred ? (
              <StarFilledIcon color="primary" className={classes.iconSmall} />
            ) : (
              <StarIcon color="primary" className={classes.iconSmall} />
            )}
          </IconButton>
        </div>
      </TableCell>
    </RootTableRow>
  );
}

function displayName(content: MasterDataEntry) {
  return content?.Name;
}

function hasColumnDefinition(columnData: ColumnData, columnId: string) {
  return columnData.find((value) => value.id === columnId) !== undefined;
}
