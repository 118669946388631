import { createTheme } from "@mui/material";

const body1Size = "1.8rem";
const body2Size = "1.6rem";
const primaryColor = "#001489";

const theme = createTheme(
  {
    spacing: 8,
    components: {
      MuiButton: {
        defaultProps: {
          variant: "outlined",
          color: "primary",
        },
        styleOverrides: {
          root: {
            fontSize: body1Size,
            textTransform: "none",
            borderRadius: 3,
          },
          sizeSmall: {
            fontSize: "1.4rem",
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          color: "primary",
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            overflowX: "hidden",
          },
        },
      },
      /**/MuiSelect: {
        defaultProps: {
          variant: "outlined",
          size: "small",
        },
        styleOverrides: {
          root: {
            // TODO: values from Figma?
            // paddingTop: "1.0rem",
            // paddingBottom: "1.0rem",
          },
          icon: {
            color: primaryColor,
          },
        },
      },/**/
      MuiTextField: {
        defaultProps: {
          variant: "outlined",
          size: "small",
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: "outlined",
        },
      },
      MuiCheckbox: {
        defaultProps: {
          color: "primary",
        },
        styleOverrides: {
          root: {
            //height: 10, // this is evil - squashes focus-circle AND messes up vertical alignment (e.g. in allocation table row)
            padding: 2, // why?
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            height: "25px",
            "&:hover td:not(.nohover)": {
              backgroundColor: "#ccd0e7 !important",
              opacity: 1.0,
              color: "rgba(0, 0, 0, 0.87) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "#dfddcb !important",
            },
          },
          head: {
            backgroundColor: "#ffffff",
            borderBottom: "1.5px #828282 solid",
            padding: 0,
            height: "50px",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 5,
            paddingBottom: 5,
            fontSize: body2Size, // 16, //STYLE TODO: define somewhere globally in the typogrpahy?!
          },
          head: {
            color: "#828282",
            fontSize: "1.4rem !important", // STYLE TODO: define somwhere globally in the typography?
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            overflow: "visible",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            paddingLeft: "0.4rem",
            fontSize: body2Size,
          },
        },
      },
      // effects e.g. the Select and TextField control
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 0,
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            color: primaryColor,
          },
          select: {
            paddingTop: "6px",
          }
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            overflowY: "visible",
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginTop: "4px",
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            fontSize: "62.5%",
            WebkitFontSmoothing: "antialiased",
            height: "100%",
            boxSizing: "content-box",
          },
          body: {
            position: "relative",
            minHeight: "100%",
            fontFamily: '"Verlag Regular", Arial, sans-serif',
            fontSize: "13px",
            lineHeight: 1.3,
            color: "#333",
            padding: 0,
            margin: 0,
            backgroundColor: "unset",
          },
          a: {
            textDecoration: "none",
            "& .disabled": {
              pointerEvents: "none",
            },
          },
          input: {
            // from former normalize.scss
            fontFamily: "inherit",
            fontSize: "100%",
            lineHeight: 1.15,
            margin: 0,
          },
        }
      },
    },
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        // main: "#001489",
        main: primaryColor,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contast with palette.primary.main
      },
      secondary: {
        main: "#6672B8",
      },
      error: {
        main: "#971B2F",
      },
      text: {
        hint: 'rgba(0, 0, 0, 0.38)',
      }
    },
    typography: {
      fontFamily: `"Verlag Regular", Arial, sans-serif`,
      htmlFontSize: 10,
      useNextVariants: true,
      // the typography is based on the MSD styleguide
      // see: https://www.figma.com/file/nMxGQL3zhfyG6TxVgoY3uN/JB_TailoringMandate_ti%26m?node-id=1%3A374
      h1: {
        fontSize: "3.6rem",
      },
      h2: {
        color: "#001489",
        fontSize: "2.4rem",
        fontWeight: "normal",
      },
      h3: {
        fontSize: "2.0rem",
      },
      body1: {
        fontSize: body1Size,
      },
      body2: {
        fontSize: body2Size,
      },
    },
    gutter: {
      edge: 70,
    },
  } as any /* TODO - fix type complaints */
);

export default theme;
