import * as React from "react";
import { styled } from '@mui/material/styles';
import { Typography } from "@mui/material";

const PREFIX = 'Footer';
const classes = {
  root: `${PREFIX}-root`
};
const Root = styled('footer')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: "#bdbdbd",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }
}));

export default function Footer(props: { version: string }) {
  return (
    <Root className={classes.root}>
      <Typography>Version {props.version}</Typography>
    </Root>
  );
}
