import React from "react";
import { Button, Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { baseUrl } from "../../store/apiUrlProvider";

export default function ExcelExportButton(props: { allocationIds: string[]; isMopo: boolean; includeDynamic: boolean }) {
  const { allocationIds, isMopo, includeDynamic } = props;
  return (
    <Tooltip title={getLabel(isMopo, allocationIds.length > 1)}>
      <Button size="medium" color="primary" href={getExportUrl(allocationIds, includeDynamic)} target="_blank">
        <LinkIcon style={{ fontSize: 20 }} />
        &nbsp; Excel
      </Button>
    </Tooltip>
  );
}

function getExportUrl(allocationIds: string[], includeDynamic: boolean) {
  const tzOffset = encodeURIComponent(new Date().getTimezoneOffset());
  const ids = allocationIds.map(encodeURIComponent).join("&guids=");
  return `${baseUrl()}api/modelportfolio/iqy_export?timezoneOffsetInMinutes=${tzOffset}&guids=${ids}&includeDynamic=${includeDynamic}`;
}

function getLabel(isMopo: boolean, isPlural: boolean) {
  let label = isMopo ? "Model Portfolio" : "allocation";
  if (isPlural) label += "s";
  return `Export the published ${label} as an Excel Web Query`;
}
