import Big from "big.js";
import Allocation from "../../../types/Allocation";
import { AllocationCellWeights, AllocationColumn, AllocationRowDescriptor } from "../types";
import { cellsInRowOrder } from "./allocationColumn";

const zero = new Big(0);

/**
 * Copy weights of a particular type to the saved snapshot (within an allocation column).
 * Ignore instrument weights which are not part of the published snapshot, if the
 * latter is the specified source to copy from.
 * Leaves category cells untouched.
 */
export default function copyWeights(
  rows: AllocationRowDescriptor[],
  column: AllocationColumn,
  allocation: Allocation,
  source: keyof Omit<AllocationCellWeights, "saved">
) {
  cellsInRowOrder(column, rows, true).forEach((c) => {
    const row = rows.find((r) => r.rowId === c.rowId);
    if (row?.isInstrument) {
      const wi = allocation.PublishedSnapshot?.Instruments.find((i) => i.Instrument.Id === row.id);
      const isPartOfSnapshot = wi && !wi.IsDynamicWeight && !wi.IsAverageWeight;
      // if we copy published weights only consider 'real' instruments on the snapshot
      if (source !== "published" || isPartOfSnapshot) {
        c.saved = c[source];
      } else {
        c.saved = zero;
      }
    }
  });
}
