import AllocationType from "./AllocationType";
import AllocationSource from "./AllocationSource";
import Distribution from "./Distribution";
import ICWeights from "./ICWeights";
import Instrument from "./Instrument";
import MasterDataEntry from "./MasterDataEntry";
import Snapshot from "./Snapshot";
import StateHistory from "./StateHistory";
import WeightsDateTime from "./WeightsDateTime";
import FrequencyUnit from "./FrequencyUnit";
import AuretoRebalancingMethod from "./AuretoRebalancingMethod";
import { AllocationPageType } from "./AllocationPageType";

export default interface Allocation {
  AllocationType: AllocationType;

  Id: string;

  IsDeleted: boolean;

  Name: string;

  ICWeights: ICWeights[];

  ProductLine: MasterDataEntry;

  ProductDistinction: MasterDataEntry;

  ReferenceCurrency: MasterDataEntry;

  RiskProfile: MasterDataEntry;

  Starred: boolean;

  /**
   * is the MoPo editable by the current user? (so not in edit by another user and also not queued)
   * this does NOT indicate, if the current user has to necessary privilages to edit the MoPo (e.g. if she is a SuperUser)
   */
  IsInEditableState: boolean;

  /**
   * is the ModelPortfolio editable at all by (non-admin) users in the UI?
   */
  IsEditable: boolean;

  /* flag to enable/disable the netting of CASH/CAFT in the tripleA export. Property is here for configuration. */
  EnableCashPositionNetting: boolean;

  Owner: MasterDataEntry;

  IsOwner: boolean;

  State: MasterDataEntry;

  StateHistory: StateHistory[];

  Comment?: string;

  Description: string;

  FrequencyUnit: FrequencyUnit;

  AuretoRebalancingMethod: AuretoRebalancingMethod;
  
  PublishedSnapshot?: Snapshot;

  SavedSnapshot?: Snapshot;

  WeightsDateTime: WeightsDateTime;

  LevelOutInstrument?: Instrument;

  Distributions: Distribution[];

  AllocationSource: AllocationSource;
}

/**
 * Deducts the AllocationPageType by inspecting the given allocations.
 * @throws An Error will be raised, when the given allocations are 'mixed' (Mopos mixed with e.g. TAA).
 */
export const allocationPageTypeFromAllocations = (allocations: Allocation[]): AllocationPageType => {
  if (!allocations || allocations.length == 0) {
    return AllocationPageType.Allocation;
  }

  const allocationType = allocations[0].AllocationType;
  if (
    allocationType == AllocationType.ModelPortfolio &&
    !allocations.every((el) => el.AllocationType == AllocationType.ModelPortfolio)
  ) {
    throw new Error("Expected all allocations to be ModelPortfolios.");
  } else if (
    allocationType != AllocationType.ModelPortfolio &&
    allocations.some((el) => el.AllocationType == AllocationType.ModelPortfolio)
  ) {
    throw new Error("Expected no allocation to be a ModelPortfolio.");
  }

  return allocationType == AllocationType.ModelPortfolio ? AllocationPageType.ModelPortfolio : AllocationPageType.Allocation;
};
