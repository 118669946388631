import * as React from "react";
import { styled } from '@mui/material/styles';
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { AppState } from "../../store/AppState";
import PanelDescriptionHeader from "./PanelDescriptionHeader";

const PREFIX = 'InstrumentStatusPanel';
const classes = {
  iFrameContainer: `${PREFIX}-iFrameContainer`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.iFrameContainer}`]: {
    marginBottom: theme.spacing(1),
  }
}));

export default React.memo(function InstrumentStatusPanel() {
  const tstCoreBaseUrl = useSelector((state: AppState) => state.masterData.tstCoreUrl);
  const statusUrl = tstCoreBaseUrl + "status/";

  return (
    <Root>
      <PanelDescriptionHeader
        button={
          <Button href={statusUrl} target="_blank">
            Open in new tab
          </Button>
        }
      >
        Status of TST Core (instrument façade) on instrument data integration and processing.
      </PanelDescriptionHeader>
      <div className={classes.iFrameContainer}>
        <iframe src={statusUrl} width="100%" height={850} />
      </div>
    </Root>
  );
});
