import * as React from "react";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { getPublishProgressStatus, ProductLineStatusMap, dataIconsMapper, ProductLineStatusApi } from "./utils";

export default function DaisyPublishProgress(props: { data: ProductLineStatusMap }) {
  const theme = useTheme();
  const dataStatus = Object.keys(props.data).map((k) => props.data[k].productDataStatus);
  const publictionStatus = Object.keys(props.data)
    .filter((k) => props.data[k].daisyPublicationStatus === ProductLineStatusApi.Uploaded)
    .map((k) => props.data[k].daisyPublicationStatus);
  const total = dataStatus.length + publictionStatus.length;

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Publish Progress
      </Typography>
      <Box display="flex" width="100%" maxWidth="28rem" border={"1px solid " + theme.palette.primary.main} borderRadius="3px" overflow="hidden">
        {getPublishProgressStatus()
          .reverse()
          .map((status) => {
            const count = dataStatus.filter((i) => i === status).length + publictionStatus.filter((i) => i === status).length;
            return (
              <React.Fragment key={status}>
                {count > 0 && (
                  <Tooltip key={status} title={`${dataIconsMapper[status.toString()].label} (${count})`}>
                    <Box
                      key={status}
                      width={(count / total) * 100 + "%"}
                      height="2rem"
                      style={{ backgroundColor: dataIconsMapper[status.toString()].getColor(theme)}}
                    />
                  </Tooltip>
                )}
              </React.Fragment>
            );
          })}
      </Box>
    </>
  );
}
