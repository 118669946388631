import * as React from "react";
import { styled } from '@mui/material/styles';
import { Divider, List, ListItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/AppState";
import IntegrationStatus from "./types/IntegrationStatus";
import PanelDescriptionHeader from "../PanelDescriptionHeader";
import { hideLoadingScreen, showLoadingScreen } from "../../../store/loading/loading-slice";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import * as api from "./apiRequests";
import IntegrationHistoryArea from "./IntegrationHistoryArea";
import StatsPanel, { StatsPanelEntry } from "../StatsPanel";
import classNames from "classnames";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { showAlert } from "../../../store/app/app-slice";
import { AlpimaRequestHistory } from "./types/AlpimaRequestHistory";
import AlpimaRequestStagingPanel from "./AlpimaRequestStagingArea";

const PREFIX = 'AlpimaIntegration';
const classes = {
  noBottomPadding: `${PREFIX}-noBottomPadding`,
  smallTopMargin: `${PREFIX}-smallTopMargin`,
  mediumTopMargin: `${PREFIX}-mediumTopMargin`,
  mediumBottomMargin: `${PREFIX}-mediumBottomMargin`,
  heading: `${PREFIX}-heading`,
  refreshIcon: `${PREFIX}-refreshIcon`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.noBottomPadding}`]: {
    paddingBottom: 0,
  },

  [`& .${classes.smallTopMargin}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.mediumTopMargin}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.mediumBottomMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.heading}`]: {},

  [`& .${classes.refreshIcon}`]: {
    cursor: "pointer" as "pointer",
    fontSize: 14,
  }
}));

/**
 * Component to run the integration of allocations from Alpima.
 * This also includes a list (as a <select> element) of the current "problematic" requests and the history.
 */
export default function AlpimaIntegration() {
  const dispatch = useDispatch();

  const canWriteAdministration = useSelector((state: AppState) => state.user.isAdmin);

  const [historyStartDate, setHistoryStartDate] = React.useState(addDays(Date.now(), -7));
  const [stagingInfoStartDate, setStagingInfoStartDate] = React.useState(addDays(Date.now(), -7));
  const [displayedDataTimestamp, setDisplayedDataTimestamp] = React.useState<Date | undefined>(undefined);

  const [status, setStatus] = React.useState<IntegrationStatus>({
    LastRun: undefined,
    Count: 0,
    PendingAllocations: 0,
    IntegratedInLast7Days: 0,
    FailedInLast7Days: 0,
    RejectedAllocationsInLast7Days: 0,
    History: [],
  });

  const [stagingInfo, setStagingInfo] = React.useState<AlpimaRequestHistory>({ TotalRequestCount: 0, Requests: [] });

  const doRefresh = async (showMoreHistory: boolean = false, showMoreStaging: boolean = false) => {
    dispatch(showLoadingScreen());

    try {
      const newStartDateHistory = addDays(historyStartDate, showMoreHistory ? -7 : 0);
      const status = await api.getIntegrationInfo("alpima", newStartDateHistory, true);
      setStatus(status);
      setHistoryStartDate(newStartDateHistory);

      const newStartDateStagingInfo = addDays(stagingInfoStartDate, showMoreStaging ? -7 : 0);
      const stagingSummary = await api.getAlpimaRequestHistory(newStartDateStagingInfo);
      setStagingInfo(stagingSummary);
      setStagingInfoStartDate(newStartDateStagingInfo);

      setDisplayedDataTimestamp(new Date());
    } catch (e) {
      dispatch(showAlert("Could not load data from backend", { type: "error" }));
      console.error("doRefresh failed", e);
    } finally {
      dispatch(hideLoadingScreen());
    }
  };

  React.useEffect(() => {
    doRefresh();
  }, []);

  const loadMoreStagingArea = React.useCallback(() => {
    doRefresh(false, true);
  }, [stagingInfoStartDate]);

  const stats: StatsPanelEntry[] = [
    { value: status.PendingAllocations, label: "pending allocations in staging" },
    { value: status.IntegratedInLast7Days, label: "integrated allocations in the last 7 days" },
    { value: status.FailedInLast7Days, label: "failed allocations in the last 7 days", isError: status.FailedInLast7Days > 0 },
    {
      value: status.RejectedAllocationsInLast7Days,
      label: "rejected allocation in the last 7 days",
      isError: status.RejectedAllocationsInLast7Days > 0,
    },
  ];

  return (
    <Root>
      <PanelDescriptionHeader>Status of allocation integration (TAAs, TCAs, MOPOs) received from Alpima.</PanelDescriptionHeader>
      <StatsPanel entries={stats} refreshCallback={doRefresh} />
      <div className={classes.smallTopMargin}>
        <List dense className={classes.noBottomPadding}>
          {displayedDataTimestamp && (
            <ListItem>
              Last refresh of displayed data at <strong>&nbsp;{format(displayedDataTimestamp, "HH:mm")}</strong>.<br />
            </ListItem>
          )}
          {status.LastRun && (
            <ListItem>
              Received last integration request <strong>&nbsp;{formatDistanceToNow(status.LastRun)}&nbsp;</strong> ago.
            </ListItem>
          )}
          {!status.LastRun && <ListItem>Last integration request unknown (before service was restarted).</ListItem>}
        </List>
      </div>
      <Divider className={classNames(classes.mediumTopMargin, classes.mediumBottomMargin)} />
      <AlpimaRequestStagingPanel
        info={stagingInfo}
        doRefresh={doRefresh}
        loadMore={loadMoreStagingArea}
        canWriteAdministration={canWriteAdministration}
      />
      <Divider className={classNames(classes.mediumTopMargin, classes.mediumBottomMargin)} />
      <IntegrationHistoryArea
        title="Integration History"
        history={status.History}
        canShowMore={status.Count > status.History.length}
        loadMore={() => {
          doRefresh(true);
        }}
      />
    </Root>
  );
}
