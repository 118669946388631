import React from "react";
import { useDispatch } from "react-redux";
import { setDialog } from "../compare-view/slice";
import useCompareSelector from "../compare-view/useCompareSelector";
import MetaViewDialog from "../compare-view/dialogs/MetaViewDialog";
import TailoringDialog from "../compare-view/dialogs/TailoringDialog";
import PublishDialog from "../compare-view/dialogs/PublishDialog";
import AddInstrumentDialog from "../compare-view/dialogs/AddInstrumentDialog";
import ExposureDialog from "../compare-view/dialogs/exposure-dialog/ExposureDialog";
import PasteDialog from "../compare-view/dialogs/paste-instruments-dialog/PasteDialog";
import { MultiplePasteDialog } from '../allocation-table/dialogs/MultiplePasteDialog';
import { MultiplePublishDialog } from '../allocation-table/dialogs/MultiplePublishDialog';

/**
 * Wraps all allocation dialogs.
 */
export default function Dialogs() {
  const dispatch = useDispatch();
  const current = useCompareSelector((s) => s.activeDialog);
  const close = () => dispatch(setDialog(undefined));

  return (
    <div>
      {current?.dialog === "metaView" ? (
        <MetaViewDialog close={close} allocation={current.allocation} />
      ) : current?.dialog === "exposure" ? (
        <ExposureDialog close={close} allocation={current.allocation} />
      ) : current?.dialog === "tailoring" ? (
        <TailoringDialog close={close} objectCode={current.allocation.Name} />
      ) : current?.dialog === "publish" ? (
        <PublishDialog close={close} allocationId={current.allocation.Id} />
      ) : current?.dialog === "publish-multiple" ? (
        <MultiplePublishDialog close={close} allocationChangeResults={current.allocationChangeResults} allocationPasteType={current.allocationPasteType} allocationRows={current.allocationRows} />
      ) : current?.dialog === "paste" ? (
        <PasteDialog close={close} allocation={current.allocation} />
      ) : current?.dialog === "paste-multiple" ? (
        <MultiplePasteDialog close={close} allocationPasteType={current.allocationPasteType} />
      ) : current?.dialog == "instrument" ? (
        <AddInstrumentDialog close={close} instrument={current.instrument} />
      ) : (
        <></>
      )}
    </div>
  );
}
