import { AllocationCellWeights, AllocationColumn, AllocationRowDescriptor } from "../types";
import traversePostOrder from "./traversePostOrder";
import { zeroWeights, addWeights, areEqualWeights, isZeroOrUnset } from "./weights";

const zero = zeroWeights();

/**
 * Sets the weights in each category node of an allocation.
 *
 * @param rows Traversed (depth-first, pre-order) nodes of an `ProjectedAssetTree`.
 */
export default function aggregateWeights(rows: AllocationRowDescriptor[], column: AllocationColumn) {
  const weights: { [level: number]: AllocationCellWeights } = {} as any; // keeps track of weights per level in a subtree

  traversePostOrder(rows, {
    onNewSubtree: (level, row) => {
      weights[level] = column.cells[row.rowId];
    },
    onSibling: (level, row) => {
      weights[level] = addWeights(weights[level], column.cells[row.rowId]);
    },
    onParent: (level, row) => {
      const self = column.cells[row.rowId];
      const children = weights[level + 1];

      // IC weights need special treatment because they are set intermediate nodes in the tree.
      const effective: AllocationCellWeights = { ...children, ic: isZeroOrUnset(children.ic) ? self.ic : children.ic };

      weights[level] = addWeights(weights[level] || zero, effective);
      column.cells[row.rowId] = { ...column.cells[row.rowId], ...effective };

      // reset the children's weights
      weights[level + 1] = zero;
    },
  });

  //column.sum = rows.map((r) => (r.isInstrument ? column.cells[r.rowId] : zero)).reduce(addWeights, zero);
  column.sum = weights[0] || zero;

  return column;
}
