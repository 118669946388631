import MasterDataEntry from "./MasterDataEntry";

export default interface MarketStructure {
  Id: number;

  MarketStructure: string;

  MarketStructureDescription: string;
}

export function fromMasterDataEntry(entry?: MasterDataEntry) {
  if (!entry) {
    return entry;
  }

  return {
    Id: parseInt(entry.Id, 10),
    MarketStructure: entry.ShortName,
    MarketStructureDescription: entry.Name,
  };
}
