import parseISO from "date-fns/parseISO";
import Allocation from "../../../types/Allocation";
import { isString } from "lodash";

/**
 * The JSON from the backend sends dates as ISO strings, so we need to convert them to Date-s manually.
 * @param allocation
 */
export default function parseAndPatchAllocationDates(allocation: Allocation) {
  if (allocation.SavedSnapshot?.AsOfDate && isString(allocation.SavedSnapshot.AsOfDate)) {
    allocation.SavedSnapshot.AsOfDate = parseISO(allocation.SavedSnapshot.AsOfDate);
  }
  if (allocation.PublishedSnapshot?.AsOfDate && isString(allocation.PublishedSnapshot.AsOfDate)) {
    allocation.PublishedSnapshot.AsOfDate = parseISO(allocation.PublishedSnapshot.AsOfDate);
  }
}
