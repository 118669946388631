import * as React from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { SearchIcon, CloseIcon } from "../shared/Icons";

/**
 * Simple component that displays in TextField that is styled for search purposes.
 * This includes a little (x) to reset the search.
 */
export default function SearchInput(props: {
  onValueChange: (value: string) => void;
  placeholder?: string;
  value: string;
  clearButtonDisabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
}) {
  const { value, placeholder, onValueChange: onInputChange, clearButtonDisabled, style, className } = props;
  const ref = React.createRef<any>();

  function clear() {
    if (ref.current) {
      ref.current.value = "";
      onInputChange("");
    }
  }

  return (
    <TextField
      className={className}
      inputRef={ref}
      style={style}
      onChange={(evt) => {
        onInputChange(evt.target.value);
      }}
      placeholder={placeholder}
      value={value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {value && (
              <IconButton onClick={clear} disabled={clearButtonDisabled} size="small" style={{ margin: 0, padding: 0 }}>
                <CloseIcon />
              </IconButton>
            )}
            {!value && <SearchIcon color="primary" />}
          </InputAdornment>
        ),
      }}
    />
  );
}
