import isValid from "date-fns/isValid";
import set from "date-fns/set";

/**
 * Checks if the provided asOfDate is valid.
 * @param previousAsOfDate
 * @param asOfDate
 * @returns
 */
export default function isValidAsOfDate(previousAsOfDate: Date | undefined, asOfDate: Date | undefined | null) {
  let isValidDate = false;
  if (!asOfDate) {
    isValidDate = false;
  } else if (asOfDate && !isValid(asOfDate)) {
    isValidDate = false;
  } else if (!previousAsOfDate && asOfDate) {
    isValidDate = true;
  } else if (previousAsOfDate && asOfDate) {
    const previousOnlyDate = set(previousAsOfDate, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const asOfOnlyDate = set(asOfDate, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

    return asOfOnlyDate.getTime() >= previousOnlyDate.getTime();
  }
  return isValidDate;
}
