import React from "react";
import { styled } from '@mui/material/styles';
import { IconButton, Tooltip } from "@mui/material";
import { EditIcon } from "../shared/Icons";

const PREFIX = 'EditButton';

const classes = {
  regularMargin: `${PREFIX}-regularMargin`,
  smallMargin: `${PREFIX}-smallMargin`
};

const RootTooltip = styled(Tooltip)((
  {
    theme
  }
) => ({
  [`& .${classes.regularMargin}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.smallMargin}`]: {
    marginRight: theme.spacing(1),
  }
})) as typeof Tooltip;

export default function EditButton(props: { tooltip: string; onClick: () => void; smallMargin?: boolean }) {
  const { tooltip, onClick, smallMargin } = props;

  return (
    <RootTooltip title={tooltip} className={smallMargin ? classes.smallMargin : classes.regularMargin}>
      <IconButton size="small" onClick={onClick}>
        <EditIcon />
      </IconButton>
    </RootTooltip>
  );
}
