import * as React from "react";
import { useMemo, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";
import Big from "big.js";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableContainer,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AllocationUploadValidationResponse } from '../../../types/AllocationUploadValidationResponse';
import { isBigNumber } from '../../../modules/asset-tree-builder';
import { AlertIcon, CheckIcon } from '../../shared/Icons';
import ErrorBox from '../../shared/ErrorBox';
import { AllocationPasteType } from '../../shared/types';

interface IAllocationValidationTableProps {
  allocationValidation: AllocationUploadValidationResponse;
  isInitialExpanded: boolean;
  allocationPasteType: AllocationPasteType;
}

export const AllocationValidationTable: React.FC<IAllocationValidationTableProps> = ({
  allocationValidation,
  isInitialExpanded,
  allocationPasteType,
}) => {
  const [isExpanded, setIsExpanded] = useState(isInitialExpanded);

  const theme = useTheme();
  const space = theme.spacing(1);
  const resolvedColor = theme.palette.text.secondary;
  const cellStyle = { width: 100, paddingLeft: space, paddingRight: space };

  const totalInstruments = allocationValidation.InstrumentWeights.length;
  const validInstruments = allocationValidation.InstrumentWeights.filter(
    (i) => i.Exists && !i.IsDeleted && i.HasRiskCurrency
  ).length;
  const allInstrumentsValid = totalInstruments === validInstruments;

  const hasAllocationError =
    !allocationValidation.Exists ||
    allocationValidation.IsDeleted ||
    !allocationValidation.IsNotLocked ||
    !allocationValidation.HasCorrectSum ||
    !allocationValidation.HasEditPermission;

  const tableRows = useMemo(
    () =>
      allocationValidation.InstrumentWeights.map((row) => {
        let isValid = row.Exists && row.HasRiskCurrency && !row.IsDeleted;

        if (allocationPasteType === "icAllocation") {
          isValid = isValid && row.HasValidRiskCurrency && row.HasValidAssetSubClass;
        }

        let resolvedText = "";

        if (isValid) {
          resolvedText = `${row.Instrument?.ShortName}`;
          if (allocationPasteType === "icAllocation") {
            resolvedText += ` / ${row.ReceivedOrDefaultRiskCurrency} / ${row.ReceivedOrDefaultAssetSubClass}`;
          } else {
            resolvedText += ` / ${row.Instrument?.RiskCurrency}`;
          }
        } else if (!row.Exists) {
          resolvedText = "Instrument not found";
        } else if (!row.HasRiskCurrency) {
          resolvedText = "Risk currency missing";
        } else if (row.IsDeleted) {
          resolvedText = "Instrument is marked as deleted";
        } else if (allocationPasteType === "icAllocation" && !row.HasValidRiskCurrency) {
          resolvedText = "Invalid risk currency";
        } else if (allocationPasteType === "icAllocation" && !row.HasValidAssetSubClass) {
          resolvedText = "Invalid asset sub class";
        }

        return (
          <TableRow key={row.InstrumentId}>
            <TableCell align="left" style={cellStyle}>
              {row.InstrumentId}
            </TableCell>
            <TableCell align="left" style={cellStyle}>
              {isBigNumber(row.Weight) && `${new Big(row.Weight).times(100).toFixed(2)}%`}
            </TableCell>
            <TableCell style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: space }}>
                {(isValid && <CheckIcon htmlColor="green" />) || <AlertIcon color="error" />}
              </div>
              <div style={{ color: isValid ? resolvedColor : "inherit" }}>{resolvedText}</div>
            </TableCell>
          </TableRow>
        );
      }),
    [allocationValidation]
  );

  return (
    <Accordion expanded={isExpanded} onChange={(_, value) => setIsExpanded(value)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{allocationValidation.AllocationCode}</Typography>
        <div style={{ marginLeft: space }}>
          {(allInstrumentsValid && !hasAllocationError && <CheckIcon htmlColor="green" />) || <AlertIcon color="error" />}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer style={{ maxHeight: "60vh", overflowY: "scroll" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left" style={cellStyle}>
                  Valor / ISIN
                </TableCell>
                <TableCell align="left" style={cellStyle}>
                  Weight
                </TableCell>
                {
                  <TableCell>
                    {allInstrumentsValid ? "Resolved Completely" : `Resolved ${validInstruments} / ${totalInstruments}`}
                  </TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {hasAllocationError && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <ErrorBox>
                      <ul>
                        {!allocationValidation.Exists && <li>Allocation doesn't exist</li>}
                        {allocationValidation.Exists && allocationValidation.IsDeleted && (
                          <li>Allocation is marked as deleted</li>
                        )}
                        {allocationValidation.Exists && !allocationValidation.IsNotLocked && (
                          <li>Allocation is locked by another user</li>
                        )}
                        {!allocationValidation.HasCorrectSum && (
                          <li>The sum of instrument weights isn't 100% +/- tolerance for the sum of weights</li>
                        )}
                        {allocationValidation.Exists && !allocationValidation.HasEditPermission && (
                          <li>No permissions to edit this allocation</li>
                        )}
                      </ul>
                    </ErrorBox>
                  </TableCell>
                </TableRow>
              )}
              {tableRows}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};