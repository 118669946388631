import { PropsWithChildren, useEffect, useState } from "react";
import { AuthProvider } from "react-oidc-context";
import { getOidcConfig } from "./oidcConfig";
import React from "react";
 
export function ConfiguredAuthProvider({
  children,
}: PropsWithChildren) {
  const [oidcConfig, setOidcConfig] = useState<Awaited<ReturnType<typeof getOidcConfig>>>();
 
  useEffect(() => {
    (async function () {
      setOidcConfig(await getOidcConfig());
    })();
  }, []);
 
  if (!oidcConfig) return <div>OidcConfig is not available yet...</div>;
 
  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>;
}