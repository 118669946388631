import KeyValuePair from "../../components/shared/KeyValuePair";
import { InstrumentList, ServerMessage } from "../../types/InstrumentList";
import MasterDataEntry from "../../types/MasterDataEntry";

export type StatusFilter = undefined | "Ok" | "Warning" | "Error";

export interface ItemFilter {
  status: StatusFilter;
}

export interface InstrumentListState {
  categories: KeyValuePair[];
  fetchStatus: {
    success?: boolean;
    error?: string;
  };
  lists: InstrumentList[];
  itemSearch?: string;
  itemFilter: ItemFilter;
}

function toTitleCase(value?: string) {
  if (!value) {
    return value;
  }
  if (value.length == 1) {
    return value.toUpperCase();
  }
  return value.substr(0, 1).toUpperCase() + value.substr(1).toLowerCase();
}

export function errorResponseToServerMessages(err: any): ServerMessage[] {
  let messages: ServerMessage[] = [];
  if (err.response?.data?.messages) {
    messages = err.response.data.messages.map((msg: any) => {
      return {
        severity: toTitleCase(msg.severity),
        text: msg.description,
      };
    });
  } else {
    let msg = "Error saving list item. Cause: " + err;
    if (err.response?.data?.message) {
      msg = "Server did not accept the request: " + err.response.data.message;
    }
    messages.push({ severity: "Error", text: msg });
  }
  return messages;
}
