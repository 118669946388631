import AllocationType from "../../../types/AllocationType";
import { AssetTree, AssetTreeType } from "../../../types/AssetTree";

/**
 * Returns a filter which checks if a given structure (tree)
 * is valid for Mopos / TAAs / SAAs / TCAs.
 */
export default function isRelevantStructure(allocationType: AllocationType) {
  return (structure: AssetTree) => {
    if (allocationType === AllocationType.ModelPortfolio) {
      return structure.Type === AssetTreeType.MarketTree;
    }

    if (allocationType === AllocationType.Tca) {
      return structure.Structure === "AA (IM CA)";
    }

    return structure.Structure === "AA (IM AA)";
  };
}
