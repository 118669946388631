import * as React from "react";
import { styled } from '@mui/material/styles';
import { Divider, Icon, IconButton, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { AppState } from "../../store/AppState";
import { EditIcon, InfoIcon } from "../shared/Icons";
import SettingsDialog from "./SettingsDialog";
import PanelDescriptionHeader from "./PanelDescriptionHeader";

const PREFIX = 'SettingsPanel';
const classes = {
  header: `${PREFIX}-header`,
  editButton: `${PREFIX}-editButton`,
  row: `${PREFIX}-row`,
  rowLabel: `${PREFIX}-rowLabel`,
  rowContent: `${PREFIX}-rowContent`,
  infoIcon: `${PREFIX}-infoIcon`,
  mainDivider: `${PREFIX}-mainDivider`,
  destinationSpacer: `${PREFIX}-destinationSpacer`,
  hint: `${PREFIX}-hint`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.header}`]: {
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.editButton}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },

  [`& .${classes.row}`]: {
    display: "flex",
    fontSize: theme.typography.body2.fontSize,
    justifyContent: "flex-start",
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.rowLabel}`]: {
    width: "22em",
    marginRight: theme.spacing(2),
  },

  [`& .${classes.rowContent}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  [`& .${classes.infoIcon}`]: {
    marginTop: -3,
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.mainDivider}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.destinationSpacer}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.hint}`]: {
    color: "lightgray",
    fontSize: "0.9em",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
}));

/**
 * Redux connected panel, that shows the General Settings and the destinations.
 */
export default function SettingsPanel() {
  const { initialValues, canWriteAdministration, trees, destinations } = useSelector((state: AppState) => {
    return {
      initialValues: state.masterData.settings,
      canWriteAdministration: state.user.isAdmin,
      trees: state.masterData.trees,
      destinations: state.masterData.destinations,
    };
  });

  const [showEditDialog, setShowEditDialog] = React.useState(false);

  return (
    <Root>
      <PanelDescriptionHeader>General settings for working with TST.</PanelDescriptionHeader>
      <SettingsDialog
        open={showEditDialog}
        initialValues={initialValues}
        trees={trees}
        closeHandle={() => setShowEditDialog(false)}
      />
      <div className={classes.header}>
        <Typography variant="h3">Settings</Typography>
        {canWriteAdministration && (
          <Tooltip title="Edit settings" className={classes.editButton}>
            <IconButton
              size="small"
              onClick={(_) => {
                setShowEditDialog(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <Row label="Default asset class tree" uiTestId="default-tree">
        {initialValues.DefaultTree}
      </Row>
      <Row label="Threshold for coloring (in %)" uiTestId="coloring-threshold">
        {initialValues.ThresholdAvgDyn}
      </Row>
      <Row label="Tolerance for the sum of weights">
        <span id="sum-tolerance">{initialValues.AllocationSumTolerance}</span>
        <Tooltip title="This uses decimal values (1.0 is 100%). Please note, that this applies to the TST-UI, the publishing to TAP and the validation of allocations from Alpima (in TST-AA).">
          <Icon className={classes.infoIcon}>
            <InfoIcon fontSize="small" />
          </Icon>
        </Tooltip>
      </Row>
      <Row label="TST mailbox">{initialValues.TstMailbox}</Row>
      <Row label="FinanceDoc request mail recipient">{initialValues.FinanceDocRequestMailRecipient}</Row>
      <Row label="Publish Retries">{initialValues.PublishRetryCount}</Row>

      <Divider className={classes.mainDivider} />

      <Typography variant="h3" gutterBottom>
        Triple'A Destinations
      </Typography>
      {destinations?.map((prop) => (
        <div key={prop.Id} className={classes.destinationSpacer}>
          <Typography variant="body2" gutterBottom>
            <strong>{prop.DestinationLong}</strong>
          </Typography>
          {prop.UploadFolder && <Row label="Upload Folder">{prop.UploadFolder}</Row>}
          {prop.SvlBaseUri && <Row label="SVL Base URI">{prop.SvlBaseUri}</Row>}
          <Row label="Strategylink Folder">{prop.StrategyLinkUploadFolder}</Row>
          <Row label="Tailoring Upload Folder">{prop.TailoringUploadFolder}</Row>
        </div>
      ))}
      <div className={classes.hint}>
        The Strategylink Folder defaults to the 'common' Upload Folder, if no configuration is provided.
        <br />
        The destinations and their settings can only be changed in the database.
      </div>
    </Root>
  );
}

const Row: React.FC<{ label: string; uiTestId?: string, children?: React.ReactNode }> = (props) => {
  return (
    <div className={classes.row}>
      <div className={classes.rowLabel}>{props.label}</div>
      <div id={props.uiTestId} className={classes.rowContent}>
        {props.children}
      </div>
    </div>
  );
};
