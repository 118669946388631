import Big from "big.js";
import { AllocationExposure, Exposure } from "./types";

export function alignExposures(allocationExposure: AllocationExposure) {
  const { published, draft } = allocationExposure;

  const categoriesInPublish = published ? published.map((x) => x[1]) : [];
  const additionalCategoriesInDraft = (draft ?? [])
    .filter((x) => x[0] > 0)
    .map((x) => x[1])
    .filter((c) => categoriesInPublish.indexOf(c) === -1);
  const allCategories = [...categoriesInPublish, ...additionalCategoriesInDraft];

  return {
    published: fillCategoryGaps(published, allCategories),
    draft: fillCategoryGaps(draft, allCategories),
  };
}

const fillCategoryGaps = (data: Exposure, categories: string[]): Exposure => {
  return categories.map((c) => {
    const [match, ..._] = data.filter((dataPoint) => dataPoint[1] === c);
    return match ? match : [Big(0.0), c];
  });
};
