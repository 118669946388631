import React from "react";
import { styled } from '@mui/material/styles';
import { BigType } from "big.js";
import cn from "classnames";
import { AllocationCell, AllocationCellWeights, ViewOptions, AllocationRowDescriptor } from "./types";
import { useDispatch } from "react-redux";
import { weightToFixedString, updateWeight, weightToRoundedString, stringToBig } from "./slice";
import { cellStyle, cellWidth, rowBackground, rowStyle } from "./commonStyles";

const PREFIX = 'WeightCells';
const classes = {
  root: `${PREFIX}-root`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    minWidth: "23rem", // should be set so that it determines the minimum width of an allocation column
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    ...rowStyle(theme),
    "& input": {
      paddingRight: theme.spacing(1),
      paddingLeft: 0,
      textAlign: "right",
      width: `calc(${cellWidth} - 2px)`, // compensate border
      border: "1px solid rgb(169, 169, 169)",
    },
    "& div": cellStyle(theme),
    "& .red": {
      backgroundColor: "#ffdada", // TODO - theme color?
    },
  }
}));

/**
 * Renders the weights of an allocation instrument resp. category.
 */
export function TotalWeightCells(props: {
  weights: AllocationCellWeights;
  options: ViewOptions;
  inEdit: boolean;
  showSaved: boolean;
}) {
  const { showIc, showDyn, showAvg } = props.options;
  const { ic, published, dynamic, average, saved } = props.weights;

  return (
    <Root className={classes.root}>
      <Weight className="icweight" value={ic} decimalPoints={2} show={showIc} />
      <Weight className="pubweight" value={published} decimalPoints={2} show />
      <Weight className="dynweight" value={dynamic} decimalPoints={2} show={showDyn} />
      <Weight className="avgweight" value={average} decimalPoints={2} show={showAvg} />
      <Weight className="savedweight" value={saved} decimalPoints={props.inEdit ? 4 : 2} show={props.showSaved || props.inEdit} />
    </Root>
  );
}

/**
 * Renders the weights of an allocation instrument resp. category.
 */
export default React.memo(function WeightCells(props: {
  row: AllocationRowDescriptor;
  cell: AllocationCell;
  allocationIndex: number;
  options: ViewOptions;
  inEdit: boolean;
  showSaved: boolean;
}) {

  const { showIc, showDyn, showAvg } = props.options;
  const { ic, published, dynamic, average, saved } = props.cell;
  const row = props.row;

  const showInput = row.isInstrument && props.inEdit;
  const suppressAvg = row.isInstrument && row.isNotional;
  const dynamicExceedsThreshold = row.isInstrument && props.cell.dynamicExceedsThreshold;
  const averageExceedsThreshold = row.isInstrument && props.cell.averageExceedsThreshold;
  const allocationIndex = props.allocationIndex;

  return (
    <Root className={classes.root} style={rowBackground(row)}>
      <Weight className="icweight" value={ic} decimalPoints={2} show={showIc} />
      <Weight className="pubweight" value={published} decimalPoints={2} show />
      <Weight className="dynweight" value={dynamic} decimalPoints={2} show={showDyn} red={dynamicExceedsThreshold} />
      <Weight
        className="avgweight"
        value={suppressAvg ? undefined : average}
        decimalPoints={2}
        show={showAvg}
        red={!suppressAvg && averageExceedsThreshold}
      />
      {showInput ? (
        <WeightInput value={saved} {...{ allocationIndex, rowId: row.rowId }} />
      ) : (
        <Weight className="savedweight" value={saved} decimalPoints={props.inEdit ? 4 : 2} show={props.showSaved} />
      )}
    </Root>
  );
});

const EMPTY = <React.Fragment>&nbsp;</React.Fragment>;
const HIDE = { display: "none" };
const NOHIDE = {};

function Weight(props: { value: BigType; decimalPoints: number; show: boolean; red?: boolean; className?: string }) {
  const { value, decimalPoints, show, red, className } = props;
  return (
    <div className={cn(className, red ? "red" : undefined)} style={(show && NOHIDE) || HIDE}>
      {weightToFixedString(value, decimalPoints) || EMPTY}
    </div>
  );
}

function WeightInput(props: { allocationIndex: number; rowId: string; value: BigType }) {
  const dispatch = useDispatch();
  const { allocationIndex, rowId, value } = props;

  const input = React.useRef("");
  const [hasFocus, setHasFocus] = React.useState(false);
  const onBlur = React.useCallback(() => setHasFocus(false), []);
  const onFocus = React.useCallback(() => setHasFocus(true), []);

  const isInvalid = input.current !== "" && stringToBig(input.current) === undefined;
  let shownValue: string;

  if (hasFocus) {
    shownValue = input.current;
  } else if (isInvalid) {
    shownValue = input.current;
  } else {
    shownValue = weightToFixedString(value, 4) || "";
    input.current = weightToRoundedString(value, 4) || "";
  }

  const dispatchUpdate = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim();
    input.current = inputValue;
    dispatch(updateWeight({ allocationIndex, rowId, input: inputValue }));
  }, []);

  return (
    <input
      className={isInvalid ? "red" : undefined}
      type="text"
      onChange={dispatchUpdate}
      onFocus={onFocus}
      onBlur={onBlur}
      value={shownValue}
    />
  );
}
