import Big, { BigType } from "big.js";

export const createReducer = (initialState: any, handlers: any) => {
  return function reducer(state = initialState, action: any) {
    if ({}.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action);
    }

    return state;
  };
};

export const removeSortKeyFromId = (str: string): string => {
  return str.substr(str.indexOf("-") + 1);
};

(Object as any).compare = function (obj1: any, obj2: any): boolean {
  if (obj1 === null && obj2 === null) {
    return true;
  }
  if (obj1 === null || obj2 === null) {
    return false;
  }

  //Loop through properties in object 1
  for (const p in obj1) {
    //Check property exists on both objects
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
      return false;
    }

    switch (typeof obj1[p]) {
      //Deep compare objects
      case "object":
        if (!(Object as any).compare(obj1[p], obj2[p])) {
          return false;
        }
        break;
      //Compare function code
      case "function":
        if (typeof obj2[p] === "undefined" || (p !== "compare" && obj1[p].toString() !== obj2[p].toString())) {
          return false;
        }
        break;
      //Compare values
      default:
        if (obj1[p] !== obj2[p]) {
          return false;
        }
    }
  }

  //Check object 2 for any extra properties
  for (const p in obj2) {
    if (obj1 === undefined) {
      return false;
    } else if (typeof obj1[p] === "undefined") {
      return false;
    }
  }
  return true;
};

export const objectWithoutKey = (object: any, key: string): any => {
  // eslint-disable-next-line no-unused-vars
  const { [key]: deletedKey, ...otherKeys } = object;
  return otherKeys;
};

export const addToWeight = (weight: BigType | string | number, summand: BigType | string | number): BigType | string | number => {
  const bigWeight = new Big(weight !== "" ? weight : 0);
  const bigSummand = new Big(summand !== "" ? summand : 0);
  const result = bigWeight.plus(bigSummand);
  //convert the result back into the typeof of the weight input
  if (typeof weight === "string") {
    return result.valueOf();
  } else if (typeof weight === "number") {
    return Number(result);
  } else {
    return result;
  }
};
