import * as React from "react";
import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PlusIcon from "@mui/icons-material/Add";
import { Banner } from "../../../store/app/AppState";
import sortBy from "lodash/sortBy";
import BannerEditor from "./BannerEditor";
import BannerItem from "./BannerItem";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/AppState";
import PanelDescriptionHeader from "../PanelDescriptionHeader";

const PREFIX = 'BannerPanel';
const classes = {
    grid: `${PREFIX}-grid`,
}
const Root = styled('div')(({ theme }) => ({
    [`& .${classes.grid}`]: {
        marginTop: theme.spacing(1.5),
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        "& .item": {
            marginRight: theme.spacing(2.5),
            marginTop: theme.spacing(2.5),
        },
    },
}));

export default React.memo(function BannerPanel() {
  const [currentEditBanner, setCurrentEditBanner] = React.useState(undefined as Partial<Banner> | undefined);
  const banners = useSelector((state: AppState) => state.app.banners);

  const sorted = sortBy(banners, (b) => b.StartDate);

  function openEditorNew(event: any) {
    event.stopPropagation();
    setCurrentEditBanner({ Id: 0 });
  }

  function closeEditor() {
    setCurrentEditBanner(undefined);
  }

  return (
    <Root>
      <PanelDescriptionHeader
        button={
          <Button startIcon={<PlusIcon />} color="primary" variant="contained" onClick={openEditorNew}>
            New Banner
          </Button>
        }
      >
        See and modify existing banners or create a new banner.
      </PanelDescriptionHeader>
      <div className={classes.grid}>
        {sorted.map((b) => (
          <div key={b.Id} className="item">
            <BannerItem {...b} onEdit={setCurrentEditBanner} />
          </div>
        ))}
        {sorted.length === 0 && <Typography>Currently, there are no banners configured.</Typography>}
      </div>
      {currentEditBanner && <BannerEditor banner={currentEditBanner} onDone={closeEditor} />}
    </Root>
  );
});
