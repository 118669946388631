import * as React from "react";
import { AllocationReference } from "../types/AllocationReference";
import { AppState } from "../store/AppState";
import { Checkbox, Collapse, FormControlLabel, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import { getSortedFavoriteAllocations, getSortedFavoriteModelPortfolios, getSortedOwned } from "../store/user/user-selector";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import PersonBorderIcon from "@mui/icons-material/PersonOutline";
import FaceIcon from "@mui/icons-material/Face";
import StarBorder from "@mui/icons-material/StarBorder";
import { setPreferPrettyInstrumentNames } from "../store/user/user-slice";

const PREFIX = 'TemporaryDrawer';

const classes = {
  list: `${PREFIX}-list`,
  fullList: `${PREFIX}-fullList`,
  checkbox: `${PREFIX}-checkbox`
};

const RootDrawer = styled(Drawer)((
  {
    theme
  }
) => ({
  [`& .${classes.list}`]: {
    width: 500,
  },

  [`& .${classes.fullList}`]: {
    width: "auto",
  },

  [`& .${classes.checkbox}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: 14,
    "& .MuiFormControlLabel-label": {
      fontSize: theme.typography.body1.fontSize,
      marginLeft: theme.spacing(3),
    },
  }
})) as typeof Drawer;

/**
 * The right sidebar (accessible from header).
 */
export default function TemporaryDrawer(props: { open: boolean; toggleOpen: () => void }) {
  const dispatch = useDispatch();
  const [toggles, setToggles] = React.useState({ favoritesOpen: true, allocationsOpen: false, ownedOpen: false });

  const user = useSelector((s: AppState) => s.user);
  const environment = useSelector((s: AppState) => s.masterData.environment);
  const favoriteModelPortfolios = useSelector(getSortedFavoriteModelPortfolios);
  const favoriteAllocations = useSelector(getSortedFavoriteAllocations);
  const owned = useSelector(getSortedOwned);

  function toggle(key: keyof typeof toggles) {
    setToggles((s) => ({ ...s, [key]: !s[key] }));
  }

  return (
    <RootDrawer anchor="right" open={props.open} onClose={props.toggleOpen}>
      <div tabIndex={0} role="button" onKeyDown={props.toggleOpen}>
        <List>
          <ListItem>
            <ListItemIcon>
              <FaceIcon />
            </ListItemIcon>
            <ListItemText
              primary={`Account: ${user.username}`}
              secondary={`Groups: ${user.groups && Object.values(user.groups).join(", ")}`}
            />
          </ListItem>
        </List>
        <Divider />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={user.preferences?.preferPrettyInstrumentNames}
              onChange={() => {
                dispatch(setPreferPrettyInstrumentNames({ value: !user.preferences?.preferPrettyInstrumentNames }));
              }}
              name="showPrettyInstrumentName"
              color="primary"
            />
          }
          label="Show pretty instrument names in allocations"
        />
        <Divider />
        <List>
          <ListItem>
            <ListItemIcon>
              <InfoOutlined />
            </ListItemIcon>
            <ListItemText primary={`Environment: ${environment}`} />
          </ListItem>
        </List>
        {favoriteModelPortfolios && favoriteModelPortfolios.length > 0 && (
          <div className={classes.list}>
            <Divider />
            <FavoriteList
              open={toggles.favoritesOpen}
              onToggle={() => toggle("favoritesOpen")}
              onNavigate={props.toggleOpen}
              icon={<StarBorder />}
              name="Favourites - Model Portfolios"
              list={favoriteModelPortfolios}
            />
          </div>
        )}
        {favoriteAllocations && favoriteAllocations.length > 0 && (
          <div className={classes.list}>
            <Divider />
            <FavoriteList
              open={toggles.allocationsOpen}
              onToggle={() => toggle("allocationsOpen")}
              onNavigate={props.toggleOpen}
              icon={<StarBorder />}
              name="Favourites - SAA & TAA"
              list={favoriteAllocations}
            />
          </div>
        )}
        {owned && owned.length > 0 && (
          <div className={classes.list}>
            <Divider />
            <FavoriteList
              open={toggles.ownedOpen}
              onToggle={() => toggle("ownedOpen")}
              onNavigate={props.toggleOpen}
              icon={<PersonBorderIcon />}
              name="Owned"
              list={owned}
            />
          </div>
        )}
      </div>
    </RootDrawer>
  );
}

function FavoriteList(props: {
  icon: JSX.Element;
  name: string;
  list: AllocationReference[];
  open: boolean;
  onToggle: () => void;
  onNavigate: () => void;
}) {
  const navigate = useNavigate();
  return (
    <List component="nav">
      <ListItem button onClick={props.onToggle}>
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.name} />
        {props.open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={props.open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {props.list &&
            props.list.map((e) => (
              <ListItem
                onClick={() => {
                  navigate(`/compare/${e.Id}`);
                  props.onNavigate();
                }}
                key={e.Id}
                button
              >
                <ListItemText inset primary={e.Name} />
              </ListItem>
            ))}
        </List>
      </Collapse>
    </List>
  );
}
