import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Allocation from "../../types/Allocation";
import AllocationSetMember from "../../types/AllocationSetMember";
import AllocationType from "../../types/AllocationType";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/AppState";
import { allocationPageRequest } from "../../store/pages/pages-slice";
import LoadingIndicator from "../shared/LoadingIndicator";
import { showAlert } from "../../store/app/app-slice";
import * as api from "./apiRequests";

/**
 * Dialog to edit allocation links.
 */

export default function EditAllocationLinksDialog(props: {
  allocation: Allocation;
  links: AllocationSetMember[];
  onClose: (links?: AllocationSetMember[]) => void;
}) {
  const { allocation, links, onClose } = props;
  const dispatch = useDispatch();

  const existingTaaCode = links.find((l) => l.AllocationType === AllocationType.Taa)?.ObjectName || "";
  const existingTcaCode = links.find((l) => l.AllocationType === AllocationType.Tca)?.ObjectName || "";
  const existingSaaCode = links.find((l) => l.AllocationType === AllocationType.Saa)?.ObjectName || "";

  const [taaCode, setTaaCode] = React.useState(existingTaaCode);
  const [tcaCode, setTcaCode] = React.useState(existingTcaCode);
  const [saaCode, setSaaCode] = React.useState(existingSaaCode);

  const isDirty = existingTaaCode !== taaCode || existingSaaCode !== saaCode || existingTcaCode !== tcaCode;
  const [isBusy, setIsBusy] = React.useState(false);
  const [errors, setErrors] = React.useState<Maybe<string[]>>();

  const pagination = useSelector((s: AppState) => s.pages.pagination.allocations);
  const allocations = Object.values(useSelector((s: AppState) => s.pages.allocations));

  React.useEffect(() => {
    if (allocations.length === 0) {
      // Load allocations into global state, alternatively we could also fetch object codes explicitly here.
      // Needed, because TAAs etc. are only in global state if the allocation page was visited before.
      dispatch(allocationPageRequest(pagination.currentPage, pagination.pageSize, pagination.order, pagination.filter));
    }
  }, []);

  async function save() {
    try {
      setErrors(undefined);
      setIsBusy(true);

      const response = await api.updateAllocationLinks(
        allocation.Id,
        [taaCode, tcaCode, saaCode].filter((c) => c !== "")
      );

      if (response.DmcErrors) {
        setErrors(response.DmcErrors);
      } else {
        dispatch(showAlert("Updated links", { type: "success" }));
        // without wrapping React complains: Can't perform a React state update on an unmounted component.
        setTimeout(() => onClose(response.Members), 0);
      }
    } catch (err) {
      dispatch(showAlert("Updating links failed", { type: "error" }));
      console.error("updating links failed", err);
      setTimeout(() => onClose(), 0);
    } finally {
      setIsBusy(false);
    }
  }

  return (
    <Dialog disableEscapeKeyDown fullWidth open={true} onClose={() => onClose()}>
      <DialogTitle>Edit Links for {allocation.Name}</DialogTitle>
      <DialogContent dividers>
        <CodeSelector
          source={allocations}
          allocation={allocation}
          type={AllocationType.Taa}
          currentCode={taaCode}
          existingCode={existingTaaCode}
          onChange={setTaaCode}
        />
        <CodeSelector
          source={allocations}
          allocation={allocation}
          type={AllocationType.Tca}
          currentCode={tcaCode}
          existingCode={existingTcaCode}
          onChange={setTcaCode}
        />
        <CodeSelector
          source={allocations}
          allocation={allocation}
          type={AllocationType.Saa}
          currentCode={saaCode}
          existingCode={existingSaaCode}
          onChange={setSaaCode}
        />
        {errors && (
          <Box marginY={2}>
            <Typography variant="body1" color="error">
              DMC did not accept the update:
            </Typography>
            {errors.map((e, i) => (
              <Typography key={i} variant="body2">
                {e}
              </Typography>
            ))}
          </Box>
        )}
      </DialogContent>
      <LoadingIndicator show={isBusy} size="40">
        <DialogActions>
          <Button autoFocus onClick={() => onClose()} disabled={isBusy}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" disabled={!isDirty || isBusy} onClick={save}>
            Save
          </Button>
        </DialogActions>
      </LoadingIndicator>
    </Dialog>
  );
}

function CodeSelector(props: {
  source: Allocation[];
  allocation: Allocation;
  type: AllocationType;
  currentCode: string;
  existingCode: string;
  onChange: (code: string) => void;
}) {
  const { source, allocation, type, currentCode, existingCode, onChange } = props;
  const codes = filterObjectCodes(source, allocation, type, existingCode);
  const noCodesAvailable = codes.length === 0;
  const label = typeString[type] || "?";

  return (
    <Box marginY={2}>
      <FormControl fullWidth>
        <InputLabel id={label + "-label"} style={{ backgroundColor: "white" }}>
          {label}
        </InputLabel>
        <Select
          id={label + "-input"}
          labelId={label + "-label"}
          value={currentCode || noneValue}
          renderValue={((value: string) => <span>{value !== noneValue ? value : noneItem}</span>) as any}
          onChange={(e: any) => onChange(e.target.value === noneValue ? "" : e.target.value)}
          disabled={noCodesAvailable}
        >
          <MenuItem value={noneValue}>{noneItem}</MenuItem>
          {codes.map((c) => (
            <MenuItem key={c} value={c}>
              {c}
            </MenuItem>
          ))}
        </Select>
        {noCodesAvailable && <FormHelperText>No {label}s found with matching currency and investment strategy.</FormHelperText>}
      </FormControl>
    </Box>
  );
}

function filterObjectCodes(source: Allocation[], allocation: Allocation, type: AllocationType, existingCode: string) {
  const codes = new Set(
    source
      .filter(
        (a) =>
          a.AllocationType === type &&
          a.ReferenceCurrency.Id === allocation.ReferenceCurrency.Id &&
          a.RiskProfile.Id === allocation.RiskProfile.Id
      )
      .map((i) => i.Name)
  );

  // in case (for whatever reasons) the current code would be filtered out
  if (existingCode !== "") {
    codes.add(existingCode);
  }

  return [...codes.values()];
}

const noneValue = "none";
const noneItem = <em style={{ color: "gray" }}>None</em>;
const typeString = {
  [AllocationType.Saa]: "SAA",
  [AllocationType.Taa]: "TAA",
  [AllocationType.Tca]: "TCA",
};
