import { parseJSON } from "date-fns";
import { request } from "../../../modules/client";
import { baseUrl } from "../../../store/apiUrlProvider";
import { AlpimaRequestHistory } from "./types/AlpimaRequestHistory";
import IntegrationStatus from "./types/IntegrationStatus";

/**
 * This file contains various requests against the backend api.
 * This does *not* mean that there aren't other requests made against the backend.
 * However those shared by various components are supposed to go here.
 */
export async function getIntegrationInfo(type: "alpima" | "excel", startDate: Date, includeHistory: boolean) {
  const segment = urlSegmentFromIntegrationType(type);

  const response = await request(
    `${baseUrl()}api/integration/allocation/${segment}info?historyStartDate=${startDate.toISOString()}&includeHistory=${includeHistory}`
  );

  const history = response.History
    ? response.History.map((e: any) => ({
        ...e,
        CreatedAt: parseIncomingDateAsLocal(e.CreatedAt),
      }))
    : null;

  return {
    ...response,
    LastRun: response.LastRun ? parseJSON(response.LastRun) : null,
    History: history,
  } as IntegrationStatus;
}

export async function getAlpimaRequestHistory(startDate: Date) {
  const response = await request(`${baseUrl()}api/integration/allocation/staging_history?startDate=${startDate.toISOString()}`);

  // convert the string values representing dates to Date-Objects
  const requests = response.Requests.map((e: any) => ({
    ...e,
    StagedAllocations: e.StagedAllocations?.map((alloc: any) => ({
      ...alloc,
      AsOfDate: Date.parse(alloc.AsOfDate),
    })),
    Recieved: parseIncomingDateAsLocal(e.Recieved),
  }));

  return {
    ...response,
    Requests: requests,
  } as AlpimaRequestHistory;
}

export async function runIntegration(type: "alpima" | "excel", forceRequestId?: string) {
  const segment = urlSegmentFromIntegrationType(type);

  let options: any = {
    method: "POST",
  };

  if (forceRequestId) {
    options.payload = {
      requestId: forceRequestId,
      force: true,
    };
  }
  return await request(`${baseUrl()}api/integration/allocation/${segment}run`, options);
}

/**
 * The date string we receive from the backend has a TZ offset of zero (alas we don't save UTC there).
 * Since date-fns/parseJson converts the received date to the local time, it would add an offset which is
 * wrong.
 * TODO / DISCUSS - better solution is to consequently story UTC dates in DB.
 */
function parseIncomingDateAsLocal(value: string) {
  return new Date(value.replace(/Z$/, ""));
}

function urlSegmentFromIntegrationType(type: "alpima" | "excel") {
  if (type === "alpima") {
    return "";
  } else if (type == "excel") {
    return "tst_excel/";
  }
}
