import * as React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useNavigate } from 'react-router';
import { InstrumentList, ServerMessage } from "../../types/InstrumentList";
import { AlertIcon, DeleteIcon, EditIcon, ViewIcon } from "../shared/Icons";
import { toShortDateString } from "../../modules/date";
import ConfirmDialog from "./ConfirmDialog";
import { AppState } from "../../store/AppState";
import { request } from "../../modules/client";
import { errorResponseToServerMessages } from "../../store/instrumentLists/InstrumentListState";
import { showAlert } from "../../store/app/app-slice";
import { instrumentListsRequest } from "../../store/instrumentLists/instrumentlist-slice";
import EditInstrumentListDialog from "./EditInstrumentListDialog";

/**
 * Displays a simple 'card' with the metadata of an InstrumentList and the possibility to navigate to the details.
 */

const PREFIX = 'InstrumentListCard';
const classes = {
    container: `${PREFIX}-container`,
    titleContainer: `${PREFIX}-titleContainer`,
    listTitle: `${PREFIX}-listTitle`,
    descriptionContainer: `${PREFIX}-descriptionContainer`,
    dateContainer: `${PREFIX}-dateContainer`,
    validationContainer: `${PREFIX}-validationContainer`,
    buttonContainer: `${PREFIX}-buttonContainer`,
}
const Root = styled('div')(({ theme }) => ({
    [`&.${classes.container}`]: {
        border: "1px solid #A8B0DE",
        cursor: "pointer",
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        font: theme.typography.body1.font,
        fontSize: theme.typography.body1.fontSize,
        boxShadow: theme.shadows[2],
        "& div": {
            display: "flex",
        },
        "&:hover": {
            boxShadow: theme.shadows[4],
        },
    },
    [`& .${classes.titleContainer}`]: {
        width: "22rem",
        flexDirection: "column",
        overflowWrap: "anywhere"
    },
    [`& .${classes.listTitle}`]: {
        fontWeight: "bold",
        color: theme.palette.primary.main
    },
    [`& .${classes.descriptionContainer}`]: {
        width: "44rem",
        alignItems: "center",
        wordWrap: "break-word",
        overflowX: "auto"
    },
    [`& .${classes.dateContainer}`]: {
        width: "10rem",
        alignItems: "center"
    },
    [`& .${classes.validationContainer}`]: {
        width: "6rem",
        alignItems: "center"
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        width: "9rem",
        alignItems: "center",
        marginRight: theme.spacing(3),
    },
}));

async function deleteListRequest(baseUrl: string, list: InstrumentList) {
  return await request(`${baseUrl}api/instrument-lists/${encodeURIComponent(list.instrumentListId)}`, {
    method: "DELETE",
  });
}

export default function InstrumentListCard(props: { list: InstrumentList; existingListIds: string[] }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tstCoreUrl = useSelector((state: AppState) => state.masterData.tstCoreUrl);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showEditDialog, setShowEditDialog] = React.useState(false);
  // The return message(s) from the server, after the Delete-Request.
  const [deleteServerMessages, setDeleteServerMessages] = React.useState<ServerMessage[]>([]);

  const navigateToDetails = () => {
    navigate(`/instrument-lists/${props.list.instrumentListId}`);
  };

  const onDeleteList = () => {
    deleteListRequest(tstCoreUrl, props.list)
      .then(() => {
        dispatch(showAlert("Successfully deleted list.", { type: "success" }));
        // Reload the lists
        dispatch(instrumentListsRequest());
        setShowDeleteDialog(false);
      })
      .catch((e: any) => {
        setDeleteServerMessages(errorResponseToServerMessages(e));
      });
  };

  return (
    <Root className={cn(classes.container, "uitest-instrumentlist-card")} onClick={navigateToDetails}>
      {showDeleteDialog && (
        <ConfirmDialog
          open={showDeleteDialog}
          title="Are you sure?"
          handleOk={onDeleteList}
          serverMessages={deleteServerMessages}
          allowCommentsOnMessages={false}
          handleCancel={() => setShowDeleteDialog(false)}
        >
          This will delete the list <i>{props.list.name}</i>.
        </ConfirmDialog>
      )}
      {showEditDialog && (
        <EditInstrumentListDialog
          open={showEditDialog}
          list={props.list}
          existingListIds={props.existingListIds}
          closeHandle={() => setShowEditDialog(false)}
        />
      )}
      <div className={classes.titleContainer}>
        <div className={classes.listTitle}>{props.list.name}</div>
        {props.list.instrumentListId}
      </div>

      <div className={classes.descriptionContainer}>{props.list.description}</div>

      <div className={classes.dateContainer}>{toShortDateString(props.list.date)}</div>

      <div className={classes.validationContainer}>
        {props.list.numberOfErrors > 0 && (
          <Tooltip title={`Click to show ${props.list.numberOfErrors} errors in list.`}>
            <IconButton size="small" disableRipple disableTouchRipple>
              <AlertIcon color="error" />
            </IconButton>
          </Tooltip>
        )}
        {props.list.numberOfWarnings > 0 && (
          <Tooltip title={`Click to show ${props.list.numberOfWarnings} warnings in list.`}>
            <IconButton size="small" disableRipple disableTouchRipple>
              <AlertIcon htmlColor="orange" />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className={classes.buttonContainer}>
        {props.list.canUpdateList && (
          <Tooltip title="Edit list properties">
            <IconButton
              size="small"
              disableRipple
              disableTouchRipple
              onClick={(evt) => {
                evt.stopPropagation();
                setShowEditDialog(true);
              }}
            >
              <EditIcon color="primary" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="View details">
          <IconButton
            size="small"
            disableRipple
            disableTouchRipple
            onClick={(evt) => {
              evt.stopPropagation();
              navigateToDetails();
            }}
          >
            <ViewIcon color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title={props.list.canDeleteList ? "Delete list" : "You are lacking permissions to delete this list."}>
          <IconButton
            // Don't disable this button, so we can stopPropagation of the click event (which would navigate otherwise).
            size="small"
            disableRipple={props.list.canDeleteList == false}
            disableTouchRipple={props.list.canDeleteList == false}
            onClick={(evt) => {
              evt.stopPropagation();
              if (props.list.canDeleteList) {
                setShowDeleteDialog(true);
              }
            }}
          >
            <DeleteIcon color={props.list.canDeleteList ? "primary" : "disabled"} />
          </IconButton>
        </Tooltip>
      </div>
    </Root>
  );
}
