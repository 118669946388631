import * as React from "react";
import Distribution from "../../types/Distribution";
import ConfirmDialog from "../shared/ConfirmDialog";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  FormControlLabel,
  TextField,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { Controller, useForm } from "react-hook-form";
import MasterDataEntry from "../../types/MasterDataEntry";
import AaaStrategyNature from "../../types/AaaStrategyNature";
import Destination from "../../types/Destination";
import useDistributionApi from "./useDistributionApi";
import Allocation from "../../types/Allocation";
import AllocationType from "../../types/AllocationType";
import { fromMasterDataEntry } from "../../types/MarketStructure";

const PREFIX = 'EditDistributionDialog';
const classes = {
  row: `${PREFIX}-row`,
  content: `${PREFIX}-content`,
  saveButton: `${PREFIX}-saveButton`,
  dialog: `${PREFIX}-dialog`,
  dialogButtons: `${PREFIX}-dialogButtons`
};
const RootDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`& .${classes.row}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.content}`]: {
    fontSize: theme.typography.body1.fontSize,
  },

  [`& .${classes.saveButton}`]: {
    marginLeft: theme.spacing(2),
  },

  [`&.${classes.dialog}`]: {
    "& .MuiDialog-paper": {
      overflow: "visible !important",
      width: "46em",
    },
  },

  [`& .${classes.dialogButtons}`]: {
    display: "flex",
    justifyContent: "space-between",
  }
})) as typeof Dialog;

/**
 * Dialog to create/update/delete a distribution from an allocation.
 *
 * If the provided distribution has a .Id < 0 will be treated as a new distribution, instead of an existing one.
 */
export default function EditDistributionDialog(props: {
  allocation: Allocation;
  distribution: Distribution;
  marketStructures: MasterDataEntry[];
  aaaStrategyNatures: AaaStrategyNature[];
  destinations: Destination[];
  createAnotherHandle: (value: Distribution) => void;
  closeHandle: () => void;
}) {
  const { allocation, distribution, marketStructures, aaaStrategyNatures, destinations, closeHandle, createAnotherHandle } =
    props;
  const isMopo = allocation.AllocationType === AllocationType.ModelPortfolio;
  const isNewDistribution = distribution.Id < 0;
  const [isBusy, setIsBusy] = React.useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState<boolean>(false);

  const api = useDistributionApi(allocation);

  const { handleSubmit, register, formState: { errors }, control, watch } = useForm<FormInputs>({
    mode: "onBlur",
  });

  const createUpdatedDistribution = (input: FormInputs) => {
    const result: Distribution = { ...distribution };
    result.Code = input.Code?.trim(); // we trim the input here, to avoid copy'n'paste errors.
    result.EnableInheritance = input.EnableInheritance;
    result.UseForAllocationPublish = input.UseForAllocationPublish;
    result.GenerateDynWeightRequest = input.GenerateDynWeightRequest;
    result.UseForStrategyLink = input.UseForStrategyLink;
    result.Destination = destinations.find((it) => it.Id == input.DestinationId) as any;
    if (isMopo) {
      const entry = marketStructures.find((it) => it.Id == input.MarketStructureId.toString());
      if (entry) {
        result.MarketStructure = fromMasterDataEntry(entry);
      }
    } else {
      result.AaaStrategyNature = aaaStrategyNatures.find((it) => it.AaaStrategyNatureShort == input.AaaStrategyNatureShort);
    }

    return result;
  };

  const saveHandler = (formInput: FormInputs) => {
    const updatedDistribution = createUpdatedDistribution(formInput);

    setIsBusy(true);
    const promise = isNewDistribution ? api.createDistribution(updatedDistribution) : api.updateDistribution(updatedDistribution);

    promise.then(
      () => {
        setIsBusy(false);
        closeHandle();
        if (formInput.CreateAnother) {
          createAnotherHandle(updatedDistribution);
        }
      },
      (error) => {
        setIsBusy(false);
      }
    );
  };

  const deleteHandler = () => {
    setIsBusy(true);
    api.deleteDistribution(distribution).then(
      () => {
        setIsBusy(false);
        setShowDeleteConfirmation(false);
        closeHandle();
      },
      (error) => {
        setIsBusy(false);
      }
    );
  };

  return (
    <>
      {showDeleteConfirmation && (
        <ConfirmDialog
          open
          okButtonLabel="Delete"
          title="Please confirm"
          autoFocusOkButton
          handleOk={deleteHandler}
          handleCancel={() => setShowDeleteConfirmation(false)}
        >
          Are you sure you want to delete this distribution?
        </ConfirmDialog>
      )}
      <RootDialog maxWidth="lg" open={true} className={classes.dialog}>
        <form onSubmit={handleSubmit(saveHandler)}>
          <DialogTitle id="confirmation-dialog-title">Edit Distribution</DialogTitle>
          <DialogContent dividers className={classes.content}>
            <div className={classes.row}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="destinations">Triple'A</InputLabel>
                <Controller
                  render={({ field }) =>
                      <Select
                          {...field}
                          labelId="destinations"
                          label="Triple'A"
                          autoFocus
                          children={destinations.map((dest) => (
                              <MenuItem value={dest.Id}>{dest.DestinationLong}</MenuItem>
                          ))}
                      />
                  }
                  control={control}
                  name="DestinationId"
                  rules={{ required: true }}
                  defaultValue={distribution?.Destination?.Id}
                />
              </FormControl>
              {errors.DestinationId?.type == "required" && (
                <Typography color="error" variant="body2">
                  A TAP instance is required.
                </Typography>
              )}
            </div>
            <div className={classes.row}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="market-structure">Market Structure</InputLabel>

                {isMopo && (
                  <Controller
                      render={({ field }) =>
                        <Select
                            {...field}
                            labelId="market-structure"
                            label="Market Structure"
                            children={marketStructures.map((ms) => (
                                <MenuItem value={ms.Id}>{ms.Name}</MenuItem>
                            ))}
                        />
                      }
                    control={control}
                    name="MarketStructureId"
                    rules={{ required: true }}
                    defaultValue={distribution?.MarketStructure?.Id}
                  />
                )}
                {!isMopo && (
                  <Controller
                    render={({ field }) =>
                      <Select
                          {...field}
                          labelId="market-structure"
                          label="Market Structure"
                          children={aaaStrategyNatures.map((sn) => (
                              <MenuItem value={sn.AaaStrategyNatureShort}>{sn.AaaStrategyNatureShort}</MenuItem>
                          ))}
                      />
                    }
                    control={control}
                    name="AaaStrategyNatureShort"
                    rules={{ required: true }}
                    defaultValue={distribution?.AaaStrategyNature?.AaaStrategyNatureShort}
                  />
                )}
                {(errors.AaaStrategyNatureShort?.type == "required" || errors.MarketStructureId?.type == "required") && (
                  <Typography color="error" variant="body2">
                    A Market Structure is required.
                  </Typography>
                )}
              </FormControl>
            </div>
            <div className={classes.row}>
              <TextField
                id="code"
                label="Code"
                error={!!errors.Code}
                fullWidth
                defaultValue={distribution?.Code}
                {...register(
                    "Code",
                    {
                      required: true
                    }
                )}
              />
              {errors.Code?.type == "required" && (
                <Typography color="error" variant="body2">
                  A code is required.
                </Typography>
              )}
            </div>
            <div className={classes.row}>
              <FormControlLabel
                style={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    id="EnableInheritance"
                    defaultChecked={distribution?.EnableInheritance}
                    {...register("EnableInheritance")}
                  />
                }
                label="Inherit"
              />
            </div>
            <div className={classes.row}>
              <FormControlLabel
                style={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    id="UseForStrategyLink"
                    {...register("UseForStrategyLink")}
                    defaultChecked={distribution?.UseForStrategyLink}
                  />
                }
                label="Use for strategy link"
              />
            </div>
            <div className={classes.row}>
              <FormControlLabel
                style={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    id="UseForAllocationPublish"
                    {...register("UseForAllocationPublish")}
                    defaultChecked={distribution?.UseForAllocationPublish}
                  />
                }
                label="Use for allocation publish"
              />
            </div>
            {watch("AaaStrategyNatureShort", distribution?.AaaStrategyNature?.AaaStrategyNatureShort) !== "AA (IM CA)" &&
              watch("DestinationId", distribution?.Destination?.Id) === 1 /*CH*/ && (
                <div className={classes.row}>
                  <FormControlLabel
                    style={{ marginLeft: 0 }}
                    control={
                      <Checkbox
                        id="GenerateDynWeightRequest"
                        {...register("GenerateDynWeightRequest")}
                        defaultChecked={distribution?.GenerateDynWeightRequest}
                      />
                    }
                    label="Request dynamic weights"
                  />
                </div>
              )}
          </DialogContent>
          <DialogActions className={classes.dialogButtons}>
            <div>
              {isNewDistribution && (
                <FormControlLabel
                  style={{ marginLeft: 0 }}
                  control={<Checkbox id="create-another" {...register("CreateAnother")} defaultChecked={false} />}
                  label="Create another"
                />
              )}
              {!isNewDistribution && (
                <Button
                  variant="contained"
                  disabled={isBusy}
                  onClick={() => {
                    setShowDeleteConfirmation(true);
                  }}
                >
                  Delete
                </Button>
              )}
            </div>
            <div>
              <Button
                disabled={isBusy}
                autoFocus
                onClick={(evt) => {
                  evt.stopPropagation();
                  props.closeHandle();
                }}
              >
                Cancel
              </Button>
              <Button
                className={classes.saveButton}
                type="submit"
                color="primary"
                variant="contained"
                disabled={
                  isBusy ||
                  !!errors.Code ||
                  !!errors.DestinationId ||
                  !!errors.MarketStructureId ||
                  !!errors.AaaStrategyNatureShort
                }
              >
                Save
              </Button>
            </div>
          </DialogActions>
        </form>
      </RootDialog>
    </>
  );
}

type FormInputs = {
  DestinationId: number;
  MarketStructureId: number;
  AaaStrategyNatureShort: string;
  Code: string;
  EnableInheritance: boolean;
  UseForStrategyLink: boolean;
  UseForAllocationPublish: boolean;
  GenerateDynWeightRequest: boolean;
  CreateAnother: boolean;
};
