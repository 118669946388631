export interface AssetTree {
  Structure: string;
  Type: AssetTreeType;
  Tree: AssetTreeRow[];
}

export interface AssetTreeRow {
  List: string;
  Rank?: string;
  Label: string;
  Tree?: AssetTreeRow[]; // leaves have no Tree property
}

// Values are in sync with the backend.
export enum AssetTreeType {
  IcStructure = 0,
  MarketTree = 1,
}
