import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import { DataSourceStatus, ProductDataStatus, sources } from "./utils";
import DataSourcePopup from "./dialogs/DataSourcePopup";
import StatusLabel from "./StatusLabel";

export default function DaisyDataSources(props: { data: DataSourceStatus }) {
  const errors = props.data.ErrorMessages || [];
  const warnings = props.data.WarningMessages || [];
  const anyErrors = Object.keys(errors).length > 0;
  const [popupOpen, setPopupOpen] = React.useState(false);
  return (
    <>
      <Typography variant="h3" gutterBottom>
        Data Sources
      </Typography>
      {anyErrors && (
        <Box marginY={1}>
          <Entry error>Publishing blocked</Entry>
        </Box>
      )}
      <Box marginY={1}>
        <Button variant="outlined" color="secondary" onClick={() => setPopupOpen(true)}>
          <Typography variant="body2">Show Details</Typography>
        </Button>
      </Box>
      <Box>
        {Object.keys(sources).map((s) => (
          <Entry key={s} error={!!errors[s]} warning={!!warnings[s]}>
            {sources[s]}
          </Entry>
        ))}
      </Box>
      {popupOpen && <DataSourcePopup data={props.data} onClose={() => setPopupOpen(false)} />}
    </>
  );
}

const Entry: React.FC<{ error?: boolean, warning?: boolean, children?: React.ReactNode }> = (props) => (
  <StatusLabel status={renderStatus(props.error, props.warning)} iconScale={0.7}>
    <Typography variant="body2" color="textPrimary">
      {props.children}
    </Typography>
  </StatusLabel>
);

function renderStatus(error?: boolean, warning?: boolean) {
  if(error) return ProductDataStatus.Error;
  if(warning) return ProductDataStatus.Warning;
  return ProductDataStatus.ReadyForPublish;
}
