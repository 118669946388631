export const transformLabel = (name: "state" | "action", value: string): string => {
  if (transformations[name] === undefined) {
    return value;
  }
  const filtered = transformations[name].filter((transfKeyValuePair: any) => {
    return transfKeyValuePair.value === value;
  });

  return filtered && filtered.length === 1 ? filtered[0].label : value;
};

const transformations = {
  state: [
    {
      value: "Draft",
      label: "Draft",
    },
    {
      value: "InEdit",
      label: "In Edit",
    },
    {
      value: "Queued",
      label: "Queued",
    },
    {
      value: "Published",
      label: "Published",
    },
  ],
  action: [
    {
      value: "Edit",
      label: "Edit",
    },
    {
      value: "Discard",
      label: "Discard",
    },
    {
      value: "Save",
      label: "Save",
    },
    {
      value: "Queueforpublish",
      label: "Queue for publish",
    },
    {
      value: "Timeout",
      label: "Timeout",
    },
    {
      value: "Publish",
      label: "Publish",
    },
  ],
};
