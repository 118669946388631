import { useSelector } from "react-redux";
import { AppState } from "../../store/AppState";
import { CompareViewState } from "./types";

/**
 * Convenience hook for compare view substate selection.
 */
export default function useCompareSelector<T>(selector: (state: CompareViewState) => T) {
  return useSelector((s: AppState) => selector(s.compare));
}
