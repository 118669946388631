import * as React from "react";
import { Button, DialogActions, Dialog, DialogContent, DialogTitle } from "@mui/material";
import TailoringInformation from "../../meta-view/TailoringInformation";

export default function TailoringDialog(props: { close: () => void; objectCode: string }) {
  const { objectCode, close } = props;

  return (
    <Dialog open={true} onClose={close} maxWidth={false}>
      <DialogTitle>
        {objectCode}
        <br />
        <small>Tailoring</small>
      </DialogTitle>
      <DialogContent>
        <TailoringInformation embedded objectCode={objectCode} />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
