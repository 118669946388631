import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { useDispatch } from "react-redux";
import { hideLoadingScreen, showLoadingScreen } from "../../store/loading/loading-slice";
import { fetchPublishStatus, getPublishInfo, PublishStatus } from "./PublishPanel";
import addDays from "date-fns/addDays";
import * as React from "react";
import * as integrationApi from "./integration/apiRequests";
import StatsPanel, { StatsPanelEntry } from "./StatsPanel";
import PanelDescriptionHeader from "./PanelDescriptionHeader";
import IntegrationStatus from "./integration/types/IntegrationStatus";
import { showAlert } from "../../store/app/app-slice";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { LinkOutlined } from "@mui/icons-material";
import useInstrumentStatus from "./useInstrumentStatus";
import usePeriodicAllocationInfo from "./allocations/usePeriodicAllocationInfo";
import AllocationType from "../../types/AllocationType";
import { fromApi } from "../compare-view/utils/weights";
import * as dynWeightsRequestsApi from "./dynamic-weights/apiRequests";
import { DynamicWeightRequestInfo } from "./dynamic-weights/DynamicWeightRequestInfo";
import { Overview as DynamicWeightRequestOverview } from "./dynamic-weights/RequestsInfo";

const PREFIX = 'DashboardPanel';
const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  statsContainer: `${PREFIX}-statsContainer`,
  statsTitleHeader: `${PREFIX}-statsTitleHeader`,
  sectionContent: `${PREFIX}-sectionContent`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.gridContainer}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.statsContainer}`]: {
    height: "100%",
    paddingLeft: theme.spacing(1),
  },

  [`& .${classes.statsTitleHeader}`]: {
    paddingTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& :last-child": {
      marginLeft: theme.spacing(1),
    },
  },

  [`& .${classes.sectionContent}`]: {
    marginRight: "16px",
  }
}));

export default function DashboardPanel() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const getInstrumentStatus = useInstrumentStatus();

  const [alpimaStatus, setAlpimaInfo] = React.useState<IntegrationStatus>(emptyIntegrationStatus);
  const [tstExcelStatus, setTstExcelStatus] = React.useState<IntegrationStatus>(emptyIntegrationStatus);
  const [publishStatus, setPublishStatus] = React.useState<PublishStatus>(emptyPublishStatus);
  const [instrumentEntries, setInstrumentEntries] = React.useState<StatsPanelEntry[]>([]);
  const [displayedDataTimestamp, setDisplayedDataTimestamp] = React.useState<Date | undefined>(undefined);
  const [dynWeightsRequestInfo, setDynWeightsRequestInfo] = React.useState<DynamicWeightRequestInfo | undefined>(undefined);

  const [allocationInfo, allocationInfoRefresh] = usePeriodicAllocationInfo();

  const doRefresh = async () => {
    dispatch(showLoadingScreen());

    try {
      const startDate = addDays(Date.now(), -7);

      const publishStatus = await fetchPublishStatus();
      setPublishStatus(publishStatus);

      const alpimaStatus = await integrationApi.getIntegrationInfo("alpima", startDate, false);
      setAlpimaInfo(alpimaStatus);

      const tstExcelStatus = await integrationApi.getIntegrationInfo("excel", startDate, false);
      setTstExcelStatus(tstExcelStatus);

      const instrumentStatus = await getInstrumentStatus();
      setInstrumentEntries(instrumentStatus);

      const requests = await dynWeightsRequestsApi.getAllRequestsInfo(true);
      setDynWeightsRequestInfo(requests[0]);

      setDisplayedDataTimestamp(new Date());
    } catch (e) {
      dispatch(showAlert("Could not load data from backend", { type: "error" }));
      console.error("doRefresh failed", e);
    } finally {
      dispatch(hideLoadingScreen());
    }
  };

  const alpimaEntries = [
    { value: alpimaStatus.PendingAllocations, label: "pending allocations in staging" },
    { value: alpimaStatus.IntegratedInLast7Days, label: "integrated allocations in the last 7 days" },
    {
      value: alpimaStatus.FailedInLast7Days,
      label: "failed allocations in the last 7 days",
      isError: alpimaStatus.FailedInLast7Days > 0,
    },
    {
      value: alpimaStatus.RejectedAllocationsInLast7Days,
      label: "rejected allocation in the last 7 days",
      isError: alpimaStatus.RejectedAllocationsInLast7Days > 0,
    },
  ];

  const tstExcelEntries = [
    { value: tstExcelStatus.PendingAllocations, label: "pending TST strategy uploads to integrate" },
    { value: tstExcelStatus.IntegratedInLast7Days, label: "integrated allocations in the last 7 days" },
    {
      value: tstExcelStatus.FailedInLast7Days,
      label: "failed allocations in the last 7 days",
      isError: tstExcelStatus.FailedInLast7Days > 0,
    },
  ];

  const invalidMopos = allocationInfo.ContainingDeletedInstruments.filter(
    (a) => a.AllocationType === AllocationType.ModelPortfolio
  );
  const invalidAllos = allocationInfo.ContainingDeletedInstruments.filter(
    (a) => a.AllocationType !== AllocationType.ModelPortfolio && a.AllocationType !== AllocationType.Unknown
  );
  const allocationEntries = [
    { value: invalidMopos.length, label: "MOPOs with deleted instruments", isError: invalidMopos.length > 0 },
    { value: invalidAllos.length, label: "TAAs/TCAs/SAAs with deleted instruments", isError: invalidAllos.length > 0 },
  ];

  React.useEffect(() => {
    doRefresh();
    allocationInfoRefresh();
  }, [getInstrumentStatus /* the first `getInstrumentStatus` has not the correct URL due to missing master data */]);

  return (
    <Root>
      <PanelDescriptionHeader>
        Overview of core TST capabilities and status. Details can be found in the respective sections.
      </PanelDescriptionHeader>
      <div>
        <Button onClick={doRefresh}>Refresh</Button>
        <List dense style={{ marginBottom: theme.spacing(1) }}>
          {displayedDataTimestamp && (
            <ListItem>
              Last refresh of displayed data at <strong>&nbsp;{format(displayedDataTimestamp, "HH:mm")}</strong>.<br />
            </ListItem>
          )}
        </List>
        <Divider style={{ marginBottom: theme.spacing(2) }} />

        <Grid container spacing={2} className={classes.gridContainer}>
          <Section header="Alpima Integration" link="alpima integration" entries={alpimaEntries} />
          <Section header="TST-Excel Integration" link="tst-excel integration" entries={tstExcelEntries} />
          <Section header="TAP Publish" link="tap publish" entries={getPublishInfo(publishStatus).stats} />
          <Section header="Instrument Status" link="instrument status" entries={instrumentEntries} />
          <Section header="Allocations" link="allocations" entries={allocationEntries} />
          {dynWeightsRequestInfo && (
            <Section
              header="Dynamic Weights"
              link="dynamic weights"
              content={<DynamicWeightRequestOverview data={dynWeightsRequestInfo} />}
            />
          )}
        </Grid>
      </div>
    </Root>
  );
}

function Section(props: { header: string; link: string; entries?: StatsPanelEntry[]; content?: JSX.Element }) {
  const { header, link, entries, content } = props;
  return (
    <Grid item xs={12} lg={6}>
      <Paper className={classes.statsContainer} variant="outlined">
        <div className={classes.statsTitleHeader}>
          <Typography variant="h3" gutterBottom>
            {header}
          </Typography>
          <DetailLink to={link} />
        </div>
        {entries && <StatsPanel dense entries={entries} />}
        <div className={classes.sectionContent}>{content}</div>
      </Paper>
    </Grid>
  );
}

function DetailLink(props: { to: string }) {
  return (
    <Tooltip title="Go to details">
      <Link to={`/administration/${props.to}`}>
        <LinkOutlined />
      </Link>
    </Tooltip>
  );
}

const emptyIntegrationStatus = {
  LastRun: undefined,
  Count: 0,
  PendingAllocations: 0,
  IntegratedInLast7Days: 0,
  FailedInLast7Days: 0,
  RejectedAllocationsInLast7Days: 0,
  History: [],
};

const emptyPublishStatus = {
  LastProcessingDate: undefined,
  LastPublishRequest: undefined,
  QueuedAllocations: [],
  QueuedLinkRequests: [],
  PublishedAllocationsInLast24h: 0,
  PublishedLinksInLast24h: 0,
  History: [],
  TotalAllocationPublishHistoryCount: 0,
  TotalStrategyLinkHistoryCount: 0,
};
