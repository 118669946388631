import * as React from "react";
import { styled } from '@mui/material/styles';
import uniqBy from "lodash/uniqBy";
import { Chip } from "@mui/material";
import InstrumentAutocomplete, { InstrumentSearchResult } from "./InstrumentAutocomplete";

const PREFIX = 'MultiInstrumentSelect';
const classes = {
  selection: `${PREFIX}-selection`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.selection}`]: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.5),
    "& > .MuiChip-root": {
      maxWidth: "100%",
      margin: theme.spacing(0.25),
    },
  }
}));

/**
 * A multi-select control for instruments.
 *
 * Renders an autocomplete input for the instrument search.
 * Selected instruments are shown as chips below the autocomplete.
 */
export default React.memo(function MultiInstrumentSelect(props: {
  label: string;
  selectedInstruments?: InstrumentLabel[];
  useExtendedSearch?: boolean;
  onChange: (selection: InstrumentSearchResult[]) => void;
}) {
  const { label, selectedInstruments, onChange, useExtendedSearch } = props;

  // Holding the actual selection as internal state and update it only, if initial
  // selection changes. Reason: We want to show the current selection immediately
  // and independent of other side effects. If the page size is set to max, then
  // we would observe a delay in rendering here which is caused by inefficient/unnecessary
  // renderings of the allocation table.
  const initialSelection = (selectedInstruments || []).map((i) => ({
    ...i,
    imsInstrumentId: "",
    isin: "",
    bloombergTicker: "",
    bloombergGlobalId: "",
    productType: "",
    defaultTradingCurrency: "",
    riskCurrency: "",
    assetSubClass: "",
  }));

  const [selection, setSelection] = React.useState<InstrumentSearchResult[]>(initialSelection);
  if (differs(initialSelection, selection)) {
    setSelection(initialSelection);
  }

  function selectInstrument(instrument: InstrumentSearchResult) {
    const newSelection = uniqBy([...selection, instrument], (i) => i.instrumentId);
    setSelection(newSelection);
    onChange(newSelection);
  }

  function deselectInstrument(instrumentId: string) {
    const newSelection = selection.filter((i) => i.instrumentId !== instrumentId);
    setSelection(newSelection);
    onChange(newSelection);
  }

  return (
    <Root>
      <InstrumentAutocomplete {...{ label, useExtendedSearch }} onSelect={selectInstrument} preventAutoBlur />
      <div className={classes.selection}>
        {selection.map((i) => {
          const deselect = () => deselectInstrument(i.instrumentId);
          return <Chip key={i.instrumentId} size="small" color="primary" label={i.name} onDelete={deselect} onClick={deselect} />;
        })}
      </div>
    </Root>
  );
});

export interface InstrumentLabel {
  instrumentId: string;
  name: string;
}

function differs(a: InstrumentSearchResult[] = [], b: InstrumentSearchResult[] = []) {
  const unionSize = new Set([...a.map((i) => i.instrumentId), ...b.map((i) => i.instrumentId)]).size;
  return a.length !== unionSize || b.length !== unionSize;
}
