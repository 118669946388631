import "isomorphic-fetch";
import { User } from "oidc-client-ts";
import { v4 as uuid } from "uuid";
import { handleRequest } from "./http-request-utils";
import { getUserFromLocalStorage } from "../auth/oidcConfig";



export const request = async (url: string, options: any = {}): Promise<any> => {
  let user: User | null = null;
  user = await getUserFromLocalStorage();

  const config = {
    method: "GET",
    ...options,
  };

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "JB-Correlation-Request-Id": uuid(), //generate a new correlation request id for every request
    ...(user?.access_token ? { "Authorization": `Bearer ${user.access_token}` } : {}),
    ...config.headers,
  };

  const params: any = {
    headers,
    method: config.method,
    credentials: "include",
  };

  if (params.method !== "GET" && config.payload) {
    params.body = JSON.stringify(config.payload);
  }

  /* please note, that while developing using the usual setup (webpack-dev-server) with the backend-api on another port in IIS-Express
     CORS is an issue and the error handling might not be, what you would normally expect
     (response errors lead to straight rejection of the promise, instead of handling them in the function)
     if you want/need to test the error handling in those cases, please consider copying the build into the backend and testing it without CORS issues
     (via npm run build:copy)
  */
  
  return handleRequest(url, params);
};
