import * as React from "react";
import { Button, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { hideLoadingScreen, showLoadingScreen } from "../../../store/loading/loading-slice";
import { showAlert } from "../../../store/app/app-slice";
import { Group } from "./types";
import * as api from "./apiRequests";

export default function GroupActiveChangeButton(props: Group & { onChange: () => void }) {
  const { GroupId, GroupName, IsGroupDeleted, AllocationCount, onChange } = props;
  const dispatch = useDispatch();
  const isDisabled = !IsGroupDeleted && AllocationCount > 0;

  const doChange = async () => {
    dispatch(showLoadingScreen());
    try {
      await api.updateGroup(GroupId, GroupName, !IsGroupDeleted);
      onChange();
    } catch (e) {
      console.error("doChange failed", e);
      dispatch(showAlert(`Failed to ${IsGroupDeleted ? "activate" : "deactivate"} group`, { type: "error" }));
    } finally {
      dispatch(hideLoadingScreen());
    }
  };

  return (
    <Tooltip title={isDisabled ? "Not possible due to owned allocations." : ""}>
      <div>
        <Button onClick={doChange} disabled={isDisabled}>
          {IsGroupDeleted ? "Activate" : "Deactivate"}
        </Button>
      </div>
    </Tooltip>
  );
}
