import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { useTheme } from "@mui/material/styles";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link } from "@mui/material";
import PlusIcon from "@mui/icons-material/Add";
import { NavigateFunction, useNavigate } from 'react-router';
import { AppState } from "../../../store/AppState";
import { CreateAllocationState } from "../../../store/admin/AdminState";
import {
  createAllocationCheckRequest,
  createAllocationCreateRequest,
  createAllocationOpen,
  createAllocationClose,
} from "../../../store/admin/admin-slice";
import AllocationSelector, { AllocationSelection, isBespoke } from "./AllocationSelector";

interface CreateAllocationDialogProps {
  dispatch: Dispatch;
  createAllocationState: CreateAllocationState;
}

export default connect((state: AppState) => ({
  createAllocationState: state.admin.createAllocationState,
}))(function CreateAllocationDialog(props: CreateAllocationDialogProps) {
  const { dispatch } = props;
  const navigate = useNavigate();
  const { isLoading, dialog, checkResult, createResult } = props.createAllocationState;
  const [selection, setSelection] = React.useState("incomplete" as AllocationSelection);

  const theme = useTheme();
  const disableCreate = isLoading || (checkResult !== "ok" && !isBespoke(selection));
  const nbsp = "\u00a0";

  let statusLine1: any;
  let statusLine2: any;

  if (selection === "incomplete") {
    statusLine1 = statusLine2 = nbsp;
  } else if (isBespoke(selection)) {
    statusLine1 = "The chosen BESPOKE allocation can be created.";
    statusLine2 = nbsp;
  } else if (checkResult === undefined) {
    statusLine1 = statusLine2 = nbsp;
  } else if (checkResult === "ok") {
    statusLine1 = "The chosen allocation doesn't exist yet. Go ahead and create it!";
    statusLine2 = nbsp;
  } else if (!!checkResult.allocationId) {
    statusLine1 = "The chosen allocation exists already!";
    statusLine2 = (
      <Link href="#" onClick={() => goToExistingAllocation(navigate)}>
        {checkResult.objectName}
      </Link>
    );
  }

  function selectionChanged(newSelection: AllocationSelection) {
    console.log(newSelection);
    setSelection(newSelection);
    if (newSelection !== "incomplete" && !isBespoke(newSelection)) {
      dispatch(createAllocationCheckRequest(newSelection));
    }
  }

  function create() {
    if (selection === "incomplete") return;
    dispatch(createAllocationCreateRequest(selection));
  }

  function allocationLinkUrl(allocationId: string) {
    return `/compare/${allocationId}`;
  }

  function goToExistingAllocation(navigate: NavigateFunction) {
    if (checkResult === undefined || checkResult === "ok") return;
    closeDialog();
    navigate(`/compare/${checkResult.allocationId}`);
  }

  function openDialog() {
    dispatch(createAllocationOpen());
  }

  function closeDialog() {
    setSelection("incomplete");
    dispatch(createAllocationClose());
  }

  return (
    <div>
      <Button startIcon={<PlusIcon />} color="primary" variant="contained" onClick={openDialog}>
        New Allocation
      </Button>

      <Dialog open={dialog === "selection"} maxWidth="md" onClose={closeDialog} aria-labelledby="createAllocation-title">
        <DialogTitle id="createAllocation-title">Create New Allocation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To create a new allocation, please fill in the fields below. We will check, if an allocation with this signature
            already exists.
          </DialogContentText>
          <AllocationSelector changed={selectionChanged} disabled={isLoading} />
          <DialogContentText style={{ marginTop: theme.spacing(2) }}>{statusLine1}</DialogContentText>
          <DialogContentText style={{ marginTop: theme.spacing(1) }}>{statusLine2}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={create} disabled={disableCreate} color="primary" variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialog === "success"} aria-labelledby="createAllocationSuccess-title">
        <DialogTitle id="createAllocationSuccess-title">Allocation Created</DialogTitle>
        <DialogContent>
          <DialogContentText>You can follow the link below to open the details view in a new tab.</DialogContentText>
          <DialogContentText>
            {createResult?.map((it) => (
              <>
                <Link href={allocationLinkUrl(it.allocationId)} target="_blank">
                  <span>{it.objectName}</span>
                </Link>
                <br />
              </>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" variant="contained">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
