import React from "react";
import { DynamicWeightRequestErrorInfo } from "./DynamicWeightRequestInfo";
import { ColumnDefinition, ColumnType } from "../../../types/GenericTable";
import { GenericTable } from "./GenericTable";

const columnDefinitions: ColumnDefinition<DynamicWeightRequestErrorInfo>[] = [
  {
    propName: "StrategyCode",
    label: "Distribution Code",
    columnType: ColumnType.ReadOnly,
  },
  {
    propName: "Currency",
    label: "Currency",
    columnType: ColumnType.ReadOnly,
  },
  {
    propName: "Date",
    label: "Date",
    columnType: ColumnType.ReadOnly,
  },
  {
    propName: "StartTime",
    label: "Start",
    columnType: ColumnType.ReadOnly,
  },
  {
    propName: "EndTime",
    label: "End",
    columnType: ColumnType.ReadOnly,
  },
  {
    propName: "ErrorMessage",
    label: "Error",
    columnType: ColumnType.ReadOnly,
  },
];

export const DynamicWeightRequestErrors = (props: { data: DynamicWeightRequestErrorInfo[] }) => {
  return (
    <>
      <GenericTable
        colDefinitions={columnDefinitions}
        data={props.data}
        keyColName="StrategyCode"
        sortBy="StrategyCode"
        title=""
        filterColumn="StrategyCode"
        rowsPerPage={15}
      />
    </>
  );
};
