import * as React from "react";
import DropdownBase, { DropdownOptions, DropdownConfig } from "./DropdownBase";

/**
 * Autocomplete dropdown component with a fixed set of string values.
 * Shows suggestions starting with the current input (case insensitive).
 */
export default function SimpleAutocomplete(props: DropdownConfig<string> & Omit<DropdownOptions<string>, "preventAutoClear">) {
  const [input, setInput] = React.useState<string>("");
  const config = { ...props, items: props.items.filter((s) => s.toUpperCase().startsWith(input)) };

  return (
    <DropdownBase
      {...config}
      formatItem={(item) => item}
      onInputChange={(input) => setInput((input || "").toUpperCase())}
      preventAutoClear
    />
  );
}
