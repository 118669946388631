import * as React from "react";
import { styled } from '@mui/material/styles';
import { AppState } from "../../store/AppState";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from 'react-router';
import { useStore } from 'react-redux';
import Allocation from "../../types/Allocation";
import BasicInformation from "./MetaViewBasicInformation";
import TailoringInformation, { isTailoringEnabled } from "./TailoringInformation";

const PREFIX = 'MetaViewContainer';
const classes = {
  leftIcon: `${PREFIX}-leftIcon`,
  button: `${PREFIX}-button`,
  headerGutter: `${PREFIX}-headerGutter`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.leftIcon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.button}`]: {
    textAlign: "left" as "left",
    fontSize: "1.4rem",
    marginLeft: 0,
    paddingLeft: 0,
  },

  [`& .${classes.headerGutter}`]: {
    paddingBottom: theme.spacing(3),
  }
}));


type MetaViewContainerProps = {
  allocation: Allocation;
};

export const MetaViewContainer: React.FC<MetaViewContainerProps> = ({ allocation }) => {
  const navigate = useNavigate();
  const store = useStore<AppState>();
  const masterData = store.getState().masterData;

  if (!allocation) {
    return <div></div>;
  }

  const editMode = () => navigate(`/compare/${allocation.Id}`);

  return <Root>
    {allocation && (
        <Grid container spacing={4}>
          <Grid item xs={12} sm={2}>
            <Typography variant="h3" className={classes.headerGutter}>
              Actions
            </Typography>
            <div>
              <div>
                <Button onClick={editMode} variant="text" className={classes.button}>
                  Go to allocation details
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={9}>
            <BasicInformation
                enableEditing={true}
                allocation={allocation}
                marketStructures={masterData.marketStructures}
                aaaStrategyNatures={masterData.aaaStrategyNatures}
                destinations={masterData.destinations}
            />
            {isTailoringEnabled(allocation) && (
                <div style={{ marginTop: "4em" }}>
                  <TailoringInformation objectCode={allocation.Name} />
                </div>
            )}
          </Grid>
        </Grid>
    )}
  </Root>;
}
