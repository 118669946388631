import React from "react";
import { styled } from '@mui/material/styles';
import { IconButton, Tooltip } from "@mui/material";
import FileCopyOutlined from "@mui/icons-material/FileCopyOutlined";
import { useDispatch } from "react-redux";
import { showAlert } from "../../store/app/app-slice";

const PREFIX = 'AllocationName';
const classes = {
  root: `${PREFIX}-root`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    ...theme.typography.body2,
  }
}));

export function formatAllocationName(name: string): { line1: string; line2: string; line3: string } {
  // Deleted allocations have an underscore as the prefix per convention.
  // Ignore it in the dissection and re-add it later in the output.
  const hasPrefixUnderscore = name.startsWith("_");
  if (hasPrefixUnderscore) {
    name = name.substring(1);
  }

  const parts = name.split("_");
  // so few parts?! seems fishy, simply return the name
  if (parts.length <= 4) {
    return { line1: name, line2: "", line3: "" };
  }

  const l1 = `${hasPrefixUnderscore ? "_" : ""}${parts.shift()}_${parts.shift()}_`;
  let l3Parts = [];

  // get the parts for line 3 - this removes the used elements from the parts-array
  let isLastPartNumeric = !isNaN(parts[parts.length - 1] as any);
  if (isLastPartNumeric) {
    // gobble up all numeric parts into line 3
    while (isLastPartNumeric) {
      l3Parts.push(parts.pop());
      isLastPartNumeric = !isNaN(parts[parts.length - 1] as any);
    }
  } else {
    // pop the last 2 parts into line 3
    l3Parts = [parts.pop(), parts.pop()];
  }
  l3Parts.reverse();

  const l2 = `${parts.join("_")}_`;
  const l3 = `${l3Parts.join("_")}`;

  return {
    line1: l1,
    line2: l2,
    line3: l3,
  };
}

/**
 * Renders an allocation name splitted into 3 lines.
 */
export default function AllocationName(props: { name: string }) {
  let { name } = props;
  const dispatch = useDispatch();

  const lines = formatAllocationName(name);

  return (
    <Root className={classes.root}>
      {lines.line1}
      <br />
      {lines.line2}
      <br />
      {lines.line3}{" "}
      {!!navigator.clipboard && (
        <Tooltip title="Copy code">
          <IconButton
            size="small"
            disableRipple
            disableFocusRipple
            onClick={() => {
              navigator.clipboard.writeText(name).then(() => {
                dispatch(showAlert("Copied code to clipboard.", { type: "success" }));
              });
            }}
          >
            <span style={{ fontSize: "0.7em" }}>
              <FileCopyOutlined fontSize="inherit" />
            </span>
          </IconButton>
        </Tooltip>
      )}
    </Root>
  );
}
