import { call, delay, put, select, fork, takeLatest, all, retry } from "redux-saga/effects";
import { request } from "../../modules/client";
import { baseUrl } from "../apiUrlProvider";
import { updateBanners, dismissBanner, deleteBanner } from "./app-slice";
import { Banner } from "./AppState";
import { AppState } from "../AppState";
import { isUserTokenValid } from "../../auth/oidcConfig";

const KEY = "dismissedBanners";
const bannerBase = baseUrl() + "api/banners/";

export default function* root() {
  yield fork(periodicallyFetchBanners);
  yield all([
    takeLatest(deleteBanner, requestDeleteBanner),
    takeLatest(dismissBanner, function* onDismiss() {
      yield syncDismissedStatus([]);
    }),
  ]);
}

function* periodicallyFetchBanners() {
  while (true) {
    try {
      const userTokenValid: boolean = yield call(isUserTokenValid);
      if (!userTokenValid) {
        yield delay(1000 * 5); // retry if not authenticated
        continue;
      }
      const fetched: Banner[] = yield call(request, bannerBase);

      yield syncDismissedStatus(fetched);
      yield put(updateBanners({ banners: fetched }));
    } catch (err) {
      console.log("fetching banners failed", err);
    }
    yield delay(1000 * 60); // poll every minute
  }
}

function* syncDismissedStatus(banners: Banner[]) {
  const dismissed: Set<number> = yield getDismissedBanners();

  banners.forEach((b) => {
    b.dismissed = dismissed.has(b.Id);
  });

  saveDismissedBanners(dismissed);
}

function* requestDeleteBanner(action: { payload: { bannerId: number } }) {
  const bannerId = action.payload.bannerId;
  try {
    yield retry(3, 10000, request, bannerBase + bannerId, { method: "DELETE" });
  } catch (err) {
    console.log(`deleting banner with id ${bannerId} failed`, err);
  }
}

function* getDismissedBanners() {
  const current: Banner[] = yield select((state: AppState) => state.app.banners);
  const dismissedCurrent = current.filter((b) => !!b.dismissed).map((b) => b.Id);
  const dismissedSaved =
    localStorage
      .getItem(KEY)
      ?.split(",")
      .map((i) => parseInt(i))
      .filter(isFinite) || [];
  return new Set<number>(dismissedCurrent.concat(dismissedSaved));
}

function saveDismissedBanners(dismissed: Set<number>) {
  localStorage.setItem(KEY, [...dismissed].join());
}
