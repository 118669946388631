import * as React from "react";

/**
 * Simple React hook the gives access to a previous prop value.
 * Stolen from: https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect
 *
 * @param value - A props value.
 */
export default function usePrevious<T>(value: T) {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
