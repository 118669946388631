import * as React from "react";
import { styled } from '@mui/material/styles';
import { Button, Checkbox, FormControl, FormControlLabel, Typography } from "@mui/material";
import { ProductLineStatusApi, getFiltersStatus, dataIconsMapper, ProductLineStatusMap, ProductDataStatus } from "./utils";

const PREFIX = 'DaisyFilterPanel';
const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: theme.spacing(28),
    "& > div + div": {
      marginTop: theme.spacing(3),
    },
    "& .MuiCheckbox-root": {
      // fixes checkbox icon spacing
      padding: theme.spacing(1),
      height: "inherit",
    },
  },

  // TODO - extract the specially styled button
  [`& .${classes.header}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    "& .MuiButton-root:hover": {
      background: "transparent",
    },
  }
}));

export default function DaisyFilterPanel(props: {
  data: ProductLineStatusMap;
  activeFilters: Set<ProductDataStatus | ProductLineStatusApi>;
  onFilterChange: (activeFilters: Set<ProductDataStatus | ProductLineStatusApi>) => void;
}) {
  const activeFilters = props.activeFilters;

  const isAnyFilterActive = activeFilters.size > 0;

  const statusCount = Object.keys(props.data).reduce((counts, k) => {
    const dataStatus = props.data[k].productDataStatus;
    const publicationStatus = props.data[k].daisyPublicationStatus;
    counts[dataStatus] = (counts[dataStatus] || 0) + 1;
    counts[publicationStatus] = (counts[publicationStatus] || 0) + 1;
    return counts;
  }, {} as { [key: number]: number });

  function clearFilters() {
    props.onFilterChange(new Set());
  }

  function toggle(filter: ProductDataStatus | ProductLineStatusApi) {
    if (activeFilters.has(filter)) {
      activeFilters.delete(filter);
    } else {
      activeFilters.add(filter);
    }
    props.onFilterChange(activeFilters);
  }

  return (
    <Root className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h3" gutterBottom>
          Filters
        </Typography>
        <Button
          variant="text"
          style={{ visibility: isAnyFilterActive ? "inherit" : "hidden" }}
          disabled={!isAnyFilterActive}
          onClick={clearFilters}
        >
          <Typography variant="body2">Clear All</Typography>
        </Button>
      </div>
      <Typography gutterBottom />

      <FormControl component="fieldset">
        {getFiltersStatus().map((s) => (
          <FormControlLabel
            key={s}
            disabled={!statusCount[s]}
            control={<Checkbox onChange={() => toggle(s)} checked={activeFilters.has(s)} />}
            label={`${dataIconsMapper[s.toString()].filterLabel} (${statusCount[s] || 0})`}
          />
        ))}
      </FormControl>
    </Root>
  );
}
