import * as React from "react";
import { useMemo } from 'react';
import { AllocationUploadValidationResponse } from '../../../types/AllocationUploadValidationResponse';
import { AllocationPasteType } from '../../shared/types';
import { AllocationValidationTable } from './AllocationValidationTable';

interface IMultiplePasteTableProps {
    allocationPasteType: AllocationPasteType;
    data: AllocationUploadValidationResponse[];
}

export const MultiplePasteTable: React.FC<IMultiplePasteTableProps> = ({ data, allocationPasteType }) => {
    const expansionPanels = useMemo(() =>
        data.map((allocationValidation, idx) =>
            <AllocationValidationTable allocationValidation={allocationValidation} isInitialExpanded={idx === 0} allocationPasteType={allocationPasteType} />
        ), [data]);

    return <>{ expansionPanels }</>;
};
