import React from "react";
import { connect } from "react-redux";

import { AppState } from "../../../store/AppState";
import AllocationSpecifier from "../../../types/AllocationSpecifier";
import MasterDataEntry from "../../../types/MasterDataEntry";
import Selector, { SelectionIndex } from "./Selector";

const allocationTypes = [
  {
    Id: "3",
    ShortName: "SAA",
    Name: "SAA",
  },
  {
    Id: "2",
    ShortName: "TAA",
    Name: "TAA",
  },
  {
    Id: "5",
    ShortName: "TCA",
    Name: "TCA",
  },
  {
    Id: "1",
    ShortName: "MOPO",
    Name: "MOPO",
  },
];

const mopoIndex = 3;
const bespokeId = "81";

export type AllocationSelection = AllocationSpecifier | "incomplete";

export function isBespoke(selection: AllocationSelection) {
  return selection !== "incomplete" && selection.productLineId === bespokeId;
}

interface AllocationSelectorProps {
  productLines: MasterDataEntry[];
  productDistinctions: MasterDataEntry[];
  currencies: MasterDataEntry[];
  riskProfiles: MasterDataEntry[];
  bmoContexts: MasterDataEntry[];
  disabled: boolean;
  changed: (selection: AllocationSelection) => void;
}

export default connect((state: AppState) => ({
  productLines: state.masterData.productLines
    .filter(pl => pl.IsAlpimaManaged == false)
    .slice(),
  productDistinctions: state.masterData.productDistinctions,
  currencies: state.masterData.currencies,
  riskProfiles: state.masterData.riskProfiles,
  bmoContexts: state.masterData.bmoContexts,
  createAllocationState: state.admin.createAllocationState,
}))(function AllocationSelector(props: AllocationSelectorProps) {
  const { changed, disabled, productLines, productDistinctions, currencies, riskProfiles, bmoContexts } = props;

  // make bespoke product line first entry
  const bespokeIndex = productLines.map((p) => p.Id).indexOf(bespokeId);
  if (bespokeIndex !== -1) {
    const [bespokeProductLine] = productLines.splice(bespokeIndex, 1);
    productLines.splice(0, 0, bespokeProductLine);
  }

  type Selection = {
    allocationType: SelectionIndex;
    productLine: SelectionIndex;
    productDistinction: SelectionIndex;
    currency: SelectionIndex;
    riskProfile: SelectionIndex;
    bmoContext: SelectionIndex;
  };
  const [selection, setSelection] = React.useState({
    allocationType: mopoIndex,
    productLine: "",
    productDistinction: "",
    currency: "",
    riskProfile: "",
    bmoContext: "",
  } as Selection);

  const noMopo = selection.allocationType !== mopoIndex;

  const changeSelection = (v: any) => {
    let newSelection = { ...selection, ...v } as Selection;
    const oldSelectionIsMopo = !noMopo;
    const newSelectionIsNoMopo = newSelection.allocationType !== mopoIndex;

    // reset product line selection, if user switched from mopo to saa/taa and bespoke was selected
    if (oldSelectionIsMopo && newSelectionIsNoMopo && selection.productLine === 0) {
      newSelection = { ...newSelection, productLine: "" };
    }

    // reset bmo context, if user switched from mopo to saa/taa
    if (oldSelectionIsMopo && newSelectionIsNoMopo) {
      newSelection = { ...newSelection, bmoContext: "" };
    }

    const isIncomplete = Object.keys(newSelection)
      .filter((k) => (k !== "bmoContext" && newSelectionIsNoMopo) || !newSelectionIsNoMopo)
      .map((k) => newSelection[k])
      .some((v) => v === "");

    setSelection(newSelection);
    changed(
      isIncomplete
        ? "incomplete"
        : {
            targetTypeId: allocationTypes[newSelection.allocationType].Id,
            productLineId: productLines[newSelection.productLine].Id,
            productDistinctionId: productDistinctions[newSelection.productDistinction].Id,
            currencyId: currencies[newSelection.currency].Id,
            riskProfileId: riskProfiles[newSelection.riskProfile].Id,
            bmoContextId: newSelection.bmoContext === "" ? newSelection.bmoContext : bmoContexts[newSelection.bmoContext].Id,
          }
    );
  };

  return (
    <React.Fragment>
      <Selector
        options={allocationTypes}
        id="allocationType"
        label="type"
        width={120}
        marginRight={2}
        value={selection.allocationType}
        changed={(allocationType) => changeSelection({ allocationType })}
        disabled={disabled}
      />

      <Selector
        options={productLines}
        id="productLine"
        label="product line & distinction"
        width={150}
        marginRight={1}
        getMenuValue={(x) => x.Name}
        value={selection.productLine}
        changed={(productLine) => changeSelection({ productLine })}
        disabled={disabled}
        dividersAt={[0]}
        disabledOptionsAt={noMopo ? [0] : []}
      />

      <Selector
        options={productDistinctions}
        id="productDistinction"
        label=""
        width={70}
        marginRight={2}
        getMenuValue={(x) => x.Name}
        value={selection.productDistinction}
        changed={(productDistinction) => changeSelection({ productDistinction })}
        disabled={disabled}
      />

      <Selector
        options={currencies}
        id="currency"
        label="ref ccy"
        width={90}
        marginRight={2}
        value={selection.currency}
        changed={(currency) => changeSelection({ currency })}
        disabled={disabled}
      />

      <Selector
        options={riskProfiles}
        id="riskProfile"
        label="inv style"
        width={80}
        marginRight={2}
        getMenuValue={(x) => x.Name}
        value={selection.riskProfile}
        changed={(riskProfile) => changeSelection({ riskProfile })}
        disabled={disabled}
      />

      <Selector
        options={bmoContexts}
        id="bmoContext"
        label="bmo"
        width={100}
        getMenuValue={(x) => x.Name}
        value={selection.bmoContext}
        changed={(bmoContext) => changeSelection({ bmoContext })}
        disabled={disabled || noMopo}
      />
    </React.Fragment>
  );
});
