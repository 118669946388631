import * as React from "react";
import { Theme } from "@mui/material";
import { AlertIcon, CheckIcon } from "../shared/Icons";

export const sources = {
  statpro: "StatPro",
  fdoc: "FinanceDoc",
  ie: "Investment Eng.",
  tst: "TST EOD",
};

// from API
export type ProductStatus = {
  ProductId: string;
  ProductLine: string;
  Currency: string;
  InvestmentStrategy: string;  
  AllowUploadInDaisy: boolean;
  ValidationMessages: ProductValidationMessage[];
};

export type ProductLinePublicationDataApi = {
  ProductLine: string;
  DaisyPublicationStatus: ProductLineStatusApi;
  UploadDate: string;
  ReferenceDate: string;
  ProductStatuses: ProductStatus[];
  UploadErrors: string;
};

export type ProductLinePublicationData = {
  productLine: string;
  daisyPublicationStatus: ProductLineStatusApi;
  productDataStatus: ProductDataStatus; 
  uploadDate: string;
  referenceDate: string;
  rows: ProductStatus[];
  canPublish: boolean;
  hasWarningsOrErrors: boolean;
  isProductLinePublishingEnabled: boolean;
  uploadErrors: string;
};

// from API
type ProductValidationMessage = {
  ProductId: string;
  IsError: boolean;
  Message: string;
  Hint?: string;
  Details?: string[];
};

// from API
export enum ProductDataStatus {
  Error = "Error",
  Warning = "Warning",
  ReadyForPublish = "ReadyForPublish",
};

export enum ProductLineStatusApi {
  Pending = "Pending",
  InProgress = "InProgress",
  Uploaded = "Uploaded",
  Warning = "Warning",
  Failed = "Failed"
};

// from API
export type DataSourceStatus = {
  Metrics: DataSourceMetrics;
  ErrorMessages: { [source: string]: string[] };
  WarningMessages: { [source: string]: string[] };
};

// from API
type DataSourceMetrics = {
  FdocFundSplittingSnapshotValidFromDate?: string;
  IeAllocationLastDate?: string;
  IeTimeseriesLastDate?: string;
  TstEodLastDate?: string;
  TstEodErrors: number;
};


export function getFiltersStatus() {
  return [
    ProductDataStatus.Error,
    ProductDataStatus.Warning,
    ProductDataStatus.ReadyForPublish,
    ProductLineStatusApi.Uploaded,
    ProductLineStatusApi.Failed,
    ProductLineStatusApi.InProgress,
    ProductLineStatusApi.Pending
  ];
}

export function getPublishProgressStatus() {
  return [
    ProductDataStatus.Error,
    ProductDataStatus.Warning,
    ProductDataStatus.ReadyForPublish,
    ProductLineStatusApi.Uploaded
  ];
}

export function getDataStatus() {
  return [
    ProductDataStatus.Error,
    ProductDataStatus.Warning,
    ProductDataStatus.ReadyForPublish,
  ];
}



export type ProductLineStatusMap = { [productLine: string]: ProductLinePublicationData };

export const dataIconsMapper = {
  Error: {
    label: "Error",
    filterLabel: "Data Error",
    getColor: (theme: Theme) => theme.palette.error.main,
    icon: <AlertIcon />
  },
  Warning: {
    label: "Warning",
    filterLabel: "Data Warning",
    getColor: (theme: Theme) => theme.palette.warning.main,
    icon: <AlertIcon />
  },
  ReadyForPublish: {
    label: "Ready For Publish",
    filterLabel: "Ready For Publish",
    getColor: (theme: Theme) => theme.palette.success.main,
    icon: <CheckIcon />
  },
  Failed: {
    label: "Failed",
    filterLabel: "Failed",
    getColor: (theme: Theme) => theme.palette.error.main,
    icon: <AlertIcon />
  },
  Pending: {
    label: "Pending",
    filterLabel: "Pending",
    getColor: (theme: Theme) => theme.palette.warning.main,
    icon: <AlertIcon />
  },
  InProgress: {
    label: "In Progress",
    filterLabel: "In Progress",
    getColor: (theme: Theme) => theme.palette.warning.main,
    icon: <AlertIcon />
  },
  Uploaded: {
    label: "Uploaded",
    filterLabel: "Published",
    getColor: (theme: Theme) => theme.palette.success.main,
    icon: <CheckIcon />
  }
}

export function getProductDataStatus(productStatuses: ProductStatus[]): ProductDataStatus {
  if( productStatuses.some(item => item.ValidationMessages.some(msg=> msg.IsError))){
    return ProductDataStatus.Error;
  }
  if (productStatuses.some(item => item.ValidationMessages && !!item.ValidationMessages.length)){
    return ProductDataStatus.Warning;
  }
  return ProductDataStatus.ReadyForPublish;
};

export function groupProductStatus(data: ProductLinePublicationDataApi[]): ProductLineStatusMap {
  let retval: ProductLineStatusMap = {}

  const hasWarnings = (productLine: ProductLinePublicationDataApi) => productLine.DaisyPublicationStatus === ProductLineStatusApi.Uploaded && productLine.UploadErrors;
  
  data.forEach(item => {
    let dataStatus = getProductDataStatus(item.ProductStatuses);
    let productLineStatus: ProductLinePublicationData = {
      canPublish: dataStatus !== ProductDataStatus.Error,
      daisyPublicationStatus: item.DaisyPublicationStatus,
      productDataStatus: dataStatus,
      hasWarningsOrErrors: dataStatus !== ProductDataStatus.ReadyForPublish, 
      productLine: item.ProductLine,
      referenceDate: item.ReferenceDate,
      rows: item.ProductStatuses,
      uploadDate: item.UploadDate,
      isProductLinePublishingEnabled: item.ProductStatuses[0].AllowUploadInDaisy,
      uploadErrors: item.UploadErrors
    }
    retval[item.ProductLine] = productLineStatus;
  });
  return retval;
}
