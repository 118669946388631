import AllocationType from "../../types/AllocationType";

/* utility function to lookup the value of properties in an objects.
   the key be the name of an inner object (e.g. inner.myprop) */
export const val = (obj: Object, key: string) => {
  if (key.indexOf(".") > 0) {
    const parts = key.split(".");
    if (parts.length > 2) {
      throw new Error("Currently only supports one level of nesting");
    } else {
      const inner = obj[parts[0]];
      if (inner) {
        return inner[parts[1]];
      } else {
        return "";
      }
    }
  } else {
    return obj[key];
  }
};

const stringConverter = (objectValue: any) => {
  if (objectValue && objectValue.Name) {
    return objectValue.Name;
  } else if (objectValue) {
    return `${objectValue}`;
  } else {
    return "";
  }
};

export const filterAndSortIds = (data: any, filter: any, sort: any) => {
  let list = Object.values(data).slice(0);
  if (filter !== undefined) {
    list = list.filter((e: any) => {
      return Object.entries(filter).every((f: any) => {
        const [filterKey, filterValue] = f;
        if (filterKey === "Id" && filterValue !== undefined) {
          return filterValue.includes(e.Id);
        }
        if (filterValue === undefined || filterValue.length === 0) {
          return true;
        }

        if (filterKey === "AllocationType" && !!filterValue) {
          return filterValue.includes(e.AllocationType.toString());
        }
        if (filterKey === "Favorite" && !!filterValue) {
          return !!e.Starred;
        }
        if (filterKey === "Owner" && !!filterValue) {
          return e.IsOwner;
        }
        if (filterKey === "Deleted" && !filterValue) {
          return e.IsDeleted === false;
        }
        if (filterKey === "ContainsDeletedInstruments" && !!filterValue) {
          return e.HasDeletedInstruments;
        }

        if (filterKey === "Name") {
          const name = e.Name || "";
          const ownerName = e.Owner && e.Owner.Name ? e.Owner.Name : "";
          const searchValue = filterValue.toLowerCase().trim();
          return name.toLowerCase().includes(searchValue) || ownerName.toLowerCase().includes(searchValue);
        }
        if (e[filterKey] === undefined || filterValue.includes === undefined) {
          return true;
        }

        const valueObject = e[filterKey];
        if (valueObject !== undefined && valueObject !== null) {
          return filterValue.includes(valueObject.Id);
        } else {
          return false;
        }
      });
    });
  }

  if (sort === undefined || sort.columnId === undefined) {
    //@ts-ignore  - ignore type error, so we don't change the behaviour during the typescript migration
    list.sort((a, b) => a.Name < b.Name);
  } else if (sort.columnId === "Star") {
    if (sort.order === "desc") {
      list.sort((a: any, b: any) => a.Starred - b.Starred);
    } else {
      list.sort((a: any, b: any) => b.Starred - a.Starred);
    }
  } else {
    const compare = (someA: any, someB: any): number => {
      let aValue = val(someA, sort.columnId);
      let bValue = val(someB, sort.columnId);

      if ((aValue === undefined || aValue === null) && (bValue === undefined || bValue == null)) {
        return 0;
      } else if (aValue === undefined || aValue === null) {
        return 1;
      } else if (bValue === undefined || bValue === null) {
        return -1;
      }

      if (sort.order === "desc") {
        // swap the values around
        const tmp = bValue;
        bValue = aValue;
        aValue = tmp;
      }

      if (sort.columnId === "AllocationType") {
        return stringConverter(AllocationType[aValue]).localeCompare(stringConverter(AllocationType[bValue]));
      }

      if (typeof aValue === "number" && typeof bValue === "number") {
        return aValue - bValue;
      }

      return stringConverter(aValue).localeCompare(stringConverter(bValue));
    };

    list.sort(compare);
  }

  return list.map((e: any) => e.Id);
};
