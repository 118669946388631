import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Big, { BigType } from "big.js";
import { UserState } from "../../store/user/UserState";
import Allocation from "../../types/Allocation";
import { AssetTree } from "../../types/AssetTree";
import Instrument from "../../types/Instrument";
import parseAndPatchAllocationDates from "./utils/parseAndPatchAllocationDates";
import {
  AllocationCellWeights,
  AllocationColumn,
  ViewOptions,
  AllocationRowDescriptor,
  InstrumentWithOverrides,
  CompareViewState,
} from "./types";
import getInstruments from "./utils/getInstruments";
import getEditInfo from "./utils/getEditInfo";
import getTableStructure from "./utils/getTableStructure";
import aggregateWeights from "./utils/aggregateWeights";
import applyWeights from "./utils/applyWeights";
import scaleWeights from "./utils/scaleWeights";
import copyColumnWeights from "./utils/copyWeights";
import { isZeroOrUnset, ZERO } from "./utils/weights";
import { cellsInRowOrder, emptyColumn } from "./utils/allocationColumn";
import { allocationSorter } from "../../modules/sorter";
import AllocationType from "../../types/AllocationType";
import { generateRowId } from "../../modules/asset-tree-builder";
import { TargetDialog } from "../shared/types";

const initialState: CompareViewState = {
  allocations: [],
  columns: [],
  _rows: [],
  visibleRows: [],
  allocationType: AllocationType.ModelPortfolio,
  options: {
    showAvg: false,
    showDyn: false,
    showIc: false,
    showEmptyAssetClasses: false,
    assetClassTree: "",
  },
  activeDialog: undefined,
  editInfo: [],
  instruments: {},
  addedInstrumentIds: [],
  highlightInstrumentIds: [],
  _getEditInfo: () => [],
  _getTableStructure: () => [],
  _isAround100: () => false,
};

const slice = createSlice({
  name: "compare-view",
  initialState,
  reducers: {
    /**
     * Clear all allocations.
     * Prevents the user from seeing a stale state during fetch when he comes back
     * to the compare view at a later time with different allocations.
     */
    clear(state) {
      state.allocations = [];
      state.columns = [];
      state._rows = [];
      state.visibleRows = [];
      state.instruments = {};
      state.addedInstrumentIds = [];
      state.highlightInstrumentIds = [];
    },

    /**
     * (Re-)initialize the whole view state.
     */
    setAllocations(
      state,
      action: PayloadAction<{
        allocations: Allocation[];
        trees: AssetTree[];
        icCurrencyInstruments: InstrumentWithOverrides[];
        user: UserState;
        allocationSumTolerance: BigType;
        thresholdAvgDyn: BigType;
      }>
    ) {
      const { allocations, trees, icCurrencyInstruments, user, allocationSumTolerance, thresholdAvgDyn } = action.payload;
      const allocationType = allocations[0]?.AllocationType || AllocationType.ModelPortfolio;
      const isMopo = allocationType === AllocationType.ModelPortfolio;
      const isTca = allocationType === AllocationType.Tca;
      const structure = isMopo ? "Ni" : isTca ? "AA (IM CA)" : "AA (IM AA)";

      state._isAround100 = (_weight: BigType) => !!_weight && _weight.minus(1.0).abs().lte(allocationSumTolerance);

      state._getTableStructure = (
        _allocations: Allocation[],
        _structure: string,
        instruments: InstrumentWithOverrides[],
        columns: AllocationColumn[]
      ) => getTableStructure(_allocations, _structure, trees, thresholdAvgDyn, instruments, columns);

      state._getEditInfo = (_allocations: Allocation[], _columns: AllocationColumn[], _rows: AllocationRowDescriptor[]) =>
        getEditInfo(user, allocationSumTolerance, _allocations, _columns, _rows);

      state.allocations = allocations.sort(allocationSorter);
      state.allocationType = allocationType;
      state.options = { ...initialState.options, showIc: isMopo, showEmptyAssetClasses: isTca, assetClassTree: structure };
      state.activeDialog = undefined;
      state.columns = Array(allocations.length).fill(0).map(emptyColumn);

      if (isTca) {
        registerInstruments(state, ...icCurrencyInstruments);
      }

      refreshTable(state);
      refreshEditInfo(state);
    },

    /**
     * Update an allocation (usually with fresh data from backend).
     */
    updateAllocation(state, action: PayloadAction<Allocation>) {
      const updated = action.payload;
      parseAndPatchAllocationDates(updated);
      const allocationIdx = state.allocations.findIndex((a) => a.Id === updated.Id);
      if (allocationIdx === -1) return;
      state.allocations[allocationIdx] = action.payload;
      state.columns[allocationIdx] = emptyColumn();
      refreshTable(state);
      refreshEditInfo(state, allocationIdx);
    },

    /**
     * Update a single compare view option (except asset class tree).
     */
    changeOption(state, action: PayloadAction<Partial<Omit<ViewOptions, "assetClassTree">>>) {
      state.options = { ...state.options, ...action.payload };
      updateVisibleRows(state);
    },

    /**
     * Update the asset structure to be shown.
     * Triggers a table structure re-evaluation.
     */
    changeAssetClassTree(state, action: PayloadAction<{ assetClassTree: string }>) {
      const { assetClassTree } = action.payload;
      state.options.assetClassTree = assetClassTree;
      refreshTable(state);
    },

    /**
     * Update a single weight.
     * Input is expected being in percent.
     * Try to do only what is absolutely necessary here for the sake of performance.
     */
    updateWeight(state, action: PayloadAction<{ allocationIndex: number; rowId: string; input: string }>) {
      const { allocationIndex, rowId, input } = action.payload;
      const column = state.columns[allocationIndex];

      const weight = stringToBig(input)?.div(100);
      const isValid = input === "" || input === undefined || weight !== undefined;
      column.cells[rowId] = { ...column.cells[rowId], saved: weight || ZERO };

      aggregateWeights(state._rows, column);
      updateHasInvalidCell(state, allocationIndex, rowId, isValid);
      updateSavedSumOk(state, allocationIndex);
      updateHasDeletedInstruments(state, allocationIndex);
      updateHasChanges(state, allocationIndex, rowId);
    },

    /**
     * Copy (and overwrite) weights of a specific type to saved within an allocation column.
     */
    copyWeights(state, action: PayloadAction<{ allocationId: string; source: keyof Omit<AllocationCellWeights, "saved"> }>) {
      const { allocationId, source } = action.payload;
      const allocationIdx = state.allocations.findIndex((a) => a.Id === allocationId);
      const allocation = state.allocations[allocationIdx];
      const column = state.columns[allocationIdx];
      copyColumnWeights(state._rows, column, allocation, source);
      aggregateWeights(state._rows, column);
      updateSavedSumOk(state, allocationIdx);
      updateHasDeletedInstruments(state, allocationIdx);
      updateHasChanges(state, allocationIdx);
    },

    /**
     * Clear all saved weights for a given allocation.
     */
    clearWeights(state, action: PayloadAction<{ allocationId: string }>) {
      const { allocationId } = action.payload;
      const allocationIdx = state.allocations.findIndex((a) => a.Id === allocationId);
      const column = state.columns[allocationIdx];
      const zero = new Big(0);
      cellsInRowOrder(column, state._rows).forEach((c) => (c.saved = zero));
      column.sum.saved = zero;
      updateSavedSumOk(state, allocationIdx);
      updateHasDeletedInstruments(state, allocationIdx);
      updateHasChanges(state, allocationIdx);
    },

    /**
     * Merge the given weights with the existing ones in the given allocation.
     * Needs a table refresh, since new instruments may be added to the structure.
     */
    mergeWeights(state, action: PayloadAction<{ allocationId: string; weights: { instrument: Instrument; weight: BigType }[] }>) {
      const { allocationId, weights } = action.payload;
      const allocationIdx = state.allocations.findIndex((a) => a.Id === allocationId);
      const column = state.columns[allocationIdx];
      const mergeInstruments = weights.map((w) => w.instrument);

      registerInstruments(state, ...mergeInstruments);
      refreshTable(state);

      // apply directly to cells
      weights.forEach((w) => {
        const cell = column.cells[generateRowId(w.instrument, state.options.assetClassTree)];
        if (cell) {
          cell.saved = w.weight;
        }
      });

      updateSavedSumOk(state, allocationIdx);
      updateHasDeletedInstruments(state, allocationIdx);
      updateHasChanges(state, allocationIdx);
      highlightInstruments(state, ...mergeInstruments);
    },

    /**
     * Scales the (non-zero) saved snapshot weights to 100 %.
     */
    scaleWeights100(state, action: PayloadAction<{ allocationId: string }>) {
      const { allocationId } = action.payload;
      const allocationIdx = state.allocations.findIndex((a) => a.Id === allocationId);
      const column = state.columns[allocationIdx];

      scaleWeights(state._rows, column);
      aggregateWeights(state._rows, column);
      updateSavedSumOk(state, allocationIdx);
      updateHasDeletedInstruments(state, allocationIdx);
      updateHasChanges(state, allocationIdx);
    },

    /**
     * Apply the difference between 100% and the saved snapshot's total weight to the
     * given level-out-instrument. The latter will be added to the snapshot, if
     * not yet existing, which is why we might need a table refresh here.
     */
    levelOutWeightDifference(state, action: PayloadAction<{ allocationId: string; levelOutInstrument: Instrument }>) {
      const { allocationId, levelOutInstrument } = action.payload;
      const allocationIdx = state.allocations.findIndex((a) => a.Id === allocationId);
      const column = state.columns[allocationIdx];

      const levelOutRowId = generateRowId(levelOutInstrument, state.options.assetClassTree);
      let levelOutRow = state._rows.find((r) => r.isInstrument && r.rowId === levelOutRowId);
      const currentLevelOutWeight = levelOutRow !== undefined ? column.cells[levelOutRow.rowId].saved : ZERO;
      const diff = new Big(1).minus(column.sum.saved).plus(currentLevelOutWeight);

      if (levelOutRow === undefined) {
        registerInstruments(state, levelOutInstrument);
        refreshTable(state);
        levelOutRow = state._rows.find((r) => r.isInstrument && r.rowId === levelOutRowId);
        if (levelOutRow === undefined) {
          throw new Error("levelOutRow is expected to exist");
        }
      }

      column.cells[levelOutRowId].saved = diff;

      aggregateWeights(state._rows, column);
      updateSavedSumOk(state, allocationIdx);
      updateHasDeletedInstruments(state, allocationIdx);
      updateHasChanges(state, allocationIdx, levelOutRowId);
      highlightInstruments(state, levelOutInstrument);
    },

    /**
     * Add the given instrument (selection from add-instrument-search) to the table structure.
     * Triggers a table structure re-evaluation.
     */
    addInstrument(state, action: PayloadAction<InstrumentWithOverrides>) {
      registerInstruments(state, action.payload);
      refreshTable(state);
      highlightInstruments(state, action.payload);
    },

    setAsOfDateOnSavedSnapshot(state, action: PayloadAction<{ allocationId: string; asOfDate: Date }>) {
      const allocation = state.allocations.find((alloc) => alloc.Id == action.payload.allocationId);
      console.log("setAsOfDateOnSavedSnapshot:" + allocation + " Value:" + action.payload.asOfDate);
      if (allocation && allocation.SavedSnapshot) {
        allocation.SavedSnapshot.AsOfDate = action.payload.asOfDate;
      }
    },

    /**
     * Reset the highlight/scroll-into-view related state.
     */
    didScrollIntoView(state) {
      state.highlightInstrumentIds = [];
    },

    /**
     * Prepare allocation object for saving (in backend).
     * Basically just applies all the (save-)weights to the SavedSnapshot.
     * No table refresh here, because we later get back a refreshed allocation anyway.
     */
    prepareForSave(state, action: PayloadAction<{ allocationId: string }>) {
      const { allocationId } = action.payload;
      const allocationIdx = state.allocations.findIndex((a) => a.Id === allocationId);
      const allocation = state.allocations[allocationIdx];
      const column = state.columns[allocationIdx];
      applyWeights(allocation, state._rows, column, state.instruments);
    },

    /**
     * Set the active dialog.
     * Closes current dialog, if payload is undefined.
     */
    setDialog(state, action: PayloadAction<TargetDialog | undefined>) {
      state.activeDialog = action.payload;
    },
  },
});

export const {
  clear,
  setAllocations,
  updateAllocation,
  changeOption,
  changeAssetClassTree,
  updateWeight,
  copyWeights,
  clearWeights,
  mergeWeights,
  scaleWeights100,
  levelOutWeightDifference,
  addInstrument,
  setAsOfDateOnSavedSnapshot,
  didScrollIntoView,
  prepareForSave,
  setDialog,
} = slice.actions;

export default slice.reducer;

/**
 * Re-evaluate the table structure and update the state accordingly.
 */
function refreshTable(state: CompareViewState) {
  // edge-case - a refreshed allocation may come with some extra instruments not seen so far
  const allInstruments = { ...state.instruments, ...getInstruments(state.allocations, state.options.assetClassTree) };

  const rows = state._getTableStructure(
    state.allocations,
    state.options.assetClassTree,
    state.addedInstrumentIds.map((id) => allInstruments[id]),
    state.columns
  );

  state.instruments = allInstruments;
  state._rows = rows;
  updateVisibleRows(state);
}

/**
 * Re-evaluates all or a specific edit info.
 * Usually follows a call to `refreshTable` after one or more allocations refreshed.
 */
function refreshEditInfo(state: CompareViewState, allocationIdx?: number) {
  if (allocationIdx === undefined) {
    state.editInfo = state._getEditInfo(state.allocations, state.columns, state._rows);
  } else {
    const [singleEditInfo] = state._getEditInfo([state.allocations[allocationIdx]], [state.columns[allocationIdx]], state._rows);
    state.editInfo[allocationIdx] = singleEditInfo;
  }
}

/**
 * Track incoming instruments.
 */
function registerInstruments(state: CompareViewState, ...instruments: InstrumentWithOverrides[]) {
  instruments.forEach((i) => {
    const rowId = generateRowId(i, state.options.assetClassTree);

    // added instruments are always visible
    // fixes https://jira.sehlat.io/browse/IMSC-10662
    state.addedInstrumentIds.push(rowId);

    if (!state.instruments[rowId]) {
      state.instruments[rowId] = i;
    }
  });
}

/**
 * Set the instruments which need user attention.
 * Determine the scroll-into-view instrument.
 * Requires up-to-date state.rows.
 */
function highlightInstruments(state: CompareViewState, ...instruments: InstrumentWithOverrides[]) {
  const highlightIds = instruments.map(x => generateRowId(x, state.options.assetClassTree));
  const firstMatch = state._rows.find((r) => r.isInstrument && highlightIds.includes(r.rowId));
  const scrollIntoViewId = firstMatch?.isInstrument && firstMatch.rowId;
  if (!scrollIntoViewId) return; // should never happen
  state.highlightInstrumentIds = [scrollIntoViewId, ...highlightIds];
}

function updateHasInvalidCell(state: CompareViewState, allocationIndex: number, rowId: string, isValid: boolean) {
  const column = state.columns[allocationIndex];
  if (isValid) {
    column._invalidCells.delete(rowId);
    state.editInfo[allocationIndex].hasInvalidCell = column._invalidCells.size > 0;
  } else {
    column._invalidCells.add(rowId);
    state.editInfo[allocationIndex].hasInvalidCell = true;
  }
}

function updateSavedSumOk(state: CompareViewState, allocationIndex: number) {
  state.editInfo[allocationIndex].savedSumOk = state._isAround100(state.columns[allocationIndex].sum.saved);
}

function updateHasDeletedInstruments(state: CompareViewState, allocationIndex: number) {
  const cells = state.columns[allocationIndex].cells;
  const deletedInstrumentRowIds = state._rows.filter((r) => r.isInstrument && r.isDeleted).map((r) => r.rowId);

  state.editInfo[allocationIndex].hasDeletedInstruments = Object.keys(cells).some(
    (rowId) => deletedInstrumentRowIds.includes(rowId) && !isZeroOrUnset(cells[rowId].saved)
  );
}

function updateHasChanges(state: CompareViewState, allocationIndex: number, rowId?: string) {
  const column = state.columns[allocationIndex];

  if (rowId !== undefined) {
    // update because a single row input changed
    const cell = column.cells[rowId];
    const cellChanged = cell.savedOriginal !== cell.saved.toFixed(6);

    if (cellChanged) {
      column._changedCells.add(rowId);
    } else {
      column._changedCells.delete(rowId);
    }
  } else {
    // update because multiple row inputs changed
    column._changedCells.clear();
    cellsInRowOrder(column, state._rows, true).forEach((c) => {
      const row = state._rows.find((r) => r.rowId === c.rowId);
      const cellChanged = row?.isInstrument && c.savedOriginal !== c.saved.toFixed(6);
      if (cellChanged) {
        column._changedCells.add(c.rowId);
      }
    });
  }

  state.editInfo[allocationIndex].hasChanges = column._changedCells.size > 0;
}

function updateVisibleRows(state: CompareViewState) {
  if (state.allocationType === AllocationType.Tca) {
    state.visibleRows = state._rows;
  } else {
    state.visibleRows = state._rows.filter((r) =>
      r.isInstrument
        ? r.isPartOfAnySnapshot || state.options.showAvg || state.addedInstrumentIds.includes(r.rowId)
        : r.level === 0 || state.options.showEmptyAssetClasses || r.instrumentCount > 0
    );
  }
}

// TODO - extract to somewhere else

export function weightToFixedString(value: BigType, decimalPoints?: number) {
  if (!value?.eq || value.eq(ZERO)) return undefined;
  let v = value.times(100);
  return !!decimalPoints ? v.toFixed(decimalPoints) : v.toString();
}

export function weightToRoundedString(value: BigType, decimalPoints?: number) {
  if (!value?.eq || value.eq(ZERO)) return undefined;
  let v = value.times(100);
  return !!decimalPoints ? v.round(decimalPoints).toString() : v.toString();
}

export function stringToBig(value: string): BigType | undefined {
  try {
    return new Big(value);
  } catch (ex) {
    return undefined;
  }
}
