import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import * as React from "react";
import SearchInput from "../shared/SearchInput";

const PREFIX = 'ItemSearch';
const classes = {
  root: `${PREFIX}-root`,
  search: `${PREFIX}-search`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.search}`]: {
    marginTop: theme.spacing(1),
    width: "80%",
  }
}));

export default function ItemSearch(props: { value: string; updateValue: (value: string) => void }) {
  const { value, updateValue } = props;

  return (
    <Root className={classes.root}>
      <div>
        <Typography variant="body1"> Search</Typography>
        <SearchInput className={classes.search} placeholder="Instruments" value={value} onValueChange={updateValue} />
      </div>
    </Root>
  );
}
