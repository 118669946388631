import * as React from "react";
import { styled } from '@mui/material/styles';
import { CircularProgress } from "@mui/material";

const PREFIX = 'LoadingIndicator';
const classes = {
  root: `${PREFIX}-root`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    position: "relative",
    "& .MuiCircularProgress-root": {
      color: theme.palette.primary.main,
      position: "absolute",
      top: "50%",
      left: "50%",
    },
  }
}));

const LoadingIndicator: React.FC<{ show: boolean; children: React.ReactNode; size: string; delayInMillis?: number }> = ({
  show,
  size,
  children,
  delayInMillis = 500,
}) => {
  const minusHalfSize = `calc(${size} / -2)`;
  const timerRef = React.useRef<number | undefined>();
  const [delayExpired, setDelayExpired] = React.useState(false);

  // cleanup
  React.useEffect(() => () => clearTimeout(timerRef.current), []);

  if (show && timerRef.current === undefined) {
    setDelayExpired(false);
    timerRef.current = (setTimeout(() => {
      setDelayExpired(true);
      timerRef.current = undefined;
    }, delayInMillis) as unknown) as number;
  }

  const showProgress = show && delayExpired;

  return (
    <Root className={classes.root}>
      {children}
      {showProgress && <CircularProgress size={size} style={{ marginTop: minusHalfSize, marginLeft: minusHalfSize }} />}
    </Root>
  );
};

export default LoadingIndicator;
