import * as React from "react";
import { styled } from '@mui/material/styles';
import { Button, Checkbox, FormControl, FormControlLabel, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import deepEqual from "lodash/isEqual";
import AllocationType from "../../types/AllocationType";
import MasterDataEntry from "../../types/MasterDataEntry";
import usePrevious from "../../modules/usePrevious";
import MultiSelect from "../shared/MultiSelect";
import MultiInstrumentSelect from "../shared/MultiInstrumentSelect";
import { MasterDataState } from "../../store/masterData/MasterDataState";
import { AllocationFilters, CommonFilters, ModelPortfolioFilters } from "../../store/pages/PagesState";
import { reverseLookupInstrumentRequest, removeAllFilter, updateFilter } from "../../store/pages/pages-slice";
import { AppState } from "../../store/AppState";
import Allocation from "../../types/Allocation";
import { AllocationPageType, allocationPageTypeToResultKey } from "../../types/AllocationPageType";

const PREFIX = 'AllocationFilterPanel';
const classes = {
  root: `${PREFIX}-root`,
  moreButton: `${PREFIX}-moreButton`,
  moreFilters: `${PREFIX}-moreFilters`,
  header: `${PREFIX}-header`,
  autocompleteMultipleContainer: `${PREFIX}-autocompleteMultipleContainer`
};
const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: theme.spacing(28),
    "& > div + div": {
      marginTop: theme.spacing(3),
    },
    "& .MuiCheckbox-root": {
      // fixes checkbox icon spacing
      padding: theme.spacing(1),
      height: "inherit",
    },
  },

  [`& .${classes.moreButton}`]: {
    position: "relative",
    left: theme.spacing(-1), // left aligns the text with the rest
    "& .MuiButton-root:hover": {
      background: "transparent",
    },
    "& h3": {
      marginRight: theme.spacing(2),
    },
    "& .expandIcon": {
      transform: "rotate(0deg)",
      transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
    },
    "& .expandIcon-expanded": {
      transform: "rotate(180deg)",
    },
  },

  [`& .${classes.moreFilters}`]: {
    "& .MuiCollapse-wrapperInner > div": {
      marginTop: theme.spacing(3),
    },
  },

  [`& .${classes.header}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    "& .MuiButton-root:hover": {
      background: "transparent",
    },
  },

  [`& .${classes.autocompleteMultipleContainer}`]: {
    marginTop: theme.spacing(1),
  }
}));

export default React.memo(function AllocationFilterPanel(props: {
  masterData: MasterDataState;
  filters: CommonFilters;
  allocationPageType: AllocationPageType;
}) {
  const dispatch = useDispatch();
  const { masterData, filters, allocationPageType } = props;
  const typePlural = allocationPageType === AllocationPageType.Allocation ? "Allocations" : "Model Portfolios";

  const isUserAdmin = useSelector((state: AppState) => state.user.isAdmin);
  const productLines = useSelector((state: AppState) => state.masterData.productLines);
  const allocations = Object.values(
    useSelector((state: AppState) => state.pages[allocationPageTypeToResultKey(allocationPageType)])
  ) as Allocation[];

  const uniqueAllocationProductLineIds = new Set(allocations.map((a) => a.ProductLine.Id));
  const usedProductLines = productLines.filter((i) => uniqueAllocationProductLineIds.has(i.Id));

  const isAnyOfMoreFiltersActive = Object.keys(filters)
    .filter((k) => k !== "Favorite" && k !== "Owner" && k !== "Name")
    .some((k) => {
      const filter = filters[k];
      return !!filter && filter.length > 0;
    });

  const isAnyFilterActive = !!filters.Favorite || !!filters.Owner || !!filters.Deleted || isAnyOfMoreFiltersActive;

  // Side-effect: Lookup allocations containing selected instruments each time
  // the reverse lookup filter changed.
  const prevReverseLookup = usePrevious(filters.reverseLookup);
  React.useEffect(() => {
    const current = filters.reverseLookup;
    if ((current !== undefined && prevReverseLookup === undefined) || !deepEqual(current, prevReverseLookup)) {
      const ids = current?.map((i) => i.instrumentId) || [];
      dispatch(reverseLookupInstrumentRequest(ids, allocationPageType));
    }
  }, [filters.reverseLookup]);

  function clearFilter() {
    dispatch(removeAllFilter(allocationPageType));
  }

  function onFilterChange(id: string, state: boolean | string[]) {
    dispatch(updateFilter({ id, state, pageType: allocationPageType }));
  }

  function changeFavoriteFilter(e: React.ChangeEvent<HTMLInputElement>) {
    onFilterChange("Favorite", e.target.checked);
  }

  function changeOwnerFilter(e: React.ChangeEvent<HTMLInputElement>) {
    onFilterChange("Owner", e.target.checked);
  }

  function changeDeletedFilter(e: React.ChangeEvent<HTMLInputElement>) {
    onFilterChange("Deleted", e.target.checked);
  }

  function changeContainsDeletedInstrumentsFilter(e: React.ChangeEvent<HTMLInputElement>) {
    onFilterChange("ContainsDeletedInstruments", e.target.checked);
  }

  const onInstrumentSelectionChange = React.useCallback(
    (selection: any) => {
      dispatch(updateFilter({ id: "reverseLookup", state: selection, pageType: allocationPageType }));
    },
    [allocationPageType]
  );

  return (
    <Root className={classes.root}>
      <div>
        <div className={classes.header}>
          <Typography variant="h3" gutterBottom>
            Filters
          </Typography>
          <Button
            variant="text"
            style={{ visibility: isAnyFilterActive ? "inherit" : "hidden" }}
            disabled={!isAnyFilterActive}
            onClick={clearFilter}
          >
            <Typography variant="body2">Clear All</Typography>
          </Button>
        </div>
        <Typography gutterBottom />
        <FormControl component="fieldset">
          <FormControlLabel
            control={<Checkbox onChange={changeFavoriteFilter} checked={!!filters.Favorite} />}
            label="Favourites"
          />
          <FormControlLabel
            control={<Checkbox onChange={changeOwnerFilter} checked={!!filters.Owner} />}
            label={"My " + typePlural}
          />
          {isUserAdmin && (
            <FormControlLabel
              control={<Checkbox onChange={changeDeletedFilter} checked={!!filters.Deleted} />}
              label="Show Deleted"
            />
          )}
          <FormControlLabel
            control={
              <Checkbox onChange={changeContainsDeletedInstrumentsFilter} checked={!!filters.ContainsDeletedInstruments} />
            }
            label="Contains Deleted Instruments"
          />
        </FormControl>
      </div>

      {masterData !== undefined && (
        <>
          {allocationPageType == AllocationPageType.Allocation && (
            <MultiSelect
              id="AllocationType"
              label="Allocation Type"
              data={[
                {
                  key: AllocationType.Saa.toString(),
                  value: "SAA",
                },
                {
                  key: AllocationType.Taa.toString(),
                  value: "TAA",
                },
                {
                  key: AllocationType.Tca.toString(),
                  value: "TCA",
                },
              ]}
              selectedKeys={(filters as AllocationFilters).AllocationType}
              onChange={onFilterChange}
            />
          )}
          <MultiSelect
            id="ProductLine"
            label="Product"
            data={toKeyValuePairs(usedProductLines)}
            selectedKeys={filters.ProductLine}
            onChange={onFilterChange}
          />
          <MultiSelect
            id="ProductDistinction"
            label="Product Distinction"
            data={toDescriptiveKeyValuePairs(masterData.productDistinctions)}
            selectedKeys={filters.ProductDistinction}
            onChange={onFilterChange}
          />
          <MultiSelect
            id="ReferenceCurrency"
            label="Reference Currency"
            data={toKeyValuePairs(masterData.currencies)}
            selectedKeys={filters.ReferenceCurrency}
            onChange={onFilterChange}
          />
          <MultiSelect
            id="RiskProfile"
            label="Investment Strategy"
            data={toKeyValuePairs(masterData.riskProfiles)}
            selectedKeys={filters.RiskProfile}
            onChange={onFilterChange}
          />
          {allocationPageType == AllocationPageType.ModelPortfolio && (
            <MultiSelect
              id="BmoContext"
              label="BMO"
              data={toKeyValuePairs(masterData.bmoContexts)}
              selectedKeys={(filters as ModelPortfolioFilters).BmoContext}
              onChange={onFilterChange}
            />
          )}
          <MultiInstrumentSelect
            label="Included Instruments"
            selectedInstruments={filters.reverseLookup}
            onChange={onInstrumentSelectionChange}
          />
        </>
      )}
    </Root>
  );
});

function toKeyValuePairs(items: MasterDataEntry[]) {
  return items.map((i) => ({ key: i.Id, value: i.ShortName }));
}

function toDescriptiveKeyValuePairs(items: MasterDataEntry[]) {
  return items.map((i) => ({ key: i.Id, value: i.Name }));
}
