import { AllocationRowDescriptor } from "../types";

export default function traversePostOrder(
  rows: AllocationRowDescriptor[],
  callbacks: {
    onNewSubtree?: (level: number, row: AllocationRowDescriptor) => void;
    onParent?: (level: number, row: AllocationRowDescriptor) => void;
    onSibling?: (level: number, row: AllocationRowDescriptor) => void;
  }
) {
  let previousLevel: number | undefined = undefined;
  const onNewSubtree = callbacks.onNewSubtree || noop;
  const onParent = callbacks.onParent || noop;
  const onSibling = callbacks.onSibling || noop;

  // Given that rows is depth-first/pre-order,
  // when we iterate in reverse order, we get post-order traversal.
  for (let i = rows.length - 1; i >= 0; i--) {
    const row = rows[i];
    const level = row.level;

    if (previousLevel === undefined || level > previousLevel) {
      // we left the previous subtree and are now in another
      onNewSubtree(level, row);
    } else if (level === previousLevel) {
      // we moved to a sibling (which must be leaf)
      onSibling(level, row);
    } else {
      // we moved to the root node of the current subtree
      onParent(level, row);
    }

    previousLevel = level;
  }
}

function noop() {}
