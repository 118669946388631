import * as React from "react";
import { styled } from '@mui/material/styles';
import { useSelector } from "react-redux";
import { CircularProgress, Modal, Typography } from "@mui/material";
import { AppState } from "../store/AppState";

const PREFIX = 'LoadingModal';
const classes = {
  paper: `${PREFIX}-paper`,
  progress: `${PREFIX}-progress`
};
const StyledModal = styled(Modal)((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    textAlign: "center",
  },

  [`& .${classes.progress}`]: {
    marginTop: 20,
  }
})) as typeof Modal;

export default function LoadingModal() {
  const open = useSelector((s: AppState) => s.loading.loading) > 0;

  return (
    <StyledModal open={open} style={{ zIndex: 5432 }}>
      <div className={classes.paper}>
        <Typography variant="h6" id="modal-title">
          Loading...
        </Typography>
        <CircularProgress className={classes.progress} size={50} />
      </div>
    </StyledModal>
  );
}
