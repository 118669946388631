import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Visibility, Replay } from "@mui/icons-material";
import React from "react";
import PanelDescriptionHeader from "../PanelDescriptionHeader";
import { getAllRequestsInfo, retriggerFailed } from "./apiRequests";
import { DynamicWeightRequestErrors } from "./DynamicWeightRequestErrors";
import { DynamicWeightRequestErrorInfo, DynamicWeightRequestInfo } from "./DynamicWeightRequestInfo";
import { ColumnDefinition, ColumnType } from "../../../types/GenericTable";
import { GenericTable } from "./GenericTable";

const PREFIX = 'RequestsInfo';
const classes = {
  header: `${PREFIX}-header`,
  dataContainer: `${PREFIX}-dataContainer`,
  overview: `${PREFIX}-overview`,
  overviewContainer: `${PREFIX}-overviewContainer`,
  gridContainer: `${PREFIX}-gridContainer`,
  button: `${PREFIX}-button`,
  numLabel: `${PREFIX}-numLabel`,
  error: `${PREFIX}-error`,
  boldLabel: `${PREFIX}-boldLabel`,
  dates: `${PREFIX}-dates`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.header}`]: {
    marginBottom: "20px",
  },

  [`& .${classes.dataContainer}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    margin: "10px 0",
    maxWidth: "900px",
  },

  [`&.${classes.overview}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },

  [`& .${classes.overviewContainer}`]: {
    width: "calc(100% - 90px)",
  },

  [`& .${classes.gridContainer}`]: {
    maxHeight: "300px",
    overflowY: "auto",
    margin: "0 0 30px",
  },

  [`& .${classes.button}`]: {
    width: "80px",
    height: "80px",
  },

  [`& .${classes.numLabel}`]: {
    fontSize: "3rem",
    fontWeight: "bold",
    textAlign: "center",
  },

  [`& .${classes.error}`]: {
    color: "#971B2F",
  },

  [`& .${classes.boldLabel}`]: {
    fontWeight: "bold",
  },

  [`& .${classes.dates}`]: {
    fontSize: "2rem",
    display: "flex",
    columnGap: "5px",
    flexWrap: "wrap",
    width: "210px",
    "& > div:nth-child(odd)": {
      flexBasis: "25%",
    },
    "& > div:nth-child(even)": {
      flexBasis: "70%",
    },
  }
}));

export function RequestsInfo() {


  const [data, setData] = React.useState<DynamicWeightRequestInfo[]>([]);
  const [errors, setErrors] = React.useState<DynamicWeightRequestErrorInfo[]>([]);
  const [lastRefreshDate, setLastRefreshDate] = React.useState(new Date());

  const colDefinitions: ColumnDefinition<DynamicWeightRequestInfo>[] = [
    {
      propName: "Date",
      label: "Date",
      columnType: ColumnType.ReadOnly,
    },
    {
      propName: "Total",
      label: "Total",
      columnType: ColumnType.ReadOnly,
      align: "right",
    },
    {
      propName: "Success",
      label: "Successful",
      columnType: ColumnType.ReadOnly,
      align: "right",
    },
    {
      propName: "Pending",
      label: "Pending",
      columnType: ColumnType.ReadOnly,
      align: "right",
    },
    {
      propName: "Errors",
      label: "Errors",
      columnType: ColumnType.ReadOnly,
      align: "right",
    },
    {
      propName: "",
      label: "Actions",
      align: "right",
      columnType: ColumnType.Dummy,
      actions: [
        {
          icon: <Visibility />,
          iconClickCallback: (item: DynamicWeightRequestInfo) => setErrors(item.ErrorDetails),
          tooltip: "View errors",
          isVisible: (item: DynamicWeightRequestInfo) => !!item.Errors,
        },
        {
          icon: <Replay />,
          iconClickCallback: async (item: DynamicWeightRequestInfo) => {
            await retriggerFailed(item.ProcessId);
            await doRefresh();
          },
          tooltip: "Re-trigger failed",
          isVisible: (item: DynamicWeightRequestInfo) => !!item.Errors,
        },
      ],
    },
  ];

  const doRefresh = async () => {
    const apiData = await getAllRequestsInfo();
    setLastRefreshDate(new Date());
    setData(apiData);
  };

  React.useEffect(() => {
    doRefresh();
  }, []);

  const lastRun = data[0];

  const sortByColumn = "Date";
  const keyColumn = "ProcessId";

  if (!lastRun) {
    return <></>;
  }

  return <Root>
    <div className={classes.header}>
      <PanelDescriptionHeader>
        Browse previous requests for dynamic weights to TripleA CH and define which allocation request data.
      </PanelDescriptionHeader>

      <div className={classes.dataContainer}>
        <div>
          <Button variant="outlined" className={classes.button} onClick={() => doRefresh()}>
            Refresh
          </Button>
        </div>
        <div className={classes.overviewContainer}>
          <Overview data={lastRun} />
        </div>
      </div>

      <div className="">
        Last refresh of displayed data at:
        <span className={classes.boldLabel}> {lastRefreshDate.toLocaleTimeString()}</span>
      </div>
    </div>

    <div className={classes.gridContainer}>
      <GenericTable
        colDefinitions={colDefinitions}
        data={data}
        sortBy={sortByColumn}
        sortByOrder="desc"
        keyColName={keyColumn}
        filterColumn={sortByColumn}
        title="Import runs"
        hidePagination={true}
        hideSearch={true}
      />
    </div>

    <Dialog open={!!errors.length} fullWidth maxWidth="lg" onClose={() => setErrors([])}>
      <DialogTitle color="primary">Request Errors</DialogTitle>
      <DialogContent>
        <DynamicWeightRequestErrors data={errors} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setErrors([])}>Close</Button>
      </DialogActions>
    </Dialog>
  </Root>;
}

export function Overview(props: { data: DynamicWeightRequestInfo }) {

  const data = props.data;

  return (
    <Root className={classes.overview}>
      <div className={classes.dates}>
        <div>Date:</div>
        <div>{data.Date}</div>
        <div>Start:</div>
        <div>{data.StartTime}</div>
        <div>End:</div>
        <div>{data.EndTime || "-"}</div>
      </div>
      <div>
        <div className={classes.numLabel}>{data.Total}</div>
        <div>allocations in scope</div>
      </div>
      <div>
        <div className={classes.numLabel}>{data.Success}</div>
        <div>successful</div>
      </div>
      <div>
        <div className={classes.numLabel}>{data.Pending}</div>
        <div>pending</div>
      </div>
      <div>
        <div className={classes.numLabel + (data.Errors ? " " + classes.error : "")}>{data.Errors}</div>
        <div>errors</div>
      </div>
    </Root>
  );
}
