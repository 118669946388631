import React from "react";
import { styled } from '@mui/material/styles';
import {
  Button,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { ProductLinePublicationData, ProductStatus, ProductDataStatus } from "../utils";
import InfoBox from "../../shared/InfoBox";
import { ViewIcon } from "../../shared/Icons";
import { postUpload } from "../apiRequests";
import ReferenceDate from "../ReferenceDate";
import { useDispatch } from "react-redux";
import { hideLoadingScreen, showLoadingScreen } from "../../../store/loading/loading-slice";
import { showAlert } from "../../../store/app/app-slice";
import ErrorBox from "../../shared/ErrorBox";

const PREFIX = 'UploadDialog';
const classes = {
  dialog: `${PREFIX}-dialog`,
  actions: `${PREFIX}-actions`,
  disabledItem: `${PREFIX}-disabledItem`,
  verticalSeparator: `${PREFIX}-verticalSeparator`,
  inlineIconButton: `${PREFIX}-inlineIconButton`
};
const RootDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`& .${classes.dialog}`]: {
    minWidth: "20rem",
  },

  [`& .${classes.actions}`]: {
    paddingRight: "24px", // align with content padding
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },

  [`& .${classes.disabledItem}`]: {
    color: "#606060",
  },

  [`& .${classes.verticalSeparator}`]: {
    paddingBottom: theme.spacing(2),
  },

  [`& .${classes.inlineIconButton}`]: {
    paddingTop: 0,
    paddingLeft: 3,
    paddingBottom: 0,
    marginBottom: 3,
  }
})) as typeof Dialog;

/**
 * Dialog to upload the provided given productLines.
 */
export default function UploadDialog(props: {
  productLines: ProductLinePublicationData[];
  onClose: (reload: boolean) => void;
  referenceDate: ReferenceDate;
  onStatusPopup: (status: ProductStatus[]) => void;
}) {
  const dispatch = useDispatch();

  const [overrideWarnings, setOverrideWarnings] = React.useState<boolean>(false);
  const [uploadErrors, setUploadErrors] = React.useState<string[] | undefined>(undefined);

  const readyProductLines = props.productLines.filter((pl) => !pl.hasWarningsOrErrors);
  const warningProductLines = props.productLines.filter((pl) => pl.canPublish && pl.hasWarningsOrErrors);
  // The product lines used when actually uploading
  const uploadProductLines = overrideWarnings ? props.productLines : readyProductLines;

  const upload = () => {
    dispatch(showLoadingScreen());
    postUpload(uploadProductLines, props.referenceDate)
      // .then((response: UploadResponse) => {
      .then(() => {
        dispatch(hideLoadingScreen());
        props.onClose(true);
      })
      .catch((reason) => {
        dispatch(hideLoadingScreen());
        dispatch(showAlert("Error uploading the Product Lines", { type: "error" }));
        setUploadErrors(["" + reason]);
      });
  };

  return (
    <Dialog open className={classes.dialog}>
      <DialogTitle color="primary">Upload</DialogTitle>
      <DialogContent>
        <Typography variant="body2" component="div">
          {readyProductLines.length > 0 && (
            <div className={classes.verticalSeparator}>
              These Product Lines are ready for Upload: <br />
              <ul>{readyProductLines.map((pl) => ProductLineRow({ productLine: pl }))}</ul>
            </div>
          )}
          {warningProductLines.length > 0 && (
            <div>
              Product Lines with Warnings:
              <ul>
                {warningProductLines.map((pl) =>
                  ProductLineRow({
                    productLine: pl,
                    className: overrideWarnings ? undefined : classes.disabledItem,
                    iconButtonClassName: classes.inlineIconButton,
                    onStatusPopup: props.onStatusPopup,
                  })
                )}
              </ul>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={overrideWarnings}
                    onChange={() => {
                      setOverrideWarnings(!overrideWarnings);
                    }}
                    name="overrideWarnings"
                    color="primary"
                  />
                }
                label="Force Upload of Product Lines with warnings"
              />
            </div>
          )}
        </Typography>
        {uploadErrors && uploadErrors.length > 0 && (
          <ErrorBox>
            {uploadErrors.map((err) => (
              <div>{err}</div>
            ))}
          </ErrorBox>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <InfoBox>This will upload the data to CenShare and approve this month's data for MSD.</InfoBox>
        <Button
          onClick={() => {
            props.onClose(false);
          }}
          disableFocusRipple
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={upload}
          autoFocus
          disableFocusRipple
          disabled={uploadProductLines.length === 0}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Trivial component showing a ProductLine as a <li>.
 * If the productline has Warnings then a button to show the validation errors is shown.
 */
function ProductLineRow(props: {
  productLine: ProductLinePublicationData;
  className?: string;
  iconButtonClassName?: string;
  onStatusPopup?: (status: ProductStatus[]) => void;
}) {
  return (
    <li className={props.className} key={props.productLine.productLine}>
      <span>{props.productLine.productLine}</span>{" "}
      {props.productLine.productDataStatus === ProductDataStatus.Warning && (
        <Tooltip title="Show validation messages">
          <IconButton
            size="small"
            disableRipple
            className={props.iconButtonClassName}
            onClick={() => props.onStatusPopup && props.onStatusPopup(props.productLine.rows.filter((r) => r.ValidationMessages.length))}
          >
            <ViewIcon />
          </IconButton>
        </Tooltip>
      )}
    </li>
  );
}
