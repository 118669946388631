import * as React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

/**
 * File contains various (SVG) icons from the IMS (MSD) styles.
 * See: https://www.figma.com/file/nMxGQL3zhfyG6TxVgoY3uN/JB_TailoringMandate_ti%26m?node-id=636%3A94439
 *
 *
 * IMPORTANT: Material-UIs SvgIcons only get the fill color from e.g. the parent button, NOT the stroke.
 * If you have an SVG that does use strokes please convert them to a path first.
 * (e.g. using Inkscape and Ctrl+Alt+C ("Kontur in Pfad umwandeln") after selecting the shape in Inkscape.
 *  SvgIcons should also use a 24x24 view port, so fixing that in Inkscape might also be a good idea.)
 */

export function PublishIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 10.832333,5.9191068 V 22.548487 h 2.267642 V 5.9191068 Z" />
      <path d="m 11.966154,4.3158125 -6.8501707,6.8472185 -0.8001708,0.803123 1.6032943,1.603294 0.8031234,-0.800171 5.2439238,-5.2439233 5.243924,5.2439233 0.803123,0.800171 1.603295,-1.603294 -0.800171,-0.803123 z" />
      <path d="M 0.25,0.25 V 6.6749877 7.808809 H 2.5176427 V 6.6749877 2.5176427 H 21.414665 v 4.157345 1.1338213 h 2.267643 V 6.6749877 0.25 Z" />
    </SvgIcon>
  );
}

export function DownloadIcon(props: SvgIconProps) {
  return <PublishIcon {...props} style={{ transform: "rotate(180deg)" }} />;
}

export function MoreIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m 12,16 c 1.1,0 2,0.9 2,2 0,1.1 -0.9,2 -2,2 -1.1,0 -2,-0.9 -2,-2 0,-1.1 0.9,-2 2,-2 z m 0,-6 c 1.1,0 2,0.9 2,2 0,1.1 -0.9,2 -2,2 -1.1,0 -2,-0.9 -2,-2 0,-1.1 0.9,-2 2,-2 z m 0,-6 c 1.1,0 2,0.9 2,2 0,1.1 -0.9,2 -2,2 -1.1,0 -2,-0.9 -2,-2 0,-1.1 0.9,-2 2,-2 z" />
    </SvgIcon>
  );
}

export function TailoringIconOutlined(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 12.033708,0 C 5.3910683,0 0,5.3910683 0,12.033708 c 0,6.642639 5.3910683,12.033708 12.033708,12.033708 6.642639,0 12.033708,-5.391069 12.033708,-12.033708 C 24.067416,5.3910683 18.676347,0 12.033708,0 Z m 0,0.57303371 c 6.332949,0 11.460674,5.12772489 11.460674,11.46067429 0,6.332949 -5.127725,11.460674 -11.460674,11.460674 -6.3329494,0 -11.46067429,-5.127725 -11.46067429,-11.460674 0,-6.3329494 5.12772489,-11.46067429 11.46067429,-11.46067429 z" />
      <path d="m 7.7359551,13.75169 c -1.4190699,0 -2.5786517,1.159582 -2.5786517,2.578652 0,1.419069 1.1595818,2.578651 2.5786517,2.578651 1.4190698,0 2.5786519,-1.159582 2.5786519,-2.578651 0,-1.41907 -1.1595821,-2.578652 -2.5786519,-2.578652 z m 0,0.85955 c 0.9545503,0 1.7191011,0.764551 1.7191011,1.719102 0,0.95455 -0.7645508,1.719101 -1.7191011,1.719101 -0.9545504,0 -1.7191012,-0.764551 -1.7191012,-1.719101 0,-0.954551 0.7645508,-1.719102 1.7191012,-1.719102 z" />
      <path d="m 7.7359551,5.1561842 c -1.4190699,0 -2.5786517,1.1595819 -2.5786517,2.5786517 0,1.4190699 1.1595818,2.5786521 2.5786517,2.5786521 1.4190698,0 2.5786519,-1.1595822 2.5786519,-2.5786521 0,-1.4190698 -1.1595821,-2.5786517 -2.5786519,-2.5786517 z m 0,0.8595506 c 0.9545503,0 1.7191011,0.7645507 1.7191011,1.7191011 0,0.9545504 -0.7645508,1.7191011 -1.7191011,1.7191011 -0.9545504,0 -1.7191012,-0.7645507 -1.7191012,-1.7191011 0,-0.9545504 0.7645508,-1.7191011 1.7191012,-1.7191011 z" />
      <path d="m 6.4309602,9.0252809 a 0.42977528,0.42977528 0 0 0 -0.3514307,0.205934 0.42977528,0.42977528 0 0 0 0.1443776,0.590941 L 18.257615,17.128336 a 0.42977528,0.42977528 0 0 0 0.589822,-0.144378 0.42977528,0.42977528 0 0 0 -0.144378,-0.590941 L 6.6693514,9.0868373 A 0.42977528,0.42977528 0 0 0 6.4309602,9.0252809 Z" />
      <path d="m 18.46243,6.8764045 a 0.42977528,0.42977528 0 0 0 -0.204815,0.061556 L 6.2239071,14.244141 a 0.42977528,0.42977528 0 0 0 -0.1443776,0.590941 0.42977528,0.42977528 0 0 0 0.5898219,0.144377 L 18.703059,7.6732795 a 0.42977528,0.42977528 0 0 0 0.144378,-0.590941 0.42977528,0.42977528 0 0 0 -0.385007,-0.205934 z" />
    </SvgIcon>
  );
}

export function InfoIconThin(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 12.009895,0 C 5.3803999,0 0,5.3803999 0,12.009895 c 0,6.629494 5.3803999,12.009894 12.009895,12.009894 6.629494,0 12.009894,-5.3804 12.009894,-12.009894 C 24.019789,5.3803999 18.639389,0 12.009895,0 Z m 0,0.57189974 c 6.320416,0 11.437994,5.11757776 11.437994,11.43799526 0,6.320416 -5.117578,11.437994 -11.437994,11.437994 -6.3204175,0 -11.43799526,-5.117578 -11.43799526,-11.437994 0,-6.3204175 5.11757776,-11.43799526 11.43799526,-11.43799526 z" />
      <path d="M 12.115332,6.8156725 A 0.62913946,0.10529403 0 0 0 11.486194,6.920967 v 1.2635286 a 0.62913946,0.10529403 0 0 0 0.629138,0.1052945 0.62913946,0.10529403 0 0 0 0.62914,-0.1052945 V 6.9209665 a 0.62913946,0.10529403 0 0 0 -0.62914,-0.105294 z" />
      <path d="m 12.046971,9.7379748 a 0.59947792,0.41277778 0 0 0 -0.599478,0.4127772 v 6.604444 a 0.59947792,0.41277778 0 0 0 0.599478,0.412779 0.59947792,0.41277778 0 0 0 0.59948,-0.412779 v -6.604444 a 0.59947792,0.41277778 0 0 0 -0.59948,-0.4127772 z" />
    </SvgIcon>
  );
}

export function InfoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M11 9H13V7H11V9ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 17H13V11H11V17Z" />
    </SvgIcon>
  );
}

export function SettingsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M1.5,24.001c-0.827,0-1.5-0.673-1.5-1.5v-21c0-0.827,0.673-1.5,1.5-1.5h21c0.827,0,1.5,0.673,1.5,1.5v21
		c0,0.827-0.673,1.5-1.5,1.5H1.5z M1.5,1.001c-0.276,0-0.5,0.224-0.5,0.5v21c0,0.276,0.224,0.5,0.5,0.5h21
		c0.276,0,0.5-0.224,0.5-0.5v-21c0-0.276-0.224-0.5-0.5-0.5H1.5z"
      />
      <path
        d="M15.5,21.001c-1.738,0-3.216-1.3-3.461-3H3.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h8.539
		c0.245-1.7,1.723-3,3.461-3s3.216,1.3,3.461,3H20.5c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5h-1.539
		C18.716,19.701,17.238,21.001,15.5,21.001z M15.5,15.001c-1.378,0-2.5,1.122-2.5,2.5s1.122,2.5,2.5,2.5s2.5-1.122,2.5-2.5
		S16.878,15.001,15.5,15.001z"
      />
      <path
        d="M9,10.001c-1.738,0-3.216-1.3-3.461-3H3.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2.039c0.245-1.7,1.723-3,3.461-3
		s3.216,1.3,3.461,3H20.5c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5h-8.039C12.216,8.701,10.738,10.001,9,10.001z M9,4.001
		c-1.379,0-2.5,1.121-2.5,2.5s1.121,2.5,2.5,2.5s2.5-1.121,2.5-2.5S10.379,4.001,9,4.001z"
      />
      <circle cx="9" cy="6.501" r="0.75" />
      <circle cx="15.5" cy="17.501" r="0.75" />
    </SvgIcon>
  );
}

export function HierarchyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M17.25,23.998c-1.241,0-2.25-1.009-2.25-2.25v-4.5c0-1.241,1.009-2.25,2.25-2.25h1.5v-0.75c0-0.414-0.336-0.75-0.75-0.75H6
		c-0.414,0-0.75,0.336-0.75,0.75v0.75h1.5c1.241,0,2.25,1.009,2.25,2.25v4.5c0,1.241-1.009,2.25-2.25,2.25h-4.5
		c-1.241,0-2.25-1.009-2.25-2.25v-4.5c0-1.241,1.009-2.25,2.25-2.25h1.5v-0.75c0-1.241,1.009-2.25,2.25-2.25h5.25v-3h-1.5
		c-1.241,0-2.25-1.009-2.25-2.25v-4.5c0-1.241,1.009-2.25,2.25-2.25h4.5c1.241,0,2.25,1.009,2.25,2.25v4.5
		c0,1.241-1.009,2.25-2.25,2.25h-1.5v3H18c1.241,0,2.25,1.009,2.25,2.25v0.75h1.5c1.241,0,2.25,1.009,2.25,2.25v4.5
		c0,1.241-1.009,2.25-2.25,2.25H17.25z M17.25,16.498c-0.414,0-0.75,0.336-0.75,0.75v4.5c0,0.414,0.336,0.75,0.75,0.75h4.5
		c0.414,0,0.75-0.336,0.75-0.75v-4.5c0-0.414-0.336-0.75-0.75-0.75H17.25z M2.25,16.498c-0.414,0-0.75,0.336-0.75,0.75v4.5
		c0,0.414,0.336,0.75,0.75,0.75h4.5c0.414,0,0.75-0.336,0.75-0.75v-4.5c0-0.414-0.336-0.75-0.75-0.75H2.25z M9.75,1.498
		C9.336,1.498,9,1.834,9,2.248v4.5c0,0.414,0.336,0.75,0.75,0.75h4.5c0.414,0,0.75-0.336,0.75-0.75v-4.5
		c0-0.414-0.336-0.75-0.75-0.75H9.75z"
      />
    </SvgIcon>
  );
}

export function FlagIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M0.5,24C0.224,24,0,23.776,0,23.5v-23C0,0.224,0.224,0,0.5,0S1,0.224,1,0.5v2.333C2.793,2.327,4.638,2.047,6.487,2
		c1.578,0,3.516,0.517,5.39,1.016C13.691,3.5,15.565,4,17,4c1.703-0.068,3.392-0.397,5.011-0.978
		C22.174,2.968,22.336,2.94,22.5,2.94c0.827,0,1.5,0.673,1.5,1.499v13.878c0,0.623-0.392,1.189-0.976,1.406
		c-1.918,0.748-3.938,1.177-6,1.275c0,0-0.001,0-0.001,0c-1.591,0-3.528-0.517-5.401-1.017C9.807,19.499,7.934,19,6.5,19
		c-1.852,0.047-3.703,0.34-5.5,0.873V23.5C1,23.776,0.776,24,0.5,24z M6.494,18c1.572,0,3.51,0.517,5.385,1.017
		C13.694,19.501,15.567,20,17,20c1.934-0.092,3.845-0.499,5.667-1.21C22.868,18.715,23,18.526,23,18.319V4.44
		c0-0.133-0.052-0.259-0.146-0.353C22.759,3.992,22.634,3.94,22.5,3.94c-0.059,0.002-0.116,0.011-0.16,0.026
		C20.632,4.58,18.841,4.928,17.02,5c0,0,0,0-0.001,0c-1.586,0-3.524-0.517-5.398-1.016C9.809,3.5,7.935,3,6.5,3
		C4.642,3.047,2.791,3.34,1,3.87v14.959C2.787,18.326,4.631,18.047,6.487,18C6.49,18,6.492,18,6.494,18z"
      />
      <path
        d="M10.5,16c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H12v-4.5c0-0.276-0.224-0.5-0.5-0.5h-1C10.224,10,10,9.776,10,9.5
		S10.224,9,10.5,9h1c0.827,0,1.5,0.673,1.5,1.5V15h1.5c0.276,0,0.5,0.224,0.5,0.5S14.776,16,14.5,16H10.5z"
      />
      <circle cx="12.5" cy="7.25" r="0.75" />
    </SvgIcon>
  );
}

export function ExcelFileIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M3.5,24C2.673,24,2,23.327,2,22.5v-21C2,0.673,2.673,0,3.5,0h12.025C19.094,0,21.999,2.904,22,6.473V22.5
		c0,0.827-0.673,1.5-1.5,1.5H3.5z M3.5,1C3.224,1,3,1.224,3,1.5v21C3,22.776,3.224,23,3.5,23h17c0.276,0,0.5-0.224,0.5-0.5V6.473
		C21,6.32,20.993,6.161,20.978,6H17.5C16.673,6,16,5.327,16,4.5V1.022C15.838,1.007,15.681,1,15.525,1H3.5z M17,4.5
		C17,4.776,17.224,5,17.5,5h3.297C20.282,3.164,18.836,1.717,17,1.203V4.5z"
      />
      <path
        d="M8.5,18.973c-0.191,0-0.362-0.106-0.448-0.276L7,16.591l-1.053,2.105c-0.085,0.17-0.257,0.276-0.448,0.276
		c-0.077,0-0.154-0.018-0.223-0.052c-0.247-0.124-0.348-0.425-0.224-0.671l1.388-2.776l-1.388-2.776
		c-0.06-0.119-0.069-0.255-0.027-0.382c0.042-0.127,0.131-0.229,0.251-0.289c0.069-0.035,0.146-0.053,0.224-0.053
		c0.191,0,0.362,0.106,0.447,0.276L7,14.355l1.053-2.105c0.085-0.171,0.257-0.276,0.447-0.276c0.077,0,0.154,0.018,0.224,0.053
		c0.119,0.06,0.208,0.162,0.251,0.289c0.042,0.127,0.033,0.262-0.027,0.382l-1.388,2.776l1.388,2.776
		c0.123,0.247,0.023,0.548-0.224,0.671C8.654,18.955,8.577,18.973,8.5,18.973z"
      />
      <path
        d="M11.5,18.973c-0.827,0-1.5-0.673-1.5-1.5v-5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v5c0,0.276,0.224,0.5,0.5,0.5h2
		c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H11.5z"
      />
      <path
        d="M15.5,18.973c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1.694c0.278,0,0.529-0.135,0.677-0.36
		c0.242-0.368,0.14-0.865-0.229-1.108L15.8,15.276c-0.511-0.336-0.814-0.903-0.81-1.517c0.008-0.998,0.803-1.783,1.811-1.786H18.5
		c0.276,0,0.5,0.224,0.5,0.5c0,0.276-0.224,0.5-0.5,0.5h-1.7c-0.443,0.001-0.807,0.357-0.81,0.794
		c-0.002,0.273,0.133,0.525,0.361,0.675l1.843,1.229c0.827,0.544,1.057,1.662,0.512,2.491c-0.334,0.508-0.896,0.811-1.503,0.811
		H15.5z"
      />
    </SvgIcon>
  );
}

export function UsersIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M22.501,24.001c-0.17,0-0.327-0.085-0.42-0.228c-0.901-1.386-2.429-2.213-4.089-2.213c-0.94,0-1.854,0.271-2.645,0.785
		c-0.574,0.373-1.055,0.854-1.428,1.428C13.827,23.915,13.67,24,13.5,24c-0.097,0-0.191-0.028-0.272-0.081
		c-0.231-0.151-0.297-0.461-0.147-0.692c0.45-0.692,1.03-1.272,1.722-1.722c0.952-0.619,2.055-0.946,3.188-0.946
		c2,0,3.843,0.998,4.928,2.668c0.15,0.231,0.084,0.541-0.147,0.692C22.692,23.973,22.598,24.001,22.501,24.001L22.501,24.001z"
      />
      <path
        d="M10.5,24.001c-0.17,0-0.327-0.085-0.42-0.228c-0.901-1.386-2.429-2.213-4.089-2.213c-0.94,0-1.854,0.271-2.644,0.785
		c-0.575,0.374-1.055,0.854-1.429,1.428C1.827,23.915,1.67,24,1.5,24c-0.097,0-0.191-0.028-0.272-0.081
		c-0.231-0.151-0.297-0.461-0.147-0.692c0.45-0.692,1.029-1.271,1.722-1.722c0.952-0.619,2.055-0.946,3.188-0.946
		c2,0,3.842,0.997,4.928,2.668c0.15,0.231,0.084,0.541-0.146,0.692C10.691,23.973,10.597,24.001,10.5,24.001
		C10.501,24.001,10.501,24.001,10.5,24.001z"
      />
      <path
        d="M16.5,11.5c-0.17,0-0.327-0.085-0.419-0.227c-0.708-1.09-1.799-1.839-3.071-2.109c-0.337-0.071-0.68-0.108-1.021-0.108
		c-0.935,0-1.849,0.272-2.642,0.788c-0.574,0.373-1.055,0.854-1.428,1.428C7.827,11.415,7.67,11.5,7.5,11.5
		c-0.097,0-0.191-0.028-0.272-0.081c-0.112-0.073-0.189-0.185-0.217-0.316c-0.028-0.131-0.003-0.264,0.07-0.376
		c0.45-0.692,1.029-1.272,1.722-1.722c0.956-0.621,2.057-0.95,3.186-0.95c0.41,0,0.824,0.044,1.229,0.13
		c1.533,0.325,2.848,1.228,3.702,2.542c0.073,0.112,0.097,0.246,0.07,0.376c-0.028,0.131-0.105,0.243-0.217,0.315
		C16.692,11.472,16.597,11.5,16.5,11.5z"
      />
      <path
        d="M12.05,7.5C9.982,7.5,8.3,5.818,8.3,3.75c0-0.646,0.171-1.286,0.493-1.852C8.809,1.865,8.827,1.84,8.84,1.822
		C9.51,0.702,10.743,0,12.05,0c1.862,0,3.458,1.392,3.711,3.238c0.002,0.009,0.007,0.03,0.009,0.056
		C15.79,3.465,15.8,3.61,15.8,3.75C15.8,5.818,14.118,7.5,12.05,7.5z M9.425,2.936C9.342,3.2,9.3,3.473,9.3,3.75
		c0,1.516,1.234,2.75,2.75,2.75c1.427,0,2.604-1.093,2.738-2.487c-0.537,0.157-1.091,0.237-1.652,0.237
		C11.779,4.25,10.476,3.786,9.425,2.936z M9.908,2.029c0.89,0.778,2.053,1.221,3.225,1.221c0.527,0,1.05-0.087,1.56-0.26
		C14.358,1.825,13.279,1,12.05,1C11.215,1,10.429,1.381,9.908,2.029z"
      />
      <path
        d="M6.05,20c-2.068,0-3.75-1.682-3.75-3.75c0-0.646,0.171-1.287,0.494-1.852c0.015-0.033,0.033-0.058,0.044-0.074
		C3.509,13.202,4.742,12.5,6.05,12.5c1.863,0,3.458,1.393,3.711,3.24c0.002,0.01,0.006,0.029,0.008,0.055
		C9.79,15.964,9.8,16.109,9.8,16.25C9.8,18.318,8.118,20,6.05,20z M3.427,15.43C3.343,15.696,3.3,15.971,3.3,16.25
		C3.3,17.767,4.534,19,6.05,19c1.428,0,2.605-1.094,2.738-2.488c-0.532,0.155-1.082,0.233-1.639,0.233
		C5.787,16.746,4.479,16.281,3.427,15.43z M3.906,14.531c0.893,0.783,2.035,1.212,3.24,1.212c0.53,0,1.05-0.084,1.547-0.251
		C8.359,14.326,7.28,13.5,6.05,13.5C5.214,13.5,4.427,13.882,3.906,14.531z"
      />
      <path
        d="M18.05,20c-2.068,0-3.75-1.682-3.75-3.75c0-0.646,0.171-1.287,0.494-1.853c0.015-0.032,0.032-0.056,0.044-0.073
		c0.672-1.123,1.905-1.824,3.212-1.824c1.864,0,3.459,1.393,3.712,3.241c0.002,0.009,0.006,0.028,0.008,0.052
		c0.021,0.171,0.031,0.316,0.031,0.457C21.8,18.318,20.118,20,18.05,20z M15.427,15.43c-0.084,0.266-0.127,0.541-0.127,0.82
		c0,1.516,1.234,2.75,2.75,2.75c1.428,0,2.605-1.094,2.738-2.488c-0.532,0.155-1.083,0.233-1.639,0.233
		C17.787,16.746,16.479,16.281,15.427,15.43z M15.906,14.531c0.893,0.783,2.035,1.212,3.24,1.212c0.53,0,1.05-0.084,1.547-0.251
		C20.359,14.326,19.28,13.5,18.05,13.5C17.214,13.5,16.427,13.882,15.906,14.531z"
      />
    </SvgIcon>
  );
}

export function AlpimaIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 22.229965,19.899193 13.659414,1.0710325 c -0.05448,-0.11959179 -0.137973,-0.20940802 -0.235712,-0.26940419 -0.09783,-0.0598181 -0.210076,-0.0896382 -0.322235,-0.0896382 -0.04518,0 -0.08795,0.0174024 -0.132187,0.0270161 -0.120704,0.026482 -0.236602,0.0762859 -0.324371,0.17478114 A 0.63013785,0.63013785 0 0 0 12.543432,1.070988 L 10.410675,5.756609 10.063694,6.5185336 3.972792,19.899193 c -0.184796,0.406043 0.112025,0.86723 0.558035,0.86723 h 17.141147 c 0.138107,0 0.259212,-0.04776 0.35882,-0.121061 0.06111,-0.04482 0.116076,-0.0972 0.1568,-0.16036 a 0.61313596,0.61313596 0 0 0 0.09591,-0.279063 0.60895225,0.60895225 0 0 0 -0.0535,-0.306746"></path>

      <path d="m 3.5923468,21.547217 c -0.5246029,0 -0.8736142,-0.55413 -0.6561905,-1.042062 L 10.507275,3.508656 10.735133,2.9972262 10.335037,2.0988469 C 10.277103,1.9692042 10.18867,1.8718397 10.084987,1.8068012 A 0.65647369,0.6708414 0 0 0 9.7430573,1.7096778 c -0.1787548,0 -0.3572737,0.072854 -0.4841863,0.2187569 A 0.67455694,0.68932042 0 0 0 9.1510793,2.0988469 L 6.8885491,7.1780677 0.05912639,22.509428 C -0.1369092,22.949451 0.17796588,23.44959 0.65110475,23.44959 H 18.834869 c 0.236687,0 0.433668,-0.125252 0.547029,-0.305072 a 0.65123286,0.66548586 0 0 0 0.101749,-0.302515 0.64542544,0.65955135 0 0 0 -0.05661,-0.332575 L 18.998324,21.547217 H 3.5922996 Z"></path>
    </SvgIcon>
  );
}

export function BrushIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12.264,4.5c-0.414,0-0.75-0.336-0.75-0.75S11.85,3,12.264,3s0.75,0.336,0.75,0.75S12.677,4.5,12.264,4.5z" />
      <path
        d="M9.264,24c-0.551,0-1-0.448-1-1v-2.275c-0.355,0.555-0.756,1.177-1.116,1.717C6.497,23.418,5.409,24,4.236,24H2.737
		c-0.191,0-0.378-0.055-0.539-0.158c-0.464-0.298-0.599-0.918-0.302-1.382c0.895-1.393,1.368-3.004,1.368-4.66V15
		c-0.551,0-1-0.448-1-1v-1.5c0-1.378,1.121-2.5,2.5-2.5h4.948L9.066,3.535c-0.023-0.221-0.023-0.448,0-0.672
		c0.09-0.854,0.507-1.622,1.175-2.162c0.579-0.468,1.277-0.716,2.018-0.716c0.113,0,0.228,0.006,0.341,0.018
		c0.854,0.09,1.622,0.508,2.162,1.175c0.54,0.668,0.788,1.506,0.698,2.36L14.816,10h4.948c1.378,0,2.5,1.121,2.5,2.5V14
		c0,0.552-0.448,1-1,1v8c0,0.552-0.448,1-1,1H9.264z M4.264,17.8c0,1.848-0.528,3.646-1.526,5.2h1.494
		c0.842,0,1.619-0.416,2.084-1.112c0.87-1.308,2.013-3.135,2.024-3.152C8.431,18.588,8.59,18.5,8.764,18.5
		c0.047,0,0.093,0.007,0.138,0.02c0.213,0.061,0.362,0.259,0.362,0.48v4h5v-2.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5V23h2
		v-3.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5V23h2v-8h-16V17.8z M4.764,11c-0.827,0-1.5,0.673-1.5,1.5V14h18v-1.5
		c0-0.827-0.673-1.5-1.5-1.5h-5.5c-0.141,0-0.276-0.06-0.371-0.165c-0.095-0.105-0.141-0.245-0.127-0.385l0.7-7.015
		c0.062-0.591-0.108-1.169-0.48-1.629c-0.372-0.46-0.901-0.748-1.489-0.81c-0.08-0.009-0.16-0.013-0.239-0.013
		c-0.508,0-0.988,0.171-1.387,0.493c-0.46,0.372-0.747,0.901-0.809,1.49c-0.016,0.154-0.016,0.311,0,0.465l0.7,7.018
		c0.014,0.141-0.032,0.281-0.127,0.385C10.54,10.94,10.405,11,10.264,11H4.764z"
      />
    </SvgIcon>
  );
}

export function DashboardIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M3.5,21c-0.104,0-0.204-0.032-0.29-0.093c-0.225-0.161-0.277-0.474-0.117-0.698l2.2-3.083
		c0.187-0.262,0.492-0.418,0.815-0.418c0.209,0,0.41,0.064,0.58,0.186L7.899,17.8L10.6,14.2c0.094-0.125,0.243-0.2,0.4-0.2
		c0.109,0,0.212,0.034,0.3,0.1c0.107,0.08,0.176,0.197,0.195,0.329c0.019,0.132-0.015,0.264-0.095,0.371l-2.7,3.6
		c-0.188,0.25-0.487,0.4-0.801,0.4l0,0c-0.218,0-0.425-0.069-0.599-0.2l-1.193-0.893l-2.199,3.083C3.813,20.922,3.661,21,3.5,21z"
      />
      <path
        d="M17,11c-2.206,0-4-1.794-4-4s1.794-4,4-4c0.275,0,0.554,0.03,0.831,0.089c0.01,0.001,0.021,0.003,0.03,0.005
		c0.01,0.002,0.022,0.006,0.034,0.011c1.615,0.365,2.85,1.719,3.068,3.372c0.003,0.012,0.006,0.025,0.008,0.035
		c0.002,0.012,0.002,0.026,0.002,0.04C20.99,6.706,21,6.858,21,7C21,9.206,19.206,11,17,11z M17,4c-1.654,0-3,1.346-3,3s1.346,3,3,3
		c1.608,0,2.925-1.272,2.997-2.863l-2.936,0.359C17.041,7.499,17.02,7.5,17,7.5c-0.141,0-0.276-0.06-0.37-0.164
		c-0.109-0.119-0.153-0.285-0.118-0.443l0.634-2.888C17.095,4.001,17.047,4,17,4z M17.64,6.418l2.236-0.273
		c-0.26-0.875-0.904-1.583-1.752-1.925L17.64,6.418z"
      />
      <path
        d="M15,21c-0.552,0-1-0.448-1-1v-6c0-0.552,0.448-1,1-1h5c0.552,0,1,0.448,1,1v6c0,0.552-0.448,1-1,1H15z M15,20h5v-6l-5,0
		L15,20z"
      />
      <path d="M16.5,17c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2c0.276,0,0.5,0.224,0.5,0.5S18.776,17,18.5,17H16.5z" />
      <path d="M16.5,19c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5S17.776,19,17.5,19H16.5z" />
      <path d="M4,11c-0.551,0-1-0.449-1-1V4c0-0.551,0.449-1,1-1h5c0.551,0,1,0.449,1,1v6c0,0.551-0.449,1-1,1H4z M4,10h5V4L4,4L4,10z" />
      <path d="M5.5,6C5.224,6,5,5.776,5,5.5S5.224,5,5.5,5h2C7.776,5,8,5.224,8,5.5S7.776,6,7.5,6H5.5z" />
      <path d="M5.5,8C5.224,8,5,7.776,5,7.5S5.224,7,5.5,7h1C6.776,7,7,7.224,7,7.5S6.776,8,6.5,8H5.5z" />
      <path
        d="M1.5,24C0.673,24,0,23.327,0,22.5v-21C0,0.673,0.673,0,1.5,0h21C23.327,0,24,0.673,24,1.5v21c0,0.827-0.673,1.5-1.5,1.5
		H1.5z M1.5,1C1.224,1,1,1.224,1,1.5v21C1,22.776,1.224,23,1.5,23h21c0.276,0,0.5-0.224,0.5-0.5v-21C23,1.224,22.776,1,22.5,1H1.5z"
      />
    </SvgIcon>
  );
}

export function GaugeDashboardIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g transform="translate(0,1.85)">
        <path d="m 12,16 c -1.103,0 -2,-0.897 -2,-2 0,-1.103 0.897,-2 2,-2 0.36,0 0.706,0.096 1.014,0.278 l 4.644,-4.642 c 0.094,-0.094 0.22,-0.146 0.354,-0.146 0.134,0 0.259,0.052 0.354,0.146 0.095,0.094 0.146,0.22 0.146,0.354 0,0.134 -0.052,0.259 -0.146,0.354 l -4.644,4.641 C 13.904,13.293 14,13.64 14,14 c 0,1.103 -0.897,2 -2,2 z m 0,-3 c -0.551,0 -1,0.448 -1,1 0,0.552 0.449,1 1,1 0.552,0 1,-0.448 1,-1 0,-0.552 -0.448,-1 -1,-1 z" />
        <path d="M 2.5,15 C 2.224,15 2,14.776 2,14.5 2,14.224 2.224,14 2.5,14 h 2 C 4.776,14 5,14.224 5,14.5 5,14.776 4.776,15 4.5,15 Z" />
        <path d="M 19.5,15 C 19.224,15 19,14.776 19,14.5 19,14.224 19.224,14 19.5,14 h 2 c 0.276,0 0.5,0.224 0.5,0.5 0,0.276 -0.224,0.5 -0.5,0.5 z" />
        <path d="m 18.929,11.63 c -0.203,0 -0.384,-0.121 -0.462,-0.309 -0.105,-0.255 0.016,-0.547 0.271,-0.653 l 1.847,-0.765 c 0.061,-0.025 0.125,-0.038 0.191,-0.038 0.204,0 0.385,0.121 0.462,0.309 0.105,0.255 -0.016,0.547 -0.271,0.653 l -1.847,0.765 c -0.061,0.025 -0.125,0.038 -0.191,0.038 z" />
        <path d="M 14.87,7.571 C 14.804,7.571 14.74,7.558 14.679,7.533 14.425,7.428 14.303,7.135 14.408,6.88 l 0.765,-1.848 c 0.077,-0.188 0.259,-0.309 0.462,-0.309 0.065,0 0.13,0.013 0.191,0.038 0.255,0.105 0.376,0.398 0.271,0.653 L 15.332,7.262 C 15.255,7.45 15.073,7.571 14.87,7.571 Z" />
        <path d="M 12,7 C 11.724,7 11.5,6.776 11.5,6.5 v -2 C 11.5,4.224 11.724,4 12,4 c 0.276,0 0.5,0.224 0.5,0.5 v 2 C 12.5,6.776 12.276,7 12,7 Z" />
        <path d="M 9.13,7.571 C 8.927,7.571 8.745,7.45 8.668,7.262 L 7.903,5.414 C 7.852,5.291 7.852,5.155 7.903,5.031 7.954,4.908 8.05,4.812 8.174,4.761 8.234,4.736 8.299,4.723 8.365,4.723 c 0.203,0 0.385,0.121 0.462,0.309 L 9.592,6.88 c 0.051,0.123 0.051,0.259 0,0.383 C 9.541,7.386 9.445,7.482 9.321,7.533 9.26,7.558 9.196,7.571 9.13,7.571 Z" />
        <path d="M 6.695,9.197 C 6.561,9.197 6.435,9.145 6.341,9.05 L 4.929,7.635 C 4.734,7.44 4.735,7.123 4.93,6.928 5.025,6.834 5.15,6.782 5.283,6.782 c 0.134,0 0.26,0.052 0.354,0.147 L 7.049,8.344 C 7.244,8.539 7.243,8.856 7.048,9.051 6.954,9.145 6.828,9.197 6.695,9.197 Z" />
        <path d="m 5.07,11.63 c -0.066,0 -0.13,-0.013 -0.191,-0.038 L 3.033,10.827 C 2.91,10.776 2.814,10.68 2.762,10.556 2.711,10.433 2.711,10.297 2.762,10.173 2.84,9.986 3.021,9.865 3.224,9.865 c 0.066,0 0.13,0.013 0.191,0.038 l 1.846,0.765 c 0.123,0.051 0.219,0.147 0.271,0.271 0.051,0.123 0.051,0.259 0,0.383 C 5.454,11.509 5.273,11.63 5.07,11.63 Z" />
        <path d="M 1.5,19 C 0.673,19 0,18.327 0,17.5 V 14 C 0,7.383 5.383,2 12,2 18.617,2 24,7.383 24,14 v 3.5 C 24,18.327 23.327,19 22.5,19 Z M 12,3 C 5.935,3 1,7.935 1,14 v 3.5 C 1,17.776 1.224,18 1.5,18 h 21 C 22.776,18 23,17.776 23,17.5 V 14 C 23,7.935 18.065,3 12,3 Z" />
      </g>
    </SvgIcon>
  );
}

export function AlertIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m 11.961865,0.83470402 c -6.2038385,0 -11.27970536,5.07586688 -11.27970536,11.27970598 0,6.203837 5.07586686,11.279703 11.27970536,11.279703 6.203837,0 11.279703,-5.075866 11.279703,-11.279703 0,-6.2038391 -5.075866,-11.27970598 -11.279703,-11.27970598 z m 0,19.73948398 c -4.6528791,0 -8.4597793,-3.8069 -8.4597793,-8.459778 0,-4.6528797 3.8069002,-8.4597799 8.4597793,-8.4597799 4.652878,0 8.459778,3.8069002 8.459778,8.4597799 0,4.652878 -3.8069,8.459778 -8.459778,8.459778 z" />
      <path d="m 13.371827,6.4745561 h -2.819926 v 7.0498159 h 2.819926 z" />
      <path d="m 11.961865,17.754261 c 0.778722,0 1.409962,-0.63124 1.409962,-1.409962 0,-0.778723 -0.63124,-1.409964 -1.409962,-1.409964 -0.778723,0 -1.409964,0.631241 -1.409964,1.409964 0,0.778722 0.631241,1.409962 1.409964,1.409962 z" />
    </SvgIcon>
  );
}

export function UploadBoxIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M12,20c-0.276,0-0.5-0.224-0.5-0.5v-9.293l-4.146,4.146C7.259,14.448,7.134,14.5,7,14.5s-0.259-0.052-0.354-0.146
		S6.5,14.134,6.5,14s0.052-0.259,0.146-0.354l5-5c0.046-0.046,0.101-0.083,0.162-0.108C11.87,8.513,11.934,8.5,12,8.5
		s0.13,0.013,0.191,0.038c0.062,0.026,0.116,0.062,0.163,0.108l5,5C17.448,13.741,17.5,13.866,17.5,14s-0.052,0.259-0.146,0.354
		S17.134,14.5,17,14.5s-0.259-0.052-0.354-0.146L12.5,10.207V19.5C12.5,19.776,12.276,20,12,20z"
      />
      <path
        d="M2,24c-0.827,0-1.5-0.673-1.5-1.5v-16c0.005-0.165,0.016-0.331,0.034-0.499c0,0,0-0.001,0-0.001
		c0-0.018,0.002-0.038,0.006-0.057c0.061-0.538,0.182-1.05,0.36-1.542l1.264-3.159c0.336-0.73,1.027-1.201,1.809-1.241L20,0
		c0.803,0.041,1.497,0.507,1.825,1.219l1.268,3.168c0.003,0.007,0.008,0.021,0.01,0.029c0.177,0.486,0.299,1.007,0.358,1.537
		C23.464,5.968,23.466,5.985,23.466,6c0,0,0,0,0,0.001c0.018,0.17,0.029,0.328,0.033,0.484l0,16.016c0,0.827-0.673,1.5-1.5,1.5H2z
		 M1.5,22.5C1.5,22.776,1.724,23,2,23h20c0.276,0,0.5-0.224,0.5-0.5v-16h-21C1.5,6.505,1.5,22.5,1.5,22.5z M22.373,5.5
		c-0.055-0.264-0.127-0.516-0.213-0.754l-1.253-3.133C20.744,1.262,20.387,1.023,19.98,1L12.5,1v4.5H22.373z M11.5,5.5V1L4,1
		C3.611,1.021,3.255,1.265,3.083,1.637l-1.248,3.12C1.752,4.988,1.682,5.237,1.627,5.5H11.5z"
      />
    </SvgIcon>
  );
}
export function StarIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 11.996094 1.25 A 0.750075 0.750075 0 0 0 11.287109 1.7675781 L 8.9863281 8.8515625 L 1.5390625 8.8515625 A 0.750075 0.750075 0 0 0 1.0976562 10.207031 L 7.1230469 14.583984 L 4.8203125 21.667969 A 0.750075 0.750075 0 0 0 5.9746094 22.505859 L 12 18.128906 L 18.025391 22.505859 A 0.750075 0.750075 0 0 0 19.179688 21.667969 L 16.876953 14.583984 L 22.902344 10.207031 A 0.750075 0.750075 0 0 0 22.460938 8.8515625 L 15.013672 8.8515625 L 12.712891 1.7675781 A 0.750075 0.750075 0 0 0 11.996094 1.25 z M 12 4.4277344 L 13.755859 9.8320312 A 0.750075 0.750075 0 0 0 14.46875 10.351562 L 20.152344 10.351562 L 15.554688 13.691406 A 0.750075 0.750075 0 0 0 15.283203 14.529297 L 17.039062 19.935547 L 12.441406 16.595703 A 0.750075 0.750075 0 0 0 11.558594 16.595703 L 6.9609375 19.935547 L 8.7167969 14.529297 A 0.750075 0.750075 0 0 0 8.4453125 13.691406 L 3.8476562 10.351562 L 9.53125 10.351562 A 0.750075 0.750075 0 0 0 10.244141 9.8320312 L 12 4.4277344 z " />
    </SvgIcon>
  );
}

export function SearchIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 16.210938 14.787109 L 14.796875 16.201172 L 15.505859 16.908203 L 21.291016 22.689453 L 22 23.396484 L 23.412109 21.982422 L 22.705078 21.275391 L 16.917969 15.494141 L 16.210938 14.787109 z " />
      <path d="M 10.738281 2 C 5.9241171 2 2 5.9208728 2 10.732422 C 2 15.543919 5.9241078 19.464844 10.738281 19.464844 C 11.74293 19.464844 12.71217 19.295954 13.613281 18.982422 C 17.030228 17.793531 19.478516 14.539843 19.478516 10.732422 C 19.478516 5.9208619 15.552472 2 10.738281 2 z M 10.738281 4 C 14.472491 4 17.478516 7.0026619 17.478516 10.732422 C 17.478516 13.683785 15.587013 16.176722 12.957031 17.091797 C 12.263456 17.333119 11.51755 17.464844 10.738281 17.464844 C 7.0041147 17.464844 4 14.462125 4 10.732422 C 4 7.0026509 7.0041054 4 10.738281 4 z" />
    </SvgIcon>
  );
}

export function Chevron(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m 11.9999,15.9999 c -0.256,0 -0.512,-0.098 -0.707,-0.293 l -6.70696,-6.70696 1.414,-1.414 5.99996,5.99996 6,-5.99996 1.414,1.414 -6.707,6.70696 c -0.195,0.195 -0.451,0.293 -0.707,0.293 z" />
    </SvgIcon>
  );
}

export function GroupIcon(props: SvgIconProps) {
  return (
    <SvgIcon>
      <path d="M10 13.125C7.24333 13.125 5 10.8817 5 8.125C5 5.36833 7.24333 3.125 10 3.125C12.7567 3.125 15 5.36833 15 8.125C15 10.8817 12.7567 13.125 10 13.125ZM10 4.375C7.9325 4.375 6.25 6.0575 6.25 8.125C6.25 10.1925 7.9325 11.875 10 11.875C12.0675 11.875 13.75 10.1925 13.75 8.125C13.75 6.0575 12.0675 4.375 10 4.375Z" />
      <path d="M10 20C7.76917 20 5.6575 19.2767 3.89 17.9083C3.8725 17.8983 3.835 17.8767 3.79833 17.84C3.79417 17.8358 3.78917 17.8308 3.785 17.8258C1.37917 15.9117 0 13.0617 0 10C0 4.48583 4.48583 0 10 0C15.5142 0 20 4.48583 20 10C20 13.0617 18.6208 15.9117 16.2142 17.8258C16.21 17.8308 16.2058 17.835 16.2017 17.8392C16.1658 17.875 16.1292 17.8967 16.1083 17.9092C14.3425 19.2767 12.2308 20 10 20ZM5.23667 17.3342C6.65833 18.2617 8.29667 18.75 10 18.75C11.7042 18.75 13.3425 18.2617 14.7642 17.3342C13.4275 16.2325 11.7508 15.63 10.0008 15.63C8.25083 15.6292 6.57417 16.2325 5.23667 17.3342ZM10 1.25C5.175 1.25 1.25 5.175 1.25 10C1.25 12.5192 2.32833 14.8958 4.21833 16.5617C5.81417 15.1533 7.85667 14.3808 10 14.3808C12.1442 14.3808 14.1858 15.1533 15.7817 16.5608C17.6717 14.8942 18.75 12.5183 18.75 9.99917C18.75 5.175 14.825 1.25 10 1.25Z" />
    </SvgIcon>
  );
}

export function HelpIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 10.224609 4.2578125 C 9.6303007 4.2167213 9.0349752 4.339481 8.5058594 4.6132812 C 7.9767451 4.887074 7.5343478 5.301639 7.2246094 5.8105469 C 6.9148833 6.3194559 6.750117 6.9042484 6.75 7.5 A 0.75 0.75 0 0 0 7.5 8.25 A 0.75 0.75 0 0 0 8.25 7.5 C 8.250063 7.1791316 8.3390455 6.8639347 8.5058594 6.5898438 C 8.672681 6.3157517 8.9103268 6.0927798 9.1953125 5.9453125 C 9.4802767 5.7978528 9.8010424 5.7317775 10.121094 5.7539062 C 10.44131 5.7760476 10.747767 5.8869688 11.009766 6.0722656 C 11.271722 6.2575252 11.47798 6.5102531 11.605469 6.8046875 C 11.733001 7.0992311 11.778467 7.4225198 11.734375 7.7402344 C 11.690272 8.0580298 11.558328 8.3587676 11.355469 8.6074219 C 11.152548 8.8560593 10.886504 9.0434342 10.583984 9.1503906 C 10.194006 9.2882748 9.8559503 9.544832 9.6171875 9.8828125 C 9.3790076 10.220424 9.2497764 10.624124 9.25 11.037109 L 9.25 11.875 A 0.75 0.75 0 0 0 10 12.625 A 0.75 0.75 0 0 0 10.75 11.875 L 10.75 11.037109 C 10.749944 10.93353 10.782083 10.832518 10.841797 10.748047 C 10.901434 10.663627 10.986363 10.598969 11.083984 10.564453 C 11.645665 10.36587 12.140899 10.01623 12.517578 9.5546875 C 12.894119 9.0931418 13.136853 8.5373902 13.21875 7.9472656 C 13.300658 7.3570602 13.219089 6.7555808 12.982422 6.2089844 C 12.74571 5.6622988 12.363397 5.1916766 11.876953 4.8476562 C 11.390552 4.5036531 10.818993 4.2989111 10.224609 4.2578125 z " />
      <path d="M 10 13.625 C 9.7901557 13.625 9.585189 13.687522 9.4101562 13.804688 C 9.2352138 13.921452 9.0979669 14.087161 9.0175781 14.28125 C 8.9372376 14.475222 8.9161914 14.688807 8.9570312 14.894531 C 8.9980203 15.100496 9.099364 15.290804 9.2480469 15.439453 C 9.396842 15.588248 9.5877748 15.687558 9.7929688 15.728516 C 9.9994001 15.76972 10.211951 15.748615 10.40625 15.667969 L 10.40625 15.669922 C 10.601107 15.589386 10.766171 15.452137 10.882812 15.277344 C 10.999881 15.102421 11.0625 14.897358 11.0625 14.6875 C 11.0625 14.406168 10.95113 14.134724 10.751953 13.935547 C 10.552776 13.73637 10.281332 13.625 10 13.625 z M 10.085938 14.257812 C 10.169891 14.27457 10.247199 14.317511 10.308594 14.378906 C 10.369857 14.440155 10.412818 14.516798 10.429688 14.601562 C 10.446427 14.685887 10.437337 14.775697 10.404297 14.855469 C 10.371208 14.935358 10.31435 15.002617 10.242188 15.050781 C 10.170278 15.098462 10.085593 15.125 10 15.125 C 9.8844682 15.125 9.7733829 15.07807 9.6914062 14.996094 C 9.6094296 14.914117 9.5625 14.803032 9.5625 14.6875 C 9.5625 14.601873 9.5891313 14.517096 9.6367188 14.445312 C 9.6844768 14.373745 9.7519944 14.316613 9.8320312 14.283203 C 9.9121299 14.249957 10.000746 14.240808 10.085938 14.257812 z " />
      <path d="M 10 -0.125 C 4.417 -0.125 -0.125 4.417 -0.125 10 C -0.125 15.583028 4.4170029 20.125 10 20.125 C 15.583025 20.125 20.125 15.583025 20.125 10 C 20.125 4.4170029 15.583028 -0.125 10 -0.125 z M 10 1.375 C 14.772372 1.375 18.625 5.2276571 18.625 10 C 18.625 14.772375 14.772375 18.625 10 18.625 C 5.2276571 18.625 1.375 14.772372 1.375 10 C 1.375 5.22766 5.22766 1.375 10 1.375 z " />
    </SvgIcon>
  );
}

export function StarFilledIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12 2L14.4697 9.60081H22.4616L15.996 14.2984L18.4656 21.8992L12 17.2016L5.53436 21.8992L8.00402 14.2984L1.53838 9.60081H9.53035L12 2Z" />
    </SvgIcon>
  );
}

export function ViewIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 12 4.25 C 5.4166667 4.25 1.3496094 11.626953 1.3496094 11.626953 A 0.750075 0.750075 0 0 0 1.3496094 12.373047 C 1.3496094 12.373047 5.4166667 19.75 12 19.75 C 18.583333 19.75 22.650391 12.373047 22.650391 12.373047 A 0.750075 0.750075 0 0 0 22.650391 11.626953 C 22.650391 11.626953 18.583333 4.25 12 4.25 z M 12 5.75 C 17.112037 5.75 20.642631 11.336578 21.052734 12 C 20.642631 12.663422 17.112037 18.25 12 18.25 C 6.8879628 18.25 3.3573686 12.663422 2.9472656 12 C 3.3573686 11.336578 6.8879628 5.75 12 5.75 z" />
      <path d="M 12 8.25 C 9.9377949 8.25 8.25 9.9377949 8.25 12 C 8.25 14.062205 9.9377949 15.75 12 15.75 C 14.062205 15.75 15.75 14.062205 15.75 12 C 15.75 9.9377949 14.062205 8.25 12 8.25 z M 12 9.75 C 13.251595 9.75 14.25 10.748405 14.25 12 C 14.25 13.251595 13.251595 14.25 12 14.25 C 10.748405 14.25 9.75 13.251595 9.75 12 C 9.75 10.748405 10.748405 9.75 12 9.75 z" />
    </SvgIcon>
  );
}

export function AddIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 12 6.75 A 0.75 0.75 0 0 0 11.25 7.5 L 11.25 16.5 A 0.75 0.75 0 0 0 12 17.25 A 0.75 0.75 0 0 0 12.75 16.5 L 12.75 7.5 A 0.75 0.75 0 0 0 12 6.75 z " />
      <path d="M 7.5 11.25 A 0.75 0.75 0 0 0 6.75 12 A 0.75 0.75 0 0 0 7.5 12.75 L 16.5 12.75 A 0.75 0.75 0 0 0 17.25 12 A 0.75 0.75 0 0 0 16.5 11.25 L 7.5 11.25 z " />
      <path d="M 12 0 C 5.3814694 0 0 5.3814694 0 12 C 0 18.618531 5.3814694 24 12 24 C 18.618531 24 24 18.618531 24 12 C 24 5.3814694 18.618531 0 12 0 z M 12 1.5 C 17.807869 1.5 22.5 6.1921306 22.5 12 C 22.5 17.807869 17.807869 22.5 12 22.5 C 6.1921306 22.5 1.5 17.807869 1.5 12 C 1.5 6.1921306 6.1921306 1.5 12 1.5 z " />
    </SvgIcon>
  );
}

export function EditIconOutlined(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 12.057085,0 C 5.4015413,0 0,5.4015413 0,12.057085 c 0,6.655544 5.4015413,12.057086 12.057085,12.057086 6.655544,0 12.057086,-5.401542 12.057086,-12.057086 C 24.114171,5.4015413 18.712629,0 12.057085,0 Z m 0,0.57414693 c 6.345252,0 11.482939,5.13768647 11.482939,11.48293807 0,6.345252 -5.137687,11.482939 -11.482939,11.482939 -6.3452516,0 -11.48293807,-5.137687 -11.48293807,-11.482939 0,-6.3452516 5.13768647,-11.48293807 11.48293807,-11.48293807 z" />
      <path d="m 14.584571,7.0138346 c -0.287241,0 -0.562895,0.1145608 -0.766019,0.317591 l -6.1125041,6.1125034 -0.828643,3.726099 3.7260991,-0.828643 0.08834,-0.08834 6.024159,-6.02416 c 0.20303,-0.203124 0.317591,-0.4787784 0.317591,-0.7660192 0,-0.2872609 -0.114608,-0.5629424 -0.317591,-0.7660201 v -0.00112 L 15.351709,7.3314256 h -0.0011 C 15.147531,7.1284423 14.87185,7.0138346 14.584589,7.0138346 Z m 0,0.8588368 c 0.05982,0 0.117593,0.023678 0.159914,0.065978 l 1.364298,1.3654167 c 0.04183,0.04224 0.06598,0.099328 0.06598,0.1587955 0,0.059729 -0.02372,0.1165218 -0.06598,0.1587955 l -5.935815,5.9358149 -2.1638669,0.48086 0.4808594,-2.163867 5.9358155,-5.9358156 c 0.04227,-0.042254 0.09907,-0.065978 0.158795,-0.065978 z" />
      <path d="m 13.636272,8.1209291 -0.607225,0.6072246 2.290232,2.2902313 0.607225,-0.607224 z" />
    </SvgIcon>
  );
}

export function ChartIconOutlined(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 12.009895,0 C 5.3803999,0 0,5.3803999 0,12.009895 c 0,6.629494 5.3803999,12.009894 12.009895,12.009894 6.629494,0 12.009894,-5.3804 12.009894,-12.009894 C 24.019789,5.3803999 18.639389,0 12.009895,0 Z m 0,0.57189974 c 6.320416,0 11.437994,5.11757776 11.437994,11.43799526 0,6.320416 -5.117578,11.437994 -11.437994,11.437994 -6.3204175,0 -11.43799526,-5.117578 -11.43799526,-11.437994 0,-6.3204175 5.11757776,-11.43799526 11.43799526,-11.43799526 z" />
      <path d="m 7.4346966,16.156168 a 0.42892481,0.42892481 0 0 0 -0.4289248,0.428924 0.42892481,0.42892481 0 0 0 0.4289248,0.428925 h 9.1503954 a 0.42892481,0.42892481 0 0 0 0.428925,-0.428925 0.42892481,0.42892481 0 0 0 -0.428925,-0.428924 z" />
      <path d="M 9.1503957,8.7214709 A 0.42892481,0.42892481 0 0 0 8.7214709,9.1503957 v 5.1470973 a 0.42892481,0.42892481 0 0 0 0.4289248,0.428925 0.42892481,0.42892481 0 0 0 0.4289249,-0.428925 V 9.1503957 A 0.42892481,0.42892481 0 0 0 9.1503957,8.7214709 Z" />
      <path d="m 12.009894,7.0057718 a 0.42892481,0.42892481 0 0 0 -0.428925,0.4289248 v 6.8627964 a 0.42892481,0.42892481 0 0 0 0.428925,0.428925 0.42892481,0.42892481 0 0 0 0.428926,-0.428925 V 7.4346966 A 0.42892481,0.42892481 0 0 0 12.009894,7.0057718 Z" />
      <path d="m 14.869393,10.43717 a 0.42892481,0.42892481 0 0 0 -0.428925,0.428925 v 3.431398 a 0.42892481,0.42892481 0 0 0 0.428925,0.428925 0.42892481,0.42892481 0 0 0 0.428925,-0.428925 V 10.866095 A 0.42892481,0.42892481 0 0 0 14.869393,10.43717 Z" />
    </SvgIcon>
  );
}

export function EditIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 14.816406 5.1835938 L 13.755859 6.2441406 L 17.755859 10.244141 L 18.816406 9.1835938 L 14.816406 5.1835938 z " />
      <path d="M 16.472656 3.25 C 15.971042 3.25 15.489574 3.4499452 15.134766 3.8046875 L 4.4589844 14.480469 L 3.0117188 20.988281 L 9.5195312 19.541016 L 9.6738281 19.386719 L 20.195312 8.8652344 C 20.549836 8.5105124 20.75 8.0290408 20.75 7.5273438 C 20.75 7.0256467 20.549821 6.5441772 20.195312 6.1894531 L 20.195312 6.1875 L 17.8125 3.8046875 C 17.457774 3.4500277 16.974305 3.25 16.472656 3.25 z M 16.472656 4.75 C 16.577208 4.75 16.678079 4.7913742 16.751953 4.8652344 L 19.134766 7.2480469 C 19.208657 7.3219828 19.25 7.4229408 19.25 7.5273438 C 19.25 7.6317467 19.208642 7.7307695 19.134766 7.8046875 L 8.7675781 18.171875 L 4.9882812 19.011719 L 5.828125 15.232422 L 16.195312 4.8652344 C 16.269104 4.7914567 16.36827 4.75 16.472656 4.75 z " />
    </SvgIcon>
  );
}

/**
 * Alias for CloseIcon
 */
export function ClearIcon(props: SvgIconProps) {
  return CloseIcon(props);
}

export function CloseIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 6.5292969 5.46875 L 5.46875 6.5292969 L 17.46875 18.53125 L 18.53125 17.46875 L 6.5292969 5.46875 z" />
      <path d="M 17.46875 5.46875 L 5.46875 17.470703 L 6.5292969 18.53125 L 18.53125 6.53125 L 17.46875 5.46875 z" />
    </SvgIcon>
  );
}

export function CheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 18.3,6.3 9,15.6 5.7,12.3 c -0.4,-0.4 -1,-0.4 -1.4,0 -0.4,0.4 -0.4,1 0,1.4 l 4,4 C 8.5,17.9 8.7,18 9,18 9.3,18 9.5,17.9 9.7,17.7 l 10,-10 c 0.4,-0.4 0.4,-1 0,-1.4 -0.4,-0.4 -1,-0.4 -1.4,0 z" />
    </SvgIcon>
  );
}

export function BackIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 9.0019531 3.9394531 L 8.4707031 4.46875 L 0.93945312 12 L 8.4707031 19.53125 L 9.0019531 20.060547 L 10.0625 19 L 9.53125 18.46875 L 3.0625 12 L 9.53125 5.53125 L 10.0625 5 L 9.0019531 3.9394531 z" />
      <path d="M 2.0019531 11.25 L 2.0019531 12.75 L 22.001953 12.75 L 22.001953 11.25 L 2.0019531 11.25 z" />
    </SvgIcon>
  );
}

export function ForwardIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 13.939453 3.9394531 L 12.878906 5 L 13.410156 5.53125 L 19.878906 12 L 13.410156 18.46875 L 12.878906 19 L 13.939453 20.060547 L 14.470703 19.53125 L 22.001953 12 L 14.470703 4.46875 L 13.939453 3.9394531 z" />
      <path d="M 2.0019531 11.25 L 2.0019531 12.75 L 22.001953 12.75 L 22.001953 11.25 L 2.0019531 11.25 z" />
    </SvgIcon>
  );
}

export function DeleteIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 5.25 5.25 A 0.750075 0.750075 0 0 0 4.5 6 L 4.5 19.5 C 4.5 20.733742 5.5162249 21.75 6.75 21.75 L 17.25 21.75 C 18.48376 21.75 19.5 20.73376 19.5 19.5 L 19.5 6 A 0.750075 0.750075 0 0 0 18.75 5.25 L 5.25 5.25 z M 6 6.75 L 18 6.75 L 18 19.5 C 18 19.92304 17.67304 20.25 17.25 20.25 L 6.75 20.25 C 6.3269151 20.25 6 19.923058 6 19.5 L 6 6.75 z" />
      <path d="M 9.75 9.75 A 0.75 0.75 0 0 0 9 10.5 L 9 16.5 A 0.75 0.75 0 0 0 9.75 17.25 A 0.75 0.75 0 0 0 10.5 16.5 L 10.5 10.5 A 0.75 0.75 0 0 0 9.75 9.75 z" />
      <path d="M 14.25 9.75 A 0.75 0.75 0 0 0 13.5 10.5 L 13.5 16.5 A 0.75 0.75 0 0 0 14.25 17.25 A 0.75 0.75 0 0 0 15 16.5 L 15 10.5 A 0.75 0.75 0 0 0 14.25 9.75 z" />
      <path d="M 2.25 5.25 A 0.75 0.75 0 0 0 1.5 6 A 0.75 0.75 0 0 0 2.25 6.75 L 21.75 6.75 A 0.75 0.75 0 0 0 22.5 6 A 0.75 0.75 0 0 0 21.75 5.25 L 2.25 5.25 z" />
      <path d="M 9.75 2.25 C 8.5162431 2.25 7.5 3.2662431 7.5 4.5 L 7.5 6 A 0.750075 0.750075 0 0 0 8.25 6.75 L 15.75 6.75 A 0.750075 0.750075 0 0 0 16.5 6 L 16.5 4.5 C 16.5 3.2662249 15.483742 2.25 14.25 2.25 L 9.75 2.25 z M 9.75 3.75 L 14.25 3.75 C 14.673058 3.75 15 4.0769151 15 4.5 L 15 5.25 L 9 5.25 L 9 4.5 C 9 4.0768969 9.3268969 3.75 9.75 3.75 z " />
    </SvgIcon>
  );
}

export function ReloadIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 11.683594 3.2578125 C 10.769099 3.2894898 9.8514601 3.460774 8.9589844 3.78125 C 5.3891014 5.0631548 3.0024747 8.4530434 3 12.246094 L 3 14.5 A 0.75 0.75 0 0 0 3.75 15.25 A 0.75 0.75 0 0 0 4.5 14.5 L 4.5 12.248047 L 4.5 12.246094 C 4.5028907 9.0812287 6.4860477 6.2630116 9.4648438 5.1933594 C 12.444281 4.1234835 15.767818 5.0353549 17.783203 7.4765625 A 0.75 0.75 0 0 0 18.839844 7.578125 A 0.75 0.75 0 0 0 18.939453 6.5234375 C 17.128342 4.3296632 14.427079 3.1627806 11.683594 3.2578125 z " />
      <path d="M 20.25 10.5 A 0.75 0.75 0 0 0 19.5 11.25 L 19.5 12.75 C 19.499454 15.825115 17.627283 18.583455 14.769531 19.71875 C 11.911678 20.854049 8.6554936 20.132877 6.5449219 17.896484 A 0.75 0.75 0 0 0 5.484375 17.865234 A 0.75 0.75 0 0 0 5.4550781 18.925781 C 7.9843064 21.605789 11.897519 22.473782 15.322266 21.113281 C 18.746914 19.752776 20.999346 16.435085 21 12.75 L 21 11.25 A 0.75 0.75 0 0 0 20.25 10.5 z " />
      <path d="M 0.75585938 10.748047 A 0.75 0.75 0 0 0 0.21875 10.966797 A 0.75 0.75 0 0 0 0.21875 12.027344 L 3.21875 15.027344 A 0.750075 0.750075 0 0 0 4.28125 15.027344 L 7.28125 12.027344 A 0.75 0.75 0 0 0 7.28125 10.966797 A 0.75 0.75 0 0 0 6.21875 10.966797 L 3.75 13.435547 L 1.28125 10.966797 A 0.75 0.75 0 0 0 0.75585938 10.748047 z " />
      <path d="M 20.255859 10.5 A 0.750075 0.750075 0 0 0 19.71875 10.71875 L 16.71875 13.71875 A 0.75 0.75 0 0 0 16.71875 14.78125 A 0.75 0.75 0 0 0 17.78125 14.78125 L 20.25 12.3125 L 22.71875 14.78125 A 0.75 0.75 0 0 0 23.78125 14.78125 A 0.75 0.75 0 0 0 23.78125 13.71875 L 20.78125 10.71875 A 0.750075 0.750075 0 0 0 20.255859 10.5 z " />
    </SvgIcon>
  );
}
