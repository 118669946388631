import React from "react";
import { styled } from '@mui/material/styles';
import { Divider, List, ListItem, ListItemText, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { updateComponentInView } from "../../store/admin/admin-slice";
import { useDispatch } from "react-redux";

const PREFIX = 'NavigationPanel';
const classes = {
  active: `${PREFIX}-active`,
  linkColor: `${PREFIX}-linkColor`,
  listItem: `${PREFIX}-listItem`,
  navIcon: `${PREFIX}-navIcon`
};
const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.active}`]: {
    fontWeight: "bolder",
  },

  [`& .${classes.linkColor}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.listItem}`]: {
    fontSize: theme.typography.body2.fontSize,
  },

  [`& .${classes.navIcon}`]: {
    marginRight: 14,
    display: "flex",
  }
}));

export type LinkElement = {
  name: string;
  active?: boolean;
  icon: JSX.Element;
};

export default React.memo(function NavigationPanel(props: { components: Array<Array<LinkElement>> }) {
  const dispatch = useDispatch();
  return (
    <StyledPaper>
      <List>
        {props.components.map((group, index) => {
          const navLinks = group.map((it) => {
            return (
              <Link
                key={it.name}
                className={classes.linkColor}
                to={`/administration/${encodeURIComponent(it.name.toLowerCase())}`}
                onClick={() => {
                  dispatch(updateComponentInView(it.name));
                }}
              >
                <ListItem className={classNames(classes.listItem, { [classes.active]: it.active })} selected={it.active}>
                  <span className={classes.navIcon}>{it.icon}</span>
                  <ListItemText primary={it.name} disableTypography />
                </ListItem>
              </Link>
            );
          });

          const isLast = index + 1 == props.components.length;

          return (
            <div key={index}>
              {navLinks}
              {!isLast && <Divider style={{ marginTop: 4, marginBottom: 4 }} />}
            </div>
          );
        })}
      </List>
    </StyledPaper>
  );
});
