import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/AppState";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import ConfirmDialog from "../shared/ConfirmDialog";
import { EditableProductLineProps, ProductLine } from "../../types/ProductLine";
import { request } from "../../modules/client";
import { baseUrl } from "../../store/apiUrlProvider";
import { showAlert, showErrorDialog } from "../../store/app/app-slice";
import { hideLoadingScreen, showLoadingScreen } from "../../store/loading/loading-slice";
import { updateProductLine } from "../../store/masterData/masterData-slice";
import PanelDescriptionHeader from "./PanelDescriptionHeader";

/**
 *  Simple component that shows the ProductLines with various flags and allows admins to toggle them.
 */
export default function ProductLinePanel() {
  const dispatch = useDispatch();
  const { productLines, canWriteAdministration } = useSelector((state: AppState) => {
    return {
      productLines: state.masterData.productLines,
      canWriteAdministration: state.user.isAdmin,
    };
  });

  // holds the 'clicked' productLine. That is: the productline, where the clicked flag already has been flipped.
  const [clickedProductLine, setClickedProductLine] = React.useState<ProductLine | null>(null);

  const save = () => {
    if (clickedProductLine) {
      dispatch(showLoadingScreen());
      saveProductLine(clickedProductLine)
        .then((responseProductLine) => {
          dispatch(hideLoadingScreen());
          setClickedProductLine(null);
          dispatch(showAlert("Updated Productline", { type: "success" }));
          // update masterdata entry
          dispatch(updateProductLine({ productLine: responseProductLine }));
        })
        .catch((err) => {
          dispatch(hideLoadingScreen());
          dispatch(showErrorDialog({ reason: "Could not update productline. Cause:" + err }));
        });
    } else {
      console.log("No 'clickedProductline within the save function. this should never happen.");
    }
  };

  return (
    <div>
      <ConfirmDialog
        open={!!clickedProductLine}
        title="Please confirm change"
        handleCancel={() => {
          setClickedProductLine(null);
        }}
        handleOk={save}
      >
        This stores the change in the productline {clickedProductLine?.ShortName}.
      </ConfirmDialog>
      <PanelDescriptionHeader>View and change flags on Product Lines</PanelDescriptionHeader>
      <div>
        <Table style={{ width: "85em", marginTop: "2em" }}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={1} />
              <TableCell colSpan={4}>DAISY Management</TableCell>
              <TableCell align="left" colSpan={3}>
                Breakdown Sort Order by Weight
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: "10em" }}>Name</TableCell>
              <TableCell style={{ width: "8em" }}>Show</TableCell>
              <TableCell style={{ width: "11em" }}>Allow Upload</TableCell>
              <TableCell style={{ width: "12em" }}>Use Benchmarks</TableCell>
              <TableCell style={{ width: "10em" }}>Asset Class</TableCell>
              <TableCell style={{ width: "10em" }}>Equity Region</TableCell>
              <TableCell style={{ width: "10em" }}>Equity Sector</TableCell>
              <TableCell style={{ width: "10em" }}>Risk Currency</TableCell>
              <TableCell style={{ width: "10em" }}>Requires TAA</TableCell>
              <TableCell style={{ width: "12em" }}>Requires MOPO</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productLines.map((pl) => (
              <Row
                key={pl.Id}
                productLine={pl}
                callback={setClickedProductLine}
                canWriteAdministration={canWriteAdministration}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

const Row = React.memo(
  (props: { productLine: ProductLine; callback: (value: ProductLine) => void; canWriteAdministration: boolean }) => {
    const { productLine, callback, canWriteAdministration } = props;
    return (
      <TableRow key={productLine.Id}>
        <TableCell>{productLine.ShortName}</TableCell>
        <TableCell>
          <Symbol
            productLine={productLine}
            property="ShowInDaisy"
            canWriteAdministration={canWriteAdministration}
            onClick={callback}
          />
        </TableCell>
        <TableCell>
          <Symbol
            productLine={productLine}
            property="AllowUploadInDaisy"
            canWriteAdministration={canWriteAdministration}
            onClick={callback}
          />
        </TableCell>
        <TableCell>
          <Symbol
            productLine={productLine}
            property="UseBenchmarksInDaisy"
            canWriteAdministration={canWriteAdministration}
            onClick={callback}
          />
        </TableCell>
        <TableCell>
          <Symbol
            productLine={productLine}
            property="OrderAssetClassBreakdownByWeight"
            canWriteAdministration={canWriteAdministration}
            onClick={callback}
          />
        </TableCell>
        <TableCell>
          <Symbol
            productLine={productLine}
            property="OrderEquityRegionBreakdownByWeight"
            canWriteAdministration={canWriteAdministration}
            onClick={callback}
          />
        </TableCell>
        <TableCell>
          <Symbol
            productLine={productLine}
            property="OrderEquitySectorBreakdownByWeight"
            canWriteAdministration={canWriteAdministration}
            onClick={callback}
          />
        </TableCell>
        <TableCell>
          <Symbol
            productLine={productLine}
            property="OrderCurrencyBreakdownByWeight"
            canWriteAdministration={canWriteAdministration}
            onClick={callback}
          />
        </TableCell>
        <TableCell>
          <Symbol
            productLine={productLine}
            property="RequiresTaa"
            canWriteAdministration={canWriteAdministration}
            onClick={callback}
          />
        </TableCell>
        <TableCell>
          <Symbol
            productLine={productLine}
            property="RequiresMopo"
            canWriteAdministration={canWriteAdministration}
            onClick={callback}
          />
        </TableCell>
      </TableRow>
    );
  }
);

/**
 * A single symbol for a flag.
 * Depending on 'canWriteAdministration' either an IconButton or an Icon.
 */
const Symbol = React.memo(
  (props: {
    productLine: ProductLine;
    onClick: (pl: ProductLine) => void;
    canWriteAdministration: boolean;
    property: keyof EditableProductLineProps;
  }) => {
    let value = props.productLine[props.property];
    var icon = value ? <CheckIcon /> : <RemoveIcon />;
    const onClick = () => {
      let updated = { ...props.productLine };
      updated[props.property] = !value;
      props.onClick(updated);
    };
    if (props.canWriteAdministration) {
      return (
        <Tooltip title="Click to change this flag">
          <IconButton onClick={onClick} size="small">
            {icon}
          </IconButton>
        </Tooltip>
      );
    } else {
      return icon;
    }
  }
);

async function saveProductLine(productLine: ProductLine): Promise<ProductLine> {
  var options = {
    method: "POST",
    payload: productLine,
  };

  return request(`${baseUrl()}api/masterdata/productLine`, options);
}
