import * as React from "react";
import PanelDescriptionHeader from "../PanelDescriptionHeader";
import CreateAllocationDialog from "../create-allocation-dialog";
import StatsPanel from "../StatsPanel";
import InEditAllocations from "./InEditAllocations";
import usePeriodicAllocationInfo from "./usePeriodicAllocationInfo";
import { Box, Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import AllocationType from "../../../types/AllocationType";
import { AllocationReference } from "../../../types/AllocationReference";
import { Link } from "react-router-dom";

export default function AllocationPanel() {
  const [info, refresh] = usePeriodicAllocationInfo();

  const invalidMopos = info.ContainingDeletedInstruments.filter((a) => a.AllocationType === AllocationType.ModelPortfolio);
  const invalidAllos = info.ContainingDeletedInstruments.filter(
    (a) => a.AllocationType !== AllocationType.ModelPortfolio && a.AllocationType !== AllocationType.Unknown
  );

  const stats = [
    { value: info.InEdit.length, label: "allocations currently in edit" },
    { value: invalidMopos.length, label: "MOPOs with deleted instruments", isError: invalidMopos.length > 0 },
    { value: invalidAllos.length, label: "TAAs/TCAs/SAAs with deleted instruments", isError: invalidAllos.length > 0 },
  ];

  return (
    <>
      <PanelDescriptionHeader button={<CreateAllocationDialog />}>
        See allocations that are currently in edit and which contain deleted instruments.
        <br />
        Create new allocation objects.
      </PanelDescriptionHeader>
      <StatsPanel entries={stats} refreshCallback={refresh} />
      <List dense>
        <ListItem>
          Last refresh of displayed data at <strong>&nbsp;{info.RefreshTime}.</strong>
        </ListItem>
      </List>
      {info.InEdit.length > 0 && (
        <>
          {divider}
          <Typography variant="h3">Allocations currently in edit</Typography>
          <InEditAllocations data={info.InEdit} onTimeoutDone={refresh} />
        </>
      )}
      {invalidMopos.length > 0 && (
        <>
          {divider}
          <Typography variant="h3">Model Portfolios containing deleted instruments</Typography>
          <AllocationList allocations={invalidMopos} />
        </>
      )}
      {invalidAllos.length > 0 && (
        <>
          {divider}
          <Typography variant="h3">TAAs/TCAs/SAAs containing deleted instruments</Typography>
          <AllocationList allocations={invalidAllos} />
        </>
      )}
    </>
  );
}

function AllocationList(props: { allocations: AllocationReference[] }) {
  return (
    <List dense style={{ width: "50rem" }}>
      {props.allocations.map((a) => (
        <ListItem key={a.Id} button>
          <ListItemText disableTypography>
            <Link to={"/compare/" + a.Id}>{a.Name}</Link>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
}

const divider = (
  <Box mt={3} mb={3}>
    <Divider />
  </Box>
);
