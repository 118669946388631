import * as React from "react";
import { styled } from '@mui/material/styles';
import { User } from "./types";
import * as api from "./apiRequests";
import { useDispatch } from "react-redux";
import { hideLoadingScreen, showLoadingScreen } from "../../../store/loading/loading-slice";
import { showAlert } from "../../../store/app/app-slice";
import DropdownBase from "../../shared/DropdownBase";

const PREFIX = 'AddGroupMemberInput';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    "& .dropdownbase-input": {
      width: "40rem",
    },
    "& .dropdownbase-menu": {
      width: theme.spacing(46.5),
      maxHeight: "33vh",
      overflowY: "auto",
      overflowX: "hidden",
    },
  }
}));

export default function AddGroupMemberInput(props: { groupId: number; onAdd: () => void }) {
  const { groupId, onAdd } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchResult, setSearchResult] = React.useState<User[]>([]);

  async function triggerSearch(input: Maybe<string>) {
    api.throttledSearch.cancel();
    input = input || "";
    if (input?.length < 2) {
      setSearchResult([]);
      return;
    }
    try {
      setIsLoading(true);
      const response = await api.throttledSearch(groupId, input);
      setSearchResult(response?.users || []);
    } catch (e) {
      console.error("triggerSearch failed", e);
      dispatch(showAlert("Failed to search users", { type: "error" }));
    } finally {
      setIsLoading(false);
    }
  }

  async function selectUser(user: Maybe<User>) {
    if (!user) {
      return;
    }
    dispatch(showLoadingScreen());
    try {
      await api.addGroupMember(groupId, user.UserId);
      onAdd();
    } catch (e) {
      console.error("selectUser failed", e);
      dispatch(showAlert("Failed to add group member", { type: "error" }));
    } finally {
      dispatch(hideLoadingScreen());
    }
  }

  return (
    <Root className={classes.root}>
      <DropdownBase
        label="Add member"
        items={searchResult}
        onInputChange={triggerSearch}
        onSelect={selectUser}
        formatItem={(_) => ""}
        renderMenuItemContent={(i) => <>{userString(i)}</>}
        focusPlaceholder="Search user by name or ID ..."
        isLoading={isLoading}
        showSearchIcon
      />
    </Root>
  );
}

function userString(u: Maybe<User>) {
  return (u && ([u.UserLastName, u.UserFirstName].join(", ") || u.UserLoginAccount)) || "";
}
