import * as React from "react";
import WarningIcon from "@mui/icons-material/Warning";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../store/app/app-slice";

/**
 * Simple Dialog that displays an error.
 * This is useful to show e.g. critical errors when talking with the backend.
 */

function formatError(error: string | Object | undefined) {
  if(!error){
    return {};
  }

  let message = "";
  let title = "Operation could not be completed.";
  if (typeof error === "string") {
    message = error;
  } else {
    // gets returned e.g. by an 'fetch' error
    if ((error as any).message) {
      message = (error as any).message;
    } else {
      message = "" + error;
    }
    if ((error as any).title){
      title = (error as any).title;
    }

  }
  if (message === "Failed to fetch") {
    message = "Problem communicating with the backend.";
  }
  return {message: message, title: title};
}

export default function ErrorDialog(props: {
  error: string | Object | undefined;
  onClose?: () => void;
  hideCloseButton?: boolean;
}) {
  const open = !!props.error;
  const dispatch = useDispatch();
  const formattedMessage = formatError(props.error);
  return (
    <React.Fragment>
      {open && (
        <Dialog open>
          <DialogTitle>
            <WarningIcon color="error" style={{ marginRight: "1em" }} />
            <span>{formattedMessage.title}</span>
            <WarningIcon color="error" style={{ marginLeft: "1em" }} />
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">{formattedMessage.message}</Typography>
          </DialogContent>

          {!props.hideCloseButton && (
            <DialogActions>
              <Button
                autoFocus
                variant="contained"
                onClick={(evt) => {
                  evt.stopPropagation();
                  if (props.onClose) {
                    props.onClose();
                  }
                  dispatch(showErrorDialog({ reason: undefined }));
                }}
              >
                Close
              </Button>
            </DialogActions>
          )}
        </Dialog>
      )}
    </React.Fragment>
  );
}
