import { useStore } from "react-redux";
import { AppState } from "../../store/AppState";
import { CompareViewState } from "./types";

/**
 * Hook giving direct compare state access.
 * Usually used only in async contexts where `useSelector` won't work, e.g. useAllocation.
 */
export default function useCompareState() {
  const store = useStore();
  return {
    /**
     * General purpose selector over current state.
     */
    select<T>(selector: (s: CompareViewState) => T) {
      return selector((store.getState() as AppState).compare);
    },
    /**
     * Convenience selector for an allocation-by-id.
     */
    getAllocation(allocationId: string) {
      const allo = (store.getState() as AppState).compare.allocations.find((a) => a.Id === allocationId);
      if (!allo) {
        throw new Error(`no allocation in store with id '${allocationId}'`);
      }
      return allo;
    },
  };
}
