import Big, { BigType } from "big.js";
import {
  AVERAGE_WEIGHT_KEY,
  DYNAMIC_WEIGHT_KEY,
  PUBLISHED_WEIGHT_KEY,
  SAVED_WEIGHT_KEY,
  Weights,
} from "../../../modules/asset-tree-builder";
import { AllocationCellWeights } from "../types";

export const ZERO = new Big(0);

export function zeroWeights(): AllocationCellWeights {
  return {
    ic: ZERO,
    published: ZERO,
    dynamic: ZERO,
    average: ZERO,
    saved: ZERO,
  };
}

export function fromApi(weights: Weights): AllocationCellWeights & { savedOriginal: string } {
  return {
    ic: ZERO, // will be set later
    published: weights[PUBLISHED_WEIGHT_KEY] || ZERO,
    dynamic: weights[DYNAMIC_WEIGHT_KEY] || ZERO,
    average: weights[AVERAGE_WEIGHT_KEY] || ZERO,
    saved: weights[SAVED_WEIGHT_KEY] || ZERO,
    savedOriginal: (weights[SAVED_WEIGHT_KEY] || ZERO).toFixed(6),
  };
}

export function addWeights(a: AllocationCellWeights, b: AllocationCellWeights): AllocationCellWeights {
  return {
    ic: a.ic.add(b.ic),
    published: a.published.add(b.published),
    dynamic: a.dynamic.add(b.dynamic),
    average: a.average.add(b.average),
    saved: a.saved.add(b.saved),
  };
}

export function areEqualWeights(a: AllocationCellWeights, b: AllocationCellWeights): boolean {
  return (
    a.ic === b.ic && a.published === b.published && a.dynamic === b.dynamic && a.average === b.average && a.saved === b.saved
  );
}

export function isZeroOrUnset(weight: BigType) {
  return !weight?.eq || weight.eq(ZERO);
}
